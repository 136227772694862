import React from "react";
import useAxios from "../../components/useAxios";

import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { TextField, Autocomplete } from "@mui/material";
import {Button} from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { TreeSelect } from "antd";

import {UserContext} from "../../App"

import moment from 'moment';

// import Sidebar from "./Sidebar";
import UserAlerts from "../../components/UserAlerts";
import DashboardHeader from "../../components/DashboardHeader";
import '../../styles/TreeSelectStyles.css'
import '../../styles/AutocompleteStyles.css'

// import Expense from "./Expense";
import ExpenseNew from "./ExpenseNew";


function ExpenseReportNew() {
    const {response, error, fetchData}  = useAxios();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const navigate = useNavigate();

    const [expenses, setExpenses] = useState([])
    const [description, setDescription] = useState('');
    const [descriptionError, setDescriptionError] = useState(false)


    const [reference, setReference] = useState('');
    const [dt, setDt] = useState(new Date());
    const [memo, setMemo] = useState('');
    const [accountStatus, setAccountStatus] = useState("")
    const user = localStorage.getItem('userID');

    const [amt, setAmt] = useState('');
    // const [txnLines, setTxnLines] = useState([{}]);
    const [txnLines, setTxnLines] = useState([]);

    // const [rows, setRows] = useState([{}]);
    // const [balancingError, setBalancingError] = useState(false);


    const { data: clientProfile } = useAxios({
        url: `/clientprofiles/${localStorage.getItem('clientID')}`,
        method: 'get'
    })  

    const addExpense = (newExpense) => {
        // console.log("WE ARE HERE",newExpense)
        setTxnLines([...txnLines, newExpense]);
        // setExpenses([...expenses, newExpense]);
        setIsModalOpen(false); // Close the modal after adding the expense
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        if (description === '') {
            setDescriptionError(true)
        }

        // if (name && mobile && email && idNumberError === false ) {
        if (description && accountStatus !== "error") {
            const client = clientProfile.id
            // const account = parseInt(acct)
            // const account = acct
            const employee = 1
            const txn_lines = txnLines;

            const expenseReport = {client, employee, reference, description, txn_lines, memo, user};
            console.log(expenseReport)

            fetchData({
                url: '/hrm/expense-reports/',
                method: 'post',
                data: expenseReport,
              })
        } 
    
    }

    useEffect(()=>{
        if (clientProfile.id){
            const reportNo = clientProfile.expense_report_prefix+String(clientProfile.expense_report_no).padStart(6, '0')
            setReference(reportNo)
        }

    },[clientProfile.id])

    useEffect(() => {
        if (response.status === 201){
            navigate('/dashboard/expense-reports/');
        }    
    }, [ response.status]); 

    return(

        <div className="dashboard-main">
            <section className="dashboard-content">

            <section className="dashboard-form dashboard-form-compact">
            {/* <section className="dashboard-form"> */}
                <UserAlerts error={error} />
                <DashboardHeader dashboardTitle="Add Expense Report" dashboardPreTitle="HRM" />

                <form noValidate autoComplete="off" onSubmit={handleSubmit}>

                    <div className="grouped-fields-light">
                        <TextField className="custom-input"
                            onChange={(e) => {
                                setDescriptionError(false)
                                setDescription(e.target.value)
                            }}
                            label="Description" 
                            variant="outlined" 
                            fullWidth
                            required
                            error={descriptionError}
                        />

                        <div className="two-columns">
                            <TextField className="custom-input"
                                onChange={(e) => {
                                    // setReferenceError(false)
                                    setReference(e.target.value)}
                                }
                                label="Reference"
                                variant="outlined" 
                                fullWidth
                                required
                                value = {reference}
                                // error={referenceError}
                            />

                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DesktopDatePicker
                                    label="Report Date"
                                    value={dt}
                                    onChange={(newDate) => {
                                        setDt(moment(newDate).format("YYYY-MM-DD"));
                                    }}
                                    renderInput={(params) => <TextField fullWidth required className="custom-input" {...params} />}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>
                    <div>

                        <table className="styled-table">
                            <thead>
                                <tr >
                                    {/* <th>ID</th> */}
                                    <th>Type</th>
                                    <th>Date</th>
                                    <th>Description</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            {txnLines && txnLines.map((expense, index) => (
                                <tbody key={index}>
                                    <tr>
                                        <td>{expense.type_name}</td>
                                        <td>{expense.date}</td>
                                        <td>{expense.description}</td>
                                        <td>{(expense.amount).toLocaleString()}</td>
                                    </tr>
                                </tbody>
                            ))}
                        </table>
                        <div className="AddRow">
                            <Button
                                id="AddRowButton"
                                onClick={() => setIsModalOpen(true)}
                                variant="outlined" 
                                size="small"
                                startIcon={<AddCircleOutlineIcon />}
                            >
                                Expense
                            </Button>
                        </div>
                        {isModalOpen && 
                            <ExpenseNew
                                addExpense={addExpense}
                                // onClose={() => setIsModalOpen(false)}
                                isModalOpen={isModalOpen}
                                setIsModalOpen={setIsModalOpen}
                                />
                            }
                    </div>


                    <div className="grouped-fields-light">
                        <TextField className="custom-input"
                            onChange={(e) => setMemo(e.target.value)}
                            label="Memo"
                            variant="outlined"
                            multiline
                            rows={3}
                            fullWidth
                        />

                </div>

                <div className="button-border-top">
                    <button id="submit" className="btn btn-primary form-buttons" type="submit">
                        Save
                    </button>
                </div>


                </form>

            </section>
            </section> 

        </div>

    )

}

export default ExpenseReportNew;
