import { useState, useContext, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";


// import useAxios from "../../components/useAxios";
import authAxios from "../../components/authAxios";

import DashboardHeader from "../../components/DashboardHeader";
import Pagination from '@mui/material/Pagination';
import { Card, CardContent } from "@mui/material";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { useMediaQuery } from 'react-responsive'
// import AccountNew from "./AccountNew";
import CollectionNew from './CollectionNew'
import ThreeDotsMenu from "../../components/ThreeDotsMenu";

import { format } from 'date-fns';


// import { UserContext } from "../../App";


const Collections = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 750px)' })

    //Pagination
    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const convertDateTime = (myDateTime) => {
        return format(new Date(myDateTime), 'dd-MM-yyyy HH:mm');
      };

    const [isModalOpen, setIsModalOpen] = useState(false);
    // const {clientProfile} = useContext(UserContext);

    const fetchCollections = async (page) => {
        const response = await authAxios.get(`/products/collections/?page=${page}`)
        setPageCount(response.data.page_count)
        // setAccountData(response.data)
        return response.data
    }

    const fetchClientProfile = async () => {
        const response = await authAxios.get(`/clientprofiles/${localStorage.getItem('clientProfile')}`);
        return response.data
    }

    const threeSubMenu = [
        {
            id: 1,
            menu: "View",
            url: "/dashboard/account/",

        }, 
    ];

    const {data:collections, status, isPreviousData} = useQuery({
        queryKey: ['collections', page], 
        queryFn: ()=>fetchCollections(page), 
        keepPreviousData : true,
        // refetchOnMount: false
    })

    const {data:clientProfile} = useQuery({
        queryKey: ['clientProfile'], 
        queryFn: ()=>fetchClientProfile(), 
        // cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    })
    

    return (
        <div className="dashboard-main">
            <section className="dashboard-content">
            <>
                <DashboardHeader dashboardTitle="Collections" dashboardPreTitle="Products" />
                <table className="styled-table">

                    <thead>
                        <tr >
                            <th>ID</th>
                            <th>Name</th>
                            <th>Date Created</th>
                            <th>Status</th>
                            <th>No of Products</th>
                            <th></th>
                        </tr>
                    </thead>

                    {collections && collections.results.map(collection => (

                        <tbody key={collection.id}>
                            <tr>
                                <td>{collection.id}</td>
                                <td>{collection.name}</td>
                                {/* <td>{collection.created_at}</td> */}
                                <td>{convertDateTime(collection.created_at)}</td>
                                {collection.is_active?
                                <td>Active</td>
                                :
                                <td>Inactive</td>
                                }

                                <td>{collection.count}</td>
                                {/* <td className='styled-table-menu'>
                                    <ThreeDotsMenu data={collection} threeSubMenu={threeSubMenu} />

                                </td> */}
                            </tr>
                        </tbody>
                    ))}

                </table>


                <Pagination 
                    className="custom-mui-pagination"
                    count={pagecount} 
                    page={page} 
                    onChange={handlePageChange} 
                />

                {isModalOpen &&
                <CollectionNew 
                    isModalOpen={isModalOpen} 
                    setIsModalOpen={setIsModalOpen}
                />
                }

                <Fab 
                    className="fab" 
                    color="primary" 
                    aria-label="add"
                    onClick={()=>{
                        setIsModalOpen(true)
                    }
                    }
                >
                    <AddIcon />
                </Fab> 
            </>
            </section>
        </div>
    );
  };
  
  export default Collections;