import { useState, useContext, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";


// import useAxios from "../../components/useAxios";
import authAxios from "../../components/authAxios";

import DashboardHeader from "../../components/DashboardHeader";
import Pagination from '@mui/material/Pagination';
import { Card, CardContent } from "@mui/material";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { useMediaQuery } from 'react-responsive'
import AccountNew from "./AccountNew";
import ThreeDotsMenu from "../../components/ThreeDotsMenu";

// import { UserContext } from "../../App";


const Accounts = () => {
  
    const isMobile = useMediaQuery({ query: '(max-width: 750px)' })

    //Pagination
    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const [isModalOpen, setIsModalOpen] = useState(false);
    // const {clientProfile} = useContext(UserContext);

    const fetchAccounts = async (page) => {
        const response = await authAxios.get(`/accounting/accounts/?page=${page}`)
        setPageCount(response.data.page_count)
        // setAccountData(response.data)
        return response.data
    }

    const fetchClientProfile = async () => {
        const response = await authAxios.get(`/clientprofiles/${localStorage.getItem('clientProfile')}`);
        return response.data
    }

    const threeSubMenu = [
        {
            id: 1,
            menu: "View",
            url: "/dashboard/account/",

        }, 
    ];
   

    const {data:accounts, status, isPreviousData} = useQuery({
        queryKey: ['accounts', page], 
        queryFn: ()=>fetchAccounts(page), 
        keepPreviousData : true,
        // refetchOnMount: false
    })

    const {data:clientProfile} = useQuery({
        queryKey: ['clientProfile'], 
        queryFn: ()=>fetchClientProfile(), 
        // cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    })
    

    return (
        // <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
        <div className="dashboard-main">
            <section className="dashboard-content">
            <>
                <DashboardHeader dashboardTitle="Chart of Accounts" dashboardPreTitle="Accounting" />

                {isMobile? 
                <>
                
                {/* {accounts && accounts.map(account => (  */}
                {accounts && accounts.results.map(account => (


                <Card className="dashboard-card" key={account.id}>                        
                    <CardContent className="dashboard-card-content">
                        <div className="dashboard-card-title">{account.name}</div>
                        <div className="dashboard-card-subtitle">{account.type_name}</div>   
                        {clientProfile?               
                        <p>Balance: {Number(account.balance).toLocaleString('en-UK', {style: 'currency', currency: clientProfile.currency_code })}</p>
                        :
                        <p>Balance: {Number(account.balance).toLocaleString('en-UK')}</p>
                        }

                        {/* <p>Account: {txn.account}</p>
                        <p>Date: {txn.date}</p>  */}
                    </CardContent>
                </Card>
                ))}

                </>

                :
                <table className="styled-table">

                    <thead>
                        <tr >
                            <th>ID</th>
                            <th>Account Name</th>
                            <th>Type</th>
                            <th>Balance</th>
                            <th></th>
                        </tr>
                    </thead>

                    {/* {accounts && accounts.map(account => ( */}

                    {accounts && accounts.results.map(account => (

                        <tbody key={account.id}>
                            <tr>
                                <td>{account.id}</td>
                                <td>{account.name}</td>
                                <td>{account.type_name}</td>
                                {clientProfile?               
                                <td>{Number(account.balance).toLocaleString('en-UK', { style: 'currency', currency: clientProfile.currency_code })}</td>
                                :
                                <td>{Number(account.balance).toLocaleString('en-UK')}</td>
                                }

                                <td className='styled-table-menu'>
                                    <ThreeDotsMenu data={account} threeSubMenu={threeSubMenu} />

                                </td>
                            </tr>
                        </tbody>
                    ))}
                    

                </table>
                }


                <Pagination 
                    className="custom-mui-pagination"
                    count={pagecount} 
                    page={page} 
                    onChange={handlePageChange} 
                />

                {isModalOpen &&
                <AccountNew 
                    isModalOpen={isModalOpen} 
                    setIsModalOpen={setIsModalOpen}
                />
                }

                <Fab 
                    className="fab" 
                    color="primary" 
                    aria-label="add"
                    onClick={()=>{
                        setIsModalOpen(true)
                    }
                    }
                >
                    <AddIcon />
                </Fab> 
            </>
            </section>
        </div>
    );
  };
  
  export default Accounts;