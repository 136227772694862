import { useState, useEffect } from 'react';
import axios from 'axios';
import authAxios from '../../components/authAxios';
import jwtDecode from 'jwt-decode';
// import { decode } from 'js-base64';

import { useQuery } from '@tanstack/react-query';


const useEbayToken = () => {
  const [ebayAccessToken, setEbayAccessToken] = useState('');
  const [ebayClientConfig, setEbayClientConfig] = useState('');
  // const [ebayRefreshToken, setEbayRefreshToken] = useState('');

  // const [ebayRefreshToken, setEbayRefreshToken] = useState(localStorage.getItem('ebayRefreshToken'));


  const redirectToEbayLogin = () => {
    const auth_url = process.env.REACT_APP_EBAY_AUTH_URL;
    const app_id = process.env.REACT_APP_EBAY_APP_ID;
    const redirect_uri = process.env.REACT_APP_EBAY_REDIRECT_URI;
    const scope = process.env.REACT_APP_EBAY_SCOPE;
    window.location.href = `${auth_url}?client_id=${app_id}&response_type=code&scope=${scope}&redirect_uri=${redirect_uri}`;
  };

  // const { data: clientProfile } = useQuery({
  const { data: ebayConfig } = useQuery({
    // queryKey: ['clientProfile'],
    queryKey: ['ebayConfig'],
    queryFn: async () => {
      // const response = await authAxios.get(`/clientprofiles/${localStorage.getItem('clientProfile')}`);
      const response = await authAxios.get("/ebay/ebay-config/");
      // console.log("RESPONSE",response.data)
      // return response.data.filter(type=>(type.code==="010"||type.code==="020"))
      // return response.data.filter(type=>(type.code==="010"||type.code==="020"))

      // setEbayRefreshToken(response.data.refresh_token)
      // console.log("TEST123",response.data.find(config=>config.client=localStorage.getItem('clientProfile')))
      // const ebayClientConfig = {}
      if (response.data.length>0){
        console.log("SUCCESS", response.data)
        const ebayClientConfig = response.data.find(config=>config.client=localStorage.getItem('clientProfile'))
        if (!ebayClientConfig.refresh_token){
          redirectToEbayLogin();
        }
        // else {
        //   setEbayRefreshToken(ebayClientConfig.refresh_token)
        // }
      }
      else {
      // if (!response.data.length>0 || !ebayRefreshToken){
      //   // console.log("NO DATA")
        redirectToEbayLogin();
      }
      // setEbayRefreshToken(response.data.find(config=>config.client=localStorage.getItem('clientProfile')))

      // if (!ebayRefreshToken || ebayRefreshToken === undefined || ebayRefreshToken === "undefined") {
      //   redirectToEbayLogin();
      // } 
      // return response.data
      return response.data.find(config=>config.client=localStorage.getItem('clientProfile'))
    },
  });

  // useEffect(() => {
  //   // Check if access token and refresh token exist in local storage
  //   const token = localStorage.getItem('ebayAccessToken');
  //   const refreshToken = localStorage.getItem('ebayRefreshToken');

  //   // If access token and refresh token do not exist, redirect user to eBay login page
  //   if (!token || !refreshToken || refreshToken === undefined || refreshToken === "undefined") {
  //     console.log("TOKEN NOT FOUND",token)
  //     redirectToEbayLogin();
  //   } 
  // }, []);


  // const refreshEbayToken = async () => {
  //   try {
  //     const refreshToken = localStorage.getItem('ebayRefreshToken');

  //     // If refresh token is blank, redirect user to eBay login page
  //     if (!refreshToken || refreshToken === undefined || refreshToken === "undefined") {
  //       redirectToEbayLogin();
  //       return null;
  //     }

  //     const response = await axios.post('/ebay/refresh-token/', { refresh_token: refreshToken });
  //     localStorage.setItem('ebayAccessToken', response.data.access_token);
  //     // localStorage.setItem('ebayRefreshToken', response.data.refresh_token);
  //     setEbayAccessToken(response.data.access_token);
  //     // setEbayRefreshToken(response.data.refresh_token);

  //     return response.data.access_token;
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // axios.interceptors.response.use(
  //   response => response,
  //   error => {
  //     if (error.response.status === 401) {
  //       console.log("EBAY TOKEN INTERCEPTOR");
  //       refreshEbayToken();
  //     }
  //     return Promise.reject(error);
  //   }
  // );

  // return { ebayAccessToken, refreshEbayToken };
  // console.log("RETURN > ACCESS:",ebayAccessToken,"REFRESH:", localStorage.getItem('ebayRefreshToken'))
  // return { ebayAccessToken, ebayRefreshToken, refreshEbayToken };
  // return { ebayRefreshToken, ebayConfig };
  return { ebayConfig };

};

export default useEbayToken;