

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from '@tanstack/react-query';

import authAxios from "../../components/authAxios";

// import { Autocomplete, InputLabel } from "@mui/material";
// import { FormControl } from "@mui/material";
// import { MenuItem } from "@mui/material";
// import { Radio } from "@mui/material";
// import { RadioGroup } from "@mui/material";
// import { FormControlLabel } from "@mui/material";
import NumberFormat from "react-number-format";
import { TextField } from "@mui/material";
// import { Select } from "@mui/material";

// import { LocalizationProvider } from "@mui/x-date-pickers";
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import moment from 'moment';


// ANT DESIGN
import { Modal } from 'antd';


import UserAlerts from "../../components/UserAlerts";


// IMAGE UPLOAD ANT DESIGN
// import { message, Upload } from 'antd';
// import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
// import Sidebar from "./Sidebar";
// import Layout from "../../components/Layout";
// import DashboardHeader from "../../components/DashboardHeader";


function CustomerNewModal({isModalOpen, setIsModalOpen, setCustomer}) {
    // const {response, error, fetchData}  = useAxios();
    // const { error, response } = useAxiosInterceptor();
    // const {redirect} = useRedirect(error, response);

    // useEffect(() => {
    //     if (response.status === 201){
    //         navigate('/dashboard/customers/');
    //     }    
    // }, [ response.status]); 


    // const { data: countries } = useAxios({
    //     url: '/countries',
    //     method: 'get'
    // })

    // const { data: contacts } = useAxios({
    //     url: '/contacts',
    //     method: 'get'
    // })

    const [error, setError] = useState('');

    const navigate = useNavigate();
    const queryClient = useQueryClient();



    // const [dt, setDt] = useState(new Date());
    const [dt, setDt] = useState(null);


    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');

    const [website, setWebsite] = useState('');
    const [id_type, setId_type] = useState('');
    const [id_number, setId_number] = useState('');
    const [id_copy, setIdCopy] = useState(null);
    const [id_files, setIdFiles] = useState([]);
    const [gender, setGender] = useState('');
    const [type, setType] = useState('10');
    const user = localStorage.getItem('userID');
    const client = localStorage.getItem('clientID');
    const [tag, setTag] = useState('CUSTOMER');
    const [nameError, setNameError] = useState(false)
    const [mobileError, setMobileError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [idNumberError, setIdNumberError] = useState(false)
    const [addressError, setAddressError] = useState(false);
    const [cityError, setCityError] = useState(false);

    const [isCompany, setIsCompany] = useState(false);
    const [isPerson, setIsPerson] = useState(false);

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleCustomerType = (e) => {
        setType(e.target.value)
        if (e.target.value==="10"){
            // console.log("TRUE")
            setIsPerson(true)
        }
        else {
            console.log("FALSE")
            setIsPerson(false)
        }
    }

    const handleIdNumber = (e) => {
        if (e.target.value.length>9){
            setIdNumberError(true)
        }
        else{
            setIdNumberError(false)
            setId_number(e.target.value)
        }
    }

    // IMAGE UPLOAD ANT DESIGN
    // const [imageUrl, setImageUrl] = useState();
    // const [loading, setLoading] = useState(false);
    // var fileList = []

    const mutation = useMutation({
        mutationFn: (customer) => {
            // return authAxios.post("/contacts/", customer);
            return authAxios.post("/contacts/", customer, {headers: {"Content-Type": "multipart/form-data"}});

        },
        onError: (error, variables, context) => {
            console.log("ERROR",error)

            if (error.response.data.error){
                // If it's an array, display each error message
                if (Array.isArray(error.response.data.error)) {
                    error.response.data.error.map((errorMessage) => (
                        setError(errorMessage)
                    ));
                } else {
                    // Handle single error message
                    setError(error.response.data.error); 
                }
            } else {
                // Fallback to Standard error message
                setError(error.message); 
            }      
        },
        onSuccess: (result) => {
            console.log("RESULT",result.data)
            // console.log("CUSTOMER",customer)
            // setCustomer(customer)
            setCustomer(result.data)

            // queryClient.invalidateQueries(['customers']);
            queryClient.refetchQueries(['customers']);
            setIsModalOpen(false)
        },
      })

    const handleSubmit = (e) => {
        e.preventDefault();

        if (name === '') {
            setNameError(true)
        }
        
        if (name) {
            const telephone = mobile.replace(/[()" "]/g,"")

            //Change the format of birth date before saving
            let birth_date = "";
            if (dt){
                birth_date = moment(dt).format("YYYY-MM-DD")
            }

            // const customer = { type, client, name, address, city, country, email, website, telephone, id_type, id_number, gender, birth_date, user, tag, id_files };

            const customer = { type, client, name, address, city, country, email, website, telephone, id_type, id_number, gender, birth_date, user, tag };
            console.log(customer)
            mutation.mutate(customer)


            // fetchData({
            //     url: '/contacts/',
            //     method: 'post',
            //     data: customer,
            //     headers: {"Content-Type" : "multipart/form-data"} //REQUIRED FOR FILE UPLOADS
            //   })
            
        } 
    
    }

    return(

        <Modal className="dashboard-modal" title="Add Customer" visible={isModalOpen} open={isModalOpen} onOk={handleSubmit} onCancel={handleCancel}>

            <section className="dashboard-form">
                <UserAlerts error={error} />

                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    {/* <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        defaultChecked=""
                        onChange={handleCustomerType}

                    >
                        <FormControlLabel className="custom-radio" value="10" control={<Radio />} label="Individual" />
                        <FormControlLabel className="custom-radio" value="20" control={<Radio />} label="Company" />
                    </RadioGroup> */}

                    <TextField className="custom-input"
                        onChange={(e) => {
                            setNameError(false)
                            setName(e.target.value)
                        }}
                        label="Name" 
                        variant="outlined" 
                        fullWidth
                        required
                        error={nameError}
                    />

                    <NumberFormat 
                        onChange={(e) => {
                            setMobileError(false)
                            setMobile(e.target.value)
                        }}
                        className="custom-input"
                        fullWidth
                        label="Telephone" 
                        variant="outlined" 
                        customInput={TextField}
                        format="### (###) ######" mask="_"
                        error={mobileError}
                    />

                    <TextField className="custom-input"
                        onChange={(e) => {
                            setEmailError(false)
                            setEmail(e.target.value)
                        }}
                        label="Email" 
                        variant="outlined" 
                        fullWidth
                        error={emailError}
                    />

                    {/* <TextField className="custom-input"
                        onChange={(e) => setWebsite(e.target.value)}
                        label="Website" 
                        variant="outlined" 
                        fullWidth
                    />  
             

                    <TextField className="custom-input"
                        onChange={(e) => setAddress(e.target.value)}
                        label="Address" 
                        variant="outlined" 
                        fullWidth
                        error={addressError}
                    />   */}

                    <TextField className="custom-input"
                        onChange={(e) => {
                            setCityError(false)
                            setCity(e.target.value)
                        }}
                        label="City/Town" 
                        variant="outlined" 
                        fullWidth
                        required
                        error={cityError}
                    />  

                    {/*<Autocomplete
                        onChange={(event, newValue) => {
                            setCountry(newValue.id);
                            console.log("VALUE", newValue.id)
                            }}
                        options={countries}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField className="custom-input" fullWidth {...params} label="Country"  /> }
                    />


                    {isPerson && 
                        <div className="grouped-fields-light">

                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            defaultChecked=""
                            onChange={(e) => setGender(e.target.value)}
                            // className="custom-input"
                        >
                            <FormControlLabel className="custom-radio" value="F" control={<Radio />} label="Female" />
                            <FormControlLabel className="custom-radio" value="M" control={<Radio />} label="Male" />
                        </RadioGroup>

                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DesktopDatePicker
                                label="Date of Birth"
                                value={dt}
                                onChange={(newDate) => {
                                    setDt(moment(newDate).format("YYYY-MM-DD"));
                                    }}
                                renderInput={(params) => <TextField fullWidth required className="custom-input" {...params} />}
                            />
                        </LocalizationProvider>
                        </div>
                    } */}
                    
                </form>

            </section>
        </Modal>

    )

}

export default CustomerNewModal;
