// import React from "react";
// import { useState } from "react";
import { Chip } from "@mui/material";
// import { createTheme, ThemeProvider } from '@mui/material/styles';

const Pillchip = ({label}) => {   

    const getColor = (label) => {
        switch (label) {
            case 'Draft':
                return { backgroundColor: '#607d8b', color: 'white'};

            case 'Paid':
                return { backgroundColor: '#009688', color: 'white'};
            
            case 'Completed':
                return { backgroundColor: '#009688', color: 'white'};
        //   case 'Submitted':
        //     return { backgroundColor: '#4caf50', color: 'white' };
        //   case 'Approved':
        //     return { backgroundColor: '#2196f3', color: 'white' };
            default:
                return {backgroundColor: '#607d8b', color: 'white'}; // Default styles
        }
        };

    const color = getColor(label)

    return (    
        <Chip label={label} size="small" sx={color} className="custom-pill-chip"/>
      );
}
 
export default Pillchip;