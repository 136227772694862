import { useState, useEffect, memo, useCallback } from "react";
import { useQuery } from "@tanstack/react-query";

import { Link } from "react-router-dom";
// import useAxios from "../components/useAxios";
// import authAxios from "../../components/authAxios";
import authAxios from "./authAxios";
import useAxiosInterceptor from "./useAxiosInterceptor";
// import axiosI

import { List } from '@mui/material';
import { ListItemButton } from '@mui/material';
import { ListItemText } from '@mui/material';
import { Collapse } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import PortraitSharpIcon from '@mui/icons-material/PortraitSharp';
import AppsSharpIcon from '@mui/icons-material/AppsSharp';
import GroupIcon from '@mui/icons-material/Group';
import InventorySharpIcon from '@mui/icons-material/InventorySharp';
import ShoppingCartSharpIcon from '@mui/icons-material/ShoppingCartSharp';
import QrCodeSharpIcon from '@mui/icons-material/QrCodeSharp';
import ShoppingBagSharpIcon from '@mui/icons-material/ShoppingBagSharp';
import BarChartIcon from '@mui/icons-material/BarChart';
import SettingsSharpIcon from '@mui/icons-material/SettingsSharp';
import SpokeIcon from '@mui/icons-material/Spoke';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import SellIcon from '@mui/icons-material/Sell';

import { faBarcode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from '../assets/zidi-logo.svg'


const Sidebar = () => {
    // const [menuItems, setMenuItems] = useState([]);
    const [open, setOpen] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState([]);

const {error} = useAxiosInterceptor();
    // const { data:menuData, fetchData:fetchMenuData}  = useAxios();   
    // const fetchMenu = () => {
    //     // fetchData({
    //     fetchMenuData({
    //         url: "/saas/client-menu",
    //         method: 'get',
    //     })
    // }

    const fetchMenu = async () => {
        const response = await authAxios.get("/saas/client-menu")
        // console.log("RES",response)
        return response.data
    }


    const handleClick = (index) => {
        const newOpen = [...open];
        newOpen[index] = !newOpen[index];
        setOpen(newOpen);
        setSelectedIndex(index);
    };


    // useEffect(()=>{
    //     fetchMenu()
    // },[])

    // useEffect(() => {
    //     if (menuData.length){
    //         setMenuItems(menuData)
    //     }
    // },[menuData.length])

    const {data:menuItems, status} = useQuery(['menuItems'], fetchMenu)
    // const {data:menuItems, status} = useQuery({
    //     queryKey: ['menuItems'], 
    //     queryFn: ()=>fetchMenu()
    // })
    // console.log("menuItems", menuItems)


    const MenuIcon = ({item}) =>{
        if (item.name === "Dashboard"){
            return <AppsSharpIcon className="dashboard-navicon"/>
        }
        // if (item.name === "POS"){
        if (item.code === "POS"){
            // return <PointOfSaleIcon className="dashboard-navicon"/>
            return <ShoppingCartSharpIcon className="dashboard-navicon"/>

        }
        if (item.name === "Sales"){
            return <SellIcon className="dashboard-navicon"/>
        }
        if (item.name === "CRM"){
            return <GroupIcon className="dashboard-navicon"/>
        }
        if (item.name === "Products"){
            return <InventorySharpIcon className="dashboard-navicon"/>
        }
        if (item.name === "Purchase"){
            return <LocalShippingIcon className="dashboard-navicon"/>
        }
        if (item.name === "Inventory"){
            return <FontAwesomeIcon icon={faBarcode} className="dashboard-navicon"/>
            // return <QrCodeSharpIcon className="dashboard-navicon"/>
        }
        if (item.name === "Orders"){
            return <ShoppingCartSharpIcon className="dashboard-navicon"/>
        }
        if (item.name === "Human Resources"){
            return <PortraitSharpIcon className="dashboard-navicon"/>
        }
        // if (item.name === "Integrations"){
        //     return <SpokeIcon className="dashboard-navicon"/>
        // }
        if (item.code === "ECOM"){
            return <SpokeIcon className="dashboard-navicon"/>
        }
        if (item.name === "Accounting"){
            return <BarChartIcon className="dashboard-navicon"/>
        }
        if (item.name === "Settings"){
            return <SettingsSharpIcon className="dashboard-navicon"/>
        }
    }

    const SingleLevel = ({item}) => {
        return(
            <ListItemButton 
                // key={item.id}
                selected={selectedIndex === item.id}
                onClick={() => handleClick(item.id)}
                className="dashboard-navlink" 
                component={Link} to={item.path}
            >
                <MenuIcon item={item} />
                <ListItemText primary={item.name} className="dashboard-navlink-text"/>
            </ListItemButton>
        )
    }

    const MultiLevel = ({item}) => {
        // console.log("MULTI LEVEL", item)
        return(
            <>
            <ListItemButton 
                // key={item.id} 
                selected={selectedIndex === item.id}
                className="dashboard-navlink" 
                onClick={() => handleClick(item.id)}>
                <MenuIcon item={item} 
            />
                <ListItemText primary={item.name} className="dashboard-navlink-text"/>
                {open[item.id] ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open[item.id]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {menuItems.map((item1) => ( 
                        <div key={item1.id}>
                            {item1.parent_menu===item.menu? 
                            <>
                                {item1.has_submenu? 
                                    <>
                                    <ListItemButton  selected={selectedIndex === item1.id} className="dashboard-submenu-navlink" onClick={() => handleClick(item1.id)}>
                                        <ListItemText primary={item1.name} className="dashboard-navlink-text"/>
                                        {open[item1.id] ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemButton>

                                    <Collapse in={open[item1.id]} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                        {menuItems.map(item2 => (
                                            <div key={item2.id}>
                                                {item2.parent_menu===item1.menu?
                                                    <ListItemButton 
                                                        selected={selectedIndex === item2.id}
                                                        className="dashboard-submenu-navlink" 
                                                        onClick={() => handleClick(item2.id)}
                                                        component={Link} to={item2.path}
                                                    >
                                                        <ListItemText primary={item2.name} className="dashboard-submenu-text"/>
                                                    </ListItemButton>    
                                                    : 
                                                    null   
                                                }
                                            </div>
                                                               
                                        ))}
                                        </List>
                                    </Collapse>
                                    </>
                                :
                                <ListItemButton 
                                    key={item1.id} 
                                    selected={selectedIndex === item1.id}
                                    className="dashboard-submenu-navlink" 
                                    component={Link} to={item1.path}
                                    onClick={() => handleClick(item1.id)}
                                    >
                                    <ListItemText primary={item1.name} className="dashboard-navlink-text"/>
                                </ListItemButton>
                                }
                            </>
                            :
                            null}
                        
                        </div>

                    ))} 
                </List>
            </Collapse>
        </>

        )

    }
    
    return(


    <section className="dashboard-menu flex-column">
        <div className="dashboard-brand">
            <Link to="/">
                <img src={logo} alt="brand logo"/>  
            </Link>

        </div>

        <List >
            {/* {menuItems.map(item => ( */}
            {menuItems && menuItems.map(item => (
            // {menuItems.results.map(item => (
                item.level===1? 
                    item.has_submenu?
                    <MultiLevel item={item} key={item.id} />
                    :
                    <SingleLevel item={item} key={item.id} />
                : 
                null
            ))}

        </List>


    </section>
    )


}

// export default Sidebar;
export default memo(Sidebar);