import React from "react"
import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";


import { TabList, TabPanel } from "@mui/lab";
import TabContext from '@mui/lab/TabContext';
import { Tab } from "@mui/material";


// import useAxios from "../../components/useAxios";
import authAxios from "../../components/authAxios";

import Layout from "../../components/Layout";
import DashboardHeader from "../../components/DashboardHeader";

// import { useMediaQuery } from 'react-responsive'
import TransactionList from "./TransactionList";
import Loading from "../../components/Loading";


// const Transactions = ({ txns, loading }) => {
const Transactions = () => {
    // const page_size = useContext(UserContext);
    // const abortController = new AbortController()

    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();
    
    // const {response, error } = useAxiosInterceptor();
    const removeBorder = true;

     // PAGINATION
     const handlePageChange = (event, value) => {
        setPage(value);
    };

    // const fetchTransactions = async (page) => {
    //     const response = await authAxios.get(`/accounting/transactions/?page=${page}`)
    //     setPageCount(response.data.page_count)
    //     return response.data
    // }

    // const {data:transactions, isLoading, isPreviousData} = useQuery({
    //     queryKey: ['transactions', page], 
    //     queryFn: ()=>fetchTransactions(page), keepPreviousData : true
    // })

    const { data: transactions } = useQuery({
        queryKey: ['transactions', page],
        queryFn: async () => {
          const response = await authAxios.get(`/accounting/transactions/?page=${page}`);
          setPageCount(response.data.page_count)
          return response.data.results
        },
    });
    // console.log("LOADING",isLoading)

 
    return (
            <div className="dashboard-main">
                <section className="dashboard-content">
                    <DashboardHeader dashboardTitle="Transactions" dashboardPreTitle="Accounting" />
                  
                    <TransactionList 
                        module="ACC" 
                        transactions={transactions} 
                        // transactions={transactions.results} 
                        // fetchTransactions={fetchTransactions} 
                        handlePageChange={handlePageChange} 
                        page={page} 
                        pagecount={pagecount}
                        // client={client}
                    />

                </section>

            </div>

    );
  };
  
  export default Transactions;