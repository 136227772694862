// import React from "react";
import { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useAxios from "../../components/useAxios";
import axios from 'axios';


import { TextField, Radio, RadioGroup, FormControlLabel, Autocomplete, InputLabel, MenuItem } from "@mui/material";
import Button from '@mui/material/Button';
// import { FormControl, Select } from "@mui/material";
// import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';


// IMAGE UPLOAD ANT DESIGN
// import { message, Upload } from 'antd';
// import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import UserAlerts from "../../components/UserAlerts";
import { UserContext } from "../../App";
import NumberFormat from "react-number-format";

import Loading from "../../components/Loading";
// import useEbayToken from "../ebay/useEbayToken";


// import DashboardHeader from "../../components/DashboardHeader";
// import Layout from "../../components/Layout";
// import ProductOptions from "./ProductOptions";


// function ProductNew() {
// const ProductNew = ({isDrawerOpen, handleDrawerClose}) => {

const OfferEdit = () => {
    const params = useParams()

    const page_size = useContext(UserContext);
    const {response, error, loading, fetchData}  = useAxios();

    //GET EBAY ACCESS TOKEN FOR LISTING FEATURE
    // const { ebayAccessToken, refreshEbayToken } = useEbayToken();

    // FETCH INVENTORY ITEM
    // const { data: item } = useAxios({
    //     url: `/inventory/items/${params.id}`,
    //     method: 'get'
    // })  

    // FETCH OFFER
    const { data: offer } = useAxios({
        url: `/ebay/offers/${params.id}`,
        method: 'get'
    }) 
 
    // const { data: inventoryTypes } = useAxios({
    //     url: '/inventory/inventory-types',
    //     method: 'get'
    // })

    // const { data: categories } = useAxios({
    //     url: '/products/categories',
    //     method: 'get'
    // })

    // console.log("CATEGORIES", categories)

    // const { data: productTypes } = useAxios({
    //     url: '/products/product-types',
    //     method: 'get'
    // })

    // const { data: units } = useAxios({
    //     url: '/products/units',
    //     method: 'get'
    // })

    // const { data: taxes } = useAxios({
    //     url: '/accounting/taxes',
    //     method: 'get'
    // })

     // FETCH ACCOUNT TYPES
     const { data: accounttypes } = useAxios({
        url: '/accounting/account-types',
        method: 'get'
    })

    // FETCH ACCOUNTS
    const [accounts, setAccounts] = useState([]);
    // const [revAccounts, setRevAccounts] = useState([]);
    const [expAccounts, setExpAccounts] = useState([]);
    const [inventoryAccounts, setInventoryAccounts] = useState([]);


    const { data: accountsData } = useAxios({
        url: `/accounting/accounts?page_size=${page_size}`,
        method: 'get'
    })


    const navigate = useNavigate();
    const [ebayOfferId, setEbayOfferId] = useState('');
    const [sku, setSku] = useState('');
    const [name, setName] = useState('');
    const [rate, setRate] = useState('');
    const [costInput, setCostInput] = useState('');
    const [upc, setUpc] = useState('');
    const [unit, setUnit] = useState('');
    const [memo, setMemo] = useState('');
    const [tax, setTax] = useState([]);
    const [availableQuantity, setAvailableQuantity] = useState();
    const [reorderLevel, setReorderLevel] = useState();


    const [inventoryType, setInventoryType] = useState('');
    // const [type, setType] = useState('');
    const [revenueAccount, setRevenueAccount] = useState('');
    const [expenseAccount, setExpenseAccount] = useState('');
    const [inventoryAccount, setInventoryAccount] = useState('');


    const user = localStorage.getItem('userID');
    const client = localStorage.getItem('clientID');

    // const [categoryError, setCategoryError] = useState(false)
    const [inventoryTypeError, setInventoryTypeError] = useState(false)
    const [nameError, setNameError] = useState(false)
    const [revenueAccountError, setRevenueAccountError] = useState(false)
    const [inventoryAccountError, setInventoryAccountError] = useState(false)

    const [isGoods, setIsGoods] = useState(false);
    const [productOptions, setProductOptions] = useState([{}]);

    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
      ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
          padding: 0,
          margin: 2,
          transitionDuration: '300ms',
          '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
              opacity: 1,
              border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            color:
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[600],
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
          },
        },
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
          width: 22,
          height: 22,
        },
        '& .MuiSwitch-track': {
          borderRadius: 26 / 2,
          backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
          opacity: 1,
          transition: theme.transitions.create(['background-color'], {
            duration: 500,
          }),
        },
      }));

    const [hasVariants, setHasVariants] = useState(false);
    const handleChange = (event) => {
        setHasVariants(event.target.checked);
        console.log(event.target.checked)
    };

    const getDefaultValues = () => {
        setEbayOfferId(offer.ebay_offer_id)
        setSku(offer.sku)
        setName(offer.name)
        setAvailableQuantity(offer.available_quantity)

        // setInventoryType(item.type)
        // setCostInput(item.cost)
        // setReorderLevel(item.reorder_level)
        // setExpenseAccount(item.expense_account)
        // setInventoryAccount(item.inventory_account)
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (name === '') {
            setNameError(true)
        }

        // if (revenueAccount === '') {
        //     setRevenueAccountError(true)
        // }

       
        if (name ) {

            //Change the format of Price field before saving
            let price = 0
            price = parseFloat(rate.replace(/,/g, ''));

            let cost = 0
            cost = parseFloat(costInput.replace(/,/g, ''));

            const expense_account = expenseAccount
            const inventory_account = inventoryAccount
            // const inventory_type = inventoryType
            const type = inventoryType
            const reorder_level = reorderLevel

            const item = { 
                // client, name, upc, unit, cost, type, inventory_type, reorder_level, memo, tax, expense_account, inventory_account, user, 
                client, name, upc, unit, cost, type, reorder_level, memo, tax, expense_account, inventory_account, user, 
            };
            console.log(item)

            fetchData({
                url: `/inventory/items/${params.id}/`,
                method: 'put',
                data: item,
                // headers: {"Content-Type" : "multipart/form-data"} //REQUIRED FOR FILE UPLOADS
            })
            .then(()=>{
                // console.log("RESPONSE", response)                    
                if (response.status === 200){
                    // navigate('/dashboard/items')
                    navigate('/dashboard/offers')

                }
            })
            
        } 
    
    }


    useEffect(() => {

        if (offer){
            getDefaultValues()
        }
        if (accountsData.count){
            // setAccounts(accts)
            setAccounts(Array.from(accountsData.results))
            const typeExp = accounttypes.find(accounttype => accounttype.code === "20300");
            setExpAccounts(accounts.filter(account => account.type === typeExp.id))
            const typeInventory = accounttypes.find(accounttype => accounttype.code === "30300");
            setInventoryAccounts(accounts.filter(account => account.type === typeInventory.id))
        }

        // if (response.status === 201){
        //     navigate('/dashboard/items');
        // }    

    }, [response.status, accountsData]); 


    // useEffect(() => {
    // }, [setExpAccounts, setInventoryAccounts]); 

    


    return(
        <>
        {loading? <Loading loading={loading}/>
        :
        <section className="dashboard-form">
            <UserAlerts error={error} />

            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <div className="grouped-fields-light">
                    <TextField className="custom-input"
                        value={ebayOfferId ?? ''}
                        label="Offer ID" 
                        inputProps={{readOnly: true}}
                        InputLabelProps={{ shrink: true, readOnly: true }}
                        variant="outlined" 
                        fullWidth
                        // disabled
                    />

                    <TextField className="custom-input"
                        value={sku ?? ''}
                        label="SKU" 
                        inputProps={{readOnly: true}}
                        InputLabelProps={{ shrink: true, readOnly: true }}
                        variant="outlined" 
                        fullWidth
                        // disabled
                    />

                    <TextField className="custom-input"
                        value={name ?? ''}
                        onChange={(e) => {
                            setNameError(false)
                            setName(e.target.value)
                        }}
                        label="Name" 
                        InputLabelProps={{ shrink: true, readOnly: true }}
                        variant="outlined" 
                        fullWidth
                        required
                        error={nameError}

                    />

                    {/* {inventoryTypes && inventoryType &&

                    <Autocomplete
                        value={inventoryTypes.find(type => type.id === inventoryType) ?? ''}
                        onChange={(event, option) => {
                            setInventoryTypeError(false)
                            setInventoryType(option.id)
                        }}
                        options={inventoryTypes}
                        getOptionLabel={(option) => option.name ?? ''}
                        renderInput={(params) => <TextField className="custom-input" fullWidth {...params} label="Inventory Type" />}
                    />
                    } */}
                </div>


                <div className="grouped-fields-light">

                    {/* <NumberFormat 
                        value={costInput ?? ''}
                        onChange={(e) => {
                            setCostInput(e.target.value)
                        }}
                        className="custom-input"
                        fullWidth
                        label="Cost" 
                        variant="outlined" 
                        customInput={TextField}
                        thousandSeparator={true} 
                    /> */}

                    <NumberFormat 
                        value={availableQuantity}
                        className="custom-input"
                        fullWidth
                        label="Available Quantity" 
                        variant="outlined" 
                        customInput={TextField}
                        thousandSeparator={true} 
                        inputProps={{readOnly: true}}
                        // disabled
                    />

                    {/* <NumberFormat 
                        value={reorderLevel ?? ''}
                        onChange={(e) => {
                            setReorderLevel(e.target.value)
                        }}
                        className="custom-input"
                        fullWidth
                        label="Reorder Level" 
                        variant="outlined" 
                        customInput={TextField}
                        thousandSeparator={true} 
                    /> */}
                </div>
                

                {/* <div className="grouped-fields-light">

                    <Typography 
                        className="custom-typography" 
                        variant="body2" 
                        gutterBottom 
                        component="div"
                    >
                        Active
                    </Typography>
                    <FormControlLabel
                        className="custom-switch"
                        control={<IOSSwitch 
                            checked={hasVariants}
                            onChange={handleChange}
                            sx={{ m: 1 }} />}
                    />
                </div> */}
               
                {/* <div className="grouped-fields-light">

                    {inventoryAccounts && inventoryAccount &&
                    <Autocomplete
                        value={inventoryAccounts.find(account => account.id === inventoryAccount) ?? ''}
                        onChange={(event, option) => {
                            setInventoryAccount(option.id)
                        }}
                        options={inventoryAccounts}
                        getOptionLabel={(option) => option.name ?? ''}
                        renderInput={(params) => <TextField className="custom-input" fullWidth {...params} label="Inventory Account" />}
                    />
                    }

                    {expAccounts && expenseAccount &&
                    <Autocomplete
                        value={expAccounts.find(account => account.id === expenseAccount) ?? ''}
                        onChange={(event, option) => {
                            setExpenseAccount(option.id)
                        }}
                        options={expAccounts}
                        getOptionLabel={(option) => option.name ?? ''}
                        renderInput={(params) => <TextField className="custom-input" fullWidth {...params} label="Expense Account" />}
                    />  
                    }

                </div> */}

               
                
                <div className="grouped-fields-light">
                    <TextField className="custom-input"
                        onChange={(e) => {
                            setMemo(e.target.value)
                        }}
                        label="Notes"
                        variant="outlined"
                        multiline
                        rows={2}
                        fullWidth
                    />
                </div>

                <FormControlLabel
                    className="custom-switch"
                    control={<IOSSwitch 
                        checked={hasVariants}
                        onChange={handleChange}
                        sx={{ m: 1 }} />}
                    label = "Publish Offer"

                />

                <div className="button-border-top button-group">
                    <button id="submit" className="btn btn-primary form-buttons" type="submit">
                        Save
                    </button>
                </div>


            </form>

            </section>
                    }
        </>

    )
                    

}

export default OfferEdit;