import React, {useEffect, useState} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRef, useCallback } from 'react';



// import DashboardHeader from "../components/DashboardHeader";
import DashboardHeader from "../src/components/DashboardHeader"


const ErrorPage = () => {
    return (
        <div className="dashboard-main">
            <section className="dashboard-content">
                {/* <DashboardHeader dashboardTitle="Error" dashboardPreTitle="Accounting"  /> */}
                <DashboardHeader dashboardPreTitle="Error"  />

 
                <div className='dashboard-error'>
                    <div className='error-header'>Ooooops!</div>
                    <h6>Sorry, something went wrong!</h6>
                    <p>Have you tried refreshing this page? If error persists, please let us know and we will resolve asap.</p>

                </div> 

            </section>
            
        </div>
    )
}

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      return { hasError: true };
    }
  
    componentDidCatch(error, info) {
      console.log("ERROR", error)
      console.log("INFO", info)
    }
  
    render() {
      if (this.state.hasError) {
        return (
            <ErrorPage/>
        );
      }
  
      return this.props.children;
    }
  }

  export default ErrorBoundary;

// class ErrorBoundary extends React.Component {
//     constructor(props) {
//       super(props);
//       this.state = { hasError: false };
//     //   this.unlisten = null;
//     }


//     static getDerivedStateFromError(error) {
//       // Update state so the next render will show the fallback UI.
//       return { hasError: true };
//     }
  
//     componentDidCatch(error, info) {
//       // Example "componentStack":
//       //   in ComponentThatThrows (created by App)
//       //   in ErrorBoundary (created by App)
//       //   in div (created by App)
//       //   in App
//       // logErrorToMyService(error, info.componentStack);
//       console.log("ERROR", error)
//       console.log("INFO", info)

//       // Subscribe to the route change event
//       const subscription = useRouter().onUpdate(() => {
//         this.setState({ hasError: false });
//       });
    
//       // Unsubscribe when the component unmounts
//       return () => {
//         subscription.unsubscribe();
//       };
  
//     }

//     componentDidUpdate(prevProps, prevState) {
//         // if (prevState.hasError && !this.state.hasError) {
//         //   // Reset the state of the error boundary
//         //   this.setState({ hasError: false });
//         // }
//     }
  
//     render() {
//       if (this.state.hasError) {
//         // You can render any custom fallback UI
//         // return this.props.fallback;
//         return (
//             <ErrorPage/>
                
//         );
//       }
  
//       return this.props.children;
//     }
//   }

//   export default ErrorBoundary;


// const ErrorBoundary = (props) => {
//     const [hasError, setHasError] = useState(false);
//     const previousLocation = useRef(null);
//     // const originalPathname = useRef(pathname)
//     // Get the current location
//     const location = useLocation();

//     // Update getDerivedStateFromError
//     componentDidCatch(error, info) {
//         console.error(error);
//         setHasError(true);
//     }
  
//     // Compare the previous and current location
//     useEffect(() => {
//         console.log("LOC", location)
//         console.log("PREV", previousLocation.current)
//         if (previousLocation === location) {
//             console.log("PREV LOCATION", previousLocation)
//             console.log("LOCATION", location)
//             setHasError(true);
//         }
//         previousLocation.current = location;
//     }, [location]);

    
//     console.log("ERR", hasError)


//     return (
//       <div >
//         {hasError ? <ErrorPage /> : props.children}
//       </div>
//     );
//   };
  
//   export default ErrorBoundary;