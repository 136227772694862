import { useState } from "react";
import { useQuery } from "@tanstack/react-query";

import authAxios from "../../components/authAxios";

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

import { format } from 'date-fns';

import DashboardHeader from "../../components/DashboardHeader";
import Pagination from '@mui/material/Pagination';
import SettingsMenu from "./SettingsMenu";
import PaymentModeNew from "./PaymentModeNew";


const PaymentModes = () =>{
    const [removeBorder, setRemoveBorder] = useState(true);

     //Pagination
     const [page, setPage] = useState(1);
     const [pagecount, setPageCount] = useState();
 
     const handlePageChange = (event, value) => {
         setPage(value);
     };
 
     const convertDateTime = (myDateTime) => {
         return format(new Date(myDateTime), 'dd-MM-yyyy HH:mm');
       };
 
     const [isModalOpen, setIsModalOpen] = useState(false);
 
    //  const threeSubMenu = [
    //      {
    //          id: 1,
    //          menu: "View",
    //          url: "/dashboard/account/",
 
    //      }, 
    //  ];

     const { data: clientPaymentModes } = useQuery({
        queryKey: ['clientPaymentModes', page],
        queryFn: async () => {
          const response = await authAxios.get(`/pos/client-payment-modes/?page=${page}`);
          setPageCount(response.data.page_count)
          return response.data.results
        },
    });

    return (
        <div className="dashboard-settings">
            <SettingsMenu />

            <div className="dashboard-content">
                <DashboardHeader dashboardTitle="Payment Modes" dashboardPreTitle="POS SETTINGS" removeBorder={removeBorder}  />
                <table className="styled-table">

                    <thead>
                        <tr >
                            <th>ID</th>
                            <th>Name</th>
                            <th>Account</th>
                            <th>Date Created</th>
                            <th>Status</th>
                            <th></th>
                        </tr>
                    </thead>

                    {clientPaymentModes && clientPaymentModes.map(mode => (

                        <tbody key={mode.id}>
                            <tr>
                                <td>{mode.id}</td>
                                <td>{mode.name}</td>
                                <td>{mode.account_name}</td>
                                <td>{convertDateTime(mode.created_at)}</td>
                                {mode.is_active?
                                <td>Active</td>
                                :
                                <td>Inactive</td>
                                }

                                {/* <td className='styled-table-menu'>
                                    <ThreeDotsMenu data={collection} threeSubMenu={threeSubMenu} />

                                </td> */}
                            </tr>
                        </tbody>
                    ))}

                </table>


                <Pagination 
                    className="custom-mui-pagination"
                    count={pagecount} 
                    page={page} 
                    onChange={handlePageChange} 
                />

                {isModalOpen &&
                <PaymentModeNew 
                    isModalOpen={isModalOpen} 
                    setIsModalOpen={setIsModalOpen}
                />
                }

                <Fab 
                    className="fab" 
                    color="primary" 
                    aria-label="add"
                    onClick={()=>{
                        setIsModalOpen(true)
                    }
                    }
                >
                    <AddIcon />
                </Fab>


            </div>

        </div>
    )
}

export default PaymentModes;
