// import React from "react";

import { useState, useRef, useEffect } from "react";
import authAxios from "../../components/authAxios";
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';


import { useNavigate, useParams } from "react-router-dom";
import NumberFormat from "react-number-format";
import { TextField } from "@mui/material";
import CustomSwitch from "../../components/CustomSwitch";

import { TreeSelect } from "antd";


const ProductEdit = ({product, setError}) => {
    const params = useParams()
    const queryClient = useQueryClient();
    const navigate = useNavigate();


    const { data: categories } = useQuery({
        queryKey: ['categories-tree'],
        queryFn: async () => {
            const response = await authAxios.get("/products/categories-tree");
            return transformCategoriesTree(response.data)
        },
    });

    const client = localStorage.getItem('clientID');
    const user = localStorage.getItem('userID');
    const [name, setName] = useState(product.name);
    const [sku, setSku] = useState(product.sku);
    const [newPrice, setNewPrice] = useState(product.price);
    // const productCategories = product.categories;
    // const [category, setCategory] = useState([]);
    const [category, setCategory] = useState(product.categories.map(category => category.toString())); // Convert IDs to strings as required by TreeSelect

    // const [category, setCategory] = useState(product.categories);

    const [memo, setMemo] = useState(product.memo);
    const [checked, setChecked] = useState(product.is_active);

    const [nameError, setNameError] = useState(false)

    console.log("CATEGORY",category)


     // Function to recursively transform data to the expected tree structure
     const transformCategoriesTree = (data) => {
        return data.map((item) => ({
            value: item.id.toString(), // Convert to string if needed
            title: item.name,
            children: item.sub_categories ? transformCategoriesTree(item.sub_categories) : [],
        }));
    };

    const filterTreeNode = (input, treeNode) => {
        const nodeLabel = treeNode.title.toLowerCase(); // Access title directly
        return nodeLabel.includes(input.toLowerCase());
    };

    const handleCategory = (e) => {
        setCategory(e)
        console.log("CATEGORY",category)
    }

    const mutation = useMutation({
        mutationFn: (product) => {
            return authAxios.put(`/products/skus/${params.id}/`, product);
        },
        onError: (error, variables, context) => {
            if (error.response.data.error){
                // If it's an array, display each error message
                if (Array.isArray(error.response.data.error)) {
                    error.response.data.error.map((errorMessage) => (
                        setError(errorMessage)
                    ));
                } else {
                    // Handle single error message
                    setError(error.response.data.error); 
                }
            } else {
                // Fallback to Standard error message
                setError(error.message); 
            }      
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(['product', 'products']);
            queryClient.refetchQueries(['product']);
            navigate('/dashboard/products/');
        },
      })

    const handleSubmit = (e) => {
        e.preventDefault();

        if (name === '') {
            setNameError(true)
        }

        if (name ) {
            const is_active = checked
            const price = parseFloat(newPrice.replace(/,/g, ''));
            const product = { client, name, category, price, memo, is_active, user };
            // const product = { 
            //     client, name, upc, unit, cost, price, type, inventory_type, category, collection, memo, tax, 
            //     revenue_account, expense_account, inventory_account, user, product_options, has_variants
            // };
            console.log("PRODUCT", product)
            mutation.mutate(product)
        } 
    }

    // useEffect(() => {
    //     if (product.categories) {
    //     //   setCategory(product.categories);
    //       setCategory(product.categories.map(category => category.toString())); // Ensure you're using IDs as strings
    //     }
    //   }, [product]);


    return(
        <section className="dashboard-form">
           
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <div className="grouped-fields-light">

                    <TextField className="custom-input"
                        value={ sku || ''}
                        onChange={(e) => {
                            // setNameError(false)
                            setSku(e.target.value)
                        }}
                        label="SKU" 
                        // InputLabelProps={{ shrink: true }}
                        variant="outlined" 
                        fullWidth
                        required
                        // error={nameError}
                    />

                    <TextField className="custom-input"
                        value={ name || ''}
                        onChange={(e) => {
                            setNameError(false)
                            setName(e.target.value)
                        }}
                        label="Name" 
                        InputLabelProps={{ shrink: true }}
                        variant="outlined" 
                        fullWidth
                        required
                        error={nameError}
                    />


                    <TreeSelect
                        className="custom-tree-select"
                        showSearch
                        style={{
                            width: '100%',
                        }}
                        dropdownStyle={{
                            maxHeight: 400,
                            overflow: 'auto',
                        }}
                        placeholder="Categories"
                        multiple
                        allowClear
                        value={category}
                        // defaultValue={category}
                        // maxTagCount={3}
                        onChange={(e)=>setCategory(e)}
                        // onChange={handleCategory}
                        treeData={categories}
                        filterTreeNode={filterTreeNode} //Enables search filter
                    />
                

                    <NumberFormat 
                        value = {newPrice}
                        onChange={(e) => {
                            setNewPrice(e.target.value)
                        }}
                        className="custom-input"
                        fullWidth
                        label="Price" 
                        variant="outlined" 
                        customInput={TextField}
                        thousandSeparator
                        readOnly
                        required
                        // error={mobileError}
                    />

                    <TextField className="custom-input"
                        value={memo ?? ''}
                        onChange={(e) => setMemo(e.target.value)}
                        label="Memo"
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        multiline
                        rows={3}
                        fullWidth
                    />
                </div>

                <div className="custom-switch-container custom-switch-container-white">
                        <CustomSwitch checked={checked} setChecked={setChecked}/>
                        <div className="custom-switch-label">
                            Active
                        </div>
                    </div>
                
                <div className="button-border-top">
                    <button id="submit" className="btn btn-primary form-buttons" type="submit">
                        Save
                    </button>
                </div>
            </form>
        </section>
    )

}

export default ProductEdit;