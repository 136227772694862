
import { useState, useEffect, useRef } from "react";
import { useQuery } from "@tanstack/react-query";

import authAxios from "../components/authAxios";

// import Layout from "../components/Layout";
import DashboardHeader from "../components/DashboardHeader";

const IncomeStatement = () => {

    const [revenue, setRevenue] = useState([]);
    const [expenses, setExpenses] = useState([]);

    const sumRevenue = useRef(0);
    const sumExpenses = useRef(0);
    const netIncome = useRef(0);

    const { data: balances } = useQuery({
        queryKey: ['balances'],
        queryFn: async () => {
            const response = await authAxios.get("/accounting/account-balance");
            return response.data
        },
    });

    const { data: accountTypes } = useQuery({
        queryKey: ['accountTypes'],
        queryFn: async () => {
          const response = await authAxios.get("/accounting/account-types");
          return response.data
        },
    });

    useEffect(()=>{
        if (balances){

            // const accumulatedBalancesArray = [];
            const arrRevenue = [];
            const arrExpenses = [];

            balances.forEach((account) => {
                const accountId = account.account;
                const type = account.account_type;
                const amount = parseFloat(account.amount);

                // ACCUMULATE REVENUE
                if (!isNaN(amount) && (type==="10100"||type==="10200")) {
                    const existingAccount = arrRevenue.find(item => item.id === accountId);

                    if (existingAccount) {
                    existingAccount.amount += amount;
                    } else {
                    // Create a new account entry
                    const newAccountEntry = {
                        id: accountId,
                        account_name: account.account_name,
                        amount,
                    };
                    arrRevenue.push(newAccountEntry);
                    }
                }

                // ACCUMULATE EXPENSES
                if (!isNaN(amount) && (type==="20100"||type==="20200"||type==="20300")) {
                    const existingAccount = arrExpenses.find(item => item.id === accountId);

                    if (existingAccount) {
                    existingAccount.amount += amount;
                    } else {
                    // Create a new account entry
                    const newAccountEntry = {
                        id: accountId,
                        account_name: account.account_name,
                        amount,
                    };
                    arrExpenses.push(newAccountEntry);
                    }
                }

            });

            // Update the state with the accumulated balances array
            setRevenue(arrRevenue)
            sumRevenue.current = arrRevenue.reduce((acc, account) => acc + parseFloat(account.amount), 0);

            setExpenses(arrExpenses)
            sumExpenses.current = arrExpenses.reduce((acc, account) => acc + parseFloat(account.amount), 0);

            netIncome.current = sumRevenue.current - sumExpenses.current
        }

    },[balances])


    return (
        <div className="dashboard-main">
            <section className="dashboard-content">
                <DashboardHeader dashboardTitle="Income Statement" dashboardPreTitle="Accounting" />
                <table className="financial-report">
                        <thead>
                        {/* <tr>
                            <th>Category</th>
                            <th>Amount</th>
                        </tr> */}
                        </thead>


                        <tbody>

                            {revenue && revenue.map(account => (
                                <tr key={account.id}>
                                    <td>{account.account_name}</td>
                                    <td>{Number(account.amount).toLocaleString()}</td>
                                </tr>
                            ))}
                            <tr className="sub-total-row">
                                <td>Total Revenue</td>
                                {/* <td>{Number(sumAssets).toLocaleString()}</td> */}
                                <td>{Number(sumRevenue.current).toLocaleString()}</td>

                            </tr>
                            <tr className="blank-row" />
                            <tr className="blank-row" />


                            {expenses && expenses.map(account => (
                                <tr key={account.id}>
                                    <td>{account.account_name}</td>
                                    <td>{Number(account.amount).toLocaleString()}</td>
                                </tr>
                            ))}
                            <tr className="sub-total-row">
                                <td>Total Expenses</td>
                                {/* <td>{Number(sumAssets).toLocaleString()}</td> */}
                                <td>{Number(sumExpenses.current).toLocaleString()}</td>

                            </tr>
                            <tr className="blank-row" />
                            <tr className="blank-row" />


                            <tr className="total-row">
                                <td>Net Income</td>
                                <td>{Number(netIncome.current).toLocaleString()}</td>
                            </tr>
                            <tr className="blank-row" />

                        </tbody>
                </table>
            </section>
        </div>
    );
};

export default IncomeStatement;


