import { useEffect, useState, useRef } from "react"
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import authAxios from "../../components/authAxios";
import DashboardHeader from "../../components/DashboardHeader";

import { IconButton } from "@mui/material";
import DescriptionIcon from '@mui/icons-material/Description';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import { Fab } from "@mui/material";


//TABS - BOOTSTRAP
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

//TABS - ANT DESIGN
// import { Tabs } from "antd";

import TransactionList from "../accounting/TransactionList";
import OrderList from "./OrderList";
// import CustomerEdit from "./CustomerEdit";
// import Loading from "../../components/Loading";

import UserAlerts from "../../components/UserAlerts";
import OrderEdit from "./OrderEdit";
import OrderView from "./OrderView";
import TransactionNewModal from "../accounting/TransactionNewModal";


const Order = () => {
    const params = useParams()
    const navigate = useNavigate()
    const [error, setError] = useState('');
    const errorRef = useRef(null);

    const orderId = params.id

    const [removeBorder, setRemoveBorder] = useState(true);
    
    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const { data: clientProfile } = useQuery({
        queryKey: ['clientProfile'],
        queryFn: async () => {
          const response = await authAxios.get(`/clientprofiles/${localStorage.getItem('clientProfile')}`);
          return response.data

        },
    });

    const { data: transactions } = useQuery({
        queryKey: ['order-ledger', page],
        queryFn: async () => {
          const response = await authAxios.get(`/accounting/order-ledger/?order=${params.id}&page=${page}`);
          setPageCount(response.data.page_count)
          return response.data.results
        },
    });

    const { data: customerOrders } = useQuery({
        queryKey: ['customer-orders', page],
        queryFn: async () => {
          const response = await authAxios.get(`/orders/customer-orders/?customer=${params.id}&page=${page}`);
          setPageCount(response.data.page_count)
          return response.data.results
        },
    });

    const { data: order } = useQuery({
        queryKey: ['order',orderId],
        queryFn: async () => {
          const response = await authAxios.get(`orders/orders/${orderId}`);
          return response.data
        },
    });

    const { data: countries } = useQuery({
        queryKey: ['countries'],
        queryFn: async () => {
          const response = await authAxios.get('/countries');
          return response.data
        },
    });

    const formatReference = (reference) => {
        return `${reference.slice(0, 3)}-${reference.slice(3, 9)}-${reference.slice(9)}`;
    };

    //CALC STATS
    let sumInvoices = 0
    let sumReceipts = 0
    let balance = 0
        
    // const calcStats = () => {
    //     try {
    //         for(let i= 0; i < transactions.length; i++){
    //             if (transactions[i].type==="010"){
    //                 sumInvoices = sumInvoices +  parseFloat(transactions[i].amount)
    //             }
    //             if (transactions[i].type==="020"){
    //                 sumReceipts = sumReceipts +  parseFloat(transactions[i].amount)
    //             }
    //         }
    //         balance = sumInvoices - sumReceipts
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    // calcStats()

    //Bootstrap Tabs
    const [key, setKey] = useState('profile');
    const handleTabChange = (k) => {
        setKey(k)
    };

    // PAGINATION
    const handlePageChange = (event, value) => {
        setPage(value);
    };

    useEffect(()=>{
        if (error){
            errorRef.current.scrollIntoView();  
        }            
    },[error])

    return (
        <div className="dashboard-main">
            {order &&

            <section className="dashboard-content dashboard-content-compact">
                 <div ref={errorRef}>
                    <UserAlerts error={error} />
                </div>

                <div className="dashboard-content-header-nav">

                    {/* <DashboardHeader dashboardTitle={order.reference} dashboardPreTitle="Order" removeBorder={removeBorder} /> */}
                    <DashboardHeader dashboardTitle={formatReference(order.reference)} dashboardPreTitle="Order" removeBorder={removeBorder} />
                    <div className="dashboard-content-header-nav-icons">
                        <Tooltip title="Statement">
                            <IconButton 
                                onClick={() => navigate('/dashboard/customer-statement/',{state:{reference:params.id}})}
                            > 
                                {/* <FontAwesomeIcon icon={faFilePdf} />  */}
                                <DescriptionIcon/>
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>


              
                <Tabs
                    activeKey={key}
                    onSelect={(k) => handleTabChange(k)}
                    className="dashboard-tabs"
                    id="customer-tabs"
                    mountOnEnter={true}
                    unmountOnExit={true}
                    transition={false}
                    >
                    
                    <Tab eventKey="profile" title="Profile">
                        <OrderView 
                            order={order}
                            setError={setError}
                            // contact={contact}
                            // countries={countries}
                        />
                    </Tab>

                    <Tab eventKey="statement" title="Ledger">
                        {clientProfile &&
                        <div className="dashboard-content-subheader">
                            <div className='dashboard-card'>
                                <div className="dashboard-card-pretitle">
                                    Invoices
                                </div>
                                <div className="dashboard-card-text">
                                    {clientProfile.currency_code} {sumInvoices.toLocaleString()}

                                </div>
                            </div>
                            <div className='dashboard-card'>
                                <div className="dashboard-card-pretitle">
                                    Receipts
                                </div>
                                <div className="dashboard-card-text">
                                    {clientProfile.currency_code} {sumReceipts.toLocaleString()}

                                </div>
                            </div>
                            <div className='dashboard-card'>
                                <div className="dashboard-card-pretitle">
                                    Balance
                                </div>
                                <div className="dashboard-card-text">
                                    {clientProfile.currency_code} {balance.toLocaleString()}
                                </div>
                            </div>

                        </div>
                        }
                        <TransactionList 
                            module="OMS" 
                            transactions={transactions} 
                            order={order} 
                            handlePageChange={handlePageChange} 
                            page={page} 
                            pagecount={pagecount}
                        />
                        <TransactionNewModal 
                            isModalOpen={isModalOpen}
                            setIsModalOpen={setIsModalOpen}
                            module="OMS"
                            order={order}
                            // setCustomer={setCustomer}
                        />
                        <Fab className="fab" onClick={()=>setIsModalOpen(true)} color="primary" aria-label="add">
                            <AddIcon />
                        </Fab>
                    </Tab>

                </Tabs> 


            </section>
            }

        </div>
    );
  };
  
  export default Order;