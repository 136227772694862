import React from "react";
import useAxios from "../../components/useAxios";

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import { TreeSelect } from "antd";


// import Sidebar from "./Sidebar";
import UserAlerts from "../../components/UserAlerts";
import DashboardHeader from "../../components/DashboardHeader";
import '../../styles/TreeSelectStyles.css'


function ExpenseTypeNew() {
    const {response, error, fetchData}  = useAxios();
    // const { error, response } = useAxiosInterceptor();
    // const {redirect} = useRedirect(error, response);


    // FETCH COUNTRIES
    // const [countries, setCountries] = useState([]);

    
    const { data: accts } = useAxios({
        url: "/accounting/accounts-tree",
        method: 'get'
    })   

    // console.log(contacts)
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [nameError, setNameError] = useState(false)

    const [memo, setMemo] = useState('');
    const [acct, setAcct] = useState('');
    const [accounts, setAccounts] = useState([]);
    const [accountStatus, setAccountStatus] = useState("")
    const user = localStorage.getItem('userID');


    const { data: clientProfile } = useAxios({
        url: `/clientprofiles/${localStorage.getItem('clientID')}`,
        method: 'get'
    })  

    const transformDataToTree = (data) => {
        return data.map((item) => ({
            value: item.id.toString(), // Convert to string if needed
            title: item.name,
            children: item.sub_accounts ? transformDataToTree(item.sub_accounts) : [],
        }));
    };

    const filterTreeNode = (input, treeNode) => {
        const nodeLabel = treeNode.title.toLowerCase(); // Access title directly
        return nodeLabel.includes(input.toLowerCase());
    };


    const handleSubmit = (e) => {
        e.preventDefault();

        // console.log(name, institution, email)

        if (name === '') {
            setNameError(true)
        }

        
        // if (name && mobile && email && idNumberError === false ) {
        if (name && accountStatus !== "error") {
            const client = clientProfile.id
            // const account = parseInt(acct)
            const account = acct
            const expenseType = {client, name, account, memo, user};
            console.log(expenseType)

            fetchData({
                url: '/hrm/expense-types/',
                method: 'post',
                data: expenseType,
              })
        } 
    
    }

    useEffect(()=>{
        if (accts.length){
            setAccounts(transformDataToTree(accts))
        }

    },[accts.length])

    useEffect(() => {
        if (response.status === 201){
            navigate('/dashboard/expense-types/');
        }    
    }, [ response.status]); 

    return(

        <div className="dashboard-main">
            <section className="dashboard-content">

            <section className="dashboard-form dashboard-form-compact">
                <UserAlerts error={error} />
                <DashboardHeader dashboardTitle="Add Expense Type" dashboardPreTitle="HRM" />

                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <div className="grouped-fields-light">

                    <TextField className="custom-input"
                        onChange={(e) => {
                            setNameError(false)
                            setName(e.target.value)
                        }}
                        label="Name" 
                        variant="outlined" 
                        fullWidth
                        required
                        error={nameError}
                    />

                    <TreeSelect
                        className="custom-tree-select"
                        style={{
                            width: '100%',
                        }}
                        dropdownStyle={{
                            maxHeight: 400,
                            overflow: 'auto',
                        }}
                        placeholder="Account"
                        status={accountStatus}
                        allowClear
                        showSearch
                        onChange={(e)=>{
                            // setAccountStatus("")
                            const selectedAccount = accounts.find((account) => account.value === e);
                            selectedAccount && selectedAccount.children.length?setAccountStatus("error"):setAccountStatus("")
                            setAcct(e)
                        }}
                        treeData={accounts}
                        filterTreeNode={filterTreeNode} //Enables search filter
                    />

                    <TextField className="custom-input"
                        onChange={(e) => setMemo(e.target.value)}
                        label="Memo"
                        variant="outlined"
                        multiline
                        rows={3}
                        fullWidth
                    />

                </div>

                <div className="button-border-top">
                    <button id="submit" className="btn btn-primary form-buttons" type="submit">
                        Save
                    </button>
                </div>


                </form>

            </section>
            </section> 

        </div>

    )

}

export default ExpenseTypeNew;
