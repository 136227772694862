import React from 'react';
import { Drawer } from "@mui/material";
import Sidebar from './Sidebar';
import TransactionNew from '../features/accounting/TransactionNew';

// const FormDrawer  = ({children }) => {
const FormDrawer = ({children, isDrawerOpen, handleDrawerClose}) => {
    // console.log("ISDRAWEROPEN",isDrawerOpen)
    return(
        <Drawer
            variant='temporary'
            // anchor='left'
            anchor="right"
            role='presentation'
            open={isDrawerOpen}
            // open={true}
            onClose={handleDrawerClose}
            className="dashboard-drawer"
            PaperProps={{
                sx: { width: "50%" },
              }}
        >
             {/* <TransactionNew handleDrawerClose={handleDrawerClose}/>  */}
             
             { children }

        </Drawer>

    )
}

export default FormDrawer;