// import React from "react";
import { useState, useEffect } from "react";
import { useQuery } from '@tanstack/react-query';

// import useAxios from "../../components/useAxios";
import authAxios from "../../components/authAxios";

import { Link } from "react-router-dom";
import Pagination from '@mui/material/Pagination';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import DashboardHeader from "../../components/DashboardHeader";
import { useMediaQuery } from 'react-responsive'
import { Card, CardContent } from "@mui/material";


import ThreeDotsMenu from "../../components/ThreeDotsMenu";


function EbayMapping() {
    // const isMobile = useMediaQuery({ query: '(max-width: 750px)' })
    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();

    // const { response } = useAxiosInterceptor();
    // const abortController = new AbortController()
    // const { data, fetchData}  = useAxios();   
    // const { data:mappingData, fetchData:fetchMappingData}  = useAxios();   


    // const [mapping, setMapping] = useState([]);
    // const fetchMapping = () => {
    //     fetchMappingData({
    //         url: `/amazon/amazon-transaction-mapping/?page=${page}`,
    //         method: 'get',
    //       })
    // }

    const { data: mapping } = useQuery({
        queryKey: ['eBayMapping', page],
        queryFn: async () => {
            const response = await authAxios.get(`/ebay/ebay-transaction-mapping/?page=${page}`);
            // console.log("TXNS",response.data)
            setPageCount(response.data.page_count)
            return response.data.results
        },
    });

    const handlePageChange = (event, value) => {
        setPage(value);
    };

     const threeSubMenu = [
        {
            id: 1,
            menu: "View",
            url: "/dashboard/customer/",

        }, 
        // {
        //     id: 2,
        //     menu: "Edit",
        //     url: "/dashboard/customeredit/",
        // }
    ];

    // useEffect (()=>{
    //     fetchMapping()
    // },[]) //IMPORTANT: RUN API CALL ONLY ONCE!

    // useEffect (() => {
    //     if (mappingData.count){
    //         setPageCount(mappingData.page_count)
    //         setMapping(Array.from(mappingData.results))
    //     }
    // },[ mappingData.count])
    

    return (
            <div className="dashboard-main">
                <section className="dashboard-content">
                    <DashboardHeader dashboardTitle="Transaction Mapping" dashboardPreTitle="eBay" />
                    

                    <table className="styled-table">
                        <thead>
                            <tr >
                                <th>ID</th>
                                {/* <th>Code</th> */}
                                <th>Name</th>
                                <th>eBay Trans Type</th>
                                <th>GL Trans Type</th>
                                <th>Debit Account</th>
                                <th>Credit Account</th>
                                <th></th>
                            </tr>
                        </thead>
                        {mapping && mapping.map(ebayMapping => ( 
                        // {expenseTypes && expenseTypes.map(expenseType => (

                            <tbody key={ebayMapping.id}>
                                <tr>
                                    <td>{ebayMapping.id}</td>
                                    <td>{ebayMapping.description}</td>
                                    <td>{ebayMapping.ebay_type_name}</td>
                                    <td>{ebayMapping.gl_type_name}</td>
                                    <td>{ebayMapping.debit_account_name}</td>
                                    <td>{ebayMapping.credit_account_name}</td>
                                    <td className='styled-table-menu'>
                                        <ThreeDotsMenu data={ebayMapping} threeSubMenu={threeSubMenu} />
                                    </td>
                                </tr>
                            </tbody>
                        ))}
                        

                    </table>

                    <Pagination 
                        className="custom-mui-pagination"
                        count={pagecount} 
                        page={page} 
                        onChange={handlePageChange} 
                    />

                    <Link to="/dashboard/ebay-mapping-new" className="nav-link">
                        <Fab className="fab" color="primary" aria-label="add">
                            <AddIcon />
                        </Fab>
                    </Link>
                </section>
            </div>

    )
}
export default EbayMapping