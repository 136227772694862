import React, {memo} from "react";
import useAxios from "../../components/useAxios";

import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Radio } from "@mui/material";
import { RadioGroup } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import NumberFormat from "react-number-format";
import { TextField } from "@mui/material";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Autocomplete } from "@mui/material";
import moment from 'moment';
import UserAlerts from "../../components/UserAlerts";
import { useCountRenders } from "../../hooks/useCountRenders";


// const CustomerEdit = () => {

const EmployeeEdit = ({employee, contact, countries}) => {

    // useCountRenders()
    const params = useParams()
    // const [isUpdated, setIsUpdated] = useState(false)

    const {fetchData, data, response}  = useAxios();
    // const { data:customerData, fetchData:fetchCustomerData}  = useAxios();   


    const [dt, setDt] = useState(new Date());

    const [name, setName] = useState(employee.name);
    const [email, setEmail] = useState(employee.email);
    // const [institution, setInstitution] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [id_type, setId_type] = useState('');
    const [id_number, setId_number] = useState('');
    const [gender, setGender] = useState('');
    const [country, setCountry] = useState('');
    // const [countryIndex, setCountryIndex] = useState('');

    const [website, setWebsite] = useState('');

    // const [isCompany, setIsCompany] = useState(false);
    const [isPerson, setIsPerson] = useState(false);


    const client = localStorage.getItem('clientID');
    const user = localStorage.getItem('userID');

    // const [customer, setCustomer] = useState([]);
    // const [contact, setContact] = useState("");

    const [nameError, setNameError] = useState(false)
    const [phoneError, setPhoneError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [idNumberError, setIdNumberError] = useState(false)

    const navigate = useNavigate();

    const handleChange = (event) => {
        setName(event.target.value);
      };

    const handleIdNumber = (e) => {
        if (e.target.value.length>9){
            setIdNumberError(true)
        }
        else{
            setIdNumberError(false)
            setId_number(e.target.value)
        }
    }

    // const getDefaultValues = () => {
    //     const country1 = countries.find(country => country.id === employee.country)
    //     setCountry(countries.indexOf(country1))
    //     function findCountryIndex() {
    //         return countries.find(country => country.id === employee.country);
    //     }
    //     setName(employee.name)
    //     setGender(employee.gender)
    //     setAddress(employee.address)    
    //     setEmail(employee.email) 
    // }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (name === '') {
            setNameError(true)
        }

        if (name ) {
            const telephone = phone.replace(/[()" "]/g,"")

            const birth_date = moment(dt).format("YYYY-MM-DD")

            const employee = { client, contact, name, address, country, email, telephone, id_type, id_number, gender, birth_date, user };
            // console.log(customer)

            fetchData({
                url: `hrm/employees/${params.id}/`,
                method: 'put',
                data: employee
            })
            .then(()=>{             
                if (response.status === 200){
                    navigate('/dashboard/employees/')

                }
            })
        } 
    }


    // useEffect(() => {
    //     if (employee && contact){
    //         getDefaultValues()
    //         if (employee.type==="10"){
    //             setIsPerson(true)
    //         }
    //     }
    // }, [employee, contact]); 


    return(
    // <Layout>

        <section className="dashboard-form">
            {/* {contact && */}
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <div className="grouped-fields-light">
                    <TextField className="custom-input"
                        value={name}
                        onChange={(e) => {
                            setNameError(false)
                            setName(e.target.value)
                            
                        }}
                        // onChange={handleChange}
                        label="Name" 
                        InputLabelProps={{ shrink: true }}
                        variant="outlined" 
                        fullWidth
                        required
                        error={nameError}
                    />
                    <TextField className="custom-input"
                        // value={email}
                        value={email ?? ''}

                        // value={customer.email}

                        onChange={(e) => {
                            setEmailError(false)
                            setEmail(e.target.value)
                        }}
                        label="Email" 
                        InputLabelProps={{ shrink: true }}
                        variant="outlined" 
                        fullWidth
                        required
                        error={emailError}
                    />

                    <NumberFormat 
                        value = {phone}
                        // value = {customer.telephone}

                        onChange={(e) => {
                            setPhoneError(false)
                            setPhone(e.target.value)
                        }}
                        className="custom-input"
                        fullWidth
                        label="Mobile" 
                        variant="outlined" 
                        customInput={TextField}
                        format="### (###) ######" mask="_"
                        // required
                        error={phoneError}
                    />

                    {/* {contact && */}
                    <TextField className="custom-input"
                        // value={address}
                        value={address ?? ''}

                        // value={contact.address}
                        onChange={(e) => setAddress(e.target.value)}
                        label="Address" 
                        InputLabelProps={{ shrink: true }}
                        variant="outlined" 
                        fullWidth
                        // required
                        // error={mobileError}
                    />
                    {/* } */}

                    {country &&

                    <Autocomplete
                        // value={country}
                        value={ countries[country]}

                        // value={country ?? ''}
                        // getOptionSelected={(option, value) => option.value === value.value}

                        onChange={(event, newValue) => {
                            setCountry(newValue.id);
                            console.log("VALUE", newValue.id)
                            }}
                        options={countries}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField className="custom-input" fullWidth {...params} label="Country"  /> }
                    />
                    }

                    <TextField className="custom-input"
                        value={website}
                        onChange={(e) => setWebsite(e.target.value)}
                        label="Website" 
                        variant="outlined" 
                        fullWidth
                        // required
                        // error={mobileError}
                    />  
                </div>
                {isPerson &&
                <>

                <div className="grouped-fields-light">
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                    >
                        <FormControlLabel className="custom-radio" value="F" control={<Radio />} label="Female" />
                        <FormControlLabel className="custom-radio" value="M" control={<Radio />} label="Male" />
                    </RadioGroup>


                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DesktopDatePicker
                            label="Date of Birth"
                            value={dt}
                            onChange={(newDate) => {
                                setDt(moment(newDate).format("YYYY-MM-DD"));
                                }}
                            renderInput={(params) => <TextField fullWidth required className="custom-input" {...params} />}
                        />
                    </LocalizationProvider>
                </div>
                </>
                }

                <div className="button-border-top">
                    <button id="submit" className="btn btn-primary form-buttons" type="submit">
                        Save
                    </button>
                </div>
            </form>
            {/* } */}
        </section>

    )

}

export default EmployeeEdit;

