import React from 'react';
import { Drawer } from "@mui/material";
import Sidebar from './Sidebar';
import SidebarMobile from './SidebarMobile';

const MobileDrawer = ({isDrawerOpen, handleDrawerClose}) => {
    return(
        <Drawer
            variant='temporary'
            anchor='left'
            role='presentation'
            open={isDrawerOpen}
            onClose={handleDrawerClose}
            className="dashboard-drawer"
            PaperProps={{
                sx: { width: "70%" },
              }}
        >
            {/* <Sidebar /> */}
            <SidebarMobile />
        </Drawer>
    )
}

export default MobileDrawer;
