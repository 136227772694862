import { useEffect, useState, useRef } from "react"
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import authAxios from "../../components/authAxios";
import DashboardHeader from "../../components/DashboardHeader";

import { IconButton } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import PrintIcon from '@mui/icons-material/Print';
import DescriptionIcon from '@mui/icons-material/Description';
import Tooltip from '@mui/material/Tooltip';


//TABS - BOOTSTRAP
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import TransactionList from "../accounting/TransactionList";
// import CustomerEdit from "./CustomerEdit";
import EmployeeEdit from "./EmployeeEdit";

import UserAlerts from "../../components/UserAlerts";


const Employee = () => {
    const params = useParams()
    const navigate = useNavigate()
    const [error, setError] = useState('');
    const errorRef = useRef(null);

    const employeeId = params.id

    const [removeBorder, setRemoveBorder] = useState(true)
    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();

    const { data: clientProfile } = useQuery({
        queryKey: ['clientProfile'],
        queryFn: async () => {
          const response = await authAxios.get(`/clientprofiles/${localStorage.getItem('clientProfile')}`);
          return response.data

        },
    });

    const { data: transactions } = useQuery({
        queryKey: ['employee-statement', employeeId, page],
        queryFn: async () => {
          const response = await authAxios.get(`/accounting/employee-ledger/?employee=${employeeId}&page=${page}`);
          setPageCount(response.data.page_count)
          return response.data.results
        },
    });

    const { data: employee } = useQuery({
        queryKey: ['employee',employeeId],

        queryFn: async () => {
          const response = await authAxios.get(`hrm/employees/${employeeId}`);
          return response.data
        },
    });

    // const { data: countries } = useQuery({
    //     queryKey: ['countries'],
    //     queryFn: async () => {
    //       const response = await authAxios.get('/countries');
    //       return response.data
    //     },
    // });


    //CALC STATS
    let sumInvoices = 0
    let sumReceipts = 0
    let balance = 0
        
    const calcStats = () => {
        try {
            for(let i= 0; i < transactions.length; i++){
                // if (transactions[i].type==="010"){
                if (transactions[i].entry_type==="D"){

                    sumInvoices = sumInvoices +  parseFloat(transactions[i].amount)
                }
                // if (transactions[i].type==="020"){
                if (transactions[i].entry_type==="C"){
                    sumReceipts = sumReceipts +  parseFloat(transactions[i].amount)
                }
            }
            balance = sumInvoices - sumReceipts
        } catch (error) {
            console.log(error);
        }
    }

    calcStats()

    //Bootstrap Tabs
    const [key, setKey] = useState('profile');
    const handleTabChange = (k) => {
        setKey(k)
    };

    // PAGINATION
    const handlePageChange = (event, value) => {
        setPage(value);
    };

    // useEffect(()=>{
    //     if (error){
    //         errorRef.current.scrollIntoView();  
    //     }            
    // },[error])


    return (
        <div className="dashboard-main">
            {employee && 

            <section className="dashboard-content dashboard-content-compact">
                 <div ref={errorRef}>
                    <UserAlerts error={error} />
                </div>

                <div className="dashboard-content-header-nav">
                    <DashboardHeader dashboardTitle={employee.name} dashboardPreTitle="Customer" removeBorder={removeBorder} />
                    <div className="dashboard-content-header-nav-icons">
                        <Tooltip title="Statement">
                            <IconButton 
                                onClick={() => {
                                    const tag = "EMPLOYEE";
                                    // navigate('/dashboard/customer-statement/',{state:{reference:params.id, tag:tag}})
                                    navigate('/dashboard/statement/',{state:{reference:params.id, tag:tag}})

                                }}
                            > 
                                {/* <FontAwesomeIcon icon={faFilePdf} />  */}
                                <DescriptionIcon/>
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
              
                <Tabs
                    activeKey={key}
                    onSelect={(k) => handleTabChange(k)}
                    className="dashboard-tabs"
                    id="customer-tabs"
                    // mountOnEnter={true}
                    // unmountOnExit={true}
                    // transition={false}
                    >
                    
                    <Tab eventKey="profile" title="Profile">
                        <EmployeeEdit 
                            employee={employee}
                            setError={setError}
                        />
                    </Tab>

                    <Tab eventKey="statement" title="Ledger">
                        {clientProfile &&
                        <div className="dashboard-content-subheader">
                            <div className='dashboard-card'>
                                <div className="dashboard-card-pretitle">
                                    Debits
                                </div>
                                <div className="dashboard-card-text">
                                    {clientProfile.currency_code} {sumInvoices.toLocaleString()}

                                </div>
                            </div>
                            <div className='dashboard-card'>
                                <div className="dashboard-card-pretitle">
                                    Credits
                                </div>
                                <div className="dashboard-card-text">
                                    {clientProfile.currency_code} {sumReceipts.toLocaleString()}

                                </div>
                            </div>
                            <div className='dashboard-card'>
                                <div className="dashboard-card-pretitle">
                                    Balance
                                </div>
                                <div className="dashboard-card-text">
                                    {clientProfile.currency_code} {balance.toLocaleString()}
                                </div>
                            </div>

                        </div>
                        }
                        <TransactionList 
                            module="HRM" 
                            transactions={transactions} 
                            handlePageChange={handlePageChange} 
                            page={page} 
                            pagecount={pagecount}
                        />
                    </Tab> 
                </Tabs> 


            </section>
            } 

        </div>
    );
  };
  
  export default Employee;