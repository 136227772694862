import { useState, useEffect, useCallback } from "react";
// import {useNavigate} from "react-router-dom";

import useAxios from "../../components/useAxios";

import EditIcon from '@mui/icons-material/Edit';
import { Divider, Fab, TextField, FormControlLabel, Switch, styled } from "@mui/material";


// FORM DIALOGUE
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
// import { UserContext } from "../../App";


const EbaySettings = ({clientProfile}) => {
    // const navigate = useNavigate();
    // const page_size = useContext(UserContext);
    const {fetchData, response}  = useAxios();
    const [open, setOpen] = useState(false);

    const client = localStorage.getItem('clientID');
    // const user = localStorage.getItem('userID');

    // FETCH EBAY CONFIG
    const { data: ebayProfiles } = useAxios({
        url: "/ebay/settings",
        method: 'get'
    })  
    const ebayProfile = ebayProfiles.find(profile => profile.client === clientProfile.client);

    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
      ))(({ theme }) => ({
        width: 42,
        height: 26,

        padding: 0,
        '& .MuiSwitch-switchBase': {
          padding: 0,
          margin: 2,
          transitionDuration: '300ms',
          '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
              opacity: 1,
              border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            color:
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[600],
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
          },
        },
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
          width: 22,
          height: 22,
        },
        '& .MuiSwitch-track': {
          borderRadius: 26 / 2,
          backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
          opacity: 1,
          transition: theme.transitions.create(['background-color'], {
            duration: 500,
          }),
        },
      }));

    
    // const [syncOffers, setSyncOffers] = useState(Boolean(ebayProfile.sync_offers));
    // const [syncOffers, setSyncOffers] = useState(false);

    // const handleSyncOffers = useCallback((e) => {
    //     console.log("E",e)
    //     // setSyncOffers(e.target.checked);
    //     setSyncOffers(e);

    // }, []);

    // useEffect(() => {

    //     if (ebayProfile){
    //         setSyncOffers(Boolean(ebayProfile.sync_offers))
    //     }
    // }, [syncOffers]); 
    

    // const [autoRelistingQty, setAutoRelistingQty] = useState(ebayProfile.auto_relisting_quantity);
    // const [autoRelistingQty, setAutoRelistingQty] = useState('');

    // const handleAutoRelistingQty = useCallback((e) => {
    //   console.log("WE ARE HERE")
    //   setAutoRelistingQty(e.target.value);
    // }, []);

    const handleClose = useCallback(() => {
      setOpen(false);
    }, []);


    useEffect(() => {
      // if (ebayProfile) {
      //   setAutoRelistingQty(ebayProfile.auto_relisting_quantity);
      // }
      console.log("USE EFFECT",open)
    }, [ebayProfile, open]);


    // const EditSettings = ({ open, syncOffers, handleSyncOffers }) => {

    const EditSettings = ({ open, handleClose }) => 
      {
        const [autoRelistingQty, setAutoRelistingQty] = useState(ebayProfile.auto_relisting_quantity);
        const [syncOffers, setSyncOffers] = useState(Boolean(ebayProfile.sync_offers));
        const [autoUpdateOos, setAutoUpdateOos] = useState(Boolean(ebayProfile.auto_update_oos));
       
        // const handleClose = useCallback(() => {
        //   setOpen(false);
        // }, []);

        const handleAutoRelistingQty = useCallback((e) => {
            setAutoRelistingQty(e.target.value);
          }, []);

        const handleSyncOffers = useCallback((e) => {
            setSyncOffers(e.target.checked);
        }, []);

        const handleAutoUpdateOos = useCallback((e) => {
            setAutoUpdateOos(e.target.checked);
        }, []);
      
        const handleSubmit = (e) => {
            e.preventDefault();
            
            if (autoRelistingQty ) {
                const auto_relisting_quantity = autoRelistingQty
                const sync_offers = syncOffers
                const auto_update_oos = autoUpdateOos

                const cp = { client, sync_offers, auto_update_oos, auto_relisting_quantity };
                console.log(cp)

                fetchData({
                    url: `/ebay/settings/${clientProfile.id}/`,
                    method: 'put',
                    data: cp
                })
                .then(()=>{                    
                    if (response.status === 200){
                        // console.log("ID",ebayProfile.id)
                        handleClose()
                    }
                })
            } 
        };

      
        return (
          <div>
            <Dialog className="dashboard-modal" open={open} onClose={handleClose} fullWidth>
              <DialogTitle>EBay Settings</DialogTitle>
              <DialogContent>
                <form noValidate autoComplete="off" onSubmit={handleSubmit}>

                    <FormControlLabel
                        className="custom-switch"
                        control={<IOSSwitch 
                            checked={syncOffers}
                            onChange={handleSyncOffers}
                            // onChange={handleSyncOffersChange}
                            // onChange={(e)=>{
                            //     console.log("E",e.target.checked)
                            //     handleSyncOffers(e.target.checked)
                            // }}
                            sx={{ m: 1 }} />}
                        label="Sync Offers"
                    />
                    <Divider />

                    <FormControlLabel
                        className="custom-switch"
                        control={<IOSSwitch 
                            checked={autoUpdateOos}
                            onChange={handleAutoUpdateOos}
                            sx={{ m: 1 }} />}
                        label="Auto Update Out of Stock Quantities"
                    />
                    <Divider />

                    <TextField
                        value={autoRelistingQty}
                        onChange={handleAutoRelistingQty}
                        className="custom-input"
                        label="Auto Relisting Quantity"
                        fullWidth
                        variant="standard"
                    />

                </form>
              </DialogContent>
              <DialogActions>
                <button onClick={handleClose} id="cancel" className="btn btn-secondary form-buttons">
                  Cancel
                </button>
                <button onClick={handleSubmit} id="submit" className="btn btn-primary form-buttons" type="submit">
                  Save
                </button>
              </DialogActions>
            </Dialog>
          </div>
        );
      };

    return (
        <>
        {ebayProfile &&
        <>
            {/* <EditSettings open={open} syncOffers={syncOffers} handleSyncOffers={handleSyncOffers}/> */}
            {/* <EditSettings open={open} autoRelistingQty={autoRelistingQty} handleAutoRelistingQty={handleAutoRelistingQty}/> */}

            <EditSettings open={open} handleClose={handleClose}/>
            <div className='dashboard-card' id="settings-card">
                <div className="settings-card-pretitle">
                    Sync Offers
                </div>
                <div className="settings-card-text">
                    <FormControlLabel
                        className="custom-switch"
                        control={<IOSSwitch 
                            checked={ebayProfile.sync_offers}
                            sx={{ m: 1 }} />}
                    />

                </div>

            </div>
            <Divider />

            <div className='dashboard-card' id="settings-card">
                <div className="settings-card-pretitle">
                    Auto Update Out of Stock Quantities
                </div>
                <div className="settings-card-text">
                    <FormControlLabel
                        className="custom-switch"
                        control={<IOSSwitch 
                            checked={ebayProfile.auto_update_oos}
                            sx={{ m: 1 }} />}
                    />

                </div>
            </div>
            <Divider />

            <div className='dashboard-card' id="settings-card">
                <div className="settings-card-pretitle">
                    Auto Re-Listing Quantity
                </div>
                <div className="settings-card-text">
                    {ebayProfile.auto_relisting_quantity}
                </div>
            </div>


            <Fab className="fab" color="primary" aria-label="edit" 
                onClick={()=>{
                    setOpen(true);
                }}>

                <EditIcon />
            </Fab>
        </>
        }
        </>


    )


}

export default EbaySettings;