
import { useState, useRef, useEffect } from "react";
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import authAxios from "../../components/authAxios";

import { InputLabel } from "@mui/material";
import { FormControl } from "@mui/material";
import { TextField, Autocomplete } from "@mui/material";
import { Select } from "@mui/material";
import { MenuItem } from "@mui/material";

import UserAlerts from "../../components/UserAlerts";

import { Modal } from 'antd';
import CustomSwitch from "../../components/CustomSwitch";

const AccountNew = ({isModalOpen, setIsModalOpen}) => {
    const errorRef = useRef(null);
    const [error, setError] = useState('');

    const queryClient = useQueryClient();

    const [checked, setChecked] = useState(false);
   
    const { data: accountTypes } = useQuery({
        queryKey: ['accountTypes'],
        queryFn: async () => {
          const response = await authAxios.get("/accounting/account-types");
          return response.data
        },
    });

    const { data: accounts } = useQuery({
        queryKey: ['accounts-parent'],
        queryFn: async () => {
          const response = await authAxios.get("/accounting/accounts-parent");
          return response.data
        },
    });
    
    const [type, setType] = useState('');
    const [parent, setParent] = useState('');
    const [name, setName] = useState('');
    const [memo, setMemo] = useState('');
    const user = localStorage.getItem('userID');
    const client = localStorage.getItem('clientID');
    const [typeError, setTypeError] = useState(false)
    const [nameError, setNameError] = useState(false)

    // const navigate = useNavigate();

    const handleCancel = () => {
        setIsModalOpen(false);
        };

  
    const mutation = useMutation({
        mutationFn: (account) => {
            return authAxios.post("/accounting/accounts/", account);
        },
        onError: (error, variables, context) => {
            if (error.response.data.error){
                // If it's an array, display each error message
                if (Array.isArray(error.response.data.error)) {
                    error.response.data.error.map((errorMessage) => (
                        setError(errorMessage)
                    ));
                } else {
                    // Handle single error message
                    setError(error.response.data.error); 
                }
            } else {
                // Fallback to Standard error message
                setError(error.message); 
            }   

        },
        onSuccess: (result) => {
            queryClient.invalidateQueries(['accounts']);
            setIsModalOpen(false);
        },
      })

    const handleSubmit = (e) => {
     
        e.preventDefault();

        setNameError(false)
        setTypeError(false)

        if (name === '') {
            setNameError(true)
        }

        if (type === '' ) {
            setTypeError(true)
        }

        if (name && type ) {
            const account = { client, type, name, parent, memo, user };
            mutation.mutate(account)
        } 
    }

    useEffect(()=>{
        if (error){
            errorRef.current.scrollIntoView();  
        }            
    },[error])

    return (
        <Modal className="dashboard-modal" title="Add Account" visible={isModalOpen} open={isModalOpen} onOk={handleSubmit} onCancel={handleCancel}>
            <section className="dashboard-form">
                <div ref={errorRef}>
                    <UserAlerts error={error}  />
                </div>

                <form noValidate autoComplete="off" onSubmit={handleSubmit}>

                    <FormControl 
                        variant="outlined" 
                        fullWidth className="custom-input" 
                        error={typeError}
                        required
                    >
                        <InputLabel>Type</InputLabel>
                        <Select
                            className="custom-select"
                            label="Type"
                            onChange={(e) => {
                                setTypeError(false)
                                setType(e.target.value)}
                            }
                            defaultValue = ""
                        >
                            {accountTypes && accountTypes.map((accountType) => { 
                                return(
                                <MenuItem key={accountType.code} value={accountType.id}>{accountType.name}</MenuItem>
                                )
                            })}   
                            
                        </Select>
                    </FormControl>


                    <TextField className="custom-input"
                        onChange={(e) => {
                            setNameError(false)
                            setName(e.target.value)}
                        }
                        label="Name" 
                        variant="outlined" 
                        // color="secondary" 
                        fullWidth
                        required
                        error={nameError}
                    />
                    <div className="custom-switch-container">
                        <CustomSwitch checked={checked} setChecked={setChecked}/>
                        <div className="custom-switch-label">
                            Is this a sub account?
                        </div>
                    </div>

                    {checked &&

                    <Autocomplete
                        // onChange={(e, newValue) => updateAccount(newValue)}  
                        onChange={(event, newValue)=>setParent(newValue.id)}
                        options={accounts}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField className="custom-input" {...params} label="Parent Account" /> }
                    />
                    }

                    <TextField className="custom-input"
                        onChange={(e) => setMemo(e.target.value)}
                        label="Memo"
                        variant="outlined"
                        multiline
                        rows={3}
                        fullWidth
                    />

                </form>
            
            
            </section>
        </Modal>

    ) 
}

export default AccountNew;
