import React from "react"
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { fab } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

// import {BrowserRouter, Routes, Route, Link} from "react-router-dom";

//import Contact from "./Contact";


function FooterSlim() {
    return (
        <>
        <footer className="footer">
            <div className="footer-logo">
                {/* <Link to={{pathname: "https://twitter.com/getgesti"}}>
                    <TwitterIcon/>
                </Link> */}
                <a href="https://twitter.com/getzidi" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faTwitter} /> 
                </a>
                {/* <a href="https://www.facebook.com/getgesti/" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-facebook"></i>
                    <FontAwesomeIcon icon={faFacebook} /> 
                </a> */}
                {/* <Link to="/contact">
                    <InstagramIcon/>
                </Link> */}
                <a href="https://instagram.com/getzidi" target="_blank" rel="noopener noreferrer">
                    {/* <FontAwesomeIcon icon={faTwitter} />  */}
                    <InstagramIcon/>
                </a>

                
            </div>

            {/* <div className="footer-section">
                <div className="footer-section-title">Solutions</div>
                <ul className="footer-list">
                    <li><a href="/">AirBnB/Furnished Apartments</a></li>
                    <li><a href="/">Student Accommodation</a></li>
                    <li><a href="/">Hostels</a></li>
                    <li><a href="/">Bed and Breakfast Hotels</a></li>
                </ul>
            </div>

            <div className="footer-section footer-section-company">
                <div className="footer-section-title">
                    Company
                </div>
                <ul className="footer-list">
                    <li><a href="/">About</a></li>
                    <li><a href="/">Team</a></li>
                    <li><a href="/">Careers</a></li>
                </ul>
            </div>

            <div className="footer-section footer-section-legal">
                <div className="footer-section-title">Legal</div>
                <ul className="footer-list">
                    <li><a href="/">Privacy Notice</a></li>
                    <li><a href="/">Terms and Conditions</a></li>
                </ul>
            </div>

            <div className="footer-section">
                <div className="footer-section-title">Connect</div>
                <ul className="footer-list">
                    <Link to="/contact">Contact</Link>
                </ul>
            </div> */}
            
        </footer>
        {/* <section className="footer-copyright">
            © 2022 Stella. All rights reserved.
        </section> */}
        </>
    )
  }

  export default FooterSlim;
