import React, { useEffect } from "react"
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import useAxios from "../../components/useAxios";
import DashboardHeader from "../../components/DashboardHeader";
import { useMediaQuery } from 'react-responsive'

// import NumberFormat from "react-number-format";

//TABS - BOOTSTRAP
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import TransactionList from "../accounting/TransactionList";
// import ItemEdit from "./ItemEdit";
import OfferEdit from "./OfferEdit";
import Relisting from "./Relisting";


const Offer = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 750px)' })
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)

    const params = useParams()
    const navigate = useNavigate()

    const { data, fetchData}  = useAxios();   
    // const { data: transactionsData, fetchData: fetchTransactionsData } = useAxios();


    // FETCH CLIENT PROFILE
    const clientID = localStorage.getItem('clientID');
    const clientProfileID = localStorage.getItem('clientProfile');

    const { data: clientprofile } = useAxios({
        url: `/clientprofiles/${clientProfileID}`,
        method: 'get'
    })  


    // FETCH INVENTORY ITEM
    // const { data: item } = useAxios({
    //     url: `/inventory/items/${params.id}`,
    //     method: 'get'
    // })  

    const { data: offer } = useAxios({
        url: `/ebay/offers/${params.id}`,
        method: 'get'
    })  

    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();

    const [transactions, setTransactions] = useState([]);
    const fetchTransactions = (testName) => {
        fetchData({
            url: `/accounting/inventory-ledger/?item=${params.id}&page=${page}`,
            method: 'get',
            identifier: 'transactions',
        })
        if (data.page){
            setTransactions(Array.from(data.results))
            setPageCount(data.page_count)
        }
    
    }

    //FETCH TRANSACTION TYPES 
    const { data: ttypes } = useAxios({
        url: '/accounting/transaction-types',
        method: 'get'
    })
    
    
    const invoice = ttypes.find(function(ttype) {
        return (ttype.code === "10");
    });


    const receipt = ttypes.find(function(ttype) {
        return (ttype.code === "20");
    });


    const handleFabClick = () => {
        navigate('/ratenew/',{state:{property:params.id}})
    }
    
    
    let sumQuantity = 0
    let sumInventoryValue = 0
       
    const calcStats = () => {
        try {
            for(let i= 0; i < transactions.length; i++){
                // if (transactions[i].type==="200"){
                    sumQuantity = sumQuantity +  parseFloat(transactions[i].quantity)
                    sumInventoryValue = sumInventoryValue +  parseFloat(transactions[i].amount)

            }
        } catch (error) {
            console.log(error);
        }

    }
    calcStats()

    const [key, setKey] = useState('profile');
    const [removeBorder, setRemoveBorder] = useState(true);

    const activeTabStatement = () => {
        setKey('statement')
    }

    // PAGINATION
    const handlePageChange = (event, value) => {
        setPage(value);
        console.log("PAGE", page)
        // fetchTransactions()
    };


    useEffect(()=>{
        fetchTransactions()
        // if (data.page){
        //     setTransactions(Array.from(data.results))
        //     setPageCount(data.page_count)
        //     // console.log("WE ARE HERE", data)
        // }
    // },[isDrawerOpen, key, page, data.page])
    },[ page, data.page])


    // console.log("ITEM", item)

    return (
        // <Layout>
            <div className="dashboard-main">

                <section className="dashboard-content">

                    <DashboardHeader dashboardTitle={offer.name} dashboardPreTitle="Listings" removeBorder={removeBorder} />

                    <Tabs
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="dashboard-tabs"
                        >
                        <Tab eventKey="profile" title="Profile">
                            <OfferEdit />
                        </Tab>

                        {/* <Tab eventKey="statement" title="Statement"> */}

                        <Tab eventKey="ledger" title="Re-Listing">
                            {/* <div className="dashboard-content-subheader">
                                <div className='dashboard-card'>
                                    <div className="dashboard-card-pretitle">
                                        Quantity
                                    </div>
                                    {offer &&
                                    <div className="dashboard-card-text">
                                        {sumQuantity.toLocaleString()}
                                    </div>
                                    }
                                    
                                </div>
                                <div className='dashboard-card'>
                                    <div className="dashboard-card-pretitle">
                                        Value
                                    </div>
                                    <div className="dashboard-card-text">
                                        {clientprofile.currency_code} {sumInventoryValue.toLocaleString()}
                                    </div>
                                </div>

                            </div> */}
                            <Relisting 
                                module="INV" 
                                transactions={transactions} 
                                // fetchTransactions={fetchTransactions} 
                                handlePageChange={handlePageChange} 
                                page={page} 
                                pagecount={pagecount}
                            />
                        </Tab>
                    </Tabs>     

                </section>

            </div>

    );
  };
  
  export default Offer;