// import React from "react";
// import useAxios from "../../components/useAxios";
import authAxios from "../../components/authAxios";

import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

import { useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Radio, RadioGroup, FormControlLabel, Autocomplete, InputLabel, MenuItem } from "@mui/material";
import { FormControl, Select } from "@mui/material";


import { TreeSelect } from "antd";

// IMAGE UPLOAD ANT DESIGN
// import { message, Upload } from 'antd';
// import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

// import Sidebar from "./Sidebar";
import UserAlerts from "../../components/UserAlerts";
import DashboardHeader from "../../components/DashboardHeader";
import { UserContext } from "../../App";
import ProductOptions from "./ProductOptions";
import NumberFormat from "react-number-format";

import CustomSwitch from "../../components/CustomSwitch";

import '../../styles/TreeSelectStyles.css'



const ProductNew = () => {
    const {page_size} = useContext(UserContext);
    const [error, setError] = useState('');
    const errorRef = useRef(null);

    const queryClient = useQueryClient();

    const navigate = useNavigate();

    const { data: inventoryTypes } = useQuery({
        queryKey: ['inventoryTypes'],
        queryFn: async () => {
          const response = await authAxios.get('/inventory/inventory-types');
          return response.data
        },
    });

    const { data: productTypes } = useQuery({
        queryKey: ['productTypes'],
        queryFn: async () => {
          const response = await authAxios.get('/products/product-types');
          return response.data
        },
    });

    const { data: units } = useQuery({
        queryKey: ['units'],
        queryFn: async () => {
          const response = await authAxios.get('/products/units');
          return response.data
        },
    });

    const { data: taxes } = useQuery({
        queryKey: ['taxes'],
        queryFn: async () => {
          const response = await authAxios.get('/accounting/taxes');
          return response.data
        },
    });

    const { data: accountTypes } = useQuery({
        queryKey: ['accountTypes'],
        queryFn: async () => {
          const response = await authAxios.get('/accounting/account-types');
          return response.data
        },
    });

    const { data: clientProfile } = useQuery({
        queryKey: ['clientProfile'],
        queryFn: async () => {
          const response = await authAxios.get(`/clientprofiles/${localStorage.getItem('clientProfile')}`);
          return response.data

        },
    });

    const { data: brands } = useQuery({
        queryKey: ['brands'],
        queryFn: async () => {
          const response = await authAxios.get(`/products/brands/?page_size=${page_size}`);
          return response.data.results;
        },
    });

    // const [categories, setCategories] = useState('');
    // const { data: categoriesTree } = useQuery({
    const { data: categories } = useQuery({
        queryKey: ['categories-tree'],
        queryFn: async () => {
            const response = await authAxios.get("/products/categories-tree");
            // return response.data
            return transformCategoriesTree(response.data)
        },
    });

    const { data: collections } = useQuery({
        queryKey: ['collections'],
        queryFn: async () => {
          const response = await authAxios.get(`/products/collections/?page_size=${page_size}`);
          return response.data.results;
        },
    });

    const { data: accounts } = useQuery({
        queryKey: ['accounts'],
        queryFn: async () => {
          const response = await authAxios.get(`/accounting/accounts?page_size=${page_size}`);
          return response.data.results;
        },
    });


    const validDataRef = useRef(false);

    const [revAccounts, setRevAccounts] = useState([]);
    const [expAccounts, setExpAccounts] = useState([]);
    const [inventoryAccounts, setInventoryAccounts] = useState([]);

    const [name, setName] = useState('');
    const [sku, setSku] = useState('');

    const [unitPrice, setUnitPrice] = useState('');
    const [costInput, setCostInput] = useState('');
    const [upc, setUpc] = useState('');
    const [unit, setUnit] = useState('');
    const [memo, setMemo] = useState('');
    const [brand, setBrand] = useState('');
    const [tax, setTax] = useState([]);
    const [category, setCategory] = useState([]);
    const [collection, setCollection] = useState([]);

    const [inventoryType, setInventoryType] = useState('');

    const [type, setType] = useState('');
    const [revenueAccount, setRevenueAccount] = useState('');
    const [expenseAccount, setExpenseAccount] = useState('');
    // const [debtorAccount, setDebtorAccount] = useState('');
    const [inventoryAccount, setInventoryAccount] = useState('');

    const user = localStorage.getItem('userID');
    const client = localStorage.getItem('clientID');

    const [skuError, setSkuError] = useState(false)
    const [priceError, setPriceError] = useState(false)
    const [inventoryTypeError, setInventoryTypeError] = useState(false)
    const [nameError, setNameError] = useState(false)
    const [revenueAccountError, setRevenueAccountError] = useState(false)
    const [inventoryAccountError, setInventoryAccountError] = useState(false)
    const [isGoods, setIsGoods] = useState(false);
    const [productOptions, setProductOptions] = useState([{}]);


    // Function to recursively transform data to the expected tree structure
    const transformCategoriesTree = (data) => {
        return data.map((item) => ({
            value: item.id.toString(), // Convert to string if needed
            title: item.name,
            children: item.sub_categories ? transformCategoriesTree(item.sub_categories) : [],
        }));
    };

    // const transformDataToTree = (data) => {
    //     return data.map((item) => ({
    //         value: item.id.toString(), // Convert to string if needed
    //         title: item.name,
    //         children: item.sub_accounts ? transformDataToTree(item.sub_accounts) : [],
    //     }));
    // };

    const filterTreeNode = (input, treeNode) => {
        const nodeLabel = treeNode.title.toLowerCase(); // Access title directly
        return nodeLabel.includes(input.toLowerCase());
    };

    const [hasVariants, setHasVariants] = useState(false);
    const handleChange = (event) => {
        setHasVariants(event.target.checked);
        // console.log(event.target.checked)
    };

    // const [checked, setChecked] = useState(false);

    const handleProductType = (e) => {
        const ptype = productTypes.find(productType => productType.code === e.target.value);
        setType(ptype.id)
        // let accTypeAsset = []
        // setType(e.target.value)
        if (e.target.value==="20"){
            setIsGoods(true)
            // accTypeAsset = accounttypes.find(accounttype => accounttype.code === "30300");
        }
        else {
            setIsGoods(false)
            // accTypeAsset = accounttypes.find(accounttype => accounttype.code === "30200");
        }
        // const accts1 = accts.filter(acct => acct.type === "30200");
        // revAccts = accounts.filter(revAcct => revAcct.type === "10100");
        // console.log("ACCOUNT",accounts)

        // setCategories(transformCategoriesTree(categoriesTree))


        const typeRev = accountTypes.find(accountType => accountType.code === "10100");
        setRevAccounts(accounts.filter(account => account.type === typeRev.id))

        // const typeExp = accounttypes.find(accounttype => (accounttype.code === "20100" || accounttype.code === "20200" ));
        const typeExp = accountTypes.find(accountType => accountType.code === "20300");
        setExpAccounts(accounts.filter(account => account.type === typeExp.id))

        const typeInventory = accountTypes.find(accountType => accountType.code === "30300");
        setInventoryAccounts(accounts.filter(account => account.type === typeInventory.id))
    }

    const handleCategory = (e) => {
        // setAccountStatus("")
        console.log("E", e)
        setCategory(e)
        // const newCategory = [...category,e]
        // setCategory(newCategory)

        // setCategory(e)
        // const selectedAccount = accounts.find((account) => account.value === e);
        // const selectedCategory = accounts.find((account) => account.value === e);
        // console.log("SELECTED ACCOUNT", selectedAccount)

        // if (selectedAccount && selectedAccount.children.length) {
        //     setAccountStatus("error")
        //     return;
        //   }
    }


    const mutation = useMutation({
        mutationFn: (product) => {
            return authAxios.post("/products/products/", product);
        },
        onError: (error, variables, context) => {
            setError(error.message)
            errorRef.current.scrollIntoView();              
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(['products']);
            navigate('/dashboard/products/');
        },
      })

    const handleSubmit = (e) => {
        e.preventDefault();
        validDataRef.current=true

        if (clientProfile.auto_sku===false && sku === '') {
            setSkuError(true)
            validDataRef.current=false
        }

        if (name === '') {
            setNameError(true)
            validDataRef.current=false
        }
       
        // if (debtorAccount === '') {
        //     setDebtorAccountError(true)
        // }

        if (revenueAccount === '') {
            setRevenueAccountError(true)
            validDataRef.current=false
        }

        if (isGoods && clientProfile.inventory_tracking && inventoryAccount === '') {
            setInventoryAccountError(true)
            validDataRef.current=false
        }
        if (isGoods && (unitPrice === '')) {
            setPriceError(true)
            validDataRef.current=false
        }
        // console.log("UNIT PRICE",unitPrice)
        // console.log("GOODS?",isGoods)
        // console.log("REF",validDataRef)
       
        // if (name && revenueAccount ) {
        if (validDataRef.current===true) {

            //Change the format of Price field before saving
            // let price = 0
            const price = parseFloat(unitPrice.replace(/,/g, ''));

            let cost = 0
            cost = parseFloat(costInput.replace(/,/g, ''));

            let inventory_type=""
            if (isGoods){
                inventory_type = inventoryType
            }
            else{
                // IF PRODUCT IS A SERVICE, SET TYPE TO FINISHED GOODS
                inventory_type = inventoryTypes.find(type => type.code === "10").id
            }

            const revenue_account = revenueAccount
            const expense_account = expenseAccount
            const inventory_account = inventoryAccount
            const product_options = productOptions
            const has_variants = hasVariants

            // PRICELIST IMPLEMENTATION
            const price_type =  "010" //Default to fixed price until further implementation


            const product = { 
                client, sku, name, upc, unit, cost, price, price_type, type, inventory_type, category, collection, brand, memo, tax, 
                revenue_account, expense_account, inventory_account, user, product_options, has_variants
            };
            // console.log("PRODUCT",product)
            mutation.mutate(product)
        } 
    }

    return(
        <div className="dashboard-main">
            <section className="dashboard-content">
                <section className="dashboard-form dashboard-form-compact">
                {/* <UserAlerts error={error} /> */}
                <div ref={errorRef}>
                    <UserAlerts error={error}  />
                </div>
                <DashboardHeader dashboardTitle="Add Product" dashboardPreTitle="Products" />

                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <div className="grouped-fields-light">
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            defaultChecked=""
                            onChange={handleProductType}
                            // className="custom-input"
                        >
                        <FormControlLabel className="custom-radio" value="10" control={<Radio />} label="Service" />
                        <FormControlLabel className="custom-radio" value="20" control={<Radio />} label="Goods" />
                        </RadioGroup>

                       

                        {isGoods && 
                        <FormControl 
                            variant="outlined" 
                            fullWidth className="custom-input" 
                            // error={categoryError}
                            required
                        >
                            <InputLabel>Inventory Type</InputLabel>
                            <Select
                                className="custom-select"
                                label="Inventory Type"
                                onChange={(e) => {
                                    console.log(e.target.value)
                                    setInventoryTypeError(false)
                                    setInventoryType(e.target.value)}
                                }
                                defaultValue = ""
                            >
                                {inventoryTypes && inventoryTypes.map((inventoryType) => { 
                                    return(
                                    <MenuItem key={inventoryType.id} value={inventoryType.id}>{inventoryType.name}</MenuItem>
                                    )
                                })}   
                                
                            </Select>
                        </FormControl>
                        }

                        <TextField className="custom-input"
                            onChange={(e) => {
                                setNameError(false)
                                setName(e.target.value)
                            }}
                            label="Name" 
                            variant="outlined" 
                            fullWidth
                            required
                            error={nameError}
                        />
                        {/* <FormGroup>
                            <FormControlLabel required control={<Switch />} label="Product Variants" />
                        </FormGroup> */}                                 

                    
                        {clientProfile && !clientProfile.auto_sku &&
                            <TextField className="custom-input"
                                onChange={(e) => {
                                    setSkuError(false)
                                    setSku(e.target.value)
                                }}
                                label="SKU" 
                                variant="outlined" 
                                fullWidth
                                required
                                error={skuError}
                            />
                        }

                    </div>

                    <div className="grouped-fields-light">                      

                        <TreeSelect
                            // className="custom-tree-select"
                            style={{
                                width: '100%',
                            }}
                            // value={value}
                            dropdownStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                            }}
                            placeholder="Categories"
                            // status={account2Status}
                            multiple
                            allowClear
                            showSearch
                            onChange={handleCategory}
                            treeData={categories}
                            filterTreeNode={filterTreeNode} //Enables search filter
                        />

                        {clientProfile && clientProfile.enable_brands && isGoods &&
                        <Autocomplete
                            onChange={(event, newValue) => setBrand(newValue.id)}
                            options={brands}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField className="custom-input" fullWidth {...params} label="Brand"  /> }
                        />
                        }

                        {collections &&
                        <Autocomplete
                            multiple
                            onChange={(event, newValue) => {
                                setCollection(newValue)
                            }}
                            options={collections}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField className="custom-input" fullWidth {...params} label="Collection"  /> }
                        />
                        }
                    </div>


                    {/* {isGoods && 
                    <> */}
                        <div className="grouped-fields-light">
                            {/* <TextField className="custom-input"
                                onChange={(e) => {
                                    setUpc(e.target.value)
                                }}
                                label="UPC" 
                                variant="outlined" 
                                fullWidth
                            /> */}
                            {isGoods &&
                            <Autocomplete
                                onChange={(event, newValue) => {
                                    setUnit(newValue.id);
                                    }}
                                options={units}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <TextField className="custom-input" fullWidth {...params} label="Unit of Measurement"  /> }
                            />
                            }

                            <NumberFormat 
                                onChange={(e) => {
                                    setCostInput(e.target.value)
                                }}
                                className="custom-input"
                                fullWidth
                                label="Cost" 
                                variant="outlined" 
                                customInput={TextField}
                                thousandSeparator={true} 
                            />
                            <NumberFormat 
                                onChange={(e) => {
                                    setPriceError(false)
                                    setUnitPrice(e.target.value)
                                }}
                                className="custom-input"
                                fullWidth
                                label="Price" 
                                variant="outlined" 
                                customInput={TextField}
                                thousandSeparator={true} 
                                required
                                error={priceError}
                            />
                        </div>
                        {/* </>


                        } */}

                        
                        {/* <div className="grouped-fields-light"> */}
                        {clientProfile && clientProfile.enable_product_variants &&
                        // <FormControlLabel
                        //     className="custom-switch"
                        //     control={<IOSSwitch 
                        //         checked={hasVariants}
                        //         onChange={handleChange}
                        //         sx={{ m: 1 }} />}
                        //     label = "Product Variants"
                        // />
                        <div className="custom-switch-container">
                            <CustomSwitch checked={hasVariants} setChecked={setHasVariants}/>
                            <div className="custom-switch-label">
                                Product has variants?
                            </div>
                        </div>
                        }
                    
                    {hasVariants && 
                        <ProductOptions 
                            rows={productOptions} 
                            setRows={setProductOptions} 
                        />
                    }

                    
                    <div className="grouped-fields-light">
                        {isGoods && clientProfile.inventory_tracking &&
                        <>
                            <FormControl 
                                variant="outlined" 
                                fullWidth className="custom-input" 
                                // error={debtorAccountError}
                                required
                            >
                                <InputLabel>Expense Account</InputLabel>
                                <Select
                                    className="custom-select"
                                    label="Expense Account"
                                    onChange={(e) => {
                                        setExpenseAccount(e.target.value)
                                        // setDebtorAccountError(false)

                                    }}
                                    defaultValue = ""
                                >
                                    {expAccounts.map((account) => { 
                                        return(
                                        <MenuItem className="custom-select-list" key={account.id} value={account.id}>{account.name}</MenuItem>
                                        )
                                    })}   
                                    
                                </Select>
                            </FormControl>

                            <FormControl 
                                variant="outlined" 
                                fullWidth className="custom-input" 
                                error={inventoryAccountError}
                                required
                            >
                                <InputLabel>Inventory Account</InputLabel>
                                <Select
                                    className="custom-select"
                                    label="Inventory Account"
                                    onChange={(e) => {
                                        setInventoryAccount(e.target.value)
                                        setInventoryAccountError(false)
                                    }}
                                    defaultValue = ""
                                >
                                    {inventoryAccounts.map((account) => { 
                                        return(
                                        <MenuItem className="custom-select-list" key={account.id} value={account.id}>{account.name}</MenuItem>
                                        )
                                    })}   
                                    
                                </Select>
                            </FormControl>
                        </>
                        }

                        <FormControl 
                            variant="outlined" 
                            fullWidth className="custom-input" 
                            error={revenueAccountError}
                            required
                        >
                            <InputLabel>Revenue Account</InputLabel>
                            <Select
                                className="custom-select"
                                label="Revenue Account"
                                onChange={(e) => {
                                    setRevenueAccount(e.target.value)
                                    setRevenueAccountError(false)
                                }}
                                defaultValue = ""
                            >
                                {revAccounts.map((account) => { 
                                    return(
                                    <MenuItem className="custom-select-list" key={account.id} value={account.id}>{account.name}</MenuItem>
                                    )
                                })}   
                                
                            </Select>
                        </FormControl>


                        {taxes &&
                        <Autocomplete
                            multiple
                            onChange={(event, newValue) => {
                                setTax(newValue)
                            }}

                            options={taxes}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField className="custom-input" fullWidth {...params} label="Taxes"  /> }
                        />
                        }

                   </div>
                   
                   <div className="grouped-fields-light">
                        <TextField className="custom-input"
                            onChange={(e) => {
                                setMemo(e.target.value)
                            }}
                            label="Notes"
                            variant="outlined"
                            multiline
                            rows={2}
                            fullWidth
                        />
                    </div>


                    <div className="button-border-top">
                        <button id="submit" className="btn btn-primary form-buttons" type="submit">
                            Save
                        </button>
                    </div>
                </form>

                </section>
            </section>
        </div>
    )

}

export default ProductNew;