// import React from "react"

import { useEffect, useState, useRef, useContext } from "react";
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

import { useNavigate } from 'react-router-dom';

// import useAxios from "../../components/useAxios";
import authAxios from "../../components/authAxios";

import { InputLabel } from "@mui/material";
import { FormControl } from "@mui/material";
import { TextField, Autocomplete } from "@mui/material";
import { Select } from "@mui/material";
import { MenuItem } from "@mui/material";

import UserAlerts from "../../components/UserAlerts";

import { Modal } from 'antd';
import CustomSwitch from "../../components/CustomSwitch";
import { UserContext } from "../../App";

// const AccountNew = ({isDrawerOpen, handleDrawerClose}) => {




const BrandNew = ({isModalOpen, setIsModalOpen}) => {
    const page_size = useContext(UserContext);
    const errorRef = useRef(null);

    const queryClient = useQueryClient();
    const [page, setPage] = useState(1);

    const [checked, setChecked] = useState(false);
    const [name, setName] = useState('');
    const [memo, setMemo] = useState('');
    const user = localStorage.getItem('userID');
    const client = localStorage.getItem('clientID');
    const [typeError, setTypeError] = useState(false)
    const [nameError, setNameError] = useState(false)

    const navigate = useNavigate();

    const handleCancel = () => {
        setIsModalOpen(false);
        };

    const mutation = useMutation({
        mutationFn: (brand) => {
            return authAxios.post("/products/brands/", brand);

        },
        onSuccess: (result) => {
            queryClient.invalidateQueries(['brands', page]);
            setIsModalOpen(false);
        },
      })

    const handleSubmit = (e) => {
     
        e.preventDefault();

        setNameError(false)
        // setTypeError(false)

        if (name === '') {
            setNameError(true)
        }

        if (name ) {
            const brand = { client, name, memo, user };
            // createAccount(account);
            mutation.mutate(brand)
        } 
    }

    return (
        <>
        <Modal className="dashboard-modal" title="Add a Brand" visible={isModalOpen} open={isModalOpen} onOk={handleSubmit} onCancel={handleCancel}>

            <section className="dashboard-form">
                {/* <div ref={errorRef}>
                    <UserAlerts error={error}  />
                </div> */}

                <form noValidate autoComplete="off" onSubmit={handleSubmit}>

                    <TextField className="custom-input"
                        onChange={(e) => {
                            setNameError(false)
                            setName(e.target.value)}
                        }
                        label="Name" 
                        variant="outlined" 
                        // color="secondary" 
                        fullWidth
                        required
                        error={nameError}
                    />

                    <TextField className="custom-input"
                        onChange={(e) => setMemo(e.target.value)}
                        label="Memo"
                        variant="outlined"
                        multiline
                        rows={3}
                        fullWidth
                        // error={messageError}
                    />
                </form>
            </section>
        </Modal>
    </>

    ) 
}

export default BrandNew;
