import { useState } from "react";
import { useEffect } from "react";

import { Collapse } from "@mui/material";
import { IconButton } from "@mui/material";
import { Alert } from "@mui/material";
import { AlertTitle } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';


const UserAlerts = ({error, success}) => {
    const [open, setOpen] = useState(true);
    const err= error.replace(/{|}|"/g,"")

    const handleClose = () => {
        setOpen(false);
      };


    // useEffect(() => {
    //     // window.scrollTo(0, 0)
    //     document.body.scrollTo(0,0)
    //     console.log("WE ARE HERE")
    //   }, [error])

    return (  
        <div className="dashboard-alert" >
            
            {error &&
            // <>
            <Collapse in={open}>
                 <Alert 
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            // onClick={() => {
                            //     setOpen(false);
                            // }}
                            onClick={handleClose}

                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    severity='error'
                    >
                    <AlertTitle>Error</AlertTitle>
                    {/* {error} */}
                    {err}

                </Alert> 
            </Collapse>
            }
            {/* </>
            : null } */}

            {success && (
                <Collapse in={open}>
                <Alert
                    action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={handleClose}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                    }
                    severity="success"
                >
                    {/* <AlertTitle>Success</AlertTitle> */}
                    {success}
                </Alert>
                </Collapse>
            )}

        </div>
    );
}
 
export default UserAlerts;