import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import useAxios from "../../components/useAxios";


import Pagination from '@mui/material/Pagination';
import DashboardHeader from "../../components/DashboardHeader";
import { useMediaQuery } from 'react-responsive'
import { Card, CardContent } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';


import Layout from "../../components/Layout";
import ThreeDotsMenu from "../../components/ThreeDotsMenu";
// import ProductNew from "../../components/ProductNew";

const Locations = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 750px)' })
    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();
    const { data, fetchData}  = useAxios();   
    
    
    // FETCH PRODUCTS 
    const [products, setProducts] = useState([]);
  
    // const fetchProducts = () => {
    //     fetchData({
    //         url: `/products/products/?page=${page}`,
    //         method: 'get',
    //       })
    // }

    // FETCH ITEMS 
    const [items, setItems] = useState([]);
    const fetchItems = () => {
        fetchData({
            url: `/inventory/items/?page=${page}`,
            method: 'get',
          })
    }

     // FETCH LOCATIONS 
     const [locations, setLocations] = useState([]);
     const fetchLocations = () => {
         fetchData({
             url: `/inventory/locations/?page=${page}`,
             method: 'get',
           })
     }

    const [isDrawerOpen, setIsDrawerOpen] = useState(false)

    const handleDrawerOpen = () => {
        setIsDrawerOpen(true)
    };
  
    const handleDrawerClose = () => {
        setIsDrawerOpen(false)
    };

    //Pagination
     const handlePageChange = (event, value) => {
        setPage(value);
    };

    const threeSubMenu = [
        {
            id: 1,
            menu: "View",
            // url: "/item/",
            url: "/dashboard/item/",

        }, 
        // {
        //     id: 2,
        //     menu: "Edit",
        //     url: "/itemedit/",
        // }
    ];


    useEffect (() => {
        fetchLocations()

        if (data.count){
            setPageCount(data.page_count)
            setLocations(Array.from(data.results))
        }
    },[page, data.count])    

    return (
        // <Layout>

            <div className="dashboard-main">

                <section className="dashboard-content">
                    
                    <DashboardHeader dashboardTitle="Locations" dashboardPreTitle="Inventory" />

                    {isMobile? 
                    <>
                    
                    {locations && locations.map(location => ( 

                    <Card className="dashboard-card" key={location.id}>
                        
                        <CardContent className="dashboard-card-content">
                            <div className="dashboard-card-title">{location.name}</div>
                            <div className="dashboard-card-subtitle">{location.id}</div>                            
                            {/* <p>{item.type_name}</p>
                            <p>{item.price}</p> */}
                        </CardContent>
                    </Card>
                    ))}

                    </>

                    :
                    // If Device is not mobile, display data using a table
                    <>
                    <table className="styled-table">
                        <thead>
                            <tr >
                                <th>ID</th>
                                <th>Name</th>
                                <th>Address</th>
                                <th>Type</th>
                                <th></th>
                            </tr>
                        </thead>

                        {locations && locations.map(location => (

                            <tbody key={location.id}>
                                <tr>
                                    <td>{location.id}</td>
                                    <td>{location.name}</td>
                                    <td>{location.address}</td>
                                    <td>{location.type_name}</td>
                                    {/* <td className='styled-table-menu'>
                                        <ThreeDotsMenu data={location} threeSubMenu={threeSubMenu} />
                                    </td> */}
                                </tr>
                            </tbody>
                        ))}
                        

                    </table>
                    </>

                    }

                    <Pagination 
                        className="custom-mui-pagination"
                        count={pagecount} 
                        page={page} 
                        onChange={handlePageChange} 
                    />


                    <Link to="/dashboard/contactnew" state={{ tag: "LOCATION" }} className="nav-link">
                        <Fab className="fab" color="primary" aria-label="add">
                            <AddIcon />
                        </Fab>
                    </Link>
                    

                </section>
            </div>

        // </Layout>


    )
}
export default Locations