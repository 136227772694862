// import React from "react";
import { useState, useEffect, useRef } from "react";
import { useQuery } from "@tanstack/react-query";

// import useAxios from "../../components/useAxios";
import authAxios from "../../components/authAxios";
import { Link, useNavigate } from "react-router-dom";


import Pagination from '@mui/material/Pagination';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import DashboardHeader from "../../components/DashboardHeader";
import { useMediaQuery } from 'react-responsive'
import { Card, CardContent } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';

import { format } from 'date-fns';

import Pillchip from "../../components/Pillchip";
import OrderList from "./OrderList";


// import OrderNew from "./OrderNew";
// import ProductNew from "../../components/ProductNew";
// import useEbayToken from "../ebay/useEbayToken";


const Orders = () => {
    // const { accessToken, refreshEbayToken } = useEbayToken();

    // const { data:orderData, fetchData:fetchOrderData}  = useAxios();   


    const isMobile = useMediaQuery({ query: '(max-width: 750px)' })
    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();

    // FETCH ORDERS 
    // const [orders, setOrders] = useState([]);
    // const fetchOrders = () => {
    //     fetchOrderData({
    //         url: `/orders/orders/?page=${page}`,
    //         method: 'get',
    //       })
    // }

    const { data: orders } = useQuery({
        queryKey: ['orders', page],
        queryFn: async () => {
          const response = await authAxios.get(`/orders/orders/?page=${page}`);
          setPageCount(response.data.page_count)
          return response.data.results
        },
    });

    // const convertDateTime = (myDateTime) => {
    //     return format(new Date(myDateTime), 'dd-MM-yyyy HH:mm');
    // };

    // const formatReference = (reference) => {
    //     return `${reference.slice(0, 3)}-${reference.slice(3, 9)}-${reference.slice(9)}`;
    // };

    //Pagination
     const handlePageChange = (event, value) => {
        setPage(value);
    };

    //  const threeSubMenu = [
    //     {
    //         id: 1,
    //         menu: "View",
    //         url: "/dashboard/order/",
    //     }, 
    // ];


    // FETCH CLIENT PROFILE
    // const clientID = localStorage.getItem('clientID');
    // const clientProfileID = localStorage.getItem('clientProfile');

    // const client = useRef("");
    // client.Id=localStorage.getItem('clientProfile');

    // const [ebayConfig, setEbayConfig] = useState([]);
    // const { data: ebayConfigData } = useAxios({
    //     url: `/ebay-config`,
    //     method: 'get'
    // })
    // console.log("EBAY",ebayConfigData)


    const [authorizationUrl, setAuthorizationUrl] = useState('');
    const navigate = useNavigate();
    const GetEbayOrders = () => {
        // const sourceURL = window.location.href;
        // const authUrl = `${ebayConfig.auth_url}?client_id=${ebayConfig.app_id}&response_type=code&scope=${ebayConfig.scope}&redirect_uri=${ebayConfig.redirect_uri}`;
        // window.location.replace(authUrl);
        // console.log("WE ARE HERE")
    }

    // useEffect (()=>{
    //     fetchOrders()
    // },[page]) //IMPORTANT: RUN API CALL ONLY ONCE!


    // useEffect (() => {
    //     if (orderData.page){
    //         setPageCount(orderData.page_count)
    //         setOrders(Array.from(orderData.results))
    //     }
    //     // if (ebayConfigData.length){
    //     //     setEbayConfig(ebayConfigData.find(ebayConfig => ebayConfig.client === parseInt(client.Id)))
    //     // }
    // // },[page, orderData.count, isDrawerOpen])
    // },[orderData])
    

    return (

            <div className="dashboard-main">

                <section className="dashboard-content">
                    
                    <DashboardHeader dashboardTitle="Sales Orders" dashboardPreTitle="Order Management" />

                    <OrderList 
                        orders={orders} 
                        handlePageChange={handlePageChange} 
                        page={page} 
                        pagecount={pagecount}
                    />

                    {/* {isMobile? 
                    <>
                    
                    {orders && orders.map(order => ( 

                    <Card className="dashboard-card" key={order.id}>
                        
                        <CardContent className="dashboard-card-content">
                            <div className="dashboard-card-title">{order.customer_name}</div>
                            <div className="dashboard-card-subtitle">{order.reference}</div>                            
                            <p>{order.status_name}</p>
                            <p>{order.total}</p>
                        </CardContent>

                    </Card>
                    ))}

                    </>

                    :
                    <>
                    <table className="styled-table">
                        <thead>
                            <tr >
                                <th>Reference</th>
                                <th>Date</th>
                                <th>Total</th>
                                <th>Status</th>
                                <th>Payment</th>
                                <th></th>
                            </tr>
                        </thead>

                        {orders && orders.map(order => (

                            <tbody key={order.id}>
                                <tr>
                                    <td>{formatReference(order.reference)}</td>
                                    <td>{convertDateTime(order.order_date)}</td>
                                    <td>{Number(order.total).toLocaleString()}</td>
                                    <td><Pillchip label={order.status_name} /></td>
                                    <td><Pillchip label={order.payment_status_name} /></td>
                                    <td className='styled-table-menu'><FontAwesomeIcon icon={faEllipsisVertical} /></td>
                                </tr>
                            </tbody>
                        ))}
                        

                    </table>
                    </>

                    } */}

                    {/* <Pagination 
                        className="custom-mui-pagination"
                        count={pagecount} 
                        page={page} 
                        onChange={handlePageChange} 
                    /> */}

                    {/* <Link to="/dashboard/ebay-accepted" className="nav-link">
                        <Fab className="fab" color="primary" aria-label="add">
                            <AddIcon onClick={GetEbayOrders}/>
                        </Fab>
                    </Link> */}

                    {/* <Link to="/dashboard/ordernew" className="nav-link">
                        <Fab className="fab" color="primary" aria-label="add">
                            <AddIcon />
                        </Fab>
                    </Link> */}

                </section>
            </div>

    )
}
export default Orders