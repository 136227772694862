import React, { useState, useEffect, useContext } from 'react';
import useAxios from "../../components/useAxios";
import {UserContext} from "../../App"


import { TextField, Autocomplete } from "@mui/material";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import moment from 'moment';

import { Button, Modal } from 'antd';
import NumberFormat from "react-number-format";

import '../../styles/AntStyles.css'


const ExpenseNew = ({ addExpense, isModalOpen, setIsModalOpen }) => {
    const page_size = useContext(UserContext);

    const [description, setDescription] = useState();
    const [type, setType] = useState();
    const [dt1, setDt1] = useState(new Date());
    const [reference, setReference] = useState();
    const [amt, setAmt] = useState();

    const showModal = () => {
    setIsModalOpen(true);
    };

    const handleOk = () => {
    setIsModalOpen(false);
    };

    const handleCancel = () => {
    setIsModalOpen(false);
    };

    const [expenseTypes, setExpenseTypes] = useState([])
    const { data: expenseTypeData } = useAxios({
        url: `/hrm/expense-types/?page_size=${page_size}`,
        method: 'get'
    })

  const [expenseData, setExpenseData] = useState({
    type: '',
    date: '',
    description: '',
    amount: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setExpenseData({ ...expenseData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Validate and submit the expense data
    const expense = {
        type_id: type.id,
        type_name: type.name,
        reference: reference,
        date: moment(dt1).format("YYYY-MM-DD"),
        description: description,
        // amount: amt,
        amount: parseFloat(amt.replace(/,/g, ''))
      };
    // console.log("EXPENSE",expense)

    addExpense(expense);
    // Reset the form and close the modal
    setExpenseData({
      type: '',
      date: '',
      description: '',
      amount: '',
    });
    setIsModalOpen(false);

    // onClose();
  };

//   console.log("MODAL GRAND CHILD",isModalOpen)

  useEffect(()=>{
    if (expenseTypeData.count){
        setExpenseTypes(Array.from(expenseTypeData.results))
    }

},[expenseTypeData.count])


  return (
    <>
    <Modal className="dashboard-modal" title="Add Expense" visible={isModalOpen} open={isModalOpen} onOk={handleSubmit} onCancel={handleCancel}>
        <div className="dashboard-form">

        <form onSubmit={handleSubmit}>
            <Autocomplete
                onChange={(event, newValue) => {
                    console.log("VALUE", newValue.id)
                    setType(newValue)

                    // handleTransType(newValue)
                    }}
                options={expenseTypes}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => 
                    <TextField 
                        className="custom-input zero-margin-top" 
                        fullWidth 
                        // error={typeError} 
                        required
                        label="Type"  
                        {...params} 
                    /> 
                }              

            />
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                    label="Transaction Date"
                    value={dt1}
                    onChange={(newDate) => {
                        setDt1(moment(newDate).format("YYYY-MM-DD"));

                    }}
                    renderInput={(params) => <TextField fullWidth required className="custom-input" {...params} />}
                />
            </LocalizationProvider>
            <TextField className="custom-input"
                onChange={(e) => {
                    // setDescriptionError(false)
                    setDescription(e.target.value)
                }}
                label="Description" 
                variant="outlined" 
                fullWidth
                required
                // error={descriptionError}
            />
             <TextField className="custom-input"
                onChange={(e) => {
                    // setDescriptionError(false)
                    setReference(e.target.value)
                }}
                label="Reference" 
                variant="outlined" 
                fullWidth
                required
                // error={descriptionError}
            />
            <NumberFormat 
                onChange={(e) => setAmt(e.target.value)} 
                className="custom-input"
                fullWidth
                label="Amount" 
                variant="outlined" 
                customInput={TextField}
                thousandSeparator={true} 
                required
                // error={amtError}
            />
        </form>
      </div>
    </Modal>
    </>

  );
};

export default ExpenseNew;
