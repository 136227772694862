import React, { useState, useEffect, useContext, useRef } from 'react';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import authAxios from '../../components/authAxios';

import { useNavigate } from "react-router-dom";
import { TextField, Autocomplete } from "@mui/material";

import UserAlerts from "../../components/UserAlerts";
import DashboardHeader from "../../components/DashboardHeader";
import ProductDetails from "../products/ProductDetails";
import {UserContext} from "../../App"


const QuoteNew = () => {
    // const page_size = useContext(UserContext);
    // const userSettings = useContext(UserContext);
    const {pageSize} = useContext(UserContext);


    const [error, setError] = useState('');
    const errorRef = useRef(null);

    const queryClient = useQueryClient();

    const [reference, setReference] = useState('');

    
    const { data: clientProfile } = useQuery({
        queryKey: ['clientProfile'],
        queryFn: async () => {
          const response = await authAxios.get(`/clientprofiles/${localStorage.getItem('clientProfile')}`);
          return response.data

        },
    });


    const { data: quoteReference } = useQuery({
        queryKey: ['quoteReference'],
        queryFn: async () => {
            const response = await authAxios.get("/crm/quote-reference");
            setReference(response.data)
            return response.data
        },
    });

    // const { data: orderStatus } = useQuery({
    //     queryKey: ['orderStatus'],
    //     queryFn: async () => {
    //         const response = await authAxios.get("/orders/order-status");
    //         return response.data
    //     },
    // });

    const { data: contacts } = useQuery({
        queryKey: ['contacts'],
        queryFn: async () => {
        //   const response = await authAxios.get(`/contacts/contacts/?page_size=${userSettings.pageSize}`);
        //   const response = await authAxios.get(`/contacts/?page_size=${userSettings.pageSize}`);
          const response = await authAxios.get(`/contacts/?page_size=${pageSize}`);

        //   return response.data.results;
          return response.data;

        },
    });

    const [isModalOpen, setIsModalOpen] = useState(false);


    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [customer, setCustomer] = useState('');
    const [contact, setContact] = useState('');
    const [memo, setMemo] = useState('');
    const [status, setStatus] = useState('');
    const user = localStorage.getItem('userID');
    const client = localStorage.getItem('clientID');
    const [customerError, setCustomerError] = useState(false)
    const [statusError, setStatusError] = useState(false)
    const [referenceError, setReferenceError] = useState(false)
    const [tag, setTag] = useState ("QUOTE")
    const [txnLines, setTxnLines] = useState([]);

    const title = "Successful! Your Quotation is ready"
    const subTitle = useRef("")

    const formatReference = (reference) => {
        return `${reference.slice(0, 3)}-${reference.slice(3, 9)}-${reference.slice(9)}`;
      };

    const addTxnLine = (newTxnLine) => {
        setTxnLines([...txnLines, newTxnLine]);
        setIsModalOpen(false); // Close the modal after adding the expense
    };


    const mutation = useMutation({
        mutationFn: (quote) => {
            return authAxios.post("/crm/quotes/", quote);
        },
        onError: (error, variables, context) => {
            if (error.response.data.error){
                // If it's an array, display each error message
                if (Array.isArray(error.response.data.error)) {
                    error.response.data.error.map((errorMessage) => (
                        setError(errorMessage)
                    ));
                } else {
                    // Handle single error message
                    setError(error.response.data.error); 
                }
            } else {
                // Fallback to Standard error message
                setError(error.message); 
            }      
        },
        onSuccess: (result) => {
            queryClient.invalidateQueries(['quotes']);
            navigate('/dashboard/confirmed/', {state:{title:title, subtitle:subTitle.current, reference:reference, tag:tag}});
            // navigate('/dashboard/quotes/')
        },
    })

    const handleSubmit = (e) => {
        e.preventDefault();

        // if (customer === '') {
        //     setCustomerError(true)
        // }

        if (reference === '') {
            setReferenceError(true)
        }

        if (reference) {

            subTitle.current = `You can now download/print the Quotation reference:${reference}.`

            const amount = txnLines.reduce((acc, txn) => acc + parseFloat(txn.total), 0);


            //UPDATE QUOTE DETAILS
            const quote_details = txnLines;
            const quote = { client, reference, name, contact, customer, memo, user, quote_details, amount };
            console.log(quote)
            mutation.mutate(quote)
        } 
    }

    useEffect(()=>{
        if (error){
            errorRef.current.scrollIntoView();  
        }            
    },[error])



    return(
        <div className="dashboard-main">
            <section className="dashboard-content">

                <section className="dashboard-form dashboard-form-compact">
                    <div ref={errorRef}>
                        <UserAlerts error={error}  />
                    </div>
                    <UserAlerts error={error} />
                    <DashboardHeader dashboardTitle="Add Quotation" dashboardPreTitle="Sales" />

                    <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                        <div className="grouped-fields-light">

                        {/* <div className="two-columns"> */}
                        <TextField className="custom-input"
                            value={formatReference(reference)}
                            onChange={(e) => {
                                setReferenceError(false)
                                setReference(e.target.value)
                            }}
                            label="Reference" 
                            variant="outlined" 
                            fullWidth
                            required
                            inputProps={{readOnly: true}}
                        />

                        <TextField className="custom-input"
                            onChange={(e) => {
                                setName(e.target.value)
                            }}
                            label="Name" 
                            variant="outlined" 
                            fullWidth
                        />

                        {contacts && 
                        <Autocomplete
                            onChange={(event, newValue) => {
                                setContact(newValue.id);
                                // console.log("VALUE", newValue.id)

                                }}
                            options={contacts}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField className="custom-input" fullWidth {...params} label="Contact"  /> }
                        />
                        } 

                        </div>

                        <ProductDetails 
                            addTxnLine={addTxnLine}
                            txnLines={txnLines} 
                            setTxnLines={setTxnLines} 
                            tag={tag}
                            isModalOpen={isModalOpen}
                            setIsModalOpen={setIsModalOpen}
                            clientProfile={clientProfile}
                        />

                        <div className="grouped-fields-light">


                        <TextField className="custom-input"
                            onChange={(e) => {
                                setMemo(e.target.value)
                            }}
                            label="Notes"
                            variant="outlined"
                            multiline
                            rows={2}
                            fullWidth
                        />
                        </div>

                        <div className="button-border-top">
                            <button id="submit" className="btn btn-primary form-buttons" type="submit">
                                Save
                            </button>
                        </div>


                    </form>

                </section>
            </section>

        </div>

    )

}

export default QuoteNew;