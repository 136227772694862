import { useState } from 'react';
import { Link } from 'react-router-dom';


// MUI LIST
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';

import Divider from '@mui/material/Divider';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AddchartIcon from '@mui/icons-material/Addchart';
import AddCardIcon from '@mui/icons-material/AddCard';
import AddShoppingCartSharpIcon from '@mui/icons-material/AddShoppingCartSharp';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCcVisa } from '@fortawesome/free-brands-svg-icons';
// import { faCartPlus } from '@fortawesome/free-solid-svg-icons';
// import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
// import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { faMinus } from '@fortawesome/free-solid-svg-icons';

const SettingsMenu = () => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [open, setOpen] = useState(false);

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
        console.log("Index",index)
    };

    const handleClick = () => {
        setOpen(!open);
      };
      
    // console.log("SELECTED", selectedIndex)

    return (
        <div className="settings-menu"> 
                <List>
                    <ListItem disablePadding>
                        <ListItemButton
                            component={Link} to="/dashboard/settings"
                            selected={selectedIndex === 0}
                            onClick={(event) => handleListItemClick(event, 0)}
                        >
                            <ListItemIcon>
                                <ManageAccountsIcon />
                            </ListItemIcon>
                            <ListItemText primary="Company" />
                        </ListItemButton>
                    </ListItem>
                    {/* <ListItem disablePadding>
                        <ListItemButton
                            selected={selectedIndex === 1}
                            onClick={(event) => handleListItemClick(event, 1)}
                        >
                            <ListItemIcon>
                                <AddchartIcon />
                            </ListItemIcon>
                            <ListItemText primary="Finance" />
                        </ListItemButton>
                    </ListItem> */}
                    <ListItem disablePadding>
                        <ListItemButton 
                            // component={Link} to="/dashboard/payment-modes"
                            // selected={selectedIndex === 2}
                            // onClick={(event) => handleListItemClick(event, 2)}
                            onClick={handleClick}
                        >
                            <ListItemIcon>
                                <AddShoppingCartSharpIcon />
                            </ListItemIcon>
                            <ListItemText primary="Point of Sale" />
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                    </ListItem>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemButton 
                                    sx={{ pl: 4 }}
                                    component={Link} to="/dashboard/payment-modes"
                                    selected={selectedIndex === 2}
                                    onClick={(event) => handleListItemClick(event, 2)}
                                >
                                    <ListItemIcon>
                                        {/* <StarBorder /> */}
                                        {/* <FontAwesomeIcon icon="fa-brands fa-cc-visa" /> */}
                                        {/* <FontAwesomeIcon icon={faCcVisa} /> */}
                                        {/* <FontAwesomeIcon icon={faChevronRight} /> */}
                                        {/* <FontAwesomeIcon icon={faCaretRight} /> */}
                                        {/* <FontAwesomeIcon icon={faMinus} /> */}

                                    </ListItemIcon>
                                    <ListItemText primary="Payment Methods" />
                                </ListItemButton>
                                </List>
                        </Collapse>
                </List>

            </div>
    )
}

export default SettingsMenu