import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryClient, useMutation } from '@tanstack/react-query';
import authAxios from "../../components/authAxios";
import NumberFormat from "react-number-format";
import { TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import moment from 'moment';

// IMAGE UPLOAD ANT DESIGN
import { message, Upload } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

const ClientProfile = ({clientProfile, countries, setError, success, setSuccess}) => {
    const queryClient = useQueryClient();
    // const [dt, setDt] = useState(new Date());
    const [name, setName] = useState(clientProfile.name);
    const [tin, setTin] = useState(clientProfile.tin);
    const [email, setEmail] = useState(clientProfile.email);
    const [phone, setPhone] = useState(clientProfile.telephone);
    const [address, setAddress] = useState(clientProfile.address);
    const [currency, setCurrency] = useState(clientProfile.currency);
    const [city, setCity] = useState(clientProfile.city);
    const [country, setCountry] = useState(clientProfile.country);
    const [website, setWebsite] = useState(clientProfile.website);
    const [debtorsControl, setDebtorsControl] = useState(clientProfile.debtors_control);
    const [apAccount, setApAccount] = useState(clientProfile.ap_account);
    const [contact, setContact] = useState(clientProfile.contact);

    const client = localStorage.getItem('clientID');
    const user = localStorage.getItem('userID');
    const [nameError, setNameError] = useState(false)
    const [cityError, setCityError] = useState(false)

    const [phoneError, setPhoneError] = useState(false)
    const [emailError, setEmailError] = useState(false)

    const [id_copy, setIdCopy] = useState(null);
    const [id_files, setIdFiles] = useState([]);
    const [logo, setLogo] = useState([]);


    const navigate = useNavigate();



    // IMAGE UPLOAD ANT DESIGN
    const [imageUrl, setImageUrl] = useState();
    const [loading, setLoading] = useState(false);
    // const [fileList, setFileList] = useState([]);
    var fileList = []


    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const beforeUpload = (file) => {
        // setFileList([...fileList, file]);

        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      
        if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
        }
      
        const isLt2M = file.size / 1024 / 1024 < 2;
      
        if (!isLt2M) {
          message.error('Image must smaller than 2MB!');
        }
      
        return isJpgOrPng && isLt2M;
      };
    
    // const handleUpload = (info) => {

    const handleUpload = (info) => {
        // console.log("UPLOAD 1",info.fileList[0].originFileObj)

        console.log("INFO",info)
        console.log("UPLOAD",info.fileList)
        console.log("ARRAY LENGTH",(info.fileList).length)

        // console.log(e.target)

        setIdCopy(info.file)
        setLogo(info.file)

        // var fileCount = info.fileList.length

        for(let i= 0; i < info.fileList.length; i++){
            fileList.push(info.fileList[i].originFileObj)
        }

        setIdFiles(fileList)


        // info.fileList.forEach((file) => {
        //     console.log("FILE",file)
        //     // formData.append('files[]', file);
        //   });


        if (info.file.status === 'uploading') {
          setLoading(true);
          return;
        }
    
        if (info.file.status === 'done') {
          // Get this url from response in real world.
          getBase64(info.file.originFileObj, (url) => {
            setLoading(false);
            setImageUrl(url);
          });
        }
    };

    const uploadButton = (
        <div>
          {loading ? <LoadingOutlined /> : <PlusOutlined />}
          <div 
            style={{
              marginTop: 8,
            }}
          >
            Upload
          </div>
        </div>
    );







    const mutation = useMutation({
        mutationFn: (cp) => {
            return authAxios.put(`/clientprofiles/${clientProfile.id}/`, cp);
        },
        onError: (error, variables, context) => {
            setSuccess("")

            if (error.response.data.error){
                // If it's an array, display each error message
                if (Array.isArray(error.response.data.error)) {
                    error.response.data.error.map((errorMessage) => (
                        setError(errorMessage)
                    ));
                } else {
                    // Handle single error message
                    setError(error.response.data.error); 
                }
            } else {
                // Fallback to Standard error message
                setError(error.message); 
            }      
        },
        onSuccess: (data, variables, context) => {
            setSuccess("Client profile has been updated")
            setError("")
            queryClient.invalidateQueries(['clientProfile']);
            queryClient.refetchQueries(['clientProfile']);
            navigate('/dashboard/settings/');
        },
      })


    const handleSubmit = (e) => {
        e.preventDefault();

        if (name === '') {
            setNameError(true)
        }

        if (phone === '' ) {
            setPhoneError(true)
        }

        if (email === '' ) {
            setEmailError(true)
        }

        if (name && phone && email) {
            const telephone = phone.replace(/[()" "]/g,"")
            // const birth_date = moment(dt).format("YYYY-MM-DD")
            const debtors_control = debtorsControl
            const ap_account = apAccount

            // const cp = { client, name, address, country, email, telephone, website, birth_date, user, currency, debtors_control, ap_account, contact, logo };
            const cp = { client, name, tin, address, city, country, email, telephone, website, user, currency, debtors_control, ap_account, contact };
            console.log(cp)
            mutation.mutate(cp)
        } 
    }

    return(
        <section className="dashboard-form">
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <div className="grouped-fields-light">
                    <TextField className="custom-input"
                        value={name}
                        // onChange={(e) => {
                        //     setNameError(false)
                        //     setName(e.target.value)
                        // }}
                        label="Name" 
                        InputLabelProps={{ shrink: true }}
                        variant="outlined" 
                        fullWidth
                        // required
                        // error={nameError}
                        InputProps={{ readOnly: true }}

                    />
                    <TextField className="custom-input"
                        value={tin ?? ''}
                        onChange={(e) => setTin(e.target.value)}
                        label="Tax Identification Number" 
                        variant="outlined" 
                        fullWidth
                        // required
                        // error={mobileError}
                    />  

                    
                </div>
                <div className="grouped-fields-light">

                    <NumberFormat 
                        value = {phone}
                        onChange={(e) => {
                            setPhoneError(false)
                            setPhone(e.target.value)
                        }}
                        className="custom-input"
                        fullWidth
                        label="Telephone" 
                        variant="outlined" 
                        customInput={TextField}
                        format="### (###) ######" mask="_"
                        required
                        error={phoneError}
                    />

                    <TextField className="custom-input"
                        value={email ?? ''}
                        onChange={(e) => {
                            setEmailError(false)
                            setEmail(e.target.value)
                        }}
                        label="Email" 
                        InputLabelProps={{ shrink: true }}
                        variant="outlined" 
                        fullWidth
                        required
                        error={emailError}
                    />
                    <TextField className="custom-input"
                        value={website ?? ''}
                        onChange={(e) => setWebsite(e.target.value)}
                        label="Website" 
                        variant="outlined" 
                        fullWidth
                        // required
                        // error={mobileError}
                    />  

                   
                </div>
                <div className="grouped-fields-light">



                    {/* {contact && */}
                    <TextField className="custom-input"
                        value={address ?? ''}
                        onChange={(e) => setAddress(e.target.value)}
                        label="Address" 
                        InputLabelProps={{ shrink: true }}
                        variant="outlined" 
                        fullWidth
                        // required
                        // error={mobileError}
                    />
                    {/* } */}
                    
                    <TextField className="custom-input"
                        value={city ?? ''}
                        onChange={(e) => {
                            setCityError(false)
                            setCity(e.target.value)
                        }}
                        label="City/Town" 
                        variant="outlined" 
                        fullWidth
                        required
                        error={cityError}
                    />  

                    {countries &&
                    <Autocomplete
                        value={country ?? ''}
                        onChange={(event, newValue) => {
                            // setCountry(newValue.id);
                            setCountry(newValue);
                            }}
                        options={countries}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField className="custom-input" fullWidth {...params} label="Country"  /> }
                    />
                    }

                 
                </div>

                {/* <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader custom-upload-card custom-input"
                    beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
                    onChange={handleUpload}
                    maxCount={1}
                    >
                    {imageUrl ? (
                        <img
                        src={imageUrl}
                        alt="avatar"
                        style={{
                            width: '100%',
                        }}
                        />
                    ) : (
                        uploadButton
                    )}
                </Upload> */}

                <div className="button-border-top">
                    
                    {!success &&
                    // <button id="submit" className="btn btn-outline-primary form-buttons">
                    //     Back
                    // </button>
                    // :
                    <button id="submit" className="btn btn-primary form-buttons" type="submit">
                        Save
                    </button>
                    }
                </div>
            </form>            
        </section>
    )

}

export default ClientProfile;

