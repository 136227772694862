import React from "react";
import { useState, useEffect } from "react";
// import axios from "axios";
import useAxios from "../../components/useAxios";
// import authAxios from "./authAxios";

import { Link } from "react-router-dom";
// import { useNavigate } from "react-router-dom";


// import Pagination from "./Pagination";
import Pagination from '@mui/material/Pagination';


// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import DashboardHeader from "../../components/DashboardHeader";
import { useMediaQuery } from 'react-responsive'
import { Card, CardContent } from "@mui/material";


import ThreeDotsMenu from "../../components/ThreeDotsMenu";


function AmazonMapping() {
    const isMobile = useMediaQuery({ query: '(max-width: 750px)' })
    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();
    // const { response } = useAxiosInterceptor();

    const abortController = new AbortController()

    const { data, fetchData}  = useAxios();   
    const { data:mappingData, fetchData:fetchMappingData}  = useAxios();   


    // FETCH EXPENSE TYPES 
    const [mapping, setMapping] = useState([]);
    const fetchMapping = () => {
        fetchMappingData({
            url: `/amazon/amazon-transaction-mapping/?page=${page}`,
            method: 'get',
          })
    }

    const handlePageChange = (event, value) => {
        setPage(value);
    };

     const threeSubMenu = [
        {
            id: 1,
            menu: "View",
            url: "/dashboard/customer/",

        }, 
        // {
        //     id: 2,
        //     menu: "Edit",
        //     url: "/dashboard/customeredit/",
        // }
    ];

    useEffect (()=>{
        fetchMapping()
        // console.log("WE ARE HERE",expenseTypeData)

    },[]) //IMPORTANT: RUN API CALL ONLY ONCE!

    useEffect (() => {
        if (mappingData.count){
            // console.log("DATA",mappingData)
            setPageCount(mappingData.page_count)
            setMapping(Array.from(mappingData.results))
        }
    },[ mappingData.count])
    

    return (
            <div className="dashboard-main">
                <section className="dashboard-content">
                    <DashboardHeader dashboardTitle="Transaction Mapping" dashboardPreTitle="Amazon" />

                    {isMobile? 
                    <>
                    
                    {mapping && mapping.map(amazonMapping => ( 

                    <Card className="dashboard-card" key={amazonMapping.id}>
                        
                        <CardContent className="dashboard-card-content">
                            <div className="dashboard-card-title">{amazonMapping.name}</div>
                            <div className="dashboard-card-subtitle">{amazonMapping.telephone}</div>                            
                            <p>{amazonMapping.address}</p>
                            <p>{amazonMapping.email}</p>
                        </CardContent>
                        {/* <CardActions>
                            <Button size="small">Booking</Button>
                        </CardActions> */}
                    </Card>
                    ))}

                    </>

                    :
                    // If Device is not mobile, display data using a table
                    <>
                    <table className="styled-table">
                        <thead>
                            <tr >
                                <th>ID</th>
                                {/* <th>Code</th> */}
                                <th>Name</th>
                                <th>Trans Type</th>
                                <th>Debit Account</th>
                                <th>Credit Account</th>
                                <th></th>
                            </tr>
                        </thead>
                        {mapping && mapping.map(amazonMapping => ( 
                        // {expenseTypes && expenseTypes.map(expenseType => (

                            <tbody key={amazonMapping.id}>
                                <tr>
                                    <td>{amazonMapping.id}</td>
                                    <td>{amazonMapping.description}</td>
                                    <td>{amazonMapping.type_name}</td>
                                    <td>{amazonMapping.debit_account_name}</td>
                                    <td>{amazonMapping.credit_account_name}</td>
                                    <td className='styled-table-menu'>
                                        <ThreeDotsMenu data={amazonMapping} threeSubMenu={threeSubMenu} />
                                    </td>
                                </tr>
                            </tbody>
                        ))}
                        

                    </table>
                    </>

                    }

                    <Pagination 
                        className="custom-mui-pagination"
                        count={pagecount} 
                        page={page} 
                        onChange={handlePageChange} 
                    />

                    <Link to="/dashboard/amazon-mapping-new" className="nav-link">
                        <Fab className="fab" color="primary" aria-label="add">
                            <AddIcon />
                        </Fab>
                    </Link>
                </section>
            </div>

        // </Layout>


    )
}
export default AmazonMapping