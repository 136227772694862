
import { useState, useEffect, useRef } from "react";
import { useQuery } from "@tanstack/react-query";

import authAxios from "../components/authAxios";

// import Layout from "../components/Layout";
import DashboardHeader from "../components/DashboardHeader";

const BalanceSheet = () => {

    // const [accumulatedBalances, setAccumulatedBalances] = useState([]);
    // const [revenues, setRevenues] = useState([]);
    // const [expenses, setExpenses] = useState([]);
    const [assets, setAssets] = useState([]);
    const [currentAssets, setCurrentAssets] = useState([]);
    const [fixedAssets, setFixedAssets] = useState([]);
    const [liabilities, setLiabilities] = useState([]);
    const [equity, setEquity] = useState([]);

    const sumFixedAssets = useRef(0);
    const sumCurrentAssets = useRef(0);
    const sumAssets = useRef(0);
    const sumLiabilities = useRef(0);
    const sumEquity = useRef(0);


    const { data: balances } = useQuery({
        queryKey: ['balances'],
        queryFn: async () => {
            const response = await authAxios.get("/accounting/account-balance");
            return response.data
        },
    });

    const { data: accountTypes } = useQuery({
        queryKey: ['accountTypes'],
        queryFn: async () => {
          const response = await authAxios.get("/accounting/account-types");
          return response.data
        },
    });

    useEffect(()=>{
        if (balances){

            const accumulatedBalancesArray = [];
            const arrAssets = [];
            const arrCurrentAssets = [];
            const arrFixedAssets = [];

            const arrLiabilities = [];
            const arrEquity = [];


            balances.forEach((account) => {
                const accountId = account.account;
                const type = account.account_type;
                const amount = parseFloat(account.amount);

                // ACCUMULATE CURRENT ASSETS
                if (!isNaN(amount) && (type==="30100"||type==="30200"||type==="30300")) {
                    // Check if the account already exists in accumulatedBalancesArray
                    // const existingAccount = accumulatedBalancesArray.find(item => item.id === accountId);
                    const existingAccount = arrCurrentAssets.find(item => item.id === accountId);


                    if (existingAccount) {
                    // Update the accumulated amount if the account already exists
                    existingAccount.amount += amount;
                    } else {
                    // Create a new account entry
                    const newAccountEntry = {
                        id: accountId,
                        account_name: account.account_name,
                        amount,
                    };
                    arrAssets.push(newAccountEntry);
                    arrCurrentAssets.push(newAccountEntry);
                    }
                }

                // ACCUMULATE FIXED ASSETS
                if (!isNaN(amount) && type==="30400") {
                    // Check if the account already exists in accumulatedBalancesArray
                    // const existingAccount = accumulatedBalancesArray.find(item => item.id === accountId);
                    const existingAccount = arrFixedAssets.find(item => item.id === accountId);

                    if (existingAccount) {
                    // Update the accumulated amount if the account already exists
                    existingAccount.amount += amount;
                    } else {
                    // Create a new account entry
                    const newAccountEntry = {
                        id: accountId,
                        account_name: account.account_name,
                        amount,
                    };
                    arrAssets.push(newAccountEntry);
                    arrFixedAssets.push(newAccountEntry);
                    }
                }

                // ACCUMULATE LIABLILITIES
                // if (!isNaN(amount) && (type==="40100"||type==="40200"||type==="40300")) {
                if (!isNaN(amount) && (type==="40100"|| type==="40150" || type==="40200"||type==="40300")) {
                    // Check if the account already exists in accumulatedBalancesArray
                    const existingAccount = arrLiabilities.find(item => item.id === accountId);

                    if (existingAccount) {
                        // Update the accumulated amount if the account already exists
                        existingAccount.amount += amount;
                        } 
                    else {
                        // Create a new account entry
                        const newAccountEntry = {
                            id: accountId,
                            account_name: account.account_name,
                            amount,
                        };
                        arrLiabilities.push(newAccountEntry);
                    }
                }

                // ACCUMULATE EQUITY
                if (!isNaN(amount) && type==="50100") {
                    // Check if the account already exists in accumulatedBalancesArray
                    // const existingAccount = accumulatedBalancesArray.find(item => item.id === accountId);
                    const existingAccount = arrEquity.find(item => item.id === accountId);

                    if (existingAccount) {
                    // Update the accumulated amount if the account already exists
                    existingAccount.amount += amount;
                    } else {
                    // Create a new account entry
                    const newAccountEntry = {
                        id: accountId,
                        account_name: account.account_name,
                        amount,
                    };
                    arrEquity.push(newAccountEntry);
                    }
                }

            });

            // Update the state with the accumulated balances array
            // setAccumulatedBalances(accumulatedBalancesArray);
            setCurrentAssets(arrCurrentAssets)
            setFixedAssets(arrFixedAssets)
            sumCurrentAssets.current = arrCurrentAssets.reduce((acc, account) => acc + parseFloat(account.amount), 0);
            sumFixedAssets.current = arrFixedAssets.reduce((acc, account) => acc + parseFloat(account.amount), 0);

            setAssets(arrAssets)
            sumAssets.current = arrAssets.reduce((acc, account) => acc + parseFloat(account.amount), 0);

            setLiabilities(arrLiabilities)
            sumLiabilities.current = arrLiabilities.reduce((acc, account) => acc + parseFloat(account.amount), 0);
            
            setEquity(arrEquity)
            sumEquity.current = arrEquity.reduce((acc, account) => acc + parseFloat(account.amount), 0);
        }

    },[balances])


    return (
        <div className="dashboard-main">
            <section className="dashboard-content">
                <DashboardHeader dashboardTitle="Balance Sheet" dashboardPreTitle="Accounting" />
                <table className="financial-report">
                        <thead>
                        {/* <tr>
                            <th>Category</th>
                            <th>Amount</th>
                        </tr> */}
                        </thead>


                        <tbody>
                        {currentAssets && currentAssets.map(account => (
                            <tr key={account.id}>
                                <td>{account.account_name}</td>
                                <td>{Number(account.amount).toLocaleString()}</td>
                            </tr>
                        ))}
                        <tr className="sub-total-row">
                            <td>Total Current Assets</td>
                            {/* <td>{Number(sumAssets).toLocaleString()}</td> */}
                            <td>{Number(sumCurrentAssets.current).toLocaleString()}</td>

                        </tr>
                        <tr className="blank-row" />


                        {fixedAssets && fixedAssets.map(account => (
                            <tr key={account.id}>
                                <td>{account.account_name}</td>
                                <td>{Number(account.amount).toLocaleString()}</td>
                            </tr>
                        ))}
                        <tr className="sub-total-row">
                            <td>Total Fixed Assets</td>
                            {/* <td>{Number(sumAssets).toLocaleString()}</td> */}
                            <td>{Number(sumFixedAssets.current).toLocaleString()}</td>

                        </tr>
                        <tr className="blank-row" />
                        <tr className="total-row">
                            <td>Assets</td>
                            <td>{Number(sumAssets.current).toLocaleString()}</td>
                        </tr>
                        <tr className="blank-row" />
                        <tr className="blank-row" />

                        
                        {liabilities && liabilities.map(account => (
                        <tr key={account.id}>
                            <td>{account.account_name}</td>
                            <td>{Number(account.amount).toLocaleString()}</td>
                        </tr>
                        ))}
                        <tr className="sub-total-row">
                            <td>Total Liabilities</td>
                            <td>{Number(sumLiabilities.current).toLocaleString()}</td>
                        </tr>
                        <tr className="blank-row" />

                        {equity && equity.map(account => (
                        <tr key={account.id}>
                            <td>{account.account_name}</td>
                            <td>{Number(account.amount).toLocaleString()}</td>
                        </tr>
                        ))}
                        <tr className="sub-total-row">
                            <td>Total Equity</td>
                            <td>{Number(sumEquity.current).toLocaleString()}</td>
                        </tr>
                        <tr className="blank-row" />

                        <tr className="total-row">
                            <td>Liabilities and Equity</td>
                            <td>{Number(sumLiabilities.current+sumEquity.current).toLocaleString()}</td>
                        </tr>
                        <tr className="blank-row" />

                        </tbody>
                </table>
            </section>
        </div>
    );
};

export default BalanceSheet;


