import { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from 'react-router-dom';

// import useAxios from "../../components/useAxios";
import authAxios from "../../components/authAxios";
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';


import Box from '@mui/material/Box';
import { TextField, Autocomplete } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
// import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import InputAdornment from '@mui/material/InputAdornment';

import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterListIcon from '@mui/icons-material/FilterList';
import Divider from '@mui/material/Divider';

import { Card, Input } from 'antd';

import Pagination from '@mui/material/Pagination';

import NumberFormat from "react-number-format";

import moment from 'moment';


import { UserContext } from "../../App";
import DashboardHeader from "../../components/DashboardHeader";

import UserAlerts from "../../components/UserAlerts";
// import '../../styles/CustomMuiStyles.css'
import '../../styles/Pos.css'
import { SettingsInputHdmiOutlined } from "@mui/icons-material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faSquarePlus } from "@fortawesome/free-regular-svg-icons";
import CustomerNewModal from "../crm/CustomerNewModal";

import '../../styles/AutocompleteStyles.css'
{/* <FontAwesomeIcon icon={regular("square-plus")} /> */}

// import CustomerNewModal from "../crm/CustomerNewModal";


const PosNew = () => {
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const page_size = useContext(UserContext);

    // const [tag, setTag] = useState ("POS")
    const tag = "POS"
    // const { Search } = Input;
    // const onSearch = (value, _e, info) => console.log(info?.source, value);
    const [searchInput, setSearchInput] = useState('');


    // DETERMINES THE NUMBER OF POS PRODUCTS TO BE DISPLAYED
    const [pageSize, setPageSize] = useState(9);

    const [pagecount, setPageCount] = useState();

    const queryClient = useQueryClient();
    const [error, setError] = useState('');
    const errorRef = useRef(null);
    const [emptyItemCount, setEmptyItemCount] = useState(0)

    const { data: orderReference } = useQuery({
        queryKey: ['orderReference'],
        queryFn: async () => {
            const response = await authAxios.get("/orders/default-order-reference");
            return response.data
        },
    });

    const { data: posInvoiceReference } = useQuery({
        queryKey: ['posInvoiceReference'],
        queryFn: async () => {
            const response = await authAxios.get(`/saas/default-reference?tag=${tag}`);
            return response.data
        },
    });

    const { data: clientProfile } = useQuery({
        queryKey: ['clientProfile'],
        queryFn: async () => {
          const response = await authAxios.get(`/clientprofiles/${localStorage.getItem('clientProfile')}`);
          return response.data
        },
    });

    const { data: transactionTypes } = useQuery({
        queryKey: ['transactionTypes'],
        queryFn: async () => {
          const response = await authAxios.get("/accounting/transaction-types");
          return response.data
        },
    });

    const { data: orderStatus } = useQuery({
        queryKey: ['orderStatus'],
        queryFn: async () => {
          const response = await authAxios.get("/orders/order-status");
          return response.data
        },
    });

    // const { data: paymentModes } = useQuery({
    //     queryKey: ['paymentModes'],
    //     queryFn: async () => {
    //       const response = await authAxios.get("/pos/pos-payment-mapping");
    //       return response.data
    //     },
    // });

    const { data: clientPaymentModes } = useQuery({
        queryKey: ['clientPaymentModes'],
        queryFn: async () => {
          const response = await authAxios.get("/pos/client-payment-modes");
          return response.data.results
        },
    });

    const { data: customers } = useQuery({
        queryKey: ['customers'],
        queryFn: async () => {
          const response = await authAxios.get(`/crm/customers?page_size=${page_size}`);
          return response.data.results
        },
    });

    const { data: items } = useQuery({
        queryKey: ['items', page],
        queryFn: async () => {
          const response = await authAxios.get(`/pos/items/?page=${page}&page_size=${pageSize}`);
          setPageCount(response.data.page_count)
          setEmptyItemCount(Math.max(pageSize - response.data.results.length, 0))
          return response.data.results
        },
    });

    
    const validData = useRef(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const user = localStorage.getItem('userID');
    // const [orderNo, setOrderNo] = useState('');
    const orderNo = useRef("")
    const [txnDate, setTxnDate] = useState(new Date());

    const [cartItems, setCartItems] = useState([])
    const itemQty = useRef(1)
    const [orderTotal, setOrderTotal] = useState("");
    const [paymentMode, setPaymentMode] = useState("");
    const [paymentModeError, setPaymentModeError] = useState(false);

    const [customer, setCustomer] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");

    const [amt, setAmt] = useState("");
    const [amtError, setAmtError] = useState(false);

    const [isCash, SetIsCash] = useState(false);
    const [isMobileMoney, SetIsMobileMoney] = useState(false);
    const [isCard, SetIsCard] = useState(false);

    const [isCheckOut, SetIsCheckOut] = useState(false);

    const title = "Successful! Your Order has been posted"
    const subTitle = useRef("")



    //Pagination
    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const updateCart = (itemId, operationId) => {
        const updatedCartItems = cartItems.map((cartItem) => {
            if (cartItem.id === itemId) {
                if (operationId === "PLUS"){
                    itemQty.current = (cartItem.quantity || 1) + 1;
                }
                if (operationId === "MINUS"){
                    itemQty.current = Math.max((cartItem.quantity || 1) - 1, 1);
                }

                return {
                    ...cartItem,
                    quantity: itemQty.current,
                    // amount: (parseFloat(cartItem.price) * itemQty.current), // Calculate the updated amount
                    sub_total: cartItem.price * itemQty.current, // Increment the sub_total
                    total: cartItem.price * itemQty.current, // Increment the sub_total
                };
            }

            return cartItem;
        });
        setCartItems(updatedCartItems);
        const total = updatedCartItems.reduce((acc, cartItem) => acc + parseFloat(cartItem.total), 0);
        setOrderTotal(total);
    }

    const addToCart = (item) => {     
         // Check if the item is already in the cart
        const existingItemIndex = cartItems.findIndex((cartItem) => cartItem.id === item.id);
        let updatedCartItems; // Declare updatedCartItems here

        if (existingItemIndex !== -1) {
            // If the item is already in the cart, update its quantity
            updatedCartItems = cartItems.map((cartItem, index) => {
                if (index === existingItemIndex) {
                    return {
                        ...cartItem,
                        quantity: cartItem.quantity + 1,
                        // amount: cartItem.amount + item.price, // Increment the amount
                        sub_total: item.price * (cartItem.quantity + 1), // Increment the sub_total
                        total: item.price * (cartItem.quantity + 1), // Increment the sub_total
                    };
                }
                return cartItem;
            });

            setCartItems(updatedCartItems);
        } else {
            // If the item is not in the cart, add it as a new item
            const newItem = {
                id: item.id,
                sku: item.sku,
                name: item.name,
                quantity: 1,
                price: parseFloat(item.price),
                sub_total: parseFloat(item.price),
                total: parseFloat(item.price),
            };

            updatedCartItems = [...cartItems, newItem]; // Create a new array with the added item
            // setCartItems([...cartItems, newItem]);
            setCartItems(updatedCartItems);
        }

        // const total = updatedCartItems.reduce((acc, cartItem) => acc + parseFloat(cartItem.amount), 0);
        const total = updatedCartItems.reduce((acc, cartItem) => acc + parseFloat(cartItem.total), 0);

        // setOrderTotal(total.toFixed(2));
        setOrderTotal(total);

    };

    const handlePaymentMode = (newValue)=> {
        // console.log("VALUE",newValue)
        setPaymentMode(newValue)
        setPaymentModeError(false)
        setAmtError(false)

        if (newValue.payment_mode_code==="10") {
            SetIsCash(true)
            SetIsMobileMoney(false)
        }
        if (newValue.payment_mode_code==="20") {
            SetIsMobileMoney(true)
            SetIsCash(false)
        }
    };

    // // ACCORDION
    // const [expanded, setExpanded] = useState(false);
    // const handleChange = (panel) => (event, isExpanded) => {
    //     setExpanded(isExpanded ? panel : false);
    // };

    const mutation = useMutation({
        // mutationFn: (order) => {
        //     return authAxios.post("/orders/orders/", order);
        // },
        mutationFn: (transaction) => {
            return authAxios.post("/accounting/transactions/", transaction);
        },
        onError: (error, variables, context) => {
            if (error.response.data.error){
                // If it's an array, display each error message
                if (Array.isArray(error.response.data.error)) {
                    error.response.data.error.map((errorMessage) => (
                        setError(errorMessage)
                    ));
                } else {
                    // Handle single error message
                    setError(error.response.data.error); 
                }
            } else {
                // Fallback to Standard error message
                setError(error.message); 
            }      
        },
        onSuccess: (result) => {
            queryClient.invalidateQueries(['transactions']);
            // navigate('/dashboard/confirmed/', {state:{title:title, subtitle:subTitle.current, reference:orderNo.current, tag:tag}})
            navigate('/dashboard/confirmed/', {state:{title:title, subtitle:subTitle.current, reference:posInvoiceReference, tag:tag}})

        },
    })


    const handleSubmit = (e) => {
        e.preventDefault();
        validData.current=true

        // console.log(reference, amt, type, memo)
        if (paymentMode===""){
            setPaymentModeError(true)
            validData.current=false
        }

        if (isCash){
            if (amt==="" || Number(amt.replace(/,/g, ''))<Number(orderTotal)){
                setAmtError(true)
                validData.current=false
            }
        }

        if (validData.current===true) {

        // if (cartItems) {

            const order_details = cartItems

            //Change the format of transaction date before saving
            // const date = moment(dt).format("YYYY-MM-DD")

            const mobile_number = mobileNumber.replace(/\D/g, ''); //Remove non-numeric characters and spaces
            const client = clientProfile.id
            orderNo.current = orderReference
            const reference = posInvoiceReference
            const account = paymentMode.account
            const payment_mode = paymentMode.mode
            const source = 'POS'
            const date = moment(txnDate).format("YYYY-MM-DD")


            // setReference(String(clientProfile.order_no).padStart(8, '0'))
            subTitle.current = `You can now download/print the Receipt for Order:${reference}.`

            // const total = orderTotal
            // const amount = parseFloat(amt.replace(/,/g, ''));
            // const amount = parseFloat(total.replace(/,/g, ''));
            const amount = orderTotal


            // SET ORDER STATUS
            const status = orderStatus.find((status) => status.code === '010').id;
            // const status = orderStatus.find((status) => status.code === '010').id;

            // GET TRANSACTION TYPE
            const type = transactionTypes.find((type) => type.code === '050').id;


            // const order = { client, reference, date, source, type, account, payment_mode, mobile_number, status, customer, total, order_details, user };
            const transaction = { client, reference, date, source, type, account, payment_mode, mobile_number, status, customer, amount, order_details, user };
            console.log(transaction)
            mutation.mutate(transaction)
        } 
    }

    console.log("CUSTOMER",customer)

    useEffect(()=>{
        if (error){
            errorRef.current.scrollIntoView();  
        }            
    },[error])

    
    // The PaperComponent prop allows customization of the component used to render the body of the popup 
    // in MUI AutoComplete. Note that simply using sx on Autocomplete does not work.
    // Reference https://stackoverflow.com/a/75132849
    function CustomPaper({ children }) {
        return (
          <Paper 
            sx={{
            '& .MuiAutocomplete-option': {
                backgroundColor: 'unset !important',
            },
            '& .MuiAutocomplete-option:hover': {
                backgroundColor: '#F0F5FF !important',
            },
            '& .MuiAutocomplete-option.Mui-focused': {
                backgroundColor: '#F0F5FF !important',
            },
            }}
          >
            {children}
          </Paper>
        );
      }

    return(     
         <section className="dashboard-pos">
          

            {items && clientProfile &&
            <>
                <div className="pos-products-container">
                    {isCheckOut===false &&
                    <>
                        <Paper
                            className="pos-search-bar"
                            component="form"
                            // sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
                            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}

                        >
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="Search Products"
                                inputProps={{ 'aria-label': 'search products' }}
                                onChange={(e) => setSearchInput(e.target.value)}
                            />
                            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                <SearchIcon />
                            </IconButton>
                            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                            <IconButton color="primary" sx={{ p: '10px' }} aria-label="categories">
                                <FilterListIcon />
                            </IconButton>
                        </Paper>
                        <div className="pos-products">
                            {/* <Search placeholder="input search text" onSearch={onSearch} enterButton /> */}
                           

                            {items && items.map((item, index) => (

                                <Box className="pos-card" key={item.id} onClick={()=>{addToCart(item)}}>

                                    <div className="pos-card-content">
                                        <div className="pos-product-price">
                                            {clientProfile.currency_code} {item.price.toLocaleString()}
                                        </div>
                                        <div className="pos-product-title">
                                            {item.name}
                                        </div>

                                    </div>
                                </Box>
                                
                            ))}

                            {Array.from({ length: emptyItemCount }).map((_, index) => (
                                <Box className="empty-pos-card" key={`empty-${index}`} />
                            ))}


                        

                        </div>

                        <Pagination 
                            className="custom-mui-pagination"
                            count={pagecount} 
                            page={page} 
                            onChange={handlePageChange} 
                        />
                    </>
                    }


                    {isCheckOut &&
                    <>
                        <section className="dashboard-form dashboard-form-compact">
                            <DashboardHeader dashboardTitle="Update Order" dashboardPreTitle="POS" />

                            <form noValidate autoComplete="off" onSubmit={handleSubmit}>

                                <Autocomplete
                                    className="margin-top-zero"
                                    // onChange={handlePaymentMode}

                                    onChange={(event, newValue) => handlePaymentMode(newValue)}
                                    // options={paymentModes}
                                    options={clientPaymentModes}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => 
                                        <TextField 
                                            className="custom-input" 
                                            fullWidth 
                                            required
                                            error={paymentModeError} 
                                            label="Payment Mode"  
                                            {...params} 
                                        /> 
                                    }              
                                />
                                {isMobileMoney&&
                                <>
                                    <TextField className="custom-input"
                                        // onChange={(e) => {
                                        //     setNameError(false)
                                        //     setName(e.target.value)
                                        // }}
                                        label="Reference" 
                                        variant="outlined" 
                                        fullWidth
                                        // required
                                        // error={nameError}
                                    />

                                    <NumberFormat 
                                        onChange={(e) => {
                                            setMobileNumber(e.target.value)
                                        }} 
                                        className="custom-input"
                                        fullWidth
                                        label="Mobile Number" 
                                        variant="outlined" 
                                        customInput={TextField}
                                        format="### (###) ######" mask="_"

                                        // thousandSeparator={true} 
                                        // required
                                        // error={amtError}
                                    />
                                </>

                                }

                                {isCash &&
                                    <NumberFormat 
                                        onChange={(e) => {
                                            setAmt(e.target.value)
                                            setAmtError(false)
                                            validData.current=false
                                        }} 
                                        className="custom-input"
                                        fullWidth
                                        label="Amount" 
                                        variant="outlined" 
                                        customInput={TextField}
                                        thousandSeparator={true} 
                                        required
                                        error={amtError}
                                    />
                                }
                                {/* <Card
                                    title="Customer"
                                    extra={<a href="#">+ Add New</a>}
                                    >
                                    <Autocomplete
                                    onChange={(event, newValue) => {
                                        // console.log("VALUE", newValue.id)
                                        setCustomer(newValue.id);
                                        }}
                                    options={customers}

                                    getOptionLabel={(option) => option.name}
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 },}} 
                                    renderInput={(params) => <TextField fullWidth {...params} /> }
                                />
                                </Card> */}
                                <div className="custom-auto-complete-combo">
                                
                                    <Autocomplete
                                        id="custom-MuiAutocomplete"
                                        value={customer ?? ''}
                                        onChange={(event, newValue) => {
                                            // setCustomer(newValue.id);
                                            setCustomer(newValue);
                                            }}
                                        options={customers}

                                        // getOptionLabel={(option) => option.name}
                                        getOptionLabel={(option) => option.name || ""}
                                        isOptionEqualToValue={(option, value) => option.name === value.name || value === ""}

                                        // sx={{
                                        //     "& .MuiAutocomplete-popper": {
                                        //         // bgcolor: "red",
                                        //         border: '1px solid #007bff !important',

                                        //       },
                                        //     // '& .MuiAutocomplete-option': {
                                        //     //     backgroundColor: '#FFFFFF',
                                        //     //     border: '1px solid #007bff !important',
                                        //     //   },
                                        //     // '& .MuiAutocomplete-option.Mui-selected': {
                                        //     //   backgroundColor: '#F0F5FF',
                                        //     // },
                                        //   }}

                                        PaperComponent={CustomPaper}
                                       
                                        renderInput={(params) => <TextField 
                                            className="custom-input" 
                                            fullWidth {...params} 
                                            label="Customer"                                     
                                        /> }
                                    />

                                    {/* {customer ?
                                        <Autocomplete
                                            value={customer}
                                            onChange={(event, newValue) => {
                                                setCustomer(newValue);
                                                }}
                                            options={customers}
                                            getOptionLabel={(option) => option.name}
                                            isOptionEqualToValue={(option, value) => option.name === value.name}
                                            renderInput={(params) => <TextField 
                                                className="custom-input" 
                                                fullWidth {...params} 
                                                label="Customer"                                     
                                            /> }
                                        />
                                        :
                                        <Autocomplete
                                            onChange={(event, newValue) => {
                                                setCustomer(newValue.id);
                                                }}
                                            options={customers}
                                            getOptionLabel={(option) => option.name}
                                            renderInput={(params) => <TextField 
                                                className="custom-input" 
                                                fullWidth {...params} 
                                                label="Customer"                                     
                                            /> }
                                        />
                                    } */}
                                    <IconButton onClick={()=>setIsModalOpen(true)} className="add-new">
                                        {/* <FontAwesomeIcon icon={faSquarePlus} /> */}
                                        <AddIcon/>
                                    </IconButton>
                                </div>
                                <CustomerNewModal 
                                    isModalOpen={isModalOpen}
                                    setIsModalOpen={setIsModalOpen}
                                    setCustomer={setCustomer}
                                />

                            </form>
                        </section>
                    </>
                    }

                   

                </div>

                <div className="cart">
                    {orderTotal &&
                    <>

                    {/* <div className="grouped-fields-light cart-table-container"> */}
                    <div className="cart-table">

                        <table className="styled-table">
                            <thead>
                                <tr >
                                    <th>Item</th>
                                    <th>Qty</th>
                                    <th>Amount</th>
                                    <th></th>

                                </tr>
                            </thead>
                            {cartItems && cartItems.map((cart, index) => (
                                <tbody  key={index}>
                                    <tr>
                                        <td>{cart.name}</td>
                                        <td>
                                            <IconButton onClick={() => updateCart(cart.id,"MINUS")}>
                                                <RemoveIcon className="pos-icon pos-icon-reduce" />
                                            </IconButton>
                                                    {cart.quantity}
                                            <IconButton onClick={() => updateCart(cart.id,"PLUS")}>
                                                <AddIcon className="pos-icon pos-icon-add" />
                                            </IconButton>
                                        </td>
                                            
                                        {/* <td>{(cart.amount).toLocaleString()}</td> */}
                                        {/* <td>{(cart.sub_total).toLocaleString()}</td> */}
                                        <td>{(cart.sub_total).toLocaleString()}</td>



                                        <td>
                                            <IconButton 
                                                // onClick={() => handleRemoveSpecificRow(idx)}
                                                className='dynamic-table-menu' 
                                                size="small">
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        </td>
                                    </tr>
                                </tbody>
                            ))}

                        </table>

                    </div>
                    <div className="cart-total">
                        <div className="cart-total-text">
                            {Number(orderTotal).toLocaleString()}
                        </div>
                    </div>

                    {!isCheckOut &&
                    <button
                        className="btn btn-primary pos-button"
                        // onClick={handleSubmit}
                        onClick={()=>{SetIsCheckOut(true)}}
                        >
                            Checkout
                    </button>
                    }
                    {isCheckOut &&
                    <button
                        className="btn btn-primary pos-button" type="submit"
                        onClick={handleSubmit}
                        >
                            Complete Order
                    </button>
                    }
                    </>
                    }

                </div>
            </>
            }
        </section>


    )

}

export default PosNew;

