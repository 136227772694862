import React from "react";
// import axios from "axios";
import useAxios from "../../components/useAxios";
// import useAxiosInterceptor from '../../components/useAxiosInterceptor';

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";

import NumberFormat from "react-number-format";

// IMAGE UPLOAD ANT DESIGN
import { message, Upload } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';



import UserAlerts from "../../components/UserAlerts";
import DashboardHeader from "../../components/DashboardHeader";


function AmazonSettlementReport() {
    const {response, error, fetchData}  = useAxios();
    // const { error, response } = useAxiosInterceptor();

    useEffect(() => {
        if (response.status === 201){
            navigate('/dashboard/amazon-transactions/');
        }    
    }, [response.status]); 

    const navigate = useNavigate();

    const [file, setFile] = useState([]);
    // const [id_copy, setIdCopy] = useState(null);
    const user = localStorage.getItem('userID');
    const client = localStorage.getItem('clientID');
    const [settlementId, setSettlementId] = useState('');
    const [settlementIdError, setSettlementIdError] = useState(false)

 
    // IMAGE UPLOAD ANT DESIGN
    const [imageUrl, setImageUrl] = useState();
    const [loading, setLoading] = useState(false);
    // const [fileList, setFileList] = useState([]);


    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const beforeUpload = (file) => {
        // setFileList([...fileList, file]);

        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      
        if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
        }
      
        const isLt2M = file.size / 1024 / 1024 < 2;
      
        if (!isLt2M) {
          message.error('Image must smaller than 2MB!');
        }
      
        return isJpgOrPng && isLt2M;
      };
    
    // const handleUpload = (info) => {

    const handleUpload = (info) => {
        // for(let i= 0; i < info.fileList.length; i++){
        //     fileList.push(info.fileList[i].originFileObj)
        // }

        // setFiles(fileList)
        setFile(info.file)

        if (info.file.status === 'uploading') {
          setLoading(true);
          return;
        }
    
        if (info.file.status === 'done') {
          // Get this url from response in real world.
          getBase64(info.file.originFileObj, (url) => {
            setLoading(false);
            setImageUrl(url);
          });
        }
    };

    const uploadButton = (
        <div>
          {loading ? <LoadingOutlined /> : <PlusOutlined />}
          <div 
            style={{
              marginTop: 8,
            }}
          >
            Upload
          </div>
        </div>
    );


    const handleSubmit = (e) => {
        e.preventDefault();

        if (settlementId === '') {
          setSettlementIdError(true)
        }

        if (settlementId) {

            const settlement_id = settlementId

            // const customer = { type, client, name, address, country, email, website, telephone, id_type, id_number, gender, birth_date, user, tag, id_files };
            const upload = { client, settlement_id, user, file };

            // console.log(upload)

            fetchData({
                url: '/amazon/amazon-upload/',
                method: 'post',
                data: upload,
                headers: {"Content-Type" : "multipart/form-data"} //REQUIRED FOR FILE UPLOADS
              })
            
        } 
    
    }

    return(
        <div className="dashboard-main">
            <section className="dashboard-content">

            <section className="dashboard-form dashboard-form-compact">
            {/* <section className="dashboard-form-light"> */}
                <UserAlerts error={error} />
                <DashboardHeader dashboardTitle="Upload Settlement Report" dashboardPreTitle="Amazon" />

                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                  <NumberFormat 
                      onChange={(e) => {
                          setSettlementIdError(false)
                          setSettlementId(e.target.value)
                      }}
                      className="custom-input"
                      fullWidth
                      label="Settlement ID" 
                      variant="outlined" 
                      customInput={TextField}
                      // format="### (###) ######" mask="_"
                      required
                      error={settlementIdError}
                    />

                    <Upload
                        name="file"
                        listType="picture-card"
                        className="avatar-uploader custom-upload-card custom-input"
                        beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
                        onChange={handleUpload}
                        // maxCount={2}
                        maxCount={1}

                        >
                        {imageUrl ? (
                            <img
                            src={imageUrl}
                            alt="avatar"
                            style={{
                                width: '100%',
                            }}
                            />
                        ) : (
                            uploadButton
                        )}
                    </Upload>

                    <div className="button-border-top">
                        <button id="submit" className="btn btn-primary form-buttons" type="submit">
                            Submit
                        </button>
                    </div>


                </form>

            </section>
            </section> 

        </div>
    )

}

export default AmazonSettlementReport;
