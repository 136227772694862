import React from "react";
import { useNavigate } from "react-router-dom";
import { Menu } from "@mui/material";
import { MenuItem } from "@mui/material";
import { IconButton } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

// const ThreeDotsMenu = (props) => {
const ThreeDotsMenu = ({data, threeSubMenu, urlWithState}) => {
    let navigate = useNavigate();

        const [anchorEl, setAnchorEl] = React.useState(null);

        // function handleMenuClick(id) {
        //     // console.log(id);
        // }
        // console.log("DATA", data)


        const handleMenuClick = (id, url) => {
            setAnchorEl(null);
            // navigate('/bookingnew/',{state:{unit:id}});
            // navigate('/bookingnew/',{state:{id}});
            {urlWithState? 
                navigate(`${url}`,{state:{id}})
            :
                navigate(`${url}${id}`,{state:{id}});
            }
            // console.log("URL", url)
            // console.log("ID", id)
            console.log("WITHSTATE", urlWithState)
            console.log("ID", id)


        }  

        const handleClick = (e) => {
            setAnchorEl(e.currentTarget);
        }


        const handleClose = () => {
            setAnchorEl(null);
        }

        // const {data} = props;

        return(
            <>
                <IconButton
                    aria-label="more"
                    id="long-button"
                    // aria-controls={open ? 'long-menu' : undefined}
                    // aria-expanded={open ? 'true' : undefined}
                    // aria-haspopup="true"
                    onClick={handleClick}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    // open={open}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    }}
                    // onClick={() => handleMenuClick(data.id)}
                >
                    {threeSubMenu.map((submenu)=>{

                        {/* <MenuItem className="styled-threedots-dropdown" onClick={handleClose}><ArrowRightIcon/> View </MenuItem> */}
                        return(

                        <MenuItem 
                            key={submenu.id} 
                            className="styled-threedots-dropdown" 
                            onClick={()=>{
                                handleMenuClick(data.id, submenu.url)
                                // setUrl(submenu.url)
                            }}
                        >
                            <ArrowRightIcon/>{submenu.menu} 
                        </MenuItem>
                        )
                    })}
                    
                </Menu>
            </>
        )

}

export default ThreeDotsMenu