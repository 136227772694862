import { useRef, useEffect } from "react";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Cta from '../../components/Cta'

import hero from '../../assets/crm-hero.png'
import './Products.css'

const Pos = () => {
    const scrollRef = useRef(null);

    useEffect(()=>{
        scrollRef.current.scrollIntoView();  
    },[])

    return (
        <div className="container-fluid">

            <div className="product-container">
                <div ref={scrollRef}>
                    <Header />
                </div>

                {/* <div className="hero"> */}

                <div className="hero hero-bg-light-grey">
                    <div className="hero-text-container">

                        <div className="hero-sub-title">
                            Point of Sale
                        </div>
                        <div className="hero-title">
                            sell smarter.
                        </div>
                        <div className="hero-text">
                            {/* A single view of your prospects and customers that gives sales, marketing and service teams the real-time data they need to deliver exceptional customer experiences and growth. */}
                            An intuitive, smart point of sale solution that offers a wide range of options to meet all your business needs. Set it up in minutes, start selling in seconds, and keep both your staff and clients satisfied!
                        </div>
                    </div>
                    <div className="hero-img-container">
                        {/* <div className="hero-img"> */}
                            <img src={hero} alt="hero"/> 
                        {/* </div> */}

                    </div>
                </div>
                <div className="features">
                    <div className="features-card">
                        <div className="feature-bullet">

                        </div>
                        <div className="features-card-title">
                            Inventory Tracking
                        </div>
                        <div className="features-card-text">
                            Automatically update inventory levels with each sale, automatically triggering re-orders, preventing overselling and stockouts.
                        </div>

                    </div>

                    <div className="features-card">
                        <div className="feature-bullet">

                        </div>
                        <div className="features-card-title">
                            Product Catalogue
                        </div>
                        <div className="features-card-text">
                            Real-time sync with product details, including variants, pricing, promotions, and discounts ensures accurate data for transactions.
                        </div>

                    </div>

                    <div className="features-card">
                        <div className="feature-bullet">

                        </div>
                        <div className="features-card-title">
                            Payment Integrations
                        </div>
                        <div className="features-card-text">
                            {/* Get a 360-degree view of your customers through a seamless connectivity of the CRM with other business applications and tools. */}
                            Seamless integration with payment processors allowing for swift and secure transactions including credit/debit cards and mobile payments. 
                        </div>

                    </div>

                    <div className="features-card">
                        <div className="feature-bullet">

                        </div>
                        <div className="features-card-title">
                            Tax Compliance
                        </div>
                        <div className="features-card-text">
                            {/* a book or other written or printed work, regarded in terms of its content rather than its physical form. */}
                            {/* A dynamic and centralized platform to systematically track and win opportunities throughout the entire sales lifecycle. */}
                            Tax rates and rules directly built into our POS solution enable dynamic calculation of sales tax, VAT, and/or other applicable levies.
                        </div>

                    </div>

                    <div className="features-card">
                        <div className="feature-bullet">

                        </div>
                        <div className="features-card-title">
                            Integration with Accounting
                        </div>
                        <div className="features-card-text">
                            {/* a book or other written or printed work, regarded in terms of its content rather than its physical form. */}
                            {/* Give teams that interacts with customers — sales, call centre and service — access to updated, real-time data. */}
                            Streamline reconciliations by automatically updating transactions, sales, and assets (inventory) from POS to your accounting module.
                        </div>

                    </div>

                    <div className="features-card">
                        <div className="feature-bullet">

                        </div>
                        <div className="features-card-title">
                            Mobile 
                        </div>
                        <div className="features-card-text">
                            {/* a book or other written or printed work, regarded in terms of its content rather than its physical form. */}
                            {/* Our responsive design enables your teams to access and update customer information from anywhere using mobile devices. */}
                            Our responsive design enables POS access from mobile devices such as tablets or smartphones for portable sales.
                        </div>

                    </div>

                    

                    <div className="features-card">
                        <div className="feature-bullet">

                        </div>
                        <div className="features-card-title">
                            Analytics
                        </div>
                        <div className="features-card-text">
                            {/* a book or other written or printed work, regarded in terms of its content rather than its physical form. */}
                            Analytics tools empower you to measure key metrics, identify trends, forecast future sales, and make informed strategic decisions.
                        </div>

                    </div>
                    

                    
                    <div className="features-card">
                        <div className="feature-bullet">

                        </div>
                        <div className="features-card-title">
                            Customization
                        </div>
                        <div className="features-card-text">
                            {/* a book or other written or printed work, regarded in terms of its content rather than its physical form. */}
                            Every business is unique, and your POS should reflect that. During implementation we mold your POS to fit your business.
                        </div>

                    </div>

                </div>

                <Cta />

                <Footer />        
            </div>
        </div>



    )

}


export default Pos;