import { useEffect, useState, useContext } from "react";
import useAxios from "../../components/useAxios";

import { TextField, Autocomplete } from "@mui/material";

import {IconButton, Button} from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Chip from '@mui/material/Chip';

import NumberFormat from "react-number-format";
import {UserContext} from "../../App"


const ProductOptions = ({rows, setRows}) => {

    const [addRowsEnabled, setAddRowsEnabled] = useState(false);

    const handleAddRow = ({idx}) => {
        const item = {};
        setRows([...rows, item]);
        setAddRowsEnabled(false);
    };

    const handleRemoveSpecificRow = (idx) => {
        const tempRows = [...rows]; // to avoid  direct state mutation
        tempRows.splice(idx, 1);

        setRows(tempRows);
        // calcTotal()
    };

    const updateOption = (e, idx) =>{        
        const tempRows = [...rows];
        tempRows[idx].order = idx; 
        tempRows[idx].option = e.target.value; 

        // setPrice(product.price)
        // console.log("ROWS",tempRows)
        // console.log("LEN",tempRows[idx].value.length)

        if (tempRows[idx].option && tempRows[idx].value.length){
            setAddRowsEnabled(true);
        }
        else{
            setAddRowsEnabled(false);
        }
        // calcTotal()
        setRows(tempRows);

    }

    const updateValue = (newValue, idx) => {
        const tempRows = [...rows];
        tempRows[idx].value = newValue; 
        console.log("LEN",tempRows[idx].value.length)

        console.log("ROWS",tempRows)
        if (tempRows[idx].option && tempRows[idx].value.length){
            setAddRowsEnabled(true);
        }
        else{
            setAddRowsEnabled(false);
        }
        setRows(tempRows);
        // calcTotal()
    }

    const AddRowButton = ({addRowsEnabled}) => {
        const tempRows = [...rows];      
        if (addRowsEnabled){
            return(
                <div className="AddRow">
                    <Button
                        id="AddRowButton"
                        onClick={handleAddRow} 
                        variant="outlined" 
                        size="small"
                        startIcon={<AddCircleOutlineIcon />}
                    >
                        Variant
                    </Button>

                </div>   
            )
        }
    }

    // useEffect(()=>{
    //     if (productsData.count){
    //         setProducts(Array.from(productsData.results))
    //     }
    // },[productsData.count])

    return(
        <>
        <table className="dynamic-table product-options-table">
        {/* <table className="dynamic-table "> */}

            <thead>
                <tr>
                    <th className="text-center">Order</th>
                    <th className="text-center">Option</th>
                    <th className="text-center">Value</th>
                    {/* <th className="text-center">Price</th>
                    <th className="text-center">Total</th> */}
                </tr>
            </thead>
            <tbody>
                {rows.map((item, idx) => (
                <tr 
                    key={idx} id="activeRow"
                >
                    <td>{idx + 1}</td>
                    <td >
                        <TextField 
                            className="td-textbox"
                            index={idx}
                            fullWidth
                            // value={rows[idx][column]}
                            // value={rows[idx].option}

                            variant="outlined" 
                            InputLabelProps={{shrink: false}}
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 },}} //Disable label visibility

                            // customInput={TextField}
                            // decimalScale={2}
                            // thousandSeparator={true} 
                            required
                            // error={rateError}
                            onChange={(e) => updateOption(e, idx)}  
                        />
                    </td>

                    <td className="options-value-col">
                        <Autocomplete
                                className="product-options-autocomplete"
                                multiple
                                limitTags={2}
                                fullWidth
                                onChange={(e, newValue) => updateValue(newValue, idx)}  


                                // options={top100Films.map((option) => option.title)}
                                options={[]}
                                // defaultValue={[top100Films[13].title]}
                                freeSolo
                                renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                ))
                                }
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 },}} //Disable label visibility
                                    fullWidth
                                    // variant="filled"
                                    // className="td-textbox"
                                    // label="freeSolo"
                                    // placeholder="Add Option"
                                />
                                )}
                            />

                    </td>
                   
                    <td>
                        <IconButton 
                            onClick={() => handleRemoveSpecificRow(idx)}
                            aria-label="Delete" 
                            className='dynamic-table-menu' 
                            size="small">
                            <RemoveCircleIcon fontSize="small" />
                        </IconButton>
                    </td>


                </tr>
                ))}
            </tbody>
        </table>

        <div className="table-total">
            <div className='table-card'>
                <AddRowButton addRowsEnabled={addRowsEnabled} />
            </div> 


        
        </div>

    
    </>

    )
}

export default ProductOptions;