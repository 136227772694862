import React, { useState, useEffect, useContext, useRef } from 'react';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import authAxios from '../../components/authAxios';

import { useNavigate } from "react-router-dom";
import { TextField, Autocomplete, InputLabel, MenuItem } from "@mui/material";
import { FormControl } from "@mui/material";
// import {IconButton, Button} from "@mui/material";

import { Select } from "@mui/material";

// import Select from '@mui/material/Select';

import moment from 'moment';


import UserAlerts from "../../components/UserAlerts";
import DashboardHeader from "../../components/DashboardHeader";
import ProductDetails from "../products/ProductDetails";
import {UserContext} from "../../App"


const OrderNew = () => {
    const page_size = useContext(UserContext);
    const [error, setError] = useState('');
    const errorRef = useRef(null);

    const queryClient = useQueryClient();

    const [reference, setReference] = useState('');

    const { data: clientProfile } = useQuery({
        queryKey: ['clientProfile'],
        queryFn: async () => {
          const response = await authAxios.get(`/clientprofiles/${localStorage.getItem('clientProfile')}`);
          return response.data

        },
    });

    const { data: orderReference } = useQuery({
        queryKey: ['orderReference'],
        queryFn: async () => {
            const response = await authAxios.get("/orders/default-order-reference");
            setReference(response.data)
            return response.data
        },
    });

    const { data: orderStatus } = useQuery({
        queryKey: ['orderStatus'],
        queryFn: async () => {
            const response = await authAxios.get("/orders/order-status");
            return response.data
        },
    });

    const { data: customers } = useQuery({
        queryKey: ['customers'],
        queryFn: async () => {
          const response = await authAxios.get(`/crm/customers/?page_size=${page_size}`);
          return response.data.results;
        },
    });

    const { data: locations } = useQuery({
        queryKey: ['locations'],
        queryFn: async () => {
          const response = await authAxios.get(`/inventory/locations?page_size=${page_size}`);
          return response.data.results
        },
    });


    const [isModalOpen, setIsModalOpen] = useState(false);


    const navigate = useNavigate();

    const [docRef, setDocRef] = useState('');
    const [customer, setCustomer] = useState('');
    const [location, setLocation] = useState('');
    const [memo, setMemo] = useState('');
    const [status, setStatus] = useState('');
    const user = localStorage.getItem('userID');
    const client = localStorage.getItem('clientID');
    const [customerError, setCustomerError] = useState(false)
    const [statusError, setStatusError] = useState(false)
    const [referenceError, setReferenceError] = useState(false)
    const [tag, setTag] = useState ("ORD")
    const [txnLines, setTxnLines] = useState([]);

    const formatReference = (reference) => {
        return `${reference.slice(0, 3)}-${reference.slice(3, 9)}-${reference.slice(9)}`;
      };

    const addTxnLine = (newTxnLine) => {
        setTxnLines([...txnLines, newTxnLine]);
        setIsModalOpen(false); // Close the modal after adding the expense
    };


    const mutation = useMutation({
        mutationFn: (order) => {
            return authAxios.post("/orders/orders/", order);
        },
        onError: (error, variables, context) => {
            if (error.response.data.error){
                // If it's an array, display each error message
                if (Array.isArray(error.response.data.error)) {
                    error.response.data.error.map((errorMessage) => (
                        setError(errorMessage)
                    ));
                } else {
                    // Handle single error message
                    setError(error.response.data.error); 
                }
            } else {
                // Fallback to Standard error message
                setError(error.message); 
            }      
        },
        onSuccess: (result) => {
            queryClient.invalidateQueries(['orders']);
            navigate('/dashboard/orders/')
        },
    })

    const handleSubmit = (e) => {
        e.preventDefault();

        if (customer === '') {
            setCustomerError(true)
        }

        if (reference === '') {
            setReferenceError(true)
        }

        if (reference && customer) {

            const doc_ref = docRef
            const source = 'ORDERS'

            //UPDATE ORDER DETAILS
            const order_details = txnLines;

            // postResults()
            // const order_details = rows;
            // console.log("POST",rows); // there you go, do as you please
            // console.log("DETAILS",order_details); // there you go, do as you please

            //Change the format of Price field before saving
            // let price = 0
            // price = parseFloat(rate.replace(/,/g, ''));

            // const sub_total = subTotal

            // const order = { client, reference, status, customer, sub_total, tax, total, memo, user, order_details };
            // const order = { client, reference, doc_ref, customer, memo, user, order_details, source };
            const order = { client, reference, doc_ref, customer, location, memo, user, order_details, source };

            console.log(order)
            mutation.mutate(order)


            // fetchData({
            //     url: '/orders/orders/',
            //     method: 'post',
            //     data: order,
            //   })

        } 
    }

    useEffect(()=>{
        if (error){
            errorRef.current.scrollIntoView();  
        }            
    },[error])


    return(
        <div className="dashboard-main">
            <section className="dashboard-content">

            {/* <section className="dashboard-form"> */}
            <section className="dashboard-form dashboard-form-compact">
                <div ref={errorRef}>
                    <UserAlerts error={error}  />
                </div>
                <UserAlerts error={error} />
                <DashboardHeader dashboardTitle="Add Order" dashboardPreTitle="Order Management" />

                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <div className="grouped-fields-light">

                    <div className="two-columns">
                        <TextField className="custom-input"
                            // value = {reference}
                            value={formatReference(reference)}
                            onChange={(e) => {
                                setReferenceError(false)
                                setReference(e.target.value)
                            }}
                            label="Reference" 
                            variant="outlined" 
                            fullWidth
                            required
                            // error={referenceError}
                            // disabled
                            inputProps={{readOnly: true}}

                        />

                        <TextField className="custom-input"
                            value = {docRef}
                            onChange={(e) => {
                                // setError(false)
                                setDocRef(e.target.value)
                            }}
                            label="LPO" 
                            variant="outlined" 
                            fullWidth
                        />
                    </div>

                    {customers && 
                    <Autocomplete
                        onChange={(event, newValue) => {
                            setCustomer(newValue.id);
                            console.log("VALUE", newValue.id)
                            }}
                        options={customers}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField className="custom-input" fullWidth {...params} label="Customer"  /> }
                    />
                    } 
                    <Autocomplete
                        onChange={(event, newValue) => {
                            setLocation(newValue.id);
                            console.log("VALUE", newValue.id)
                            }}
                        options={locations}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField className="custom-input" fullWidth {...params} label="Location"  /> }
                    />

                    {/* <FormControl 
                        variant="outlined" 
                        fullWidth className="custom-input" 
                        error={statusError}
                        required
                    >
                        <InputLabel>Status</InputLabel>
                        <Select
                            className="custom-select"
                            label="Status"
                            onChange={(e) => {
                                setStatusError(false)
                                setStatus(e.target.value)}
                            }
                            defaultValue = ""
                        >
                            {orderStatus && orderStatus.map((status) => { 
                                return(
                                <MenuItem key={status.id} value={status.id}>{status.name}</MenuItem>
                                )
                            })}   
                            
                        </Select>
                    </FormControl> */}
                    </div>

                    <ProductDetails 
                        addTxnLine={addTxnLine}
                        txnLines={txnLines} 
                        setTxnLines={setTxnLines} 
                        tag={tag}
                        isModalOpen={isModalOpen}
                        setIsModalOpen={setIsModalOpen}
                        clientProfile={clientProfile}
                        location={location}
                    />

                    <div className="grouped-fields-light">


                    <TextField className="custom-input"
                        onChange={(e) => {
                            setMemo(e.target.value)
                        }}
                        label="Notes"
                        variant="outlined"
                        multiline
                        rows={2}
                        fullWidth
                    />
                    </div>

                    <div className="button-border-top">
                        <button id="submit" className="btn btn-primary form-buttons" type="submit">
                            Save
                        </button>
                    </div>


                </form>

            </section>
            </section>

        </div>

    )

}

export default OrderNew;