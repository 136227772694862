
import { Link } from "react-router-dom";

import Pagination from '@mui/material/Pagination';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';

import Pillchip from "../../components/Pillchip";
import ThreeDotsMenu from "../../components/ThreeDotsMenu";



const OrderList = ({orders, handlePageChange, page, pagecount}) => {

    const convertDateTime = (myDateTime) => {
        return format(new Date(myDateTime), 'dd-MM-yyyy HH:mm');
    };

    const formatReference = (reference) => {
        return `${reference.slice(0, 3)}-${reference.slice(3, 9)}-${reference.slice(9)}`;
    };

    const threeSubMenu = [
        {
            id: 1,
            menu: "View",
            url: "/dashboard/order/",
        }, 
    ];

    return (
        <>
            <table className="styled-table">
                <thead>
                    <tr >
                        <th>Reference</th>
                        <th>Date</th>
                        <th>Total</th>
                        <th>Status</th>
                        <th>Payment</th>
                        <th></th>
                    </tr>
                </thead>

                {orders && orders.map(order => (

                    <tbody key={order.id}>
                        <tr>
                            <td>{formatReference(order.reference)}</td>
                            <td>{convertDateTime(order.order_date)}</td>
                            <td>{Number(order.total).toLocaleString()}</td>
                            <td><Pillchip label={order.status_name} /></td>
                            <td><Pillchip label={order.payment_status_name} /></td>
                            {/* <td className='styled-table-menu'><FontAwesomeIcon icon={faEllipsisVertical} /></td> */}
                            <td className='styled-table-menu'><ThreeDotsMenu data={order} threeSubMenu={threeSubMenu} /></td>
                        </tr>
                    </tbody>
                ))}
                

            </table>

            <Pagination 
                className="custom-mui-pagination"
                count={pagecount} 
                page={page} 
                onChange={handlePageChange} 
            />

            <Link to="/dashboard/ordernew" className="nav-link">
                <Fab className="fab" color="primary" aria-label="add">
                    <AddIcon />
                </Fab>
            </Link>

        </>
    )

}

export default OrderList;