import { useState, useContext, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";

import authAxios from "../../components/authAxios";
import DashboardHeader from "../../components/DashboardHeader";
import Pagination from '@mui/material/Pagination';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { useMediaQuery } from 'react-responsive'
import CollectionNew from './CollectionNew'
import ThreeDotsMenu from "../../components/ThreeDotsMenu";

import { format } from 'date-fns';
import ProductCategoryNew from "./ProductCategoryNew";


// import { UserContext } from "../../App";


const ProductCategories = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 750px)' })

    //Pagination
    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const convertDateTime = (myDateTime) => {
        return format(new Date(myDateTime), 'dd-MM-yyyy HH:mm');
      };

    const [isModalOpen, setIsModalOpen] = useState(false);
    // const {clientProfile} = useContext(UserContext);

    const { data: categories } = useQuery({
        queryKey: ['categories'],
        queryFn: async () => {
          const response = await authAxios.get(`/products/categories?page=${page}`);
          return response.data.results;
        },
    });

    const { data: clientProfile } = useQuery({
        queryKey: ['clientProfile'],
        queryFn: async () => {
          const response = await authAxios.get(`/clientprofiles/${localStorage.getItem('clientProfile')}`);
          return response.data

        },
    });

    const threeSubMenu = [
        {
            id: 1,
            menu: "View",
            url: "/dashboard/account/",

        }, 
    ];   
    

    return (
        <div className="dashboard-main">
            <section className="dashboard-content">
            <>
                <DashboardHeader dashboardTitle="Product Categories" dashboardPreTitle="Products" />
                <table className="styled-table">

                    <thead>
                        <tr >
                            <th>ID</th>
                            <th>Name</th>
                            <th>Date Created</th>
                            <th>Status</th>
                            <th>No of Products</th>
                            <th></th>
                        </tr>
                    </thead>

                    {categories && categories.map(category => (

                        <tbody key={category.id}>
                            <tr>
                                <td>{category.id}</td>
                                <td>{category.name}</td>
                                {/* <td>{category.created_at}</td> */}
                                <td>{convertDateTime(category.created_at)}</td>
                                {category.is_active?
                                <td>Active</td>
                                :
                                <td>Inactive</td>
                                }

                                <td>{category.count}</td>
                                {/* <td className='styled-table-menu'>
                                    <ThreeDotsMenu data={category} threeSubMenu={threeSubMenu} />

                                </td> */}
                            </tr>
                        </tbody>
                    ))}

                </table>


                <Pagination 
                    className="custom-mui-pagination"
                    count={pagecount} 
                    page={page} 
                    onChange={handlePageChange} 
                />

                {isModalOpen &&
                <ProductCategoryNew
                    isModalOpen={isModalOpen} 
                    setIsModalOpen={setIsModalOpen}
                />
                }

                <Fab 
                    className="fab" 
                    color="primary" 
                    aria-label="add"
                    onClick={()=>{
                        setIsModalOpen(true)
                    }
                    }
                >
                    <AddIcon />
                </Fab> 
            </>
            </section>
        </div>
    );
  };
  
  export default ProductCategories;