import React from "react";
import useAxios from "../../components/useAxios";


import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { InputLabel } from "@mui/material";
import { FormControl } from "@mui/material";
import { MenuItem } from "@mui/material";
import NumberFormat from "react-number-format";
import { TextField } from "@mui/material";
import { Select } from "@mui/material";
import { Autocomplete } from "@mui/material";


// import axios from "axios";
// import authAxios from "./authAxios";
// import useAxiosInterceptor from '../components/useAxiosInterceptor';
// import { Radio } from "@mui/material";
// import { RadioGroup } from "@mui/material";
// import { FormControlLabel } from "@mui/material";
// import { LocalizationProvider } from "@mui/x-date-pickers";
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
// import { Autocomplete } from "@mui/material";
// import moment from 'moment';
// import Sidebar from "./Sidebar";
// import UserAlerts from "./UserAlerts";
// import LayoutForm from "./LayoutForm";
// import Layout from "./Layout";
// import DashboardHeader from "./DashboardHeader";

const AccountEdit = () => {
    const params = useParams()
    // const [isUpdated, setIsUpdated] = useState(false)

    const {fetchData, data, response}  = useAxios();


    const [dt, setDt] = useState(new Date());

    const [name, setName] = useState("");
    // const [email, setEmail] = useState("");
    const [number, setNumber] = useState("");
    const [balance, setBalance] = useState("");
    const [isSystem, setIsSystem] = useState("");
    const [memo, setMemo] = useState('');
    // const [mobile, setMobile] = useState('');
    const [type, setType] = useState('');

    const client = localStorage.getItem('clientID');
    const user = localStorage.getItem('userID');

    const [account, setAccount] = useState([]);
    // const [contact, setContact] = useState("");

    const [nameError, setNameError] = useState(false)
    // const [idNumberError, setIdNumberError] = useState(false)
    // const [typeError, setTypeError] = useState(false)

    const { data: accountsData } = useAxios({
        url: `accounting/accounts/${params.id}`,
        method: 'get'
    })

    const { data: acctypes } = useAxios({
        url: '/accounting/account-types',
        method: 'get'
    })

    const navigate = useNavigate();

    // const handleChange = (event) => {
    //     setName(event.target.value);
    //   };

    const getDefaultValues = () => {
        setName(account.name)
        setType(account.type)
        setNumber(account.number)
        setBalance(account.balance)
        setMemo(account.memo)
        setIsSystem(account.is_system)
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (name === '') {
            setNameError(true)
        }

        if (name ) {

            const is_system = isSystem

            const account = { client, name, type, number, balance, memo, is_system, user };
            console.log(account)

            fetchData({
                url: `/accounting/accounts/${params.id}/`,
                method: 'put',
                data: account
            })
            .then(()=>{                    
                if (response.status === 200){
                    // navigate('/accounts/')
                    navigate('/dashboard/accounts/')

                }
            })
        } 
    }


    useEffect(() => {
        if (accountsData){
            setAccount(accountsData)
        }
    }, [accountsData]); 

    useEffect(() => {
        if (account){
            getDefaultValues()
        }
    }, [account]); 


    return(
    // <Layout>

        <section className="dashboard-form">
            {/* {contact && */}
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <div className="grouped-fields-light">
                    <TextField className="custom-input"
                        // value={name}
                        value={ name ?? ''}

                        // value={account.name}

                        onChange={(e) => {
                            setNameError(false)
                            setName(e.target.value)
                            
                        }}
                        // onChange={handleChange}
                        label="Name" 
                        InputLabelProps={{ shrink: true }}
                        variant="outlined" 
                        fullWidth
                        required
                        error={nameError}
                    />

                    {type &&

                    <Autocomplete
                        // value={ acctypes[type] }
                        value={ acctypes[type] ?? ''}

                        onChange={(event, newValue) => {
                            setType(newValue.id);
                            // console.log("VALUE", newValue.id)
                            }}
                        options={acctypes}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField className="custom-input" fullWidth {...params} label="Type"  /> }
                    />
                    }

                    <NumberFormat 
                        value = {balance}

                        className="custom-input"
                        fullWidth
                        label="Balance" 
                        variant="outlined" 
                        customInput={TextField}
                        thousandSeparator
                        readOnly
                        disabled

                        // required
                        // error={mobileError}
                    />

                    <TextField className="custom-input"
                        // value={email}
                        // value={number}
                        value={number ?? ''}

                        onChange={(e) => {
                            setNumber(e.target.value)
                        }}
                        label="Number" 
                        InputLabelProps={{ shrink: true }}
                        variant="outlined" 
                        fullWidth
                        // required
                        // error={emailError}
                    />

                    <TextField className="custom-input"
                        // value={memo}
                        value={memo ?? ''}

                        onChange={(e) => setMemo(e.target.value)}
                        label="Memo"
                        InputLabelProps={{ shrink: true }}

                        variant="outlined"
                        multiline
                        rows={3}
                        fullWidth
                        // required
                    />
                </div>
                
                {!isSystem &&
                <div className="button-border-top">
                    <button id="submit" className="btn btn-primary form-buttons" type="submit">
                        Save
                    </button>
                </div>
                }
            </form>
            {/* } */}
        </section>
        // </Layout>

    )

}

export default AccountEdit;
