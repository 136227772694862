// import React from "react";
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';


import useAxios from "../../components/useAxios";
import authAxios from "../../components/authAxios";

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Autocomplete } from "@mui/material";
import { TreeSelect } from "antd";


// import Sidebar from "./Sidebar";
import UserAlerts from "../../components/UserAlerts";
import DashboardHeader from "../../components/DashboardHeader";
import '../../styles/TreeSelectStyles.css'


function EbayMappingNew() {
    // const {response, error, fetchData}  = useAxios();
    const [error, setError] = useState('');
    const queryClient = useQueryClient();



    // const { data: transactionTypes } = useAxios({
    //     url: '/accounting/transaction-types',
    //     method: 'get'
    // })

    const { data: transactionTypes } = useQuery({
        queryKey: ['transactionTypes'],
        queryFn: async () => {
            const response = await authAxios.get('/accounting/transaction-types');
            return response.data
        },
    });

    const { data: ebayTransactionTypes } = useQuery({
        queryKey: ['ebayTransactionTypes'],
        queryFn: async () => {
            const response = await authAxios.get('/ebay/ebay-transaction-types');
            return response.data
        },
    });
    
    const { data: accts } = useAxios({
        url: "/accounting/accounts-tree",
        method: 'get'
    })   

    // console.log(contacts)
    const navigate = useNavigate();

    const [description, setDescription] = useState('');
    const [name, setName] = useState('');
    const [type, setType] = useState('');
    const [ebayType, setEbayType] = useState('');

    const [debitAccount, setDebitAccount] = useState('');
    const [creditAccount, setCreditAccount] = useState('');
    const [memo, setMemo] = useState('');

    const [accounts, setAccounts] = useState([]);
    const [accountStatus, setAccountStatus] = useState("")
    const user = localStorage.getItem('userID');

    // const [nameError, setNameError] = useState(false)
    // const [descriptionError, setDescriptionError] = useState(false)


    const [typeError, setTypeError] = useState(false);

    const { data: clientProfile } = useAxios({
        url: `/clientprofiles/${localStorage.getItem('clientID')}`,
        method: 'get'
    })  

    const transformDataToTree = (data) => {
        return data.map((item) => ({
            value: item.id.toString(), // Convert to string if needed
            title: item.name,
            children: item.sub_accounts ? transformDataToTree(item.sub_accounts) : [],
        }));
    };

    const filterTreeNode = (input, treeNode) => {
        const nodeLabel = treeNode.title.toLowerCase(); // Access title directly
        return nodeLabel.includes(input.toLowerCase());
    };


    const mutation = useMutation({
        mutationFn: (mapping) => {
            return authAxios.post("/ebay/ebay-transaction-mapping/", mapping);
        },
        onError: (error, variables, context) => {
            if (error.response.data.error){
                // If it's an array, display each error message
                if (Array.isArray(error.response.data.error)) {
                    error.response.data.error.map((errorMessage) => (
                        setError(errorMessage)
                    ));
                } else {
                    // Handle single error message
                    setError(error.response.data.error); 
                }
            } else {
                // Fallback to Standard error message
                setError(error.message); 
            }      
        },
        onSuccess: (result) => {
            queryClient.invalidateQueries(['eBayMapping']);
            navigate('/dashboard/ebay-mapping/')
        },
    })

    const handleSubmit = (e) => {
        e.preventDefault();

        // console.log(name, institution, email)

        // if (description === '') {
        //     setDescriptionError(true)
        // }

        // if (name === '') {
        //     setNameError(true)
        // }

        if (type === '') {
            setTypeError(true)
        }

        if (type ) {
            const client = clientProfile.id
            const debit_account = debitAccount
            const credit_account = creditAccount
            const gl_transaction_type = type
            const ebay_transaction_type = ebayType

            const mapping = {client, gl_transaction_type, ebay_transaction_type, debit_account, credit_account, memo, user};
            console.log(mapping)
            mutation.mutate(mapping)


            // fetchData({
            //     url: '/amazon/amazon-transaction-mapping/',
            //     method: 'post',
            //     data: mapping,
            //   })
        } 
    
    }

   

    useEffect(()=>{
        if (accts.length){
            setAccounts(transformDataToTree(accts))
        }

    },[accts.length])

    // useEffect(() => {
    //     if (response.status === 201){
    //         navigate('/dashboard/amazon-mapping/');
    //     }    
    // }, [ response.status]); 

    return(

        <div className="dashboard-main">
            <section className="dashboard-content">

            <section className="dashboard-form dashboard-form-compact">
                <UserAlerts error={error} />
                <DashboardHeader dashboardTitle="Add Transaction Mapping" dashboardPreTitle="eBay" />

                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <div className="grouped-fields-light">

                        {/* <TextField className="custom-input"
                            onChange={(e) => {
                                setDescriptionError(false)
                                setDescription(e.target.value)
                            }}
                            label="Description" 
                            variant="outlined" 
                            fullWidth
                            required
                            error={descriptionError}
                        /> */}

                        {/* <TextField className="custom-input"
                            onChange={(e) => {
                                setNameError(false)
                                setName(e.target.value)
                            }}
                            label="Column Name" 
                            variant="outlined" 
                            fullWidth
                            required
                            error={nameError}
                        /> */}

                        <Autocomplete
                            onChange={(event, newValue) => {
                                console.log("VALUE", newValue.id)
                                // setTypeError(false)
                                setEbayType(newValue.id)
                                }}
                            options={ebayTransactionTypes}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => 
                                <TextField 
                                    className="custom-input" 
                                    fullWidth error={typeError} 
                                    required
                                    label="eBay Transaction Type"  

                                    {...params} 
                                /> 
                            }                          
                        />

                        <Autocomplete
                            onChange={(event, newValue) => {
                                console.log("VALUE", newValue.id)
                                setTypeError(false)
                                setType(newValue.id)
                                }}
                            options={transactionTypes}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => 
                                <TextField 
                                    className="custom-input" 
                                    fullWidth error={typeError} 
                                    required
                                    label="GL Transaction Type"  
                                    {...params} 
                                /> 
                            }                          
                        />


                        <TreeSelect
                            className="custom-tree-select"
                            style={{
                                width: '100%',
                            }}
                            dropdownStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                            }}
                            placeholder="Debit Account"
                            status={accountStatus}
                            allowClear
                            showSearch
                            onChange={(e)=>{
                                // setAccountStatus("")
                                const selectedAccount = accounts.find((account) => account.value === e);
                                selectedAccount && selectedAccount.children.length?setAccountStatus("error"):setAccountStatus("")
                                setDebitAccount(e)
                            }}
                            treeData={accounts}
                            filterTreeNode={filterTreeNode} //Enables search filter
                        />

                        <TreeSelect
                            className="custom-tree-select"
                            style={{
                                width: '100%',
                            }}
                            dropdownStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                            }}
                            placeholder="Credit Account"
                            status={accountStatus}
                            allowClear
                            showSearch
                            onChange={(e)=>{
                                // setAccountStatus("")
                                const selectedAccount = accounts.find((account) => account.value === e);
                                selectedAccount && selectedAccount.children.length?setAccountStatus("error"):setAccountStatus("")
                                setCreditAccount(e)
                            }}
                            treeData={accounts}
                            filterTreeNode={filterTreeNode} //Enables search filter
                        />

                        <TextField className="custom-input"
                            onChange={(e) => setMemo(e.target.value)}
                            label="Memo"
                            variant="outlined"
                            multiline
                            rows={3}
                            fullWidth
                        />

                    </div>

                <div className="button-border-top">
                    <button id="submit" className="btn btn-primary form-buttons" type="submit">
                        Save
                    </button>
                </div>


                </form>

            </section>
            </section> 

        </div>

    )

}

export default EbayMappingNew;
