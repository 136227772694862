import { useEffect, useState, useContext, useRef } from "react";

import authAxios from "../../components/authAxios";

import { useNavigate, useLocation } from 'react-router-dom';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';


import { Autocomplete, TextField } from "@mui/material";
import { FormControl, FormControlLabel } from "@mui/material";
// import { GroupHeader, GroupItems } from "@mui/icons-material";
import { Radio, RadioGroup } from "@mui/material";
import { InputLabel } from "@mui/material";
import { Select } from "@mui/material";
import { MenuItem } from "@mui/material";
import {Button} from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';


import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
// import {makeStyles} from "@mui/material";
// import { makeStyles } from '@mui/styles';

import NumberFormat from "react-number-format";

import moment from 'moment';
import UserAlerts from "../../components/UserAlerts";
import Layout from "../../components/Layout";
import {UserContext} from "../../App"
// import JournalVoucher from "./JournalVoucher";
// import ProductDetails from "../products/ProductDetails";

import { TreeSelect } from "antd";

// import '../../styles/AutocompleteStyles.css'
// import '../../styles/TreeSelectStyles.css'

// ANT DESIGN
import { Modal } from 'antd';
// import TransactionNew from './TransactionNew';

const TransactionNewModal = ({isModalOpen, setIsModalOpen, module, order}) => {

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    // const loc = useLocation()
    // const module = loc.state.module
    // console.log("STATE",loc.state)


    const queryClient = useQueryClient();

    const errorRef = useRef(null);

    const navigate = useNavigate();
    const page_size = useContext(UserContext);
    const [error, setError] = useState('');

    const { data: clientProfile } = useQuery({
        queryKey: ['clientProfile'],
        queryFn: async () => {
          const response = await authAxios.get(`/clientprofiles/${localStorage.getItem('clientProfile')}`);
          return response.data

        },
    });

    const { data: transactionTypes } = useQuery({
        queryKey: ['transactionTypes'],
        queryFn: async () => {
            const response = await authAxios.get("/accounting/transaction-types");
            if (module==="OMS"){
                return response.data.filter(type=>(type.code==="010"||type.code==="020"))
            }
            else{
                // return response.data
                return response.data.filter(type=>type.code!=="050")
            }
        },
    });

    const { data: paymentModes } = useQuery({
        queryKey: ['paymentModes'],
        queryFn: async () => {
          const response = await authAxios.get("/accounting/payment-modes");
          return response.data
        },
    });

    const { data: locations } = useQuery({
        queryKey: ['locations'],
        queryFn: async () => {
          const response = await authAxios.get(`/inventory/locations?page_size=${page_size}`);
        //   return response.data
          return response.data.results

        },
    });

    const { data: suppliers } = useQuery({
        queryKey: ['suppliers'],
        queryFn: async () => {
          const response = await authAxios.get(`/purchase/suppliers?page_size=${page_size}`);
          return response.data.results

        },
    });

    const { data: accounttypes } = useQuery({
        queryKey: ['accounttypes'],
        queryFn: async () => {
          const response = await authAxios.get("/accounting/account-types");
          return response.data
        },
    });

    const { data: accts } = useQuery({
        queryKey: ['accounts-tree'],
        queryFn: async () => {
          const response = await authAxios.get("/accounting/accounts-tree");
          return response.data
        },
    });

    // const { data: customers } = useQuery({
    //     queryKey: ['customers'],
    //     queryFn: async () => {
    //       const response = await authAxios.get(`/crm/customers?page_size=${page_size}`);
    //       return response.data.results

    //     },
    // });

    const amtLabel = useRef("")
    amtLabel.current='Price'


    const [accounts, setAccounts] = useState([]);
    const [accounts2, setAccounts2] = useState([]);

    // const [isModalOpen, setIsModalOpen] = useState(false);
    const user = localStorage.getItem('userID');
    const [reference, setReference] = useState('');
    const [docRef, setDocRef] = useState('');
    const [dt, setDt] = useState(new Date());
    const [account1, setAccount1] = useState('');
    const [account2, setAccount2] = useState('');
    const [accountTree, setAccountTree] = useState('');


    const [type, setType] = useState('');
    const [amt, setAmt] = useState('');
    const [entryType, setEntryType] = useState('');
    const [paymentMode, setPaymentMode] = useState('');
    const [description, setDescription] = useState('');
    const [memo, setMemo] = useState('');
    // const [customer, setCustomer] = useState('');
    const [customer, setCustomer] = useState(order.customer);

    // const [location, setLocation] = useState('');
    const [location, setLocation] = useState(order.location);


    const [supplier, setSupplier] = useState('');
    const [isInvoice, setIsInvoice] = useState(false);
    const [isJournalVoucher, setIsJournalVoucher] = useState(false);
    const [isGrn, setIsGrn] = useState(false);

    const [referenceError, setReferenceError] = useState(false);
    const [amtError, setAmtError] = useState(false);
    const [typeError, setTypeError] = useState(false);
    const [customerError, setCustomerError] = useState(false)
    const [descriptionError, setDescriptionError] = useState(false)
    const [balancingError, setBalancingError] = useState(false);


    const [showPaymentMode, setShowPaymentMode] = useState(false);
    const [showDocRef, setShowDocRef] = useState(false);
    const [showCustomers, setShowCustomers] = useState(false);
    const [showSuppliers, setShowSuppliers] = useState(false);
    const [showLocation, setShowLocation] = useState(false);
    const [showAccount1, setShowAccount1] = useState(false);
    const [showAccount2, setShowAccount2] = useState(false);
    const [showAmount, setShowAmount] = useState(false);
    const [showBlock2, setShowBlock2] = useState(false);

    const [accountLabel, setAccountLabel] = useState("Account")
    const [accountStatus, setAccountStatus] = useState("")

    const [account2Label, setAccount2Label] = useState("")
    const [account2Status, setAccount2Status] = useState("")

    const [refLabel, setRefLabel] = useState("Reference")
    const [docRefLabel, setDocRefLabel] = useState("Reference")

    const [txnLines, setTxnLines] = useState([]);


    const [title, setTitle] = useState ("")
    const [subTitle, setSubTitle] = useState ("")
    const [tag, setTag] = useState ("")

    const txn = useRef(null);

    const [value, setValue] = useState();

    

    // const handleTransType = (e) => {
    const handleTransType = (newValue) => {
        setTypeError(false)
        setReference('');
        setShowCustomers(false)
        setShowLocation(false)
        setShowSuppliers(false)
        setShowAccount1(false)
        setShowAccount2(false)
        setShowAmount(false)
        setShowBlock2(false)
        setShowDocRef(false)
        setIsGrn(false)


        if (newValue.code==="010" || "020"){
            setShowCustomers(true)
        }

        // INVOICE TRANSACTION
        if (newValue.code==="010"){

            const invoiceType = transactionTypes.filter(ttype => ttype.code === "010")   

            setTxnLines(order.order_details)

            setIsInvoice(true)
            setShowAmount(false)
            setShowPaymentMode(false)
            setIsJournalVoucher(false)
            setReference(clientProfile.invoice_no)

            setTitle("Successful! Your invoice has been posted")
            setSubTitle(`Invoice number:${reference} can now be shared with the customer via print, email, WhatsApp or SMS`)
            setType(invoiceType.id)

            if (clientProfile.inventory_tracking){
                setShowLocation(true)
            }
            else{
                setShowLocation(false)
            }


            const invoiceNo = String(clientProfile.invoice_no).padStart(8, '0')
            setReference(invoiceNo)

            //Set transaction type for invoices to to Debit
            setEntryType("D")
            setTag("INV")

            //Hide and default to Control Account 
            if (clientProfile.debtors_control){
                setAccount1(clientProfile.debtors_control);
            }
            else{
                setShowAccount1(true)
                // Filter receivable accounts only
                const accts1 = accts.filter(acct => acct.type === "30200");
                // setAccounts(accts1)
                setAccounts(transformDataToTree(accts1))

            }

        }

        // RECEIPT TRANSACTION
        if (newValue.code==="020"){
            setIsInvoice(false)
            setIsJournalVoucher(false)
            setShowDocRef(true)
            setShowLocation(false)
            setShowAmount(true)
            setShowBlock2(true)
            setShowPaymentMode(true)

            //ASSIGN THE NEXT RECEIPT NUMBER
            const receiptNo = String(clientProfile.receipt_no).padStart(8, '0')
            setReference(receiptNo)
            setRefLabel("Receipt No")

            //SET TRANSACTION ENTRY TYPE FOR RECEIPTS TO BANK/CASH ACCOUNTS
            setEntryType("D")

            // FILTER BANK AND CASH ACCOUNTS ONLY 
            const acctype = accounttypes.find(accounttype => accounttype.code === "30100");

            // const accts1 = Array.from(accts.results).filter(acct => acct.type === acctype.id);
            const accts1 = accts.filter(acct => acct.type === acctype.id);

            // setAccounts(accts1)
            setAccounts(transformDataToTree(accts1))
            setShowAccount1(true)


            //Hide and default to Control Account 
            if (clientProfile.debtors_control){
                setAccount2(clientProfile.debtors_control);
            }
            else{
                setShowAccount2(true)
                const accts2 = accts.filter(acct => acct.type === "30200");
                // setAccounts2(accts2)
                setAccounts2(transformDataToTree(accts2))

            }

        }

        // POST SUPPLIER BILLS
        if (newValue.code==="100"){
            setShowCustomers(false)
            setIsInvoice(false)
            setShowPaymentMode(false)
            setShowLocation(false)
            setShowSuppliers(true)
            setShowAmount(true)
            setShowBlock2(true)

            //Hide and default to Control Account 
            if (clientProfile.ap_account){
                setAccount1(clientProfile.ap_account);
            }
            else{
                setShowAccount1(true)
                const accts1 = accts.filter(acct => acct.type === "40100");
                // setAccounts(accts1)
                setAccounts(transformDataToTree(accts1))
            }

            // CONTRA ACCOUNT - FILTER EXPENSE ACCOUNTS ONLY 
            // const acctype2 = accounttypes.find(accounttype => accounttype.code === "20100");
            const acctype2 = accounttypes.filter(accounttype => accounttype.code === "20100" || accounttype.code === "20200" || accounttype.code === "20300");
            
             // Extract the ID from each object in the acctype2 array
            const acctype2IDs = acctype2.map(acct2 => acct2.id);

            // Use includes function to check if the account type in the Accounts array 
            //  const accts2 = accts.filter(acct2 => acct2.type === acctype2.id);
            const accts2 = accts.filter(acct2 => acctype2IDs.includes(acct2.type));

            // setAccounts2(accts2)
            setAccounts2(transformDataToTree(accts2))
            setShowAccount2(true)

            setAccountLabel("A/P Account")
            setAccount2Label("Expense Account")
            setEntryType("C")
        }

        // POST PAYMENT
        if (newValue.code==="110"){
            setShowCustomers(false)
            setIsInvoice(false)
            setShowSuppliers(true)
            setShowAmount(true)
            setShowBlock2(true)
            setShowAccount2(true)
            setShowPaymentMode(true)

            // HIDE CONTRA ACCOUNT IF DEFAULT ACCOUNTS PAYABLE EXISIS 
            // ACCOUNT 1 SHOULD BE AP. DO NOT SWITCH!
            if (clientProfile.ap_account){
                setAccount1(clientProfile.ap_account);
                setShowAccount1(false)
            }
            else{
                setShowAccount1(true)
                const accts1 = accts.filter(acct => acct.type === "40100");
                // setAccounts(accts1)
                setAccounts(transformDataToTree(accts1))
            }

            // FILTER BANK/CASH AND CREDIT CARD ACCOUNTS ONLY 
            // const acctype = accounttypes.find(accounttype => accounttype.code === "30100");
            // const accts2 = accts.filter(acct => acct.type === acctype.id);

            const acctype = accounttypes.filter(accounttype => (accounttype.code === "30100"||accounttype.code === "40150"));
            const acctypeIDs = acctype.map(acct => acct.id);
            const accts2 = accts.filter(acct => acctypeIDs.includes(acct.type));
            setAccounts2(transformDataToTree(accts2))

            setAccount2Label("Bank/Cash Account")
            setEntryType("D")
        }

        // POST GRN
        if (newValue.code==="200"){
            setIsInvoice(false)
            setIsGrn(true)
            setIsJournalVoucher(false)
            setShowCustomers(false)
            setShowLocation(true)
            setShowSuppliers(true)
            setShowAmount(false)
            setShowPaymentMode(false)
            setEntryType("D")
            setTag("GRN")
        }


        // POST JOURNAL VOUCHERS
        if (newValue.code==="900"){
            setIsInvoice(false)
            setIsGrn(false)
            setShowCustomers(false)
            setIsJournalVoucher(true)
            setShowAmount(false)
            setShowPaymentMode(false)
            setReference(clientProfile.journal_no)
            const jvNo = clientProfile.journal_prefix + String(clientProfile.journal_no).padStart(7, '0')
            // const jvNo = String(clientProfile.journal_no).padStart(8, '0')
            setReference(jvNo)
        }

        // POST EXPENSES
        if (newValue.code==="910"){
            setIsInvoice(false)
            setIsGrn(false)
            setIsJournalVoucher(false)
            setShowPaymentMode(false)
            setShowCustomers(false)
            setShowAccount1(true)
            setShowAccount2(true)
            setShowAmount(true)
            setShowBlock2(true)
            // setShowProperties(true)
            setAccountLabel("Bank/Cash Account")
            setAccount2Label("Expense Account")
            setEntryType("C")

            // FILTER BANK/CASH AND CREDIT CARD ACCOUNTS ONLY 
            // const acctype = accounttypes.find(accounttype => accounttype.code === "30100");
            // const accts1 = accts.filter(acct => acct.type === acctype.id);
            // setAccounts(transformDataToTree(accts1))

            const acctype = accounttypes.filter(accounttype => (accounttype.code === "30100"||accounttype.code === "40150"));
            const acctypeIDs = acctype.map(acct => acct.id);
            const accts1 = accts.filter(acct => acctypeIDs.includes(acct.type));
            setAccounts(transformDataToTree(accts1))


            // CONTRA ACCOUNT - FILTER EXPENSE ACCOUNTS ONLY 
            // const acctype2 = accounttypes.find(accounttype => accounttype.code === "20100");
            const acctype2 = accounttypes.filter(accounttype => accounttype.code === "20100" || accounttype.code === "20200" || accounttype.code === "20300");

            // Extract the ID from each object in the acctype2 array
            const acctype2IDs = acctype2.map(acct2 => acct2.id);

            // Use includes function to check if the account type in the Accounts array 
            const accts2 = accts.filter(acct2 => acctype2IDs.includes(acct2.type));
            setAccounts2(transformDataToTree(accts2))
        }

        // const txntype = ttypes.find(function(ttype) {
        const txntype = transactionTypes.find(function(ttype) {
            return (ttype.code === newValue.code);
        });
        setType(txntype.id)
        txn.type = newValue.code
        // console.log("CODE", txn.type)
    }

    const handlePaymentMode = (e) =>{
        const mode = paymentModes.find(mode => mode.code === e.target.value)
        setPaymentMode(mode.id)
    }

    // Function to recursively transform data to the expected tree structure
    const transformDataToTree = (data) => {
        return data.map((item) => ({
            value: item.id.toString(), // Convert to string if needed
            title: item.name,
            // children: item.sub_accounts? 
            //     item.sub_accounts.map((child) => ({
            //         value: child.id.toString(), // Convert to string if needed
            //         title: child.name,
            //         children: [], // No need to recursively call here
            //     }))
            // : [], // No children for this item
            children: item.sub_accounts ? transformDataToTree(item.sub_accounts) : [],
        }));
    };

    const filterTreeNode = (input, treeNode) => {
        const nodeLabel = treeNode.title.toLowerCase(); // Access title directly
        return nodeLabel.includes(input.toLowerCase());
    };

    const handleSelectedAccount = (e) => {
        setAccountStatus("")
        setAccount1(e)
        console.log("E", e)
        const selectedAccount = accounts.find((account) => account.value === e);
        // console.log("SELECTED ACCOUNT", selectedAccount)

        if (selectedAccount && selectedAccount.children.length) {
            setAccountStatus("error")
            // console.log("PARENT")
            return;
          }
    }

    const handleSelectedAccount2 = (e) => {
        setAccount2Status("")
        setAccount2(e)
        const selectedAccount2 = accounts2.find((account) => account.value === e);
        // console.log("SELECTED ACCOUNT", selectedAccount)

        if (selectedAccount2 && selectedAccount2.children.length) {
            setAccount2Status("error")
            // console.log("PARENT")
            return;
          }
    }

    const addJv = (newJv) => {
        // console.log("WE ARE HERE",newExpense)
        setTxnLines([...txnLines, newJv]);
        // setExpenses([...expenses, newExpense]);
        setIsModalOpen(false); // Close the modal after adding the expense
    };

    const addTxnLine = (newTxnLine) => {
        // console.log("WE ARE HERE",newExpense)
        setTxnLines([...txnLines, newTxnLine]);
        // setExpenses([...expenses, newExpense]);
        setIsModalOpen(false); // Close the modal after adding the expense
    };


    const mutation = useMutation({
        mutationFn: (transaction) => {
            return authAxios.post("/accounting/transactions/", transaction);
        },
        onError: (error, variables, context) => {
            console.log("ERROR",error)

            if (error.response.data.error){
                // If it's an array, display each error message
                if (Array.isArray(error.response.data.error)) {
                    error.response.data.error.map((errorMessage) => (
                        setError(errorMessage)
                    ));
                } else {
                    // Handle single error message
                    setError(error.response.data.error); 
                }
            } else {
                // Fallback to Standard error message
                setError(error.message); 
            }      
        },
        onSuccess: (result) => {
            queryClient.invalidateQueries(['transactions']);
            if (isInvoice){
                navigate('/dashboard/confirmed/', {state:{title:title, subtitle:subTitle, reference:reference}});
            }
            else {
                navigate('/dashboard/transactions/');
            }
        },
      })


    const handleSubmit = (e) => {
        // console.log("LINES", txnLines)

        e.preventDefault();

        setAmtError(false)

        if (reference === "" ) {
            // setTypeError(true)
            setReferenceError(true)
        }

        if (!isJournalVoucher && !amt > 0 ) {
            setAmtError(true)
        }

        if (type === "" ) {
            setTypeError(true)
        }

        if (!isJournalVoucher && description === "" ) {
            setDescriptionError(true)
        }

        // console.log(reference, amt, type, memo)
        if (reference && !amtError && type && !descriptionError && !balancingError) {

            let entry_type = entryType
            let account = account1
            const contra = account2
            const payment_mode = paymentMode
            const doc_ref = docRef
            const client = clientProfile.id
            const txn_lines = txnLines;

            //Change the format of Price field before saving
            let amount = 0
            // const amount = parseFloat(amt.replace(/,/g, ''));

            if (isInvoice){
                // amount = amt
                amount = txnLines.reduce((acc, ln) => acc + parseFloat(ln.total), 0);
            }
            else {
                if (!isJournalVoucher && !isGrn){
                amount = parseFloat(amt.replace(/,/g, ''));
                }
            }
            // if (entry_type = "C") {
            //     amount = amount * -1
            // }

            //Change the format of transaction date before saving
            const date = moment(dt).format("YYYY-MM-DD")

            const transaction = { client, customer, location, supplier, order, reference, doc_ref, date, type, payment_mode, account, contra, amount, description, memo, entry_type, txn_lines, user };
            console.log(transaction)
            mutation.mutate(transaction)
        } 
    }

    useEffect(()=>{
        if (error){
            errorRef.current.scrollIntoView();  
        }            
    },[error])

    return(
        <Modal className="dashboard-modal-wide" title="New Transaction" visible={isModalOpen} open={isModalOpen} onOk={handleSubmit} onCancel={handleCancel}>
            <section className="dashboard-form">
                <div ref={errorRef}>
                    <UserAlerts error={error}  />
                </div>



                <form noValidate autoComplete="off" onSubmit={handleSubmit}>

                    <Autocomplete
                        onChange={(event, newValue) => {
                            console.log("VALUE", newValue.id)
                            handleTransType(newValue)
                            }}
                        options={transactionTypes}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => 
                            <TextField 
                                className="custom-input" 
                                fullWidth 
                                error={typeError} 
                                required
                                label="Type"  
                                {...params} 
                            /> 
                        }                          
                    />

                    {showPaymentMode && 
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        defaultChecked=""

                        // onChange={(e) => setPaymentMode(e.target.value)}
                        onChange={handlePaymentMode}

                        className="custom-input"
                    >
                        <FormControlLabel className="custom-radio" value="10" control={<Radio />} label="Cash" />
                        <FormControlLabel className="custom-radio" value="20" control={<Radio />} label="Mobile Money" />
                        <FormControlLabel className="custom-radio" value="30" control={<Radio />} label="Credit Card" />
                        <FormControlLabel className="custom-radio" value="40" control={<Radio />} label="Cheque" />

                    </RadioGroup>
                    }
                    <div className="two-columns">


                        <TextField className="custom-input"
                            onChange={(e) => {
                                setReferenceError(false)
                                setReference(e.target.value)}
                            }
                            // label="Reference" 
                            label={refLabel}
                            variant="outlined" 
                            fullWidth
                            required
                            value = {reference}
                            error={referenceError}
                        />

                        {showDocRef &&
                        <TextField className="custom-input"
                            onChange={(e) => {
                                setDocRef(e.target.value)}
                            }
                            // label="Reference" 
                            label={docRefLabel}
                            variant="outlined" 
                            fullWidth
                            // required
                            value = {docRef}
                            // error={referenceError}
                        />
                        }

                        
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DesktopDatePicker
                                label="Transaction Date"
                                value={dt}
                                onChange={(newDate) => {
                                    setDt(moment(newDate).format("YYYY-MM-DD"));

                                }}
                                renderInput={(params) => <TextField fullWidth required className="custom-input" {...params} />}
                            />
                        </LocalizationProvider>
                    </div>


                    {showCustomers && 
                        <TextField className="custom-input"
                            // onChange={(e) => {
                            //     setReferenceError(false)
                            //     setReference(e.target.value)}
                            // }
                            label="Customer" 
                            variant="outlined" 
                            fullWidth
                            disabled
                            // InputProps={{ readOnly: true }}
                            // readOnly
                            // required
                            value = {order.customer_name}
                            // error={referenceError}
                        />
                        // <FormControl 
                        //     variant="outlined" 
                        //     fullWidth className="custom-input" 
                        //     error={customerError}
                        //     required
                        // >
                        //     <InputLabel>Customer</InputLabel>
                        //     <Select
                        //         className="custom-select"
                        //         label="Customer"
                        //         onChange={(e) => {
                        //             setCustomerError(false)
                        //             setCustomer(e.target.value)}
                        //         }
                        //         defaultValue = ""
                        //         error={customerError}
                        //     >
                        //         {customers.map((customer) => { 
                        //             return(
                        //             <MenuItem key={customer.id} value={customer.id}>{customer.name}</MenuItem>
                        //             )
                        //         })}   
                                
                        //     </Select>
                        // </FormControl>
                    }

                    {showLocation &&

                        // <Autocomplete
                        //     onChange={(event, newValue) => {
                        //         setLocation(newValue.id);
                        //         console.log("VALUE", newValue.id)
                        //         }}
                        //     options={locations}
                        //     getOptionLabel={(option) => option.name}
                        //     renderInput={(params) => <TextField className="custom-input" fullWidth {...params} label="Location"  /> }
                        // />
                        <TextField className="custom-input"
                        label="Location" 
                        variant="outlined" 
                        fullWidth
                        // InputProps={{ readOnly: true }}
                        // readOnly
                        disabled
                        value = {order.location_name}
                    />
                    }

                    {showSuppliers &&
                    <Autocomplete
                        onChange={(event, newValue) => {
                            console.log("VALUE", newValue.id)

                            setSupplier(newValue.id);
                            }}
                        options={suppliers}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField className="custom-input" fullWidth {...params} label="Supplier"  /> }
                    />
                    }
                    

                    {showBlock2 &&
                    <>
                    {/* <div className="grouped-fields-light">
                        <div className="two-columns"> */}

                            {showAccount1 && 
                                <TreeSelect
                                    // className="custom-tree-select"
                                    
                                    // value={account1}
                                    status={accountStatus}
                                    style={{
                                        width: '100%',
                                    }}
                                    // value={value}
                                    dropdownStyle={{
                                        maxHeight: 400,
                                        overflow: 'auto',
                                    }}
                                    placeholder={accountLabel}
                                    allowClear
                                    // treeDefaultExpandAll
                                    showSearch
                                    onChange={handleSelectedAccount}
                                    // onChange={(e) => {
                                    //     console.log("SELECTED",e)
                                    //     setAccount1(e)
                                    // }}
                                    // fieldNames={label: {name}, value: id, children: sub_accounts}
                                    treeData={accounts}
                                    filterTreeNode={filterTreeNode} //Enables search filter
                                />
                            }

                            {showAccount2 && 
                                <TreeSelect
                                    // className="custom-tree-select"
                                    style={{
                                        width: '100%',
                                    }}
                                    // value={value}
                                    dropdownStyle={{
                                        maxHeight: 400,
                                        overflow: 'auto',
                                    }}
                                    placeholder={account2Label}
                                    status={account2Status}
                                    allowClear
                                    showSearch
                                    onChange={handleSelectedAccount2}
                                    // onChange={(e) => {setAccount2(e)}}
                                    // fieldNames={label: {name}, value: id, children: sub_accounts}
                                    treeData={accounts2}
                                    filterTreeNode={filterTreeNode} //Enables search filter
                                />
                            }
                        {/* </div> */}

                        {showAmount &&
                        <NumberFormat 
                            onChange={(e) => setAmt(e.target.value)} 
                            className="custom-input"
                            fullWidth
                            label="Amount" 
                            variant="outlined" 
                            customInput={TextField}
                            thousandSeparator={true} 
                            required
                            error={amtError}

                        />
                        }
                    {/* </div> */}
                    </> 

                    }

                    {isInvoice &&
                    //    <ProductDetails 
                    //        addTxnLine={addTxnLine}
                    //        txnLines={txnLines} 
                    //        setTxnLines={setTxnLines} 
                    //        tag={tag}
                    //        isModalOpen={isModalOpen}
                    //        setIsModalOpen={setIsModalOpen}
                    //        clientProfile={clientProfile}
                    //        location={location}
                    //    />
                        <div className="product-details-container">
                            <table className="styled-table">
                                <thead>
                                    <tr >
                                        <th>SKU</th>
                                        <th>Product</th>
                                        <th>Quantity</th>
                                        <th>{amtLabel.current}</th>
                                        <th>Total</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                {txnLines && txnLines.map((product, index) => (
                                    <tbody key={index}>
                                        <tr>
                                            <td>{product.sku}</td>
                                            <td>{product.name}</td>
                                            <td>{Number(product.quantity).toLocaleString()}</td>
                                            {tag==="GRN" && <td>{Number(product.cost).toLocaleString()}</td>}
                                            {(tag==="INV" || tag==='ORD') && <td>{Number(product.price).toLocaleString()}</td>}
                                            <td>{Number(product.total).toLocaleString()}</td>
                                            <td>
                                                <IconButton 
                                                    size="small">
                                                    <DeleteIcon fontSize="small" />
                                                </IconButton>
                                            </td>
                                        </tr>
                                    </tbody>
                                ))}
                            </table>
                        </div>
                    } 

                    {/* {isJournalVoucher &&
                        <JournalVoucher 
                            accts={accts}
                            clientProfile={clientProfile}
                            addTxnLine={addTxnLine}
                            txnLines={txnLines} 
                            setTxnLines={setTxnLines} 
                            tag={tag}
                            isModalOpen={isModalOpen}
                            setIsModalOpen={setIsModalOpen}
                        />
                        
                    } */}
                    {/* {isGrn &&
                        <ProductDetails 
                            addTxnLine={addTxnLine}
                            txnLines={txnLines} 
                            setTxnLines={setTxnLines} 
                            tag={tag}
                            isModalOpen={isModalOpen}
                            setIsModalOpen={setIsModalOpen}
                            clientProfile={clientProfile}
                        />
                    } */}


                    {!isJournalVoucher &&
                    <TextField className="custom-input"
                        onChange={(e) => {
                            setDescriptionError(false)
                            setDescription(e.target.value)}
                        }
                        label="Description" 
                        variant="outlined" 
                        fullWidth
                        required
                        value = {description}
                        error={descriptionError}
                    />
                    }

                    <TextField className="custom-input"
                        onChange={(e) => {
                            setMemo(e.target.value)
                        }}
                        label="Memo"
                        variant="outlined"
                        multiline
                        rows={2}
                        fullWidth
                        // error={memoError}
                    />

                    {/* <div className="button-border-top">
                        <button id="submit" className="btn btn-primary" type="submit">
                            Save
                        </button>
                    </div> */}

                </form>
            </section>


        </Modal>
    )

}

export default TransactionNewModal