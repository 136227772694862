import React from "react";
import { useState, useEffect } from "react";
// import axios from "axios";
import useAxios from "../../components/useAxios";
// import authAxios from "./authAxios";

import { createSearchParams, Link } from "react-router-dom";
// import { useNavigate } from "react-router-dom";


// import Pagination from "./Pagination";
import Pagination from '@mui/material/Pagination';


// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import DashboardHeader from "../../components/DashboardHeader";
import { useMediaQuery } from 'react-responsive'
import { Card, CardContent } from "@mui/material";

import Layout from "../../components/Layout";
import ThreeDotsMenu from "../../components/ThreeDotsMenu";
// import FormDrawer from "./FormDrawer";


function EbayOffers() {
    const isMobile = useMediaQuery({ query: '(max-width: 750px)' })
    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();
    // const { response } = useAxiosInterceptor();

    const abortController = new AbortController()

    const { data, fetchData}  = useAxios();   


    // FETCH CUSTOMERS 
    const [customers, setCustomers] = useState([]);

    const fetchCustomers = () => {
        fetchData({
            url: `/crm/customers/?page=${page}`,
            method: 'get',
            signal: abortController.signal
          })
    }

    // FETCH OFFERS 
    const [offers, setOffers] = useState([]);
    const fetchOffers = () => {
        fetchData({
            url: `/ebay/offers/?page=${page}`,
            method: 'get'
          })
    }

    const handlePageChange = (event, value) => {
        setPage(value);
    };

     const threeSubMenu = [
        {
            id: 1,
            menu: "View",
            url: "/dashboard/offer/",

        }, 
        // {
        //     id: 2,
        //     menu: "Edit",
        //     url: "/customeredit/",
        // }
    ];

    useEffect (() => {
        // fetchCustomers()
        fetchOffers()
        // console.log("CUSTOMERS",data)
        if (data.page){
            setPageCount(data.page_count)
            // setCustomers(Array.from(data.results))
            setOffers(Array.from(data.results))
        }

        return () => {
            abortController.abort();
        };
    },[ page, data.page ])
    

    return (
        // <Layout>

            <div className="dashboard-main">

                <section className="dashboard-content">
                    
                    <DashboardHeader dashboardTitle="Listings" dashboardPreTitle="EBAY" />

                    {isMobile? 
                    <>
                    
                    {offers && offers.map(offer => ( 

                    <Card className="dashboard-card" key={offer.id}>
                        
                        <CardContent className="dashboard-card-content">
                            <div className="dashboard-card-title">{offer.name}</div>
                            <div className="dashboard-card-subtitle">{offer.telephone}</div>                            
                            <p>{offer.address}</p>
                            <p>{offer.email}</p>
                            {/* <p>Gender: {customer.gender}</p> */}
                            {/* <Pillchip label={booking.status_name} /> */}
                        </CardContent>
                        {/* <CardActions>
                            <Button size="small">Booking</Button>
                        </CardActions> */}
                    </Card>
                    ))}

                    </>

                    :
                    // If Device is not mobile, display data using a table
                    <>
                    <table className="styled-table">
                        <thead>
                            <tr >
                                <th>Offer ID</th>
                                <th>SKU</th>
                                <th>Name</th>
                                {/* <th>Format</th> */}
                                <th>Available Quantity</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>

                        {offers && offers.map(offer => (

                            <tbody key={offer.id}>
                                <tr>
                                    <td>{offer.ebay_offer_id}</td>
                                    <td>{offer.sku}</td>
                                    <td>{offer.name}</td>
                                    <td>{offer.available_quantity}</td>
                                    <td>{offer.status}</td>
                                    {/* <td>{offer.address}</td> */}

                                    {/* <td>
                                        <Pillchip label={room.room_status} />
                                    </td>
                                    <td>{Number(customer.price).toLocaleString('en-UK', { style: 'currency', currency: 'KES' })}</td> */}

                                    {/* <td className='styled-table-menu'><FontAwesomeIcon icon={faEllipsisVertical} /></td> */}
                                    <td className='styled-table-menu'>
                                        <ThreeDotsMenu data={offer} threeSubMenu={threeSubMenu} />

                                    </td>
                                </tr>
                            </tbody>
                        ))}
                        

                    </table>
                    </>

                    }

                    {/* <Pagination
                        postsPerPage={postsPerPage}
                        totalPosts={customers.length}
                        paginate={paginate}
                    /> */}

                    <Pagination 
                        className="custom-mui-pagination"
                        count={pagecount} 
                        page={page} 
                        onChange={handlePageChange} 
                    />

                    {/* <Link to="/dashboard/customernew" className="nav-link">
                        <Fab className="fab" color="primary" aria-label="add">
                            <AddIcon />
                        </Fab>
                    </Link> */}
                </section>
            </div>

        // </Layout>


    )
}
export default EbayOffers