import { useState, useRef, useContext } from "react";
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

import { useNavigate } from 'react-router-dom';

import authAxios from "../../components/authAxios";
import { TextField, Autocomplete } from "@mui/material";
import UserAlerts from "../../components/UserAlerts";

import {TreeSelect, Modal } from 'antd';
import { UserContext } from "../../App";

const PaymentModeNew = ({isModalOpen, setIsModalOpen}) => {
    const page_size = useContext(UserContext);
    const errorRef = useRef(null);
    const [error, setError] = useState('');


    const queryClient = useQueryClient();
    const [page, setPage] = useState(1);

    const [mode, setMode] = useState('');
    const [account, setAccount] = useState('');
    const [name, setName] = useState('');
    const [memo, setMemo] = useState('');
    const user = localStorage.getItem('userID');
    const client = localStorage.getItem('clientID');

    const [nameError, setNameError] = useState(false)

    const navigate = useNavigate();

    const handleCancel = () => {
        setIsModalOpen(false);
        };
    
    // const [accounts, setAccounts] = useState([]);
    // const { data: accts } = useQuery({

    const { data: accounts } = useQuery({
        queryKey: ['accounts-tree'],
        queryFn: async () => {
            const response = await authAxios.get("/accounting/accounts-tree");

            //TRANSFORM TO TREE AND FILTER BANK/CASH ACCOUNTS ONLY 
            return transformDataToTree(response.data.filter(account => account.type_code === "30100"))
        },
    });

    const { data: paymentModes } = useQuery({
        queryKey: ['paymentModes'],
        queryFn: async () => {
            const response = await authAxios.get("/accounting/payment-modes");
            return response.data
        },
    });

    const transformDataToTree = (data) => {
        return data.map((item) => ({
            value: item.id.toString(), // Convert to string if needed
            title: item.name,
            children: item.sub_accounts ? transformDataToTree(item.sub_accounts) : [],
        }));
    };

    const filterTreeNode = (input, treeNode) => {
        const nodeLabel = treeNode.title.toLowerCase(); // Access title directly
        return nodeLabel.includes(input.toLowerCase());
    };

  
    const mutation = useMutation({
        mutationFn: (paymentMode) => {
            return authAxios.post("/pos/client-payment-modes/", paymentMode);

        },
      
        onError: (error, variables, context) => {
            console.log("ERROR",error)

            if (error.response.data.error){
                // If it's an array, display each error message
                if (Array.isArray(error.response.data.error)) {
                    error.response.data.error.map((errorMessage) => (
                        setError(errorMessage)
                    ));
                } else {
                    // Handle single error message
                    setError(error.response.data.error); 
                }
            } else {
                // Fallback to Standard error message
                setError(error.message); 
            }      
        },
        onSuccess: (result) => {
            queryClient.invalidateQueries(['clientPaymentModes', page]);
            setIsModalOpen(false);
        },
      })

    const handleSubmit = (e) => {
     
        e.preventDefault();

        setNameError(false)

        if (name === '') {
            setNameError(true)
        }

        if (name ) {
            const paymentMode = { client, name, mode, account, memo, user };
            mutation.mutate(paymentMode)
        } 
    }

    return (
        <>
        <Modal className="dashboard-modal" title="Add Payment Mode" visible={isModalOpen} open={isModalOpen} onOk={handleSubmit} onCancel={handleCancel}>

            <section className="dashboard-form">
                <div ref={errorRef}>
                    <UserAlerts error={error}  />
                </div>

                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <TextField className="custom-input"
                        onChange={(e) => {
                            setNameError(false)
                            setName(e.target.value)}
                        }
                        label="Name" 
                        variant="outlined" 
                        // color="secondary" 
                        fullWidth
                        required
                        error={nameError}
                    />

                    <Autocomplete
                        onChange={(event, newValue) => setMode(newValue.id)}
                        options={paymentModes}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => 
                            <TextField 
                                className='custom-input'
                                label="Mode" 
                                {...params} 
                            />
                        }
                    />

                    <TreeSelect
                        className="custom-tree-select"
                        style={{
                            width: '100%',
                        }}
                        dropdownStyle={{
                            maxHeight: 400,
                            overflow: 'auto',
                        }}
                        placeholder="Account"
                        allowClear
                        showSearch
                        onChange={(e, label) => {
                            setAccount(e)
                        }}
                        treeData={accounts}
                        filterTreeNode={filterTreeNode} //Enables search filter
                    />

                   

                    <TextField className="custom-input"
                        onChange={(e) => setMemo(e.target.value)}
                        label="Memo"
                        variant="outlined"
                        multiline
                        rows={3}
                        fullWidth
                    />

                </form>
            
            
            </section>
        </Modal>
    </>


    ) 
}

export default PaymentModeNew;
