import React from "react";
import { useState, useEffect } from "react";
// import axios from "axios";
import useAxios from "../../components/useAxios";
// import authAxios from "./authAxios";

import { Link } from "react-router-dom";
// import { useNavigate } from "react-router-dom";


// import Pagination from "./Pagination";
import Pagination from '@mui/material/Pagination';


// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import DashboardHeader from "../../components/DashboardHeader";
import { useMediaQuery } from 'react-responsive'
import { Card, CardContent } from "@mui/material";


import ThreeDotsMenu from "../../components/ThreeDotsMenu";
import Pillchip from "../../components/Pillchip";


function ExpenseReports() {
    const isMobile = useMediaQuery({ query: '(max-width: 750px)' })
    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();
    // const { response } = useAxiosInterceptor();

    const abortController = new AbortController()

    const { data, fetchData}  = useAxios();   
    const { data:expenseReportData, fetchData:fetchExpenseReportData}  = useAxios();   


    // FETCH REPORTS 
    const [expenseReports, setExpenseReports] = useState([]);

    const fetchExpenseReports = () => {
        // fetchData({
        fetchExpenseReportData({
            url: `/hrm/expense-reports/?page=${page}`,
            method: 'get',
          })
    }

    const handlePageChange = (event, value) => {
        setPage(value);
    };

     const threeSubMenu = [
        {
            id: 1,
            menu: "View",
            // url: "/customer/",
            url: "/dashboard/expense-report/",

        }, 
        // {
        //     id: 2,
        //     menu: "Edit",
        //     url: "/dashboard/customeredit/",
        // }
    ];

    useEffect (()=>{
        fetchExpenseReports()
    },[]) //IMPORTANT: RUN API CALL ONLY ONCE!

    useEffect (() => {
        if (expenseReportData.count){
            // console.log("CUSTOMERS",customerData)
            setPageCount(expenseReportData.page_count)
            setExpenseReports(Array.from(expenseReportData.results))
        }
    },[ expenseReportData.count ])
    

    return (
            <div className="dashboard-main">
                <section className="dashboard-content">
                    <DashboardHeader dashboardTitle="Expense Reports" dashboardPreTitle="HRM" />

                    {isMobile? 
                    <>
                    
                    {expenseReports && expenseReports.map(expenseReport => ( 

                    <Card className="dashboard-card" key={expenseReport.id}>
                        
                        <CardContent className="dashboard-card-content">
                            <div className="dashboard-card-title">{expenseReport.name}</div>
                            <div className="dashboard-card-subtitle">{expenseReport.telephone}</div>                            
                            <p>{expenseReport.address}</p>
                            <p>{expenseReport.email}</p>
                        </CardContent>
                        {/* <CardActions>
                            <Button size="small">Booking</Button>
                        </CardActions> */}
                    </Card>
                    ))}

                    </>

                    :
                    // If Device is not mobile, display data using a table
                    <>
                    <table className="styled-table">
                        <thead>
                            <tr >
                                <th>ID</th>
                                <th>Reference</th>
                                <th>Date</th>
                                <th>Description</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>

                        {expenseReports && expenseReports.map(expenseReport => (

                            <tbody key={expenseReport.id}>
                                <tr>
                                    <td>{expenseReport.id}</td>
                                    <td>{expenseReport.reference}</td>
                                    <td>
                                        {new Date(expenseReport.created_at).toLocaleDateString('en-UK', {
                                            year: 'numeric',
                                            month: '2-digit',
                                            day: '2-digit',
                                        })}{' '}
                                        {new Date(expenseReport.created_at).toLocaleTimeString('en-UK', {
                                            hour: '2-digit',
                                            minute: '2-digit',
                                        })}
                                    </td>
                                    
                                    {/* <td>{(expenseReport.created_at).toLocaleDateString('en-UK', {
                                            year: 'numeric',
                                            month: '2-digit',
                                            day: '2-digit',
                                        })}</td> */}
                                    {/* // <td>{expenseReport.created_at}</td> */}
                                    <td>{expenseReport.description}</td>
                                    <td>{expenseReport.amount}</td>
                                    {/* <td>{expenseReport.status_name}</td> */}
                                    <td><Pillchip label={expenseReport.status_name} /></td>
                                    <td className='styled-table-menu'>
                                        <ThreeDotsMenu data={expenseReport} threeSubMenu={threeSubMenu} />
                                    </td>
                                </tr>
                            </tbody>
                        ))}
                        

                    </table>
                    </>

                    }

                    <Pagination 
                        className="custom-mui-pagination"
                        count={pagecount} 
                        page={page} 
                        onChange={handlePageChange} 
                    />

                    <Link to="/dashboard/expense-report-new" className="nav-link">
                        <Fab className="fab" color="primary" aria-label="add">
                            <AddIcon />
                        </Fab>
                    </Link>
                </section>
            </div>

        // </Layout>


    )
}
export default ExpenseReports