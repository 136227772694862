import React from "react";
import { useNavigate } from "react-router-dom";


function Cta() {
    const navigate = useNavigate();

    return (
        <section className="cta">

            <div className="cta-text">
                {/* Whether you own or manage a single or multi-unit property, we provide customised technology solutions for accommodation management. Contact us today to book a demo. */}
                From managing finances to streamlining sales and tracking inventory, our technology is designed to make running your business easier and more efficient. Sign up for a Demo today and experience the power of our smart ERP software.
                {/* Don't miss out on this opportunity to optimizing your business operations and grow! */}
            </div>
            <div className="cta-button">
              <button className="btn btn-outline-primary" onClick={() => navigate("/contact")}>Request Demo</button>
              {/* <button className="btn btn-outline-primary" onClick={() => navigate("/")}>Request Demo</button> */}

            </div>
        </section>

    ) 
}
    
export default Cta