// import React from "react"

import { useEffect, useState, useRef, useContext } from "react";
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

import { useNavigate } from 'react-router-dom';

// import useAxios from "../../components/useAxios";
import authAxios from "../../components/authAxios";

import { InputLabel } from "@mui/material";
import { FormControl } from "@mui/material";
import { TextField, Autocomplete } from "@mui/material";
import { Select } from "@mui/material";
import { MenuItem } from "@mui/material";

import UserAlerts from "../../components/UserAlerts";

import { Modal } from 'antd';
import CustomSwitch from "../../components/CustomSwitch";
import { UserContext } from "../../App";


const ProductCategoryNew = ({isModalOpen, setIsModalOpen}) => {
    const page_size = useContext(UserContext);
    const errorRef = useRef(null);
    const [error, setError] = useState('');


    const queryClient = useQueryClient();
    // const [page, setPage] = useState(1);

    const [checked, setChecked] = useState(false);  
    const [name, setName] = useState('');
    const [parent, setParent] = useState('');
    const [memo, setMemo] = useState('');
    const user = localStorage.getItem('userID');
    const client = localStorage.getItem('clientID');
    const [typeError, setTypeError] = useState(false)
    const [nameError, setNameError] = useState(false)

    // const navigate = useNavigate();

    const { data: categories } = useQuery({
        queryKey: ['categories'],
        queryFn: async () => {
          const response = await authAxios.get(`/products/categories?page_size=${page_size}`);
          return response.data.results;
        },
    });

    const handleCancel = () => {
        setIsModalOpen(false);
        };
  
    const mutation = useMutation({
        mutationFn: (category) => {
            return authAxios.post("/products/categories/", category);
        },
        onError: (error, variables, context) => {
            setError(error.message)
            errorRef.current.scrollIntoView();              
        },
        onSuccess: (result) => {
            queryClient.invalidateQueries(['categories']);
            setIsModalOpen(false);
        },
      })

    const handleSubmit = (e) => {
     
        e.preventDefault();
        setNameError(false)

        if (name === '') {
            setNameError(true)
        }

        if (name ) {
            const category = { client, name, parent, memo, user };
            mutation.mutate(category)
        } 
    }

    return (
        <>
        <Modal className="dashboard-modal" title="Add Product Category" visible={isModalOpen} open={isModalOpen} onOk={handleSubmit} onCancel={handleCancel}>

            <section className="dashboard-form">
                <div ref={errorRef}>
                    <UserAlerts error={error}  />
                </div>

                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <TextField className="custom-input"
                        onChange={(e) => {
                            setNameError(false)
                            setName(e.target.value)}
                        }
                        label="Name" 
                        variant="outlined" 
                        // color="secondary" 
                        fullWidth
                        required
                        error={nameError}
                    />
                    <div className="custom-switch-container">
                        <CustomSwitch checked={checked} setChecked={setChecked}/>
                        <div className="custom-switch-label">
                            Is this a sub category?
                        </div>
                    </div>

                    {checked &&

                    <Autocomplete
                        onChange={(event, newValue)=>setParent(newValue.id)}
                        options={categories}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField className="custom-input" {...params} label="Parent Category" /> }
                    />
                    }

                    <TextField className="custom-input"
                        onChange={(e) => setMemo(e.target.value)}
                        label="Memo"
                        variant="outlined"
                        multiline
                        rows={3}
                        fullWidth
                        // error={messageError}
                    />

                </form>
            
            
            </section>
        </Modal>
    </>


    ) 
}

export default ProductCategoryNew;
