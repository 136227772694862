import { useNavigate } from 'react-router-dom';

import { Result } from 'antd';
import {IconButton, Button} from "@mui/material";
// import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
// import AttachEmailIcon from '@mui/icons-material/AttachEmail';
// import MailOutlineIcon from '@mui/icons-material/MailOutline';
// import WhatsAppIcon from '@mui/icons-material/WhatsApp';


const EbayDeclined = () => {
    const navigate = useNavigate();


    //Pass state variables from router
    // const location = useLocation();
    // const navigate = useNavigate();
    // const {fetchData}  = useAxios();   
    // const title = location.state.title;
    // const subTitle = location.state.subtitle;
    // const reference = location.state.reference;
    // const id = location.state.id;
    // console.log("LOCATION",location)

    const printInvoice = () => {
        // navigate('/dashboard/invoicereport/',{state:{reference:reference}});
    }


    return (
        <div className="dashboard-main">
            <Result
                status="error"
                title="eBay Authorisation Failed!"
                subTitle="Something went wrong with your authorisation. Please contact support"
                extra={[
                        // <Button type="primary" key="console">
                        //     Go Console
                        // </Button>,
                        // <Button key="Print">Print Invoice</Button>,
                        <Button
                            key="one"
                            variant="outlined" 
                            size="small"
                            // onClick={printInvoice}
                            startIcon={<DownloadForOfflineIcon />}
                        >
                            Dashboard
                        </Button>,
                        
                ]}
            />

        </div>
    )
}

export default EbayDeclined;