import { useRef, useEffect } from "react";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Cta from "../../components/Cta";

// import hero from '../../assets/crm-hero.svg'
import hero from '../../assets/crm-hero.png'

// import './Crm.css'
import './Products.css'

const Inventory = () => {
    const scrollRef = useRef(null);

    useEffect(()=>{
        scrollRef.current.scrollIntoView();  
    },[])

    console.log(scrollRef)

    return (
        <div className="container-fluid">

            {/* <div className="crm-container"> */}

            <div className="product-container">
                <div ref={scrollRef}>
                    <Header />
                </div>

                <div className="hero hero-bg-cyan">
                    <div className="hero-text-container">

                        <div className="hero-sub-title">
                            Inventory Management
                        </div>
                        <div className="hero-title">
                            Inventory simplified.
                        </div>
                        <div className="hero-text">
                            {/* A single view of your prospects and customers that gives sales, marketing and service teams the real-time data they need to deliver exceptional customer experiences and growth. */}
                            Having full, real-time visibility to accurate inventory levels is critical to running a smooth operation. Whether you have a single location or many, our inventory management system gives you this visibility.
                        </div>
                    </div>
                    <div className="hero-img-container">
                        <img src={hero} alt="hero"/> 
                    </div>
                </div>


                <div className="features">
                    <div className="features-card">
                        <div className="feature-bullet">

                        </div>
                        <div className="features-card-title">
                            Realtime Inventory Tracking
                        </div>
                        <div className="features-card-text">
                            {/* Our CRM is designed to streamline and automate routine sales tasks allowing your teams to focus on strategic and high-value activities. */}
                            {/* Realtime inventory updates as transactions occur minimizes the risk of stockouts or overstock situations, enabling businesses to optimize their supply chain, enhance order fulfillment processes, and improve overall operational efficiency */}
                            {/* Monitor stock levels and item availability in real-time to prevent stockouts and overstock situations. Enable immediate updates across multiple locations and sales channels. */}
                            Automatically update inventory levels with each sale across multiple locations and channels, enabling you to optimize supply chain, enhance order fulfillment, and improve overall operational efficiency.
                        </div>

                    </div>

                    <div className="features-card">
                        <div className="feature-bullet">

                        </div>
                        <div className="features-card-title">
                            Multi Location
                        </div>
                        <div className="features-card-text">
                            {/* a book or other written or printed work, regarded in terms of its content rather than its physical form. */}
                            {/* Store and organize all customer and lead information (names, emails, phone numbers, interactions, etc.) in one place. */}
                            {/* Seamlessly organize detailed information about your prospects and customers including names, contact details, and interactions. */}
                            Manage inventory across multiple physical locations through a smart, unified, location-agnostic platfrom. The platform allows you to optimize their supply chain, streamline inventory transfers, and fulfill orders. 
                        </div>

                    </div>

                    <div className="features-card">
                        <div className="feature-bullet">

                        </div>
                        <div className="features-card-title">
                            Traceability
                        </div>
                        <div className="features-card-text">
                            {/* A dynamic and centralized platform to systematically track and win opportunities throughout the entire sales lifecycle. */}
                            Assign unique identifiers such as serial and lot numbers to each unit of inventory. Capture comprehensive data about each inventory unit, including manufacturing details and expiration dates.
                        </div>

                    </div>

                    <div className="features-card">
                        <div className="feature-bullet">

                        </div>
                        <div className="features-card-title">
                            Inventory Valuation
                        </div>
                        <div className="features-card-text">
                            {/* Give teams that interacts with customers — sales, call centre and service — access to updated, real-time data. */}
                            Automatic calculation of inventory value that supports different costing methods including FIFO, LIFO, Weighted Average, and Specific Identification. 
                        </div>

                    </div>

                    <div className="features-card">
                        <div className="feature-bullet">

                        </div>
                        <div className="features-card-title">
                            Integration with Accounting
                        </div>
                        <div className="features-card-text">
                            {/* Get a 360-degree view of your customers through a seamless connectivity of the CRM with other business applications and tools. */}
                            Seamless integration with Accounting module provides accurate, realtime and automated inventory financial reporting in the general ledger.
                        </div>

                    </div>

                    <div className="features-card">
                        <div className="feature-bullet">

                        </div>
                        <div className="features-card-title">
                            Analytics
                        </div>
                        <div className="features-card-text">
                            {/* a book or other written or printed work, regarded in terms of its content rather than its physical form. */}
                            Analytics tools empower you to measure key metrics, identify trends, forecast re-orders, and make informed strategic decisions.
                        </div>

                    </div>                    

                    <div className="features-card">
                        <div className="feature-bullet">

                        </div>
                        <div className="features-card-title">
                            Mobile 
                        </div>
                        <div className="features-card-text">
                            {/* a book or other written or printed work, regarded in terms of its content rather than its physical form. */}
                            Our responsive design enables your teams to access and update customer information from anywhere using mobile devices.
                        </div>

                    </div>
                    <div className="features-card">
                        <div className="feature-bullet">

                        </div>
                        <div className="features-card-title">
                            Customization
                        </div>
                        <div className="features-card-text">
                            {/* a book or other written or printed work, regarded in terms of its content rather than its physical form. */}
                            Every business is unique, and your CRM should reflect that. During implementation we mold your Inventory Management System to fit your business.
                        </div>

                    </div>

                </div>

                <Cta />

                <Footer />        
            </div>
        </div>



    )

}


export default Inventory;