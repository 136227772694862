import { useState, useEffect } from 'react';
import axios from 'axios';

import { useParams, useNavigate } from "react-router-dom";
import { TextField } from '@mui/material';

import CustomSnackBar from '../components/CustomSnackBar';
import UserAlerts from '../components/UserAlerts';
import logo from '../assets/zidi-logo.svg'


function ResetPassword() {
  let navigate = useNavigate();
  let params = useParams();
  const [error, setError] = useState('');
  // const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState('');
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  // function loginUser() {
  const [new_password1, setNewPassword1] = useState();
  const [new_password2, setNewPassword2] = useState();
  // const [uid, setUid] = useState(params.uid);
  // const [token, setToken] = useState(params.token)
  const uid = params.uid;
  const token = params.token
  // useEffect(() => {
  //   console.log("TOKEN", token);
  // },[token]);

  const [password1Error, setPassword1Error] = useState(false)
  const [password2Error, setPassword2Error] = useState(false)


  const handleSubmit = (e) => {
    e.preventDefault();

    setPassword1Error(false)
    setPassword2Error(false)
    // setLoading(true)


    if (!new_password1) {
      setPassword1Error(true)
    }
    if (!new_password2) {
      setPassword2Error(true)
    }

    if (new_password1 && new_password2) {
      const credentials = { new_password1, new_password2, uid, token };
      // console.log("credentials", credentials)

        axios.post("/dj-rest-auth/password/reset/confirm/", credentials )
        // axios.post(`/password/reset/${uid}/${token}/`, credentials )
        .then(res => {
            // setToken(res.data.access);
            setResponse(res)
            setSuccess(true)
            setSuccessMessage("Your password change was successful!")
            // navigate("/login/");

        })
        .catch(function (error) {
          if (error.response.status === 400 ){
            setError(error.message + " - " + error.response.request.response);
            }
            else if (error.response.status === 401){
            setError(error.response.status +" " + error.response.statusText + " - " + error.response.data.detail);
            }
            else {
            setError(error.response.status + " " + error.response.statusText);
            }
            console.log("error", error);        
          });

            // console.log("error", error);
        // });

      }
    }

  const handleSnackBarClose = () => {
    // setLoading(false)
    setSuccess(false)
  };

  useEffect(() => {
    console.log("RESPONSE",response)
    console.log("SUCCESS",success)

    if (response.status === 200 && success === false ){
        navigate("/login/");
    }    
  }, [ response.status, success]); 
  // }, [ response.status]); 


  return(
    <div className="container-fluid">
        <div className="auth-form">
          <UserAlerts error={error} />

          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        
            <div className='auth-form-brand'>
              <img src={logo} alt="brand logo"/>  

            </div>

            <TextField
                margin="normal"
                required
                fullWidth
                id="password"
                label="New Password"
                type="password"
                name="password"
                // autoComplete="email"
                autoFocus
                onChange={e => setNewPassword1(e.target.value)}
                error={password1Error}
              />

            <TextField
              margin="normal"
              required
              fullWidth
              name="password1"
              label="Confirm Password"
              type="password"
              id="password2"
              onChange={e => setNewPassword2(e.target.value)}
              error={password2Error}
            />
            <div className='auth-form-button'>
              <button className='btn btn-primary' type="submit">Reset Password</button>
            </div>
            {/* {loading && <CircularProgress />} */}
          </form>
          <CustomSnackBar success={success} successMessage={successMessage} handleSnackBarClose={handleSnackBarClose}/>

        </div>
      </div>

  )
}

export default ResetPassword