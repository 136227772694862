import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

import { PictureAsPdf } from "@mui/icons-material";
import { Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import axios from "axios"
import useAxios from "../components/useAxios";
import useAxiosInterceptor from "../components/useAxiosInterceptor";

import DashboardHeader from "../components/DashboardHeader";
import Layout from "../components/Layout";
import { useEffect, useState } from "react";

import { TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import moment from 'moment';


const MonthlyReport = () => {

  // const { data, response, fetchData}  = useAxios();   
  const { data, fetchData}  = useAxios();   

  // const { error, response } = useAxiosInterceptor();

  const [startdt, setStartDt] = useState(null);
  const [enddt, setEndDt] = useState(null);


  // const fetchReport = () => {
  //   // const filename = 'monthly-report.pdf'
  //   try {

  //     fetchData({
  //       url: "/monthlyreport",
  //       method: 'get',
  //       // data: dt,
  //       responseType: 'blob'
  //     }
      
  //     )
      

  //   }catch (error) {
  //     console.error(error);
  //   }

  // }

  // const generateReport = () => {
  //   const filename = 'monthly-report.pdf'

  //   const url = window.URL.createObjectURL(new Blob([data]));
  //   const link = document.createElement('a');
  //   link.href = url;
  //   link.setAttribute('download', filename);
  //   document.body.appendChild(link);
  //   link.click();

  // }


  useEffect(()=>{
    // fetchReport()
    console.log("WE ARE HERE",data)

  },[data])

  const handleSubmit = (e) => {
    e.preventDefault();

    // console.log("DT",dt)

    const start_date = moment(startdt).format("YYYY-MM-DD")
    const end_date = moment(enddt).format("YYYY-MM-DD")

    const period = { start_date, end_date };


    fetchData({
      url: '/monthlyreport/',
      // url: '/monthlyreport',
      method: 'post',
      data: period,
      responseType: 'blob'
    })

    // fetchReport()

    const filename = 'monthly-report.pdf'

    // const url = window.URL.createObjectURL(new Blob([data]));
    const url = window.URL.createObjectURL(new Blob([data]));


    // console.log("URL",url)
    // console.log("RESPONSE",response)
    console.log("DATA",data)



    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
  }

  return (
        <Layout>

             <div className="dashboard-main">

                 {/* <section className="dashboard-content"> */}
                 <section className="dashboard-form">

                 <DashboardHeader dashboardTitle="Monthly Report" dashboardPreTitle="Accounting" />
                    {/* <List>
                      <ListItem disablePadding>

                      <ListItemButton onClick={generateReport}>
                        <ListItemIcon className="custom-list-icon">

                       

                          <FontAwesomeIcon icon={faFilePdf} /> 

                        </ListItemIcon>
                        <ListItemText primary="Monthly Report" />

                      </ListItemButton>
                      </ListItem>

                    </List> */}
                    <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DesktopDatePicker
                                label="Start Date"
                                value={startdt}
                                onChange={(newDate) => {
                                    setStartDt(moment(newDate).format("YYYY-MM-DD"));
                                  }}
                                renderInput={(params) => <TextField fullWidth required className="custom-input" {...params} />}
                            />
                            <DesktopDatePicker
                                label="End Date"
                                value={enddt}
                                onChange={(newDate) => {
                                    setEndDt(moment(newDate).format("YYYY-MM-DD"));
                                  }}
                                renderInput={(params) => <TextField fullWidth required className="custom-input" {...params} />}
                            />
                      </LocalizationProvider>

                    <div className="button-border-top">
                            <button id="submit" className="btn btn-primary form-buttons" type="submit">
                                Report
                            </button>
                    </div>
                    </form>
                  
                    
                 </section>
             </div>

        </Layout>
    );  
}

export default MonthlyReport