import {memo, useContext} from "react";
import { Outlet } from "react-router-dom";
import { useQuery } from '@tanstack/react-query';

import authAxios from "./authAxios";

import Sidebar from "./Sidebar";
import { useMediaQuery } from 'react-responsive'
// import { useCountRenders } from "../hooks/useCountRenders";

import ErrorBoundary from "../errorBoundary";
import { useLocation } from "react-router-dom";
import { UserContext } from "../App";


const Layout = () => {
    const location =  useLocation();
    // useCountRenders()
    const isMobile = useMediaQuery({ query: '(max-width: 750px)' })
    const { setClientProfile } = useContext(UserContext);

    const { data: clientProfile } = useQuery({
        queryKey: ['clientProfile'],
        queryFn: async () => {
            const response = await authAxios.get(`/clientprofiles/${localStorage.getItem('clientProfile')}`);
            setClientProfile(response.data)
            return response.data
        },
    });

    return(
        <div className="dashboard-container">
            {/* {isMobile? null: <Sidebar /> } */}
            {isMobile? null: 
            <Sidebar /> }

            {/* { children } */}
            <ErrorBoundary key={location.pathname}>
                <Outlet />
            </ErrorBoundary>
        </div>

    )
}

export default memo(Layout);