
import { useState, useEffect } from "react"
import axios from 'axios';


const GetOrders = () => {
// const MyComponent = () => {
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        console.log("WE ARE HERE")
        // axios.get('https://api.ebay.com/ws/api.dll', {

        // axios.get('https://api.sandbox.ebay.com/ws/api.dll', {
        //     params: {
        //     'OPERATION-NAME': 'GetOrders',
        //     // 'SERVICE-VERSION': '1.13.0',
        //     'GLOBAL-ID': 'EBAY-US',
        //     // 'SECURITY-APPNAME': 'your-app-id',
        //     'SECURITY-APPNAME': 'DicksonO-Zidi-SBX-1cd6d2723-704a8aff',
        //     'RESPONSE-DATA-FORMAT': 'JSON',
        //     'AUTHORIZATION': 'v^1.1#i^1#f^0#r^1#p^3#I^3#t^Ul4xMF83OkFBRjI4QkNFNjYxNUUzQkQ5RDJFNDM2RjlGRTUxRENCXzNfMSNFXjEyODQ=',
        //     // 'CREATE_TIME_FROM': '2021-01-01T00:00:00.000Z',
        //     // 'CREATE_TIME_TO': '2022-03-01T00:00:00.000Z',
        //     'detailLevel': 'ReturnAll',
        //     'outputSelector': 'OrderArray.Order.TransactionArray.Transaction',
        //     }
        // })
        axios.get('https://api.sandbox.ebay.com/sell/fulfillment/v1/order', {
            withCredentials: true,
            headers:{
                // 'AUTHORIZATION': 'Bearer ' + 'v^1.1#i^1#f^0#r^1#p^3#I^3#t^Ul4xMF83OkFBRjI4QkNFNjYxNUUzQkQ5RDJFNDM2RjlGRTUxRENCXzNfMSNFXjEyODQ=',
                'AUTHORIZATION': 'v^1.1#i^1#f^0#r^1#p^3#I^3#t^Ul4xMF83OkFBRjI4QkNFNjYxNUUzQkQ5RDJFNDM2RjlGRTUxRENCXzNfMSNFXjEyODQ=',
            },
            params: {
                // 'GLOBAL-ID': 'EBAY-US',
                'SECURITY-APPNAME': 'DicksonO-Zidi-SBX-1cd6d2723-704a8aff',
            }
        })
        .then(response => {
            setOrders(response.data.OrderArray.Order);
            console.log("RESPONSE", response.data)
        })
        .catch(error => {
            console.log("ERROR",error);
        });
    }, []);

// render orders data
};

export default GetOrders;