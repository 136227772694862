// import React from "react";
import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";

import authAxios from "../../components/authAxios"

import { createSearchParams, Link } from "react-router-dom";
// import { useNavigate } from "react-router-dom";


import Pagination from '@mui/material/Pagination';

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import DashboardHeader from "../../components/DashboardHeader";
import { useMediaQuery } from 'react-responsive'
import { Card, CardContent } from "@mui/material";

// import Layout from "../../components/Layout";
import ThreeDotsMenu from "../../components/ThreeDotsMenu";

import { format } from 'date-fns';


// import ProductNew from "./ProductNew";

function Products() {
    const isMobile = useMediaQuery({ query: '(max-width: 750px)' })
    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();
    // const { data, fetchData}  = useAxios();   

    const convertDateTime = (myDateTime) => {
        return format(new Date(myDateTime), 'dd-MM-yyyy HH:mm');
    };

    const { data: products, status, isPreviousData } = useQuery({
        queryKey: ['products', page],
        queryFn: async () => {
          const response = await authAxios.get(`/products/skus/?page=${page}`);
          setPageCount(response.data.page_count)
          return response.data.results
        },
        keepPreviousData : true,
    });

    const [isDrawerOpen, setIsDrawerOpen] = useState(false)

    const handleDrawerOpen = () => {
        setIsDrawerOpen(true)
    };
  
    const handleDrawerClose = () => {
        setIsDrawerOpen(false)
    };

    //Pagination
     const handlePageChange = (event, value) => {
        setPage(value);
    };

     const threeSubMenu = [
        {
            id: 1,
            menu: "View",
            url: "/dashboard/product/",
        }, 
        // {
        //     id: 2,
        //     menu: "Edit",
        //     url: "/customeredit/",
        // }
    ];


    return (
            <div className="dashboard-main">

                <section className="dashboard-content">
                    
                    <DashboardHeader dashboardTitle="Products" dashboardPreTitle="Products" />

                    {isMobile? 
                    <>
                    
                    {products && products.map(product => ( 

                    <Card className="dashboard-card" key={product.id}>
                        
                        <CardContent className="dashboard-card-content">
                            <div className="dashboard-card-title">{product.name}</div>
                            <div className="dashboard-card-subtitle">{product.sku}</div>                            
                            <p>{product.type_name}</p>
                            <p>{product.price}</p>
                            {/* <p>Gender: {customer.gender}</p> */}
                            {/* <Pillchip label={booking.status_name} /> */}
                        </CardContent>
                        {/* <CardActions>
                            <Button size="small">Booking</Button>
                        </CardActions> */}
                    </Card>
                    ))}

                    </>

                    :
                    // If Device is not mobile, display data using a table
                    <>
                    <table className="styled-table">
                        <thead>
                            <tr >
                                <th>SKU</th>
                                <th>Name</th>
                                <th>Date Created</th>
                                {/* <th>Product</th> */}
                                <th>Price</th>
                                <th></th>
                            </tr>
                        </thead>

                        {products && products.map(product => (

                            <tbody key={product.id}>
                                <tr>
                                    <td>{product.sku}</td>
                                    <td>{product.name}</td>
                                    <td>{convertDateTime(product.created_at)}</td>

                                    {/* <td>{product.product_name}</td> */}

                                    <td>{Number(product.price).toLocaleString()}</td>

                                    <td className='styled-table-menu'>
                                        <ThreeDotsMenu data={product} threeSubMenu={threeSubMenu} />
                                    </td>
                                </tr>
                            </tbody>
                        ))}
                        

                    </table>
                    </>

                    }

                    <Pagination 
                        className="custom-mui-pagination"
                        count={pagecount} 
                        page={page} 
                        onChange={handlePageChange} 
                    />

                    <Link to="/dashboard/productnew" className="nav-link">
                        <Fab className="fab" color="primary" aria-label="add">
                            <AddIcon />
                        </Fab>
                    </Link>

                </section>
            </div>

        // </Layout>


    )
}
export default Products