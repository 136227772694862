import React from "react"
import { useEffect, useState, useContext } from "react";
import useAxios from "./useAxios";
// import useAxiosInterceptor from "./useAxiosInterceptor";
import {UserContext} from "../App"

// import Layout from "./Layout";
import DashboardHeader from "./DashboardHeader";
import 'chart.js/auto'
import { Bar, Doughnut } from "react-chartjs-2";
// import DoughnutChart from "./DoughnutChart";
// import { datePickerValueManager } from "@mui/x-date-pickers/DatePicker/shared";
// import { AccountBalance } from "@mui/icons-material";

import moment from 'moment';
import Loading from "./Loading";


function Dashboard() {

    const page_size = useContext(UserContext);
    // console.log("PG",page_size)

    //Axios
    // const { data: profile, loading } = useAxios('/profile')
    const { loading } = useAxios()


    // FETCH CLIENT PROFILE
    // const clientID = localStorage.getItem('clientID');
    const clientProfileID = localStorage.getItem('clientProfile');


    const { data: clientprofile } = useAxios({
        url: `/clientprofiles/${clientProfileID}`,
        method: 'get'
    })  


    // FETCH CUSTOMER
    const [customers, setCustomers] = useState([]);
    const { data: customerData } = useAxios({
    // const { data: customers } = useAxios({
        url: `/crm/customers?page_size=${page_size}`,
        method: 'get'
    })  


     // FETCH PRODUCTS
     const [products, setProducts] = useState([]);
     const { data: productData } = useAxios({
     // const { data: customers } = useAxios({
         url: `/products/products?page_size=${page_size}`,
         method: 'get'
     })  


    // FETCH LEADS
    const { data: leads } = useAxios({
        url: "/leads",
        method: 'get'
    })  

    //FETCH ACCOUNT TYPE 
    const { data: accounttypes } = useAxios({
        // url: '/accounttypes',
        url: '/accounting/account-types',
        method: 'get'
    })

    const { data: txntypes } = useAxios({
        // url: '/transtypes',
        url: '/accounting/transaction-types',
        method: 'get'
    })

    const income = accounttypes.find(accounttype => accounttype.code === "10100")
    const otherincome = accounttypes.find(accounttype => accounttype.code === "10200")
    const expense = accounttypes.find(accounttype => accounttype.code === "20100")
    const receivable = accounttypes.find(accounttype => accounttype.code === "30200")
    // const invoice = txntypes.find(txntype => txntype.code === "10")

  
    // FETCH ACCOUNTS 
    const { data: accounts } = useAxios({
        url: `/accounting/accounts?page_size=${page_size}`,
        method: 'get'
    })
    

    // FETCH TRANSACTION 
    const { data: transactions } = useAxios({
        url: `/accounting/transactions?page_size=${page_size}`,
        method: 'get'
    })

    // FETCH ACCOUNT BALANCES 
    const { data: monthlyRevenue } = useAxios({
        url: "/accounting/monthly-revenue",
        method: 'get'
    })


    const [debtors, setDebtors] = useState([]);
    const [revaccts, setRevaccts] = useState([]);
    const [invAccts, setInvAccts] = useState([]);


    // const revaccts = Array.from(accounts.results).filter(account => account.type === income.id || account.type === otherincome.id)
    // const debtors = Array.from(accounts.results).filter(account => account.id === clientprofile.debtors_control)
    // const revaccts = accounts.filter(account => account.type === income.id || account.type === otherincome.id)
    // const debtors = accounts.find(account => account.id === clientprofile.debtors_control)
    // const expaccts = Array.from(accounts).filter(account => account.type === expense.id)    

    // const [sumInvoices, setSumInvoices] = useState(0)
    let cntCustomers = 0
    let cntLeads = 0
    let cntProducts = 0
    // let sumBookings = 0
    let sumRevenue = 0
    // let sumDebtors = 0

    // sumDebtors = debtors.balance
    // const sumDebtors = debtors.balance

    let sumInvoices = 0
    let sumInventory = 0
    let balance = 0
    
    // let balance = 0
    // let customer = ""
        
    const calcStats = () => {

        // CUSTOMERS

        for(let i= 0; i < customers.length; i++){
            cntCustomers = cntCustomers + 1
        }
        // console.log("COUNT", cntCustomers)


        // LEADS
        // for(let i= 0; i < leads.length; i++){
        //     cntLeads = cntLeads + 1
        // }

         // PRODUCTS
        for(let i= 0; i < products.length; i++){
            cntProducts = cntProducts + 1
        }

        // REVENUE
        for(let i= 0; i < revaccts.length; i++){
            sumRevenue = sumRevenue + parseFloat(revaccts[i].balance)
        }

        // DEBTORS
        // console.log("DEBTORS",debtors)
        for(let i= 0; i < debtors.length; i++){
            balance = balance + parseFloat(debtors[i].balance)
        }

        // INVENTORY
        for(let i= 0; i < invAccts.length; i++){
            sumInventory = sumInventory + parseFloat(invAccts[i].balance)
        }
    }
    calcStats()

    //CREATE EXPENSE DOUGHNUT CHART
    const [expenseData, setExpenseData] = useState({})
    const [viewChart, setViewChart] = useState(false)
    const createChart = () => {
        const expaccts = Array.from(accounts.results).filter(account => account.type === expense.id)
        // const expaccts = accounts.filter(account => account.type === expense.id)

        setExpenseData({
            labels: expaccts.map((expacct) => expacct.name),
            datasets: [
            {
                label: "Expenses",
                data: expaccts.map((expacct) => expacct.balance),
                backgroundColor: [
                    "#ecf0f1",
                    "#50AF95",
                    // "#d81159", //Added
                    "#ffafcc", //Added
                    "#3a86ff", //Added
                    "#8338ec", //Added
                    "#38b000", //Added


                    "#f3ba2f",
                    "#2a71d0",
                ],
            },
            ],
        });

    }

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            padding: 20,
            // padding: {
            //     // right: 5
            // }
        },
        plugins: {
            legend: {
                // display: false,
                position: 'right',
                labels: {
                    font: {
                        size: 11,
                    }
                //     color: 'rgb(255, 99, 132)'                   
                }
            }
        }
    }
    
    //CREATE REVENUE BAR CHART
    const [revenueData, setRevenueData] = useState({})
    const [viewBarChart, setViewBarChart] = useState(false)
    const createBarChart = () => {
        // const revenues = Array.from(transactions).filter(transaction => (transaction.type === invoice.id && transaction.entry_type === "C"))
        // const revenues = AccountBalance.filter(period => (period.year === invoice.id && transaction.entry_type === "C"))

        setRevenueData({
            labels: monthlyRevenue.map((period) => moment().month(period.month-1).format("MMM")),


            datasets: [
            {
                label: "Revenue",
                data: monthlyRevenue.map((period) => period.amount),

                backgroundColor: [
                    "#ecf0f1",
                    "#50AF95",
                    "#d81159", //Added
                    "#f3ba2f",
                    "#2a71d0",
                ],
            },
            ],
        });

        
    }

    const barChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            padding: 20,
            // padding: {
            //     // right: 5
            // }
        },
        plugins: {
            legend: {
                display: false,
                // position: 'right',
                labels: {
                    font: {
                        size: 8,
                    }
                //     color: 'rgb(255, 99, 132)'                   
                }
            }
        },
        scales: {
            x: {
              grid: {
                display: false,
              },
            },
      
            y: {
              grid: {
                // display: false,
                color: 'rgba(217,143,7,0.1)',
              },
              display: false,
            },
        }
    }


    useEffect(()=>{
        if (accounts.count){
            setViewChart(true)
            createChart()
            setRevaccts(Array.from(accounts.results).filter(account => account.type === income.id || account.type === otherincome.id))
            setDebtors(Array.from(accounts.results).filter(account => account.type === receivable.id))
            setInvAccts(Array.from(accounts.results).filter(account => account.type === income.id || account.type === otherincome.id))

        }

        if (transactions.count){
            setViewBarChart(true)
            createBarChart()
        }

        if (customerData.count){
            setCustomers(Array.from(customerData.results))
        }

        if (productData.count){
            setProducts(Array.from(productData.results))
        }
    // },[accounts.length, transactions.length])
    },[accounts.count, transactions.count, customerData.count, productData.count])

    // console.log("CUSTOMERS", customers)
    // console.log("CUSTOMER DATA", customerData)


    return (
        // <Layout>

        // {/* <div className="dashboard-container"> */}

        //     {/* <Leads  leads={leads} loading={loading}/> */}
            <div className="dashboard-main">
                
                
                <section className="dashboard-content">

                    <DashboardHeader dashboardTitle="Insights" dashboardPreTitle="Dashboard" />

                    {loading? <Loading loading={loading}/>
                    :
                    <>

                    <div className="dashboard-content-subheader" id="dashboard-charts">


                        <div className='dashboard-card'>
                            <div className="dashboard-card-pretitle">
                                Revenue
                            </div>
                            {viewBarChart && 

                            <div className="dashboard-card-chart">
                                <Bar data={revenueData} options={barChartOptions}/>
                            </div>
                            }

                        </div>
                        <div className='dashboard-card'>
                            <div className="dashboard-card-pretitle">
                                Expenses
                            </div>

                            { viewChart && 
                                <div className="dashboard-card-chart" >

                                {/* <div className="dashboard-card-text" > */}
                                    <Doughnut 
                                        data={expenseData} 
                                        options={chartOptions}/>
                                </div>

                            }
                        </div>
                        {/* <div className='dashboard-card'>
                            <div className="dashboard-card-pretitle">
                                Products
                            </div>

                            {viewChart && 
                                <div className="dashboard-card-text" >
                                    <Doughnut data={expenseData}/>
                                </div>
                            }
                        </div> */}
                    </div>

                    <div className="dashboard-content-subheader" id="dashboard-landing">
                        
                        {/* <div className='dashboard-card'>
                            <div className="dashboard-card-pretitle">
                                Leads
                            </div>
                            <div className="dashboard-card-text">
                                {cntLeads}
                            </div>
                        </div> */}
                        <div className='dashboard-card'>
                            <div className="dashboard-card-pretitle">
                                Products
                            </div>
                            <div className="dashboard-card-text">
                                {cntProducts}
                            </div>
                        </div>
                        <div className='dashboard-card'>
                            <div className="dashboard-card-pretitle">
                                Customers
                            </div>
                            <div className="dashboard-card-text">
                                {cntCustomers}
                            </div>
                        </div>
                        {/* <div className='dashboard-card'>
                            <div className="dashboard-card-pretitle">
                                Bookings
                            </div>
                            <div className="dashboard-card-text">
                                {cntBookings}
                            </div>
                        </div>
                        <div className='dashboard-card'>
                            <div className="dashboard-card-pretitle">
                                Value of Bookings
                            </div>
                            <div className="dashboard-card-text">
                                KES {sumBookings.toLocaleString()}
                            </div>
                        </div> */}
                        {/* <div className='dashboard-card'>
                            <div className="dashboard-card-pretitle">
                                Revenue
                            </div>
                            <div className="dashboard-card-text">
                                {clientprofile.currency_code} {sumRevenue.toLocaleString()}
                            </div>
                        </div> */}
                        <div className='dashboard-card'>
                            <div className="dashboard-card-pretitle">
                                Inventory
                            </div>
                            <div className="dashboard-card-text">
                                {/* KES {sumRevenue.toLocaleString()} */}
                                {clientprofile.currency_code} {sumInventory.toLocaleString()}
                            </div>
                        </div>
                        <div className='dashboard-card'>
                            <div className="dashboard-card-pretitle">
                                Debtors
                            </div>
                            <div className="dashboard-card-text">
                                {/* KES {balance.toLocaleString()} */}
                                {clientprofile.currency_code} {balance.toLocaleString()}


                            </div>
                        </div>

                    </div>

                    {/* <Leads leads={currentPosts} loading={loading}/> */}
                    {/* <Leads leads={leads} loading={loading}/> */}
                    </>
                    }
                </section>
            </div>


        // </Layout>

    ) 

}

export default Dashboard