import React, { useCallback } from "react";
import { useState, useEffect } from "react";
import authAxios from "../../components/authAxios";
// import useAxios from "../../components/useAxios";
// import axios from "axios";
// import authAxios from "../../components/authAxios";

// import useAxiosInterceptor from "./useAxiosInterceptor";

import { createSearchParams, Link } from "react-router-dom";
// import { useNavigate } from "react-router-dom";

import Pagination from '@mui/material/Pagination';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import DashboardHeader from "../../components/DashboardHeader";
import { useMediaQuery } from 'react-responsive'
import { Card, CardContent } from "@mui/material";
import Button from '@mui/material/Button';
import SyncIcon from '@mui/icons-material/Sync';


import Layout from "../../components/Layout";
import ThreeDotsMenu from "../../components/ThreeDotsMenu";

// import ProductNew from "../../components/ProductNew";
import Loading from "../../components/Loading";

import { format } from 'date-fns';

// import debounce from 'lodash.debounce';

const AmazonTransactions = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 750px)' })
    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();
    // const { loading, data, fetchData}  = useAxios();   
    // const { loading, data, fetchData, abortController}  = useAxios();   
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');


    // const abortController = new AbortController();
    
    // FETCH PRODUCTS 
    // const [products, setProducts] = useState([]);
  

    // FETCH TRANSACTIONS 
    const [transactions, setTransactions] = useState([]);
    // const transactionsRef = useRef([]); // Use ref to maintain stable reference
    const fetchTransactions = useCallback(async () => {
        // fetchData({
        //     url: `/amazon/amazon-transactions/?page=${page}`,
        //     method: 'get',
        //     // signal: abortController.signal

        //   })
        try{
            setLoading(true);
            const response = await authAxios.get(`/amazon/amazon-transactions/?page=${page}`)
            setTransactions(Array.from(response.data.results))
            // transactionsRef.current = Array.from(response.data.results)
            setPageCount(response.data.page_count)
        } 
        catch(error) {
            console.log("USE AXIOS ERROR",error)
            setError( error.message);
        } 
        finally {
            setLoading(false);
        }
    })
   

    // const fetchTransactions = debounce(async (page) => {
    //     const response = await axios.get(`/amazon/amazon-transactions/?page=${page}`)
    //     return response.data
    //   }, 500);

    
    // SYNC TRANSACTIONS
    const syncTransactions = async () => {
        // fetchData({
        //     url: '/amazon/sync-transactions',
        //     method: 'get',
        //   })
        try{
            setLoading(true);
            const response = await authAxios.get('/amazon/sync-transactions')
            setTransactions(Array.from(response.data.results))
            setPageCount(response.data.page_count)
        } 
        catch(error) {
            console.log("USE AXIOS ERROR",error)
            setError( error.message);
        } 
        finally {
            setLoading(false);
        }
    }

    const [isDrawerOpen, setIsDrawerOpen] = useState(false)

    const handleDrawerOpen = () => {
        setIsDrawerOpen(true)
    };
  
    const handleDrawerClose = () => {
        setIsDrawerOpen(false)
    };

    //Pagination
     const handlePageChange = (event, value) => {
        setPage(value);
    };

    const threeSubMenu = [
        {
            id: 1,
            menu: "View",
            // url: "/item/",
            url: "/dashboard/item/",

        }, 
        // {
        //     id: 2,
        //     menu: "Edit",
        //     url: "/itemedit/",
        // }
    ];

    const convertDateTime = (myDateTime) => {
        return format(new Date(myDateTime), 'dd-MM-yyyy HH:mm');
      };

    // useEffect (() => {
        // fetchTransactions()

        // if (data.count){
        //     setPageCount(data.page_count)
        //     setTransactions(Array.from(data.results))

        // }
    // },[page])   

    // const MemoizedTableRow = React.memo(({ transaction }) => {
    //     return (
    //       <tr>
    //         <td>{transaction.id}</td>
    //         <td>{transaction.date}</td>
    //         <td>{transaction.category}</td>
    //         <td>{transaction.type}</td>
    //         <td>{transaction.currency_code}</td>
    //         <td>{Number(transaction.amount).toLocaleString()}</td>
    //         <td className='styled-table-menu'>
    //           <ThreeDotsMenu data={transaction} threeSubMenu={threeSubMenu} />
    //         </td>
    //       </tr>
    //     );
    //   }, (prevProps, nextProps) => {
    //     return prevProps.transaction.id === nextProps.transaction.id;
    //   });
    


    useEffect (() => {
        fetchTransactions()

        // return () => abortController.abort();
    },[page])    



    return (
        // <Layout>

            <div className="dashboard-main">

                <section className="dashboard-content">
                    
                    <DashboardHeader dashboardTitle="Transactions" dashboardPreTitle="Amazon" />


                    {isMobile? 
                    <>
                    
                    {transactions && transactions.map(transaction => ( 

                    <Card className="dashboard-card" key={transaction.id}>
                        
                        <CardContent className="dashboard-card-content">
                            <div className="dashboard-card-title">{transaction.name}</div>
                            <div className="dashboard-card-subtitle">{transaction.sku}</div>                            
                            <p>{transaction.type_name}</p>
                            <p>{transaction.price}</p>
                            {/* <p>Gender: {customer.gender}</p> */}
                            {/* <Pillchip label={booking.status_name} /> */}
                        </CardContent>
                        {/* <CardActions>
                            <Button size="small">Booking</Button>
                        </CardActions> */}
                    </Card>
                    ))}

                    </>

                    :
                    // If Device is not mobile, display data using a table
                    <>
                    {loading?
                        <Loading loading={loading}/>
                    :
                    <table className="styled-table">
                        <thead>
                            <tr >
                                {/* <th>SKU</th> */}
                                {/* <th>Name</th> */}
                                {/* <th>Gender</th> */}
                                {/* <th>Type</th> */}
                                <th>ID</th>
                                <th>Date</th>
                                <th>Type</th>
                                <th>Order</th>
                                <th>Description</th>
                                <th>Amaount</th>

                                <th></th>
                            </tr>
                        </thead>

                        {transactions && transactions.map(transaction => (
                            <tbody key={transaction.id}>
                                <tr>
                                    <td>{transaction.id}</td>
                                    <td>{convertDateTime(transaction.date)}</td>
                                    <td>{transaction.type_description}</td>
                                    <td>{transaction.order_id}</td>
                                    <td>{transaction.description}</td>
                                    {/* <td>{transaction.currency_code}</td> */}
                                    <td>{Number(transaction.amount).toLocaleString()}</td>

                                    <td className='styled-table-menu'>
                                        <ThreeDotsMenu data={transaction} threeSubMenu={threeSubMenu} />
                                    </td>
                                </tr>
                            </tbody>
                        ))}
                        
                    </table>
                    }
                    </>

                    }
                   

                    <Pagination 
                        className="custom-mui-pagination"
                        count={pagecount} 
                        page={page} 
                        onChange={handlePageChange} 
                    />


                    {/* <div className="button-border-top">
                        <button id="submit" className="btn btn-outline-primary form-buttons" onClick={syncTransactions}>
                            Sync
                        </button>
                        <Button variant="outlined" onClick={syncTransactions}>Sync</Button>

                    </div>  */}

                    {/* <Link to="/transactionnew" className="nav-link"> */}
                        <Fab className="fab" color="primary" aria-label="add" onClick={syncTransactions}>
                            {/* <AddIcon /> */}
                            <SyncIcon/>
                        </Fab>
                    {/* </Link> */}
                    

                </section>
            </div>

        // </Layout>


    )
}
export default AmazonTransactions