// import React, {memo} from "react";
import { useNavigate } from "react-router-dom";
import NumberFormat from "react-number-format";
import { TextField } from "@mui/material";

import {Button} from "@mui/material";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';



const TransactionView = ({transaction}) => {
    // const client = localStorage.getItem('clientID');
    // const user = localStorage.getItem('userID');
    const navigate = useNavigate()


    return(
        <section className="dashboard-form">
            <form noValidate autoComplete="off" >
                <div className="grouped-fields-light">
                    <TextField className="custom-input"
                        label="Reference"
                        variant="outlined" 
                        fullWidth
                        // value = {transaction.reference}
                        defaultValue = {transaction.reference}
                        InputProps={{ readOnly: true }}
                    />
                    {transaction.doc_ref &&
                    <TextField className="custom-input"
                        label="Document Ref"
                        variant="outlined" 
                        fullWidth
                        // value = {transaction.doc_ref}
                        defaultValue = {transaction.doc_ref}
                        InputProps={{ readOnly: true }}


                    />
                    }
                    <TextField className="custom-input"
                        label="Date"
                        variant="outlined" 
                        fullWidth
                        // value = {transaction.date}
                        defaultValue = {transaction.date}
                        InputProps={{ readOnly: true }}
                    />

                    <TextField className="custom-input"
                        label="Account"
                        variant="outlined" 
                        fullWidth
                        // value = {transaction.account_name}
                        defaultValue = {transaction.account_name}
                        InputProps={{ readOnly: true }}
                    />

                    <div className="two-columns">
                        <NumberFormat 
                            className="custom-input"
                            fullWidth
                            label="Amount" 
                            variant="outlined" 
                            customInput={TextField}
                            thousandSeparator={true} 
                            // value = {transaction.amount}
                            defaultValue = {transaction.amount}
                            // disabled
                            InputProps={{ readOnly: true }}

                        />
                        <TextField className="custom-input"
                            label="Entry Type"
                            variant="outlined" 
                            fullWidth
                            // value = {transaction.entry_type}
                            defaultValue = {transaction.entry_type}
                            InputProps={{ readOnly: true }}
                        />
                    </div>

                    <TextField className="custom-input"
                        label="Description"
                        variant="outlined" 
                        fullWidth
                        // value = {transaction.description}
                        defaultValue = {transaction.description}
                        InputProps={{ readOnly: true }}

                    />
                    <TextField className="custom-input"
                        label="Memo"
                        variant="outlined" 
                        fullWidth
                        multiline
                        rows={2}
                        // value = {transaction.memo}
                        defaultValue = {transaction.memo}
                        InputProps={{ readOnly: true }}
                    />
                  
                </div>
                <div className="button-border-top">
                    {/* <button className="btn btn-primary form-buttons" onClick={()=>navigate("/dashboard/transactions")}>
                        Back
                    </button> */}
                    <Button
                        onClick={()=>navigate("/dashboard/transactions")}
                        variant="outlined" 
                        size="small"
                        startIcon={<ArrowBackIcon />}
                    >
                        Back
                    </Button>

                    {/* {transaction.type_code==="010" &&
                    <Button
                        // id="AddRowButton"
                        onClick={() => navigate('/dashboard/invoicereport/',{state:{reference:transaction.reference}})}
                        variant="outlined" 
                        size="small"
                        startIcon={<InsertDriveFileIcon />}
                    >
                        PDF
                    </Button>
                    } */}

                </div>
             
                
            </form>

        </section>
    )
}

export default TransactionView;