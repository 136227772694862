import CircularProgress from '@mui/material/CircularProgress';
// import Box from '@mui/material/Box';


const Loading = ({loading}) => {
    // console.log("LOADING", loading)

    return (
        <div className='dashboard-loading'>    
            {loading &&
            <CircularProgress 
                size={150}
                // thickness={4}
            />
            }
        </div>
    );
}

export default Loading;