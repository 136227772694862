
import logo from '../assets/zidi-logo.svg'
import foxgenLogo from '../assets/foxgen-logo.jpeg'
import conceptSpacesLogo from '../assets/concept-spaces-logo.svg'
import wadiLogo from '../assets/wadi-logo.svg'
import absoluteCommerceLogo from '../assets/absolute-commerce-logo.svg'
import rohimaxLogo from '../assets/rohimax-logo.svg'
import evogressLogo from '../assets/evogress-logo.svg'
import candqLogo from '../assets/candq-logo.svg'
import patapayLogo from '../assets/patapay-logo.svg'


const Clients = () =>{
    return(
        <section className="clients">
            <div className="clients-heading">
                Our Clients
            </div>
            <div className="clients-text">
                We deliver measurable impact for customers across different industries. We partner with you to understand your challenges, opportunities and offer solutions that drive real results.
            </div>

            <div className='clients-logos-container'>
                <img className='clients-logo' src={conceptSpacesLogo} alt="Concept Spaces Logo"/>  
                <img className='clients-logo' src={wadiLogo} alt="brand logo"/>  
                <img className='clients-logo' src={absoluteCommerceLogo} alt="brand logo"/>  
                <img className='clients-logo' src={foxgenLogo} alt="brand logo"/>  
                <img className='clients-logo' src={rohimaxLogo} alt="brand logo"/>  
                <img className='clients-logo' src={evogressLogo} alt="brand logo"/>  
                <img className='clients-logo' src={candqLogo} alt="brand logo"/>  
                <img className='clients-logo' src={patapayLogo} alt="brand logo"/>  
            </div>


        </section>
    )


}

export default Clients