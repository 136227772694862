import { useState } from "react";
import { useQuery } from "@tanstack/react-query";

import authAxios from "../../components/authAxios";
import Pagination from '@mui/material/Pagination';
import DashboardHeader from "../../components/DashboardHeader";
import { useMediaQuery } from 'react-responsive'
import { Card, CardContent } from "@mui/material";

import ThreeDotsMenu from "../../components/ThreeDotsMenu";

const Items = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 750px)' })
    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();

    const { data: items, status, isPreviousData } = useQuery({
        queryKey: ['items', page],
        queryFn: async () => {
          const response = await authAxios.get(`/inventory/items/?page=${page}`);
          setPageCount(response.data.page_count)
          return response.data.results
        },
        keepPreviousData : true,
    });

    //Pagination
     const handlePageChange = (event, value) => {
        setPage(value);
    };

    const threeSubMenu = [
        {
            id: 1,
            menu: "View",
            // url: "/item/",
            url: "/dashboard/item/",

        }, 
        // {
        //     id: 2,
        //     menu: "Edit",
        //     url: "/itemedit/",
        // }
    ];


    return (
            <div className="dashboard-main">

                <section className="dashboard-content">
                    
                    <DashboardHeader dashboardTitle="Items" dashboardPreTitle="Inventory" />

                    {isMobile? 
                    <>
                    
                    {items && items.map(item => ( 

                    <Card className="dashboard-card" key={item.id}>
                        
                        <CardContent className="dashboard-card-content">
                            <div className="dashboard-card-title">{item.name}</div>
                            <div className="dashboard-card-subtitle">{item.sku}</div>                            
                            <p>{item.type_name}</p>
                            <p>{item.price}</p>
                            {/* <p>Gender: {customer.gender}</p> */}
                            {/* <Pillchip label={booking.status_name} /> */}
                        </CardContent>
                        {/* <CardActions>
                            <Button size="small">Booking</Button>
                        </CardActions> */}
                    </Card>
                    ))}

                    </>

                    :
                    // If Device is not mobile, display data using a table
                    <>
                    <table className="styled-table">
                        <thead>
                            <tr >
                                <th>SKU</th>
                                <th>Name</th>
                                {/* <th>Gender</th> */}
                                {/* <th>Type</th> */}
                                <th>Cost</th>
                                <th>Quantity</th>
                                <th>Value</th>
                                <th>Re-Order Level</th>

                                <th></th>
                            </tr>
                        </thead>

                        {items && items.map(item => (

                            <tbody key={item.id}>
                                <tr>
                                    <td>{item.sku}</td>
                                    <td>{item.name}</td>
                                    {/* <td>{customer.gender}</td> */}
                                    {/* <td>{item.type_name}</td> */}
                                    <td>{Number(item.cost).toLocaleString()}</td>
                                    <td>{Number(item.quantity).toLocaleString()}</td>
                                    <td>{Number(item.cost*item.quantity).toLocaleString()}</td>
                                    <td>{Number(item.reorder_level).toLocaleString()}</td>

                                    <td className='styled-table-menu'>
                                        <ThreeDotsMenu data={item} threeSubMenu={threeSubMenu} />
                                    </td>
                                </tr>
                            </tbody>
                        ))}
                        

                    </table>
                    </>

                    }

                    <Pagination 
                        className="custom-mui-pagination"
                        count={pagecount} 
                        page={page} 
                        onChange={handlePageChange} 
                    />

                    {/* <Link to="/transactionnew" className="nav-link">
                        <Fab className="fab" color="primary" aria-label="add">
                            <AddIcon />
                        </Fab>
                    </Link> */}
                    

                </section>
            </div>

    )
}
export default Items