import React, { useState, useEffect, useContext, useRef } from 'react';

import { useQuery } from '@tanstack/react-query';
import authAxios from '../../components/authAxios';

import { TextField, Autocomplete } from "@mui/material";
import { Radio, RadioGroup, FormControlLabel } from "@mui/material";
import {Button} from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';


import moment from 'moment';
import { TreeSelect, Modal } from 'antd';
import NumberFormat from "react-number-format";

import '../../styles/JournalVoucher.css'
import {UserContext} from "../../App"


// const JournalVoucher = ({ addJv, isModalOpen, setIsModalOpen, txnLines }) => {
const JournalVoucher = ({ accts, clientProfile, addTxnLine, isModalOpen, setIsModalOpen, txnLines, tag }) => {

    // const page_size = useContext(UserContext);
    // const {clientProfile} = useContext(UserContext);
    // const { clientProfile } = useContext(UserContext);
    const {pageSize} = useContext(UserContext);

    const showEmployees = useRef(false);


    // console.log("PAGE SIZE",pageSize)
    // console.log("CLIENT PROFILE",clientProfile)

    const { data: employeeData } = useQuery({
        queryKey: ['employees'],
        queryFn: async () => {
            const response = await authAxios.get(`/hrm/employees/?page_size=${pageSize}`);
            setEmployees(response.data.results)
            return response.data.results;

        },
    });



    const [description, setDescription] = useState();
    // const [type, setType] = useState();
    const [employees, setEmployees] = useState([]);
    const [employee, setEmployee] = useState("");

    const [account, setAccount] = useState();
    const [accountName, setAccountName] = useState();

    const [dt1, setDt1] = useState(new Date());
    const [reference, setReference] = useState();
    const [amt, setAmt] = useState();
    const [debitAmount, setDebitAmount] = useState();
    const [creditAmount, setCreditAmount] = useState();
    const [entryType, setEntryType] = useState();


    const showModal = () => {
    setIsModalOpen(true);
    };

    const handleOk = () => {
    setIsModalOpen(false);
    };

    const handleCancel = () => {
    setIsModalOpen(false);
    };

    const [accounts, setAccounts] = useState([]);
    // const { data: accts } = useAxios({
    //     url: "/accounting/accounts-tree",
    //     method: 'get'
    // })   

  const [jvData, setJvData] = useState({
    type: '',
    date: '',
    description: '',
    debit_amount: '',
    credit_amount: '', 
    entry_type: ''
    });

  const transformDataToTree = (data) => {
    return data.map((item) => ({
        // value: item.id.toString(), // Convert to string if needed
        value: item.id, // Convert to string if needed
        title: item.name,
        children: item.sub_accounts ? transformDataToTree(item.sub_accounts) : [],
    }));
    };

    const filterTreeNode = (input, treeNode) => {
        const nodeLabel = treeNode.title.toLowerCase(); // Access title directly
        return nodeLabel.includes(input.toLowerCase());
    };

    const handleAccount=(e)=>{
        // console.log(e)
        // console.log(accts)
        const acct = accts.find(acct => acct.id === e);
        if (acct.is_control && acct.id===clientProfile.employee_control){
            console.log("A/C",acct)
            showEmployees.current=true
        }

    }

    const handleEntryType = (e) => {
        console.log("ENTRY",e.target.value)
        setEntryType(e.target.value)
        if (e.target.value==="D"){
            setDebitAmount(parseFloat(amt.replace(/,/g, '')))
            setCreditAmount('')
        }
        if (e.target.value==="C"){
            setCreditAmount(parseFloat(amt.replace(/,/g, '')))
            setDebitAmount('')
        }
    };

  const handleSubmit = (e) => {
    e.preventDefault();

    const jv = {
        account:account,
        account_name:accountName,
        reference: reference,
        date: moment(dt1).format("YYYY-MM-DD"),
        description: description,
        debit_amount: debitAmount,
        credit_amount: creditAmount,
        entry_type: entryType,
        employee: employee
      };

    // addJv(jv);
    addTxnLine(jv);

    console.log("JV",jv)


    // Reset the form and close the modal
    setJvData({
      type: '',
      date: '',
      description: '',
      debit_amount: '',
      credit_amount: '',
      entry_type: '',
      employee: ''
    });
    setIsModalOpen(false);
    setEmployee('')
    showEmployees.current=false

    // onClose();
  };


  useEffect(()=>{
    if (accts.length){
        setAccounts(transformDataToTree(accts))
    }

    },[accts])


  return (
    <>
        <div className="journal-voucher-table">
            <table className="styled-table">
                <thead>
                    <tr >
                        {/* <th>ID</th> */}
                        <th>Account</th>
                        <th>Debit</th>
                        <th>Credit</th>
                        <th>Description</th>
                        <th></th>


                    </tr>
                </thead>
                {txnLines && txnLines.map((jv, index) => (
                    <tbody key={index}>
                        <tr>
                            <td>{jv.account_name}</td>
                            <td>{jv.debit_amount? Number(jv.debit_amount).toLocaleString():""}</td>
                            <td>{jv.credit_amount? Number(jv.credit_amount).toLocaleString():""}</td>
                            <td>{jv.description}</td>
                            <td>
                                <IconButton 
                                    // className='dynamic-table-menu' 
                                    size="small">
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </td>
                            {/* <td>{jv.credit.toLocaleString()}</td> */}
                        </tr>
                    </tbody>
                ))}
            </table>
        </div>

        <div className="AddRow">
            <Button
                id="AddRowButton"
                onClick={() => setIsModalOpen(true)}
                variant="outlined" 
                size="small"
                startIcon={<AddCircleOutlineIcon />}
            >
                Journal Entry
            </Button>
        </div>
        {isModalOpen && 
            // <JournalVoucher
            //     addJv={addJv}
            //     isModalOpen={isModalOpen}
            //     setIsModalOpen={setIsModalOpen}
            //     />

            <Modal className="dashboard-modal" title="Add Journal Entry" visible={isModalOpen} open={isModalOpen} onOk={handleSubmit} onCancel={handleCancel}>
                <div className="dashboard-form">

                    <form onSubmit={handleSubmit}>

                        <TreeSelect
                            style={{
                                width: '100%',
                            }}
                            dropdownStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                            }}
                            placeholder="Account"
                            allowClear
                            showSearch
                            onChange={(e, label) => {
                                setAccount(e)
                                setAccountName(label[0])
                                handleAccount(e)
                            }}
                            treeData={accounts}
                            filterTreeNode={filterTreeNode} //Enables search filter
                        />

                        {showEmployees.current &&
                        <Autocomplete
                            onChange={(event, newValue) => {
                                setEmployee(newValue.id)
                                }}
                            options={employees}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => 
                                <TextField 
                                    className='custom-input'
                                    label="Employee" 
                                    {...params} 
                                />
                            }
                        />
                        }

                        <TextField className="custom-input"
                            onChange={(e) => {
                                // setDescriptionError(false)
                                setDescription(e.target.value)
                            }}
                            label="Description" 
                            variant="outlined" 
                            fullWidth
                            required
                            inputProps={{ maxLength: 40 }} // Set the maximum character limit here
                            // error={descriptionError}
                        />
                        <TextField className="custom-input"
                            onChange={(e) => {
                                // setDescriptionError(false)
                                setReference(e.target.value)
                            }}
                            label="Reference" 
                            variant="outlined" 
                            fullWidth
                            required
                            // error={descriptionError}
                        />
                        <NumberFormat 
                            onChange={(e) => setAmt(e.target.value)} 
                            className="custom-input"
                            fullWidth
                            label="Amount" 
                            variant="outlined" 
                            customInput={TextField}
                            thousandSeparator={true} 
                            required
                            // error={amtError}
                        />
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            defaultChecked=""
                            onChange={handleEntryType}
                            className="custom-radio-group"
                        >
                            <FormControlLabel className="custom-radio" value="D" control={<Radio />} label="Debit" />
                            <FormControlLabel className="custom-radio" value="C" control={<Radio />} label="Credit" />
                        </RadioGroup>

                    </form>
                </div>
            </Modal>
        }
    </>
  );
};

export default JournalVoucher;
