import React from "react";
import useAxios from "../../components/useAxios";

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Autocomplete } from "@mui/material";
import { TreeSelect } from "antd";


// import Sidebar from "./Sidebar";
import UserAlerts from "../../components/UserAlerts";
import DashboardHeader from "../../components/DashboardHeader";
import '../../styles/TreeSelectStyles.css'


function AmazonMappingNew() {
    const {response, error, fetchData}  = useAxios();

    const { data: transactionTypes } = useAxios({
        url: '/accounting/transaction-types',
        method: 'get'
    })
    
    const { data: accts } = useAxios({
        url: "/accounting/accounts-tree",
        method: 'get'
    })   

    // console.log(contacts)
    const navigate = useNavigate();

    const [description, setDescription] = useState('');
    const [name, setName] = useState('');
    const [type, setType] = useState('');
    const [debitAccount, setDebitAccount] = useState('');
    const [creditAccount, setCreditAccount] = useState('');
    const [memo, setMemo] = useState('');

    const [accounts, setAccounts] = useState([]);
    const [accountStatus, setAccountStatus] = useState("")
    const user = localStorage.getItem('userID');

    const [nameError, setNameError] = useState(false)
    const [descriptionError, setDescriptionError] = useState(false)


    const [typeError, setTypeError] = useState(false);

    const { data: clientProfile } = useAxios({
        url: `/clientprofiles/${localStorage.getItem('clientID')}`,
        method: 'get'
    })  

    const transformDataToTree = (data) => {
        return data.map((item) => ({
            value: item.id.toString(), // Convert to string if needed
            title: item.name,
            children: item.sub_accounts ? transformDataToTree(item.sub_accounts) : [],
        }));
    };

    const filterTreeNode = (input, treeNode) => {
        const nodeLabel = treeNode.title.toLowerCase(); // Access title directly
        return nodeLabel.includes(input.toLowerCase());
    };


    const handleSubmit = (e) => {
        e.preventDefault();

        // console.log(name, institution, email)

        if (description === '') {
            setDescriptionError(true)
        }

        if (name === '') {
            setNameError(true)
        }

        if (type === '') {
            setTypeError(true)
        }

        
        // if (name && mobile && email && idNumberError === false ) {
        if (name && accountStatus !== "error") {
            const client = clientProfile.id
            // const account = parseInt(acct)
            const debit_account = debitAccount
            const credit_account = creditAccount

            const mapping = {client, name, description, type, debit_account, credit_account, memo, user};
            console.log(mapping)

            fetchData({
                url: '/amazon/amazon-transaction-mapping/',
                method: 'post',
                data: mapping,
              })
        } 
    
    }

    useEffect(()=>{
        if (accts.length){
            setAccounts(transformDataToTree(accts))
        }

    },[accts.length])

    useEffect(() => {
        if (response.status === 201){
            navigate('/dashboard/amazon-mapping/');
        }    
    }, [ response.status]); 

    return(

        <div className="dashboard-main">
            <section className="dashboard-content">

            <section className="dashboard-form dashboard-form-compact">
                <UserAlerts error={error} />
                <DashboardHeader dashboardTitle="Add Transaction Mapping" dashboardPreTitle="HRM" />

                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <div className="grouped-fields-light">

                        <TextField className="custom-input"
                            onChange={(e) => {
                                setDescriptionError(false)
                                setDescription(e.target.value)
                            }}
                            label="Description" 
                            variant="outlined" 
                            fullWidth
                            required
                            error={descriptionError}
                        />

                        <TextField className="custom-input"
                            onChange={(e) => {
                                setNameError(false)
                                setName(e.target.value)
                            }}
                            label="Column Name" 
                            variant="outlined" 
                            fullWidth
                            required
                            error={nameError}
                        />

                        <Autocomplete
                            onChange={(event, newValue) => {
                                console.log("VALUE", newValue.id)
                                setTypeError(false)
                                setType(newValue.id)
                                }}
                            options={transactionTypes}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => 
                                <TextField 
                                    className="custom-input" 
                                    fullWidth error={typeError} 
                                    required
                                    label="Type"  
                                    {...params} 
                                /> 
                            }                          
                        />


                        <TreeSelect
                            className="custom-tree-select"
                            style={{
                                width: '100%',
                            }}
                            dropdownStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                            }}
                            placeholder="Debit Account"
                            status={accountStatus}
                            allowClear
                            showSearch
                            onChange={(e)=>{
                                // setAccountStatus("")
                                const selectedAccount = accounts.find((account) => account.value === e);
                                selectedAccount && selectedAccount.children.length?setAccountStatus("error"):setAccountStatus("")
                                setDebitAccount(e)
                            }}
                            treeData={accounts}
                            filterTreeNode={filterTreeNode} //Enables search filter
                        />

                        <TreeSelect
                            className="custom-tree-select"
                            style={{
                                width: '100%',
                            }}
                            dropdownStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                            }}
                            placeholder="Credit Account"
                            status={accountStatus}
                            allowClear
                            showSearch
                            onChange={(e)=>{
                                // setAccountStatus("")
                                const selectedAccount = accounts.find((account) => account.value === e);
                                selectedAccount && selectedAccount.children.length?setAccountStatus("error"):setAccountStatus("")
                                setCreditAccount(e)
                            }}
                            treeData={accounts}
                            filterTreeNode={filterTreeNode} //Enables search filter
                        />

                        <TextField className="custom-input"
                            onChange={(e) => setMemo(e.target.value)}
                            label="Memo"
                            variant="outlined"
                            multiline
                            rows={3}
                            fullWidth
                        />

                    </div>

                <div className="button-border-top">
                    <button id="submit" className="btn btn-primary form-buttons" type="submit">
                        Save
                    </button>
                </div>


                </form>

            </section>
            </section> 

        </div>

    )

}

export default AmazonMappingNew;
