import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

import { Result } from 'antd';
import {IconButton, Button} from "@mui/material";
// import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';


const AmazonAccepted = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const client = localStorage.getItem('clientID');


    useEffect(() => {
        // Extract the authorization code from the URL parameters
        const searchParams = new URLSearchParams(location.search);
        const authorizationCode = searchParams.get('code');
    
        // Make an API call to Django backend to exchange the authorization code for an access token

        axios.post('/amazon/token/', { code: authorizationCode, client: client })
            .then(response => {
                localStorage.setItem('amazonAccessToken', response.data.access_token);
                localStorage.setItem("amazonRefreshToken", response.data.refresh_token);
                // Do something else with the response

                console.log("RESPONSE", response)
                console.log("DATA", response.data)
                console.log("STORAGE", localStorage)
                })
            .catch(error => {
                console.error(error);
                });
      }, [location.search]);


    return (
        <div className="dashboard-main">
            <Result
                status="success"
                title="Amazon SP Authorisation Successfu!"
                subTitle="You can now access, update and sync your Amazon Selling Partner data from this application."
                extra={[
                        // <Button type="primary" key="console">
                        //     Go Console
                        // </Button>,
                        // <Button key="Print">Print Invoice</Button>,
                        <Button
                            key="one"
                            variant="outlined" 
                            size="small"
                            // startIcon={<DownloadForOfflineIcon />}
                            onClick={()=>{
                                navigate('/dashboard/');
                            }}
                        >
                            Dashboard
                        </Button>,
                        
                ]}
            />

        </div>
    )
}

export default AmazonAccepted;