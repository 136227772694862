
import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
// import { AlertTitle } from '@mui/material';
// import { withStyles } from '@material-ui/core/styles';
// import { withStyles } from '@mui/material';

// const styles = theme => ({
//     close: {
//       width: theme.spacing.unit * 4,
//       height: theme.spacing.unit * 4,
//     },
//   });


const CustomSnackBar = ({success, successMessage, handleSnackBarClose}) => {
    // const [open, setOpen] = React.useState(false);

   

    const action = (
        <React.Fragment>
        
        <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            // onClick={handleClose}
            onClick={handleSnackBarClose}
        >
            <CloseIcon fontSize="small" />
        </IconButton>
        </React.Fragment>
    );

    return (
        <div className='custom-snack-bar'>
        <Snackbar
            // open={open}
            open={success}
            autoHideDuration={3000}
            onClose={handleSnackBarClose}
            // message="Note archived"
            action={action}
            anchorOrigin={{vertical: "bottom", horizontal: "center" }}
        >
            <Alert 
                variant="filled"
                severity="success" 
                sx={{ width: '100%' }}
                action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        handleSnackBarClose();
                      }}
                    >
                        <CloseIcon fontSize="inherit" />

                    </IconButton>
                    }
                >           

                {successMessage}

            </Alert>
            {/* <Alert onClose={() => {}}>This is a success alert — check it out!</Alert> */}
                
        </Snackbar>
        </div>
    );

   
}

export default CustomSnackBar;
// export default withStyles(styles)(CustomSnackBar);