import React from "react";
import { useState, useEffect } from "react";
// import axios from "axios";
import useAxios from "../../components/useAxios";
// import authAxios from "./authAxios";

import { Link } from "react-router-dom";
// import { useNavigate } from "react-router-dom";


// import Pagination from "./Pagination";
import Pagination from '@mui/material/Pagination';


// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import DashboardHeader from "../../components/DashboardHeader";
import { useMediaQuery } from 'react-responsive'
import { Card, CardContent } from "@mui/material";

import ThreeDotsMenu from "../../components/ThreeDotsMenu";


function Employees() {
    const isMobile = useMediaQuery({ query: '(max-width: 750px)' })
    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();

    const { data, fetchData}  = useAxios();   
    const { data:employeeData, fetchData:fetchEmployeeData}  = useAxios();   


    // FETCH CUSTOMERS 
    const [employees, setEmployees] = useState([]);

    const fetchEmployees = () => {
        fetchEmployeeData({
            url: `/hrm/employees/?page=${page}`,
            method: 'get',
          })
    }

    const handlePageChange = (event, value) => {
        setPage(value);
    };

     const threeSubMenu = [
        {
            id: 1,
            menu: "View",
            // url: "/customer/",
            url: "/dashboard/employee/",

        }, 
        // {
        //     id: 2,
        //     menu: "Edit",
        //     url: "/dashboard/customeredit/",
        // }
    ];

    useEffect (()=>{
        fetchEmployees()
    },[page]) //IMPORTANT: RUN API CALL ONLY ONCE!

    useEffect (() => {
        if (employeeData.page){
            // console.log("CUSTOMERS",customerData)
            setPageCount(employeeData.page_count)
            setEmployees(Array.from(employeeData.results))
        }
    },[employeeData])
    

    return (
            <div className="dashboard-main">
                <section className="dashboard-content">
                    <DashboardHeader dashboardTitle="Employees" dashboardPreTitle="HRM" />

                    {isMobile? 
                    <>
                    
                    {employees && employees.map(employee => ( 

                    <Card className="dashboard-card" key={employee.id}>
                        
                        <CardContent className="dashboard-card-content">
                            <div className="dashboard-card-title">{employee.name}</div>
                            <div className="dashboard-card-subtitle">{employee.telephone}</div>                            
                            <p>{employee.address}</p>
                            <p>{employee.email}</p>
                        </CardContent>
                        {/* <CardActions>
                            <Button size="small">Booking</Button>
                        </CardActions> */}
                    </Card>
                    ))}

                    </>

                    :
                    // If Device is not mobile, display data using a table
                    <>
                    <table className="styled-table">
                        <thead>
                            <tr >
                                <th>ID</th>
                                <th>Name</th>
                                <th>Telephone</th>
                                <th>Email</th>
                                <th>ID</th>
                                <th></th>
                            </tr>
                        </thead>

                        {employees && employees.map(employee => (

                            <tbody key={employee.id}>
                                <tr>
                                    <td>{employee.id}</td>
                                    <td>{employee.name}</td>
                                    <td>{employee.telephone}</td>
                                    <td>{employee.email}</td>
                                    <td>{employee.id_number}</td>
                                    <td className='styled-table-menu'>
                                        <ThreeDotsMenu data={employee} threeSubMenu={threeSubMenu} />
                                    </td>
                                </tr>
                            </tbody>
                        ))}
                        

                    </table>
                    </>

                    }

                    <Pagination 
                        className="custom-mui-pagination"
                        count={pagecount} 
                        page={page} 
                        onChange={handlePageChange} 
                    />

                    <Link to="/dashboard/employeenew" className="nav-link">
                        <Fab className="fab" color="primary" aria-label="add">
                            <AddIcon />
                        </Fab>
                    </Link>
                </section>
            </div>

        // </Layout>


    )
}
export default Employees