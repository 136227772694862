import { useState, useEffect, useCallback, useContext } from "react";
import {useNavigate} from "react-router-dom";

import useAxios from "../../components/useAxios";

import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import EditIcon from '@mui/icons-material/Edit';
import { TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";


// FORM DIALOGUE
// import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { UserContext } from "../../App";



const AccountingSettings = ({clientProfile}) => {
    const navigate = useNavigate();
    const page_size = useContext(UserContext);

    const {fetchData, data, response, error}  = useAxios();

    // const [editMode, setEditMode] = useState(false);
    const [open, setOpen] = useState(false);

    const client = localStorage.getItem('clientID');
    const user = localStorage.getItem('userID');

    // FETCH CURRENCIES
    const { data: currencies } = useAxios({
        url: '/accounting/currencies',
        method: 'get'
    })

    // FETCH CHART OF ACCOUNTS
    const [accounts, setAccounts] = useState([]);
    const { data: accts } = useAxios({
        url: `/accounting/accounts?page_size=${page_size}`,
        method: 'get'
    })

    // FETCH ACCOUNT TYPES
    const { data: accounttypes } = useAxios({
        url: '/accounting/account-types',
        method: 'get'
    })


    const EditSettings = ({ open }) => {
        // const curr = currencies.find(currency => currency.id === clientProfile.currency)
        // const [currency, setCurrency] = useState(currencies.indexOf(curr));
        const debtorsAccType = accounttypes.find(accounttype => accounttype.code === "30200");
        const debtorsAccTypeId = debtorsAccType ? debtorsAccType.id : null;

        const apAccType = accounttypes.find(accounttype => accounttype.code === "40100");
        const apAccTypeId = apAccType ? apAccType.id : null;


        const [currency, setCurrency] = useState(clientProfile.currency);
        const [invoiceNo, setInvoiceNo] = useState(clientProfile.invoice_no);
        const [receiptNo, setReceiptNo] = useState(clientProfile.receipt_no);

        const [debtorsControl, setDebtorsControl] = useState(clientProfile.debtors_control);
        const [apAccount, setApAccount] = useState(clientProfile.ap_account);

        const [currencyError, setCurrencyError] = useState(false);

       
        const handleClose = useCallback(() => {
          setOpen(false);
        }, []);
      
        const handleCurrencyChange = useCallback((event, newValue) => {
            // setCurrency(currencies.indexOf(newValue));
            setCurrency(newValue.id);
            setCurrencyError(false);
        }, []);
      
        const handleArChange = useCallback((event, newValue) => {
            // setCurrency(currencies.indexOf(newValue));
            setDebtorsControl(newValue.id);
            // setCurrencyError(false);
        }, []);

        const handleApChange = useCallback((event, newValue) => {
            setApAccount(newValue.id);
            // setCurrencyError(false);
        }, []);

        const handleInvoiceNoChange = useCallback((e) => {
          setInvoiceNo(e.target.value);
        }, []);

        const handleReceiptNoChange = useCallback((e) => {
            setReceiptNo(e.target.value);
          }, []);
      
        const handleSubmit = (e) => {
        // const handleSubmit = useCallback((e) => {
            e.preventDefault();

            if (currency === '') {
                setCurrencyError(true)
            }
            
            if (currency ) {

                const debtors_control = debtorsControl
                const ap_account = apAccount
                const invoice_no = invoiceNo
                const receipt_no = receiptNo
                const name = clientProfile.name
                const email = clientProfile.email
                const telephone = clientProfile.telephone
                const address = clientProfile.address
                const website = clientProfile.website
                const contact = clientProfile.contact
                const country = clientProfile.country
                const birth_date = clientProfile.birth_date

                const cp = { client, name, address, country, email, telephone, website, birth_date, user, currency, debtors_control, ap_account, contact, invoice_no, receipt_no };
                console.log(cp)

                fetchData({
                    url: `/clientprofiles/${clientProfile.id}/`,
                    method: 'put',
                    data: cp
                })
                .then(()=>{                    
                    if (response.status === 200){
                        // console.log("ID",clientProfile.id)
                        handleClose()
                    }
                })
            } 
        // }, []);
        };

      
        return (
          <div>
            <Dialog className="dashboard-modal" open={open} onClose={handleClose} fullWidth>
              <DialogTitle>Edit Settings</DialogTitle>
              <DialogContent>
                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <Autocomplete
                        // value={currencies[currency]}
                        value={currencies.find(curr => curr.id === currency)}
                        // value={currencies.find(currency => currency.id === clientProfile.currency)}

                        onChange={handleCurrencyChange}
                        options={currencies}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField autoFocus className="custom-input" variant="standard" error={currencyError} fullWidth {...params} label="Currency" />}
                    />
                    <Autocomplete
                        value={accounts.find(account => account.id === debtorsControl)}
                        onChange={handleArChange}
                        // options={accounts.filter(account => account.type === debtorsAccType.id )}
                        options={accounts.filter(account => account.type === debtorsAccTypeId)}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField autoFocus className="custom-input" variant="standard" fullWidth {...params} label="Debtors Control Account" />}
                    />
                    <Autocomplete
                        value={accounts.find(account => account.id === apAccount)}
                        onChange={handleApChange}
                        // options={accounts.filter(account => account.type === apAccType.id )}
                        options={accounts.filter(account => account.type === apAccTypeId )}

                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField autoFocus className="custom-input" variant="standard" fullWidth {...params} label="Creditors Control Account" />}
                    />
                    
                    <TextField
                        value={invoiceNo}
                        onChange={handleInvoiceNoChange}
                        className="custom-input"
                        label="Next Invoice No"
                        fullWidth
                        variant="standard"
                    />

                    <TextField
                        value={receiptNo}
                        onChange={handleReceiptNoChange}
                        className="custom-input"
                        label="Next Receipt No"
                        fullWidth
                        variant="standard"
                    />
                    {/* <button id="submit" className="btn btn-primary form-buttons" type="submit">
                        Save
                    </button> */}
                </form>
              </DialogContent>
              <DialogActions>
                <button onClick={handleClose} id="cancel" className="btn btn-secondary form-buttons">
                  Cancel
                </button>
                <button onClick={handleSubmit} id="submit" className="btn btn-primary form-buttons" type="submit">
                  Save
                </button>
              </DialogActions>
            </Dialog>
          </div>
        );
      };
   
     
    useEffect(() => {
        if (accts.count){
            setAccounts(Array.from(accts.results))
        }
    }, [clientProfile, currencies, accts, accounttypes]); 

    // console.log("DATA",currenciesData)
    // console.log("ACCTS",accounts)


    return (
        <>
            <EditSettings open={open} />
            <div className='dashboard-card' id="settings-card">
                <div className="settings-card-pretitle">
                    Currency
                </div>
                <div className="settings-card-text">
                    {clientProfile.currency_name} ({clientProfile.currency_code})

                </div>
            </div>
            
            <Divider />
            
            <div className='dashboard-card' id="settings-card">
                <div className="settings-card-pretitle">
                    Debtors Control Account
                </div>
                <div className="settings-card-text">
                    {clientProfile.ap_account_name}

                </div>
            </div>
            <div className='dashboard-card' id="settings-card">
                <div className="settings-card-pretitle">
                    Creditors Control Account
                </div>
                <div className="settings-card-text">
                    {clientProfile.ar_account_name}

                </div>
            </div>

            <Divider />

            <div className='dashboard-card' id="settings-card">
                <div className="settings-card-pretitle">
                    Next Invoice Number
                </div>
                <div className="settings-card-text">
                    {/* {client.invoice_no} */}
                    {clientProfile.invoice_no}

                </div>
            </div>
            <Divider />
            <div className='dashboard-card' id="settings-card">
                <div className="settings-card-pretitle">
                    Next Receipt Number
                </div>
                <div className="settings-card-text">
                    {/* {client.receipt_no} */}
                    {clientProfile.receipt_no}

                </div>
            </div>
            <Divider />

            <Fab className="fab" color="primary" aria-label="edit" 
                onClick={()=>{
                    // setEditMode(true)
                    setOpen(true);
                }}>

                <EditIcon />
            </Fab>
            {/* </>
            } */}
        </>

    )


}

export default AccountingSettings;