//import logo from './logo.svg';
import { useEffect, useState, createContext } from 'react';
import {Routes, Route, useLocation, useNavigate} from "react-router-dom";

// import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { useQuery } from '@tanstack/react-query';


import authAxios from './components/authAxios';
import jwt from 'jwt-decode';

import 'bootstrap/dist/css/bootstrap.css';
// import "antd/dist/antd.css";
import "antd/dist/antd.min.css";
import './style.css'


import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Dashboard from './components/Dashboard';
import TransactionNew from './features/accounting/TransactionNew'
// import Dashchart from "./components/Dashchart";  
// import Chartnew from "./components/Chartnew";
import AccountNew from './features/accounting/AccountNew';
import Login from './pages/Login';
import ResetPassword from './pages/ResetPassword';
import ForgotPassword from './pages/ForgotPassword';


import Customers from './features/crm/Customers';
import Customer from './features/crm/Customer';
import CustomerNew from './features/crm/CustomerNew';
import CustomerEdit from './features/crm/CustomerEdit';
import Lead from './components/Lead';
import Leads from './features/crm/Leads';
import LeadNew from './components/LeadNew';

import Suppliers from './features/purchase/Suppliers';

// import Statement from './components/Statement';

// import Bookings from './components/Bookings';
// import BookingNew from './components/BookingNew';
// import CheckIn from './components/CheckIn';
// import CheckOut from './components/Checkout';



// import Properties from './components/Properties';
// import Property from './components/Property';
// import PropertyNew from './components/PropertyNew';
// import RateNew from './components/RateNew';
// import UnitTypeNew from './components/UnitTypeNew';
// import Units from './components/Units';
// import Unit from './components/Unit';
// import UnitNew from './components/UnitNew';

import Accounts from './features/accounting/Accounts';
import Transactions from './features/accounting/Transactions';
import Confirmed from './components/Confirmed'
import Agents from './components/Agents';
import AppSettings from './features/settings/AppSettings';
import Join from './pages/join';

import AgentNew from './components/AgentNew';
import MonthlyReport from './reports/MonthlyReport';
import InvoiceReport from './reports/InvoiceReport';


// import useToken from './components/useToken';

import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material';
import { StyledEngineProvider } from '@mui/material';

import Products from './features/products/Products';
import ProductNew from './features/products/ProductNew'
// import Collections from './features/products/Collections';
import Collections from './features/products/Collections';

// import Orders from './components/Orders';
import Orders from './features/orders/Orders'
import OrderNew from './features/orders/OrderNew';

import Employees from './features/hrm/Employees'
import Employee from './features/hrm/Employee';
import EmployeeEdit from './features/hrm/EmployeeEdit';
import ExpenseTypes from './features/hrm/ExpenseTypes'
import ExpenseTypeNew from './features/hrm/ExpenseTypeNew'
import ExpenseReports from './features/hrm/ExpenseReports'
import ExpenseReportNew from './features/hrm/ExpenseReportNew'


import {defaults} from "chart.js"
import Account from './features/accounting/Account';
// import ProductNew from './components/ProductNew';


// import SupplierNew from './components/SupplierNew';
import SupplierNew from './features/purchase/SupplierNew';
import Supplier from './features/purchase/Supplier';


import Items from './features/inventory/Items';
import Item from './features/inventory/Item';
import InventoryNew from './features/inventory/InventoryNew';
import Locations from './features/inventory/Locations';

import GetOrders from './services/ebay/GetOrders';
// import IncomeStatement from './archive/IncomeStatementOld';
import IncomeStatement from './reports/IncomeStatement';
import Layout from './components/Layout';
import ContactNew from './archive/ContactNewOld';

import EbayAccepted from './features/ebay/EbayAccepted';
import EbayDeclined from './features/ebay/EbayDeclined';
// import EbayGetTransactions from './features/ebay/EbayGetTransactions';
import EbayOffers from './features/ebay/Offers';
import Offer from './features/ebay/Offer';

import AmazonAccepted from './features/amazon/AmazonAccepted';
import AmazonTransactions from './features/amazon/AmazonTransactions';
import PosNew from './features/pos/posNew';
import TransactionsUpload from './features/amazon/AmazonSettlementReport';
import AmazonUploads from './features/amazon/AmazonSettlementReport';
import AmazonSettlementReport from './features/amazon/AmazonSettlementReport';
import AmazonMapping from './features/amazon/AmazonMapping';
import AmazonMappingNew from './features/amazon/AmazonMappingNew';
import EmployeeNew from './features/hrm/EmployeeNew';
// import { Update } from '@mui/icons-material';
import ProductCategories from './features/products/ProductCategories';
import Transaction from './features/accounting/Transaction';
import Product from './features/products/Product';
import BalanceSheet from './reports/BalanceSheet';
import Brands from './features/products/Brands';
import PaymentModes from './features/settings/PaymentModes';
import PaymentModeNew from './features/settings/PaymentModeNew';
import CustomerStatement from './reports/CustomerStatement';
import Quotes from './features/crm/Quotes';
import QuoteNew from './features/crm/QuoteNew';
import Order from './features/orders/Order';
import OrderEdit from './features/orders/OrderEdit';
import EbayMapping from './features/ebay/EbayMapping';
import EbayMappingNew from './features/ebay/EbayMappingNew';
import EbayTransactions from './features/ebay/EbayTransactions';
import Crm from './pages/products/Crm';
import Pos from './pages/products/Pos';
import Statement from './reports/Statement';
import Inventory from './pages/products/Inventory';

//Override MUI global settings
const theme = createTheme({
  typography: {
    fontFamily: 'Poppins',
  },
  // overrides: {
  //   MuiButton: {
  //     root: {
  //       textTransform : 'non'
  //     }
  //   }
  // }
})

//CONTEXT FOR CUSTOM PAGE SIZE SETTING
export const UserContext = createContext();
// const pageSize = 1000


// export const UserContext = createContext({
//   pageSize: 1000,
//   clientProfile: null,
// });



function App() {
  //SET DEFAULT FONT FOR CHART JS
  defaults.font.family = 'Poppins';

  // const queryClient = new QueryClient()


  const location = useLocation();
  const navigate = useNavigate();


  // const [pageSize, setPageSize] = useState(1000);
  const pageSize = 1000
  const page_size = 1000


  const token = localStorage.getItem('accessToken');

  // FETCH CLIENT PROFILE
  const [clientProfile, setClientProfile] = useState(null);
  // const fetchClientProfile = async () => {
  //   //Decode JWT token
  //   const accessToken = token.replace('Bearer ','');
  //   const decodeToken = jwt(accessToken); // decode your token here
  //   const response = await authAxios.get(`/clientprofiles/${decodeToken.client_profile}`);
  //   setClientProfile(response.data)
  // };

  // const { data: clientProfile } = useQuery({
  //   queryKey: ['clientProfile'],
  //   queryFn: async () => {
  //     const response = await authAxios.get(`/clientprofiles/${localStorage.getItem('clientProfile')}`);
  //     return response.data
  //   },
  // });



  // useEffect(()=>{
  //   // UPDATE PROFILE CONTEXT IF USER IS ACCESSING PROTECTED ROUTES
  //   if (location.pathname.startsWith('/dashboard')){
  //     // if (!clientProfile){
  //     //   if (token){
  //     //     fetchClientProfile()
  //     //   }
  //     //   else {
  //     //     navigate('/login');
  //     //   }
  //     // }
  //     if (!token){
  //       navigate('/login');
  //     }
  //     console.log("TOKEN",token)
  //     console.log("PATH",location)

  //   }

  // },[location])


  // console.log("STORAGE",localStorage)


  // useAxiosInterceptor();

  // localStorage.removeItem("accessToken"); 
  // localStorage.removeItem("refreshToken"); 

  
  // const { token, setToken } = useToken();
  // const [token, setToken] = useState([])
  // console.log("token1", token)

  // console.log("WE ARE HERE");

  // const acctkn = localStorage.getItem('accessToken')
  // const [token, setToken] = useState(acctkn)
  // console.log("TOKEN", token)
  // console.log("AT", acctkn)

  // if(!token) {
  //   // return <Login setToken={setToken} />
  //   // console.log("WE ARE STILL HERE");
  //   return <Login />
  // }


  return (
  // <QueryClientProvider client={queryClient}>

    <UserContext.Provider value={{ pageSize, page_size, clientProfile, setClientProfile }}>

    
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
          <Routes>
            <Route path="/" element={ <Home/> } />


            {/* <Route path="/" element={<MainLayout />}> */}
            <Route path="/join" element={ <Join/> } />  
            <Route path="/login" element={ <Login/> } />  
            <Route path="/forgot" element={ <ForgotPassword/> } />  
            {/* <Route path="/reset" element={ <ResetPassword/> } />   */}
            <Route path="/password/reset/:uid/:token" element={ <ResetPassword/> } />  
            {/* <Route path="/password-reset/:uid/:token" element={ <ResetPassword/> } />   */}
            <Route path="/contact" element={ <Contact/> } />  


            <Route path="/products"  > 
              <Route path="crm" element={ <Crm/> }/>
              <Route path="pos" element={ <Pos/> }/>
              <Route path="inventory" element={ <Inventory/> }/>
            </Route>



            <Route path="ebay-accepted" element={ <EbayAccepted /> } /> 
            <Route path="ebay-declined" element={ <EbayDeclined /> } /> 

            <Route path="amazon-accepted" element={ <AmazonAccepted /> } /> 

            <Route path="/dashboard" element={ <Layout/> } > 
              <Route path="stats" element={ <Dashboard/> } /> 
              <Route path="posnew" element={ <PosNew/> } />  
              <Route path="contactnew" element={ <ContactNew/> } />  

              <Route path="leads" element={ <Leads/> } />  
              <Route path="lead/:id" element={ <Lead/> } />  
              <Route path="leadnew" element={ <LeadNew/> } />  
              <Route path="customers" element={ <Customers/> } />  
              <Route path="customer/:id" element={ <Customer/> } />  
              <Route path="customernew" element={ <CustomerNew/> } />  
              <Route path="customeredit/:id" element={ <CustomerEdit/> } /> 
              <Route path="quotes" element={ <Quotes/> } />  
              <Route path="quote-new" element={ <QuoteNew/> } />  


              <Route path="suppliers" element={ <Suppliers/> } />  
              <Route path="suppliernew" element={ <SupplierNew/> } />  
              <Route path="supplier/:id" element={ <Supplier/> } />  

              <Route path="products" element={ <Products/> } />  
              <Route path="product/:id" element={ <Product/> } />  

              <Route path="collections" element={ <Collections/> } />  
              <Route path="product-categories" element={ <ProductCategories/> } />  
              <Route path="brands" element={ <Brands/> } />  


              <Route path="productnew" element={ <ProductNew/> } /> 

              <Route path="locations" element={ <Locations/> } />  
              <Route path="items" element={ <Items/> } />  
              <Route path="item/:id" element={ <Item/> } />   

              <Route path="orders" element={ <Orders/> } />  
              <Route path="ordernew" element={ <OrderNew/> } />  
              <Route path="getorders" element={ <GetOrders/> } /> 
              <Route path="order/:id" element={ <Order/> } />  
              <Route path="orderedit/:id" element={ <OrderEdit/> } /> 


              <Route path="employees" element={ <Employees/> } />  
              <Route path="employee/:id" element={ <Employee/> } />  
              <Route path="employeenew" element={ <EmployeeNew/> } />  
              <Route path="expense-types" element={ <ExpenseTypes/> } />  
              <Route path="expense-type-new" element={ <ExpenseTypeNew/> } />  
              <Route path="expense-reports" element={ <ExpenseReports/> } />  
              <Route path="expense-report-new" element={ <ExpenseReportNew/> } />  

              <Route path="accounts" element={ <Accounts/> } />  
              <Route path="account/:id" element={ <Account/> } />  
              <Route path="accountnew" element={ <AccountNew/> } />  
              <Route path="transactions" element={ <Transactions/> } />  
              <Route path="transaction/:id" element={ <Transaction/> } />  
              <Route path="transactionnew" element={ <TransactionNew/> } />  
              <Route path="confirmed" element={ <Confirmed/> } />  
              <Route path="monthlyreport" element={ <MonthlyReport/> } />  
              <Route path="invoicereport" element={ <InvoiceReport/> } />  
              <Route path="customer-statement" element={ <CustomerStatement/> } />  {/*Re factored to "statement"*/}
              <Route path="statement" element={ <Statement/> } />  


              <Route path="income-statement" element={ <IncomeStatement/> } />  
              <Route path="balance-sheet" element={ <BalanceSheet/> } />  

              {/* <Route path="statement/:id" element={ <Statement/> } />  NOTE: MOVED TO ARCHIVE. NOLONGER IN USE */}

              {/* <Route path="ebay-accepted" element={ <EbayAccepted /> } />  */}
              <Route path="ebay-transactions" element={ <EbayTransactions /> } /> 
              <Route path="ebay-mapping" element={ <EbayMapping /> } /> 
              <Route path="ebay-mapping-new" element={ <EbayMappingNew /> } /> 
              <Route path="ebay-offers" element={ <EbayOffers /> } /> 
              <Route path="offer/:id" element={ <Offer/> } />   

              <Route path="amazon-mapping" element={ <AmazonMapping /> } /> 
              <Route path="amazon-mapping-new" element={ <AmazonMappingNew /> } /> 
              <Route path="amazon-settlement-report" element={ <AmazonSettlementReport /> } /> 
              <Route path="amazon-transactions" element={ <AmazonTransactions /> } /> 

              <Route path="settings" element={ <AppSettings /> } /> 
              <Route path="payment-modes" element={ <PaymentModes/> } />  
              <Route path="payment-mode-new" element={ <PaymentModeNew/> } />  
            </Route>


            {/* <Route path="/agents" element={ <Agents/> } />  
            <Route path="/agentnew" element={ <AgentNew/> } />    */}


          </Routes>
      </StyledEngineProvider>

    </ThemeProvider>

    </UserContext.Provider>



    );
}

export default App;
