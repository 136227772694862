import React from 'react';

import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'


import './style.css';
import App from './App';
import axios from 'axios';


const queryClient = new QueryClient()


// set axios global defaults
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;


const root = createRoot(document.getElementById("root"));


root.render(

    // <React.StrictMode> //****DISABLED BECAUSE WAS DUPLICATING API CALLS IN AXIOS 
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <App />
        </QueryClientProvider>

      </BrowserRouter>
    // </React.StrictMode> */}
);


