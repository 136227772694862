import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";

import authAxios from "../../components/authAxios";

// import { Link } from "react-router-dom";
import Pagination from '@mui/material/Pagination';

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import DashboardHeader from "../../components/DashboardHeader";
import { useMediaQuery } from 'react-responsive'
import { Card, CardContent } from "@mui/material";

import ThreeDotsMenu from "../../components/ThreeDotsMenu";
import ContactNew from "../../components/ContactNew";

const Customers = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 750px)' })
    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const tag = "CUSTOMER"

    const { data: customers, status, isPreviousData } = useQuery({
        queryKey: ['customers', page],
        queryFn: async () => {
          const response = await authAxios.get(`/crm/customers/?page=${page}`);
          setPageCount(response.data.page_count)
          return response.data.results
        },
        keepPreviousData : true,
    });

    const { data: clientProfile } = useQuery({
        queryKey: ['clientProfile'],
        queryFn: async () => {
          const response = await authAxios.get(`/clientprofiles/${localStorage.getItem('clientProfile')}`);
          return response.data
        },
    });

    const handlePageChange = (event, value) => {
        setPage(value);
    };

     const threeSubMenu = [
        {
            id: 1,
            menu: "View",
            url: "/dashboard/customer/",

        }, 
        // {
        //     id: 2,
        //     menu: "Edit",
        //     url: "/dashboard/customeredit/",
        // }
    ];   

    return (
            <div className="dashboard-main">
                <section className="dashboard-content">
                    <DashboardHeader dashboardTitle="Customers" dashboardPreTitle="CRM" />

                    {isMobile? 
                    <>
                    
                    {customers && customers.map(customer => ( 

                    <Card className="dashboard-card" key={customer.id}>
                        
                        <CardContent className="dashboard-card-content">
                            <div className="dashboard-card-title">{customer.name}</div>
                            <div className="dashboard-card-subtitle">{customer.telephone}</div>                            
                            <p>{customer.address}</p>
                            <p>{customer.email}</p>
                        </CardContent>
                        {/* <CardActions>
                            <Button size="small">Booking</Button>
                        </CardActions> */}
                    </Card>
                    ))}

                    </>

                    :
                    // If Device is not mobile, display data using a table
                    <>
                    <table className="styled-table">
                        <thead>
                            <tr >
                                <th>ID</th>
                                <th>Name</th>
                                <th>Telephone</th>
                                {/* <th>Email</th> */}
                                <th>Address</th>
                                {/* <th className="cell-align-right">Quotations</th>
                                <th className="cell-align-right">Orders</th> */}
                                <th className="cell-align-right">Balance</th>
                                <th></th>
                            </tr>
                        </thead>

                        {customers && customers.map(customer => (

                            <tbody key={customer.id}>
                                <tr>
                                    <td>{customer.id}</td>
                                    <td>{customer.name}</td>
                                    <td>{customer.telephone}</td>
                                    {/* <td>{customer.email}</td> */}
                                    <td>{customer.address}</td>
                                    {/* <td>{Number(customer.quotations).toLocaleString('en-UK')}</td>
                                    <td>{Number(customer.orders).toLocaleString('en-UK')}</td> */}

                                    {clientProfile && clientProfile.currency_code? 
                                        <td className="cell-align-right">{Number(customer.balance).toLocaleString('en-UK', { style: 'currency', currency: clientProfile.currency_code })}</td>
                                        :
                                        <td>{Number(customer.balance).toLocaleString('en-UK')}</td>
                                    }
                                    <td className='styled-table-menu'>
                                        <ThreeDotsMenu data={customer} threeSubMenu={threeSubMenu} />
                                    </td>
                                </tr>
                            </tbody>
                        ))}
                        

                    </table>
                    </>

                    }
                    
                    <ContactNew 
                        isModalOpen={isModalOpen}
                        setIsModalOpen={setIsModalOpen}
                        tag={tag}
                        // property={property.id}
                    />

                    <Pagination 
                        className="custom-mui-pagination"
                        count={pagecount} 
                        page={page} 
                        onChange={handlePageChange} 
                    />

                    {/* <Link to="/dashboard/customernew" className="nav-link">
                        <Fab className="fab" color="primary" aria-label="add">
                            <AddIcon />
                        </Fab>
                    </Link> */}
                    <Fab className="fab" color="primary" aria-label="add" onClick={()=>{
                        setIsModalOpen(true)}}
                        >
                        <AddIcon />
                    </Fab>
                </section>
            </div>

        // </Layout>


    )
}
export default Customers