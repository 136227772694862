import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';

import useAxios from "../components/useAxios";


const Statement = () => {

  const location = useLocation();
  const { data, fetchData}  = useAxios();   
  const reference = location.state.reference;
  const tag = location.state.tag;

  // const id = location.state.id;

  const fetchReport = () => {

    console.log("REFERENCE",location.state)

    fetchData({
        // url: `/accounting/customer-statement/?reference=${reference}&tag=${tag}`,
        url: `/accounting/statement/?reference=${reference}&tag=${tag}`,
        method: 'get',
        responseType: 'blob'
    })
  }


  useEffect(()=>{
      fetchReport()

      if (data.size){
          const filename = 'statement.pdf'
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
      }
  },[data.size])

  console.log("DATA",data)
  console.log("Blob",Blob)

}

export default Statement;