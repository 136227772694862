
import { useState, useEffect, useRef } from 'react';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';


import Pagination from '@mui/material/Pagination';
import SyncIcon from '@mui/icons-material/Sync';
import Fab from '@mui/material/Fab';

import authAxios from '../../components/authAxios';
import useEbayToken from './useEbayToken';
import DashboardHeader from '../../components/DashboardHeader';
import ThreeDotsMenu from "../../components/ThreeDotsMenu";
import UserAlerts from "../../components/UserAlerts";
import Loading from '../../components/Loading';


import { format } from 'date-fns';

// const syncTransactions =  async (ebayConfig) => {
//     // const { ebayConfig } = useEbayToken();
//     // console.log("TESTING",ebayConfig)
//     // const { data: txns } = useQuery({
//     //     queryKey: ['eBayTxns'],
//     //     queryFn: async () => {
//         const response = await authAxios.get(`/ebay/get-transactions?client=${ebayConfig.client}`);
//         console.log("TXNS",response.data)
//         return response.data
//     //     },
//     // });
// }


const EbayTransactions = () => {
    const { ebayConfig } = useEbayToken();
    // const [transactions, setTransactions] = useState([]);
    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const errorRef = useRef(null);

    const queryClient = useQueryClient();

    const { data: transactions } = useQuery({
        queryKey: ['eBayTransactions', page],
        queryFn: async () => {
            const response = await authAxios.get(`/ebay/ebay-transactions/?page=${page}`);
            // console.log("TXNS",response.data)
            setPageCount(response.data.page_count)
            return response.data.results
        },
    });

    // const syncTransactions =  async () => {
    //     const response = await authAxios.get(`/ebay/get-transactions?client=${ebayConfig.client}`);
    //     console.log("TXNS",response.data)
    //     return response.data
    // }

    const mutation = useMutation({
        mutationFn: (txn) => {
            setLoading(true)
            return authAxios.post("/ebay/get-transactions/", txn);
        },
        onError: (error, variables, context) => {
            setLoading(false)
            if (error.response.data.error){
                // If it's an array, display each error message
                if (Array.isArray(error.response.data.error)) {
                    error.response.data.error.map((errorMessage) => (
                        setError(errorMessage)
                    ));
                } else {
                    // Handle single error message
                    setError(error.response.data.error); 
                }
            } else {
                // Fallback to Standard error message
                setError(error.message); 
            }      
        },
        onSuccess: (result) => {
            setLoading(false)
            queryClient.invalidateQueries(['eBayTransactions']);
            // navigate('/dashboard/ebay-mapping/')
        },
    })

    const syncTransactions =  async () => {
        // const response = await authAxios.get(`/ebay/get-transactions?client=${ebayConfig.client}`);
        // console.log("TXNS",response.data)
        // return response.data
        const client=ebayConfig.client
        const txn = {client}
        mutation.mutate(txn)
    }

    //Pagination
    const handlePageChange = (event, value) => {
        setPage(value);
    };


    const threeSubMenu = [
        {
            id: 1,
            menu: "View",
            // url: "/item/",
            url: "/dashboard/item/",
        }, 
    ];

    const convertDateTime = (myDateTime) => {
        return format(new Date(myDateTime), 'dd-MM-yyyy HH:mm');
    };

    useEffect(()=>{
        if (error){
            errorRef.current.scrollIntoView();  
        }            
    },[error])


    return(
        <div className="dashboard-main">
            <section className="dashboard-content">
                <div ref={errorRef}>
                    <UserAlerts error={error}  />
                </div>
                <DashboardHeader dashboardTitle="Account Transactions" dashboardPreTitle="eBay" />

                {loading?

                    <Loading loading={loading}  />
                :
                <>
                <table className="styled-table">
                    <thead>
                        <tr >
                            <th>No</th>
                            <th>Date</th>
                            <th>Reference</th>
                            <th>Type</th>
                            <th>Currency</th>
                            <th>Amount</th>
                            <th></th>
                        </tr>
                    </thead>



                    {transactions && transactions.map(transaction => (

                    // {transactions && transactions.map(transaction => (
                        <tbody key={transaction.id}>
                            <tr>
                                {/* <td>{transaction.id}</td> */}
                                <td>{transaction.id}</td>
                                <td>{convertDateTime(transaction.date)}</td>
                                <td>{transaction.reference}</td>
                                <td>{transaction.transaction_type}</td>
                                <td>{transaction.currency_code}</td>
                                {/* <td>{Number(transaction.amount).toLocaleString()}</td> */}
                                <td>{Number(transaction.amount).toLocaleString('en-UK')}</td>

                                <td className='styled-table-menu'>
                                    <ThreeDotsMenu data={transaction} threeSubMenu={threeSubMenu} />
                                </td>
                            </tr>
                        </tbody>
                    ))} 
                    
                </table>

                <Pagination 
                    className="custom-mui-pagination"
                    count={pagecount} 
                    page={page} 
                    onChange={handlePageChange} 
                />
                </>
                }



                <Fab className="fab" 
                    color="primary" 
                    aria-label="add" 
                    onClick={syncTransactions}
                >
                    <SyncIcon/>
                </Fab>
            </section>
        </div>

    )

}

export default EbayTransactions