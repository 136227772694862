import React from "react"
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import axios from 'axios';
// import useAxios from "../components/useAxios";
// import useAxiosInterceptor from "../components/useAxiosInterceptor";

import Header from "../components/Header";
import { IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material';
import { RadioGroup } from "@mui/material";
import { Radio } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import { FormControl } from "@mui/material";
import { FormLabel } from "@mui/material";

import NumberFormat from "react-number-format";
import FooterSlim from "../components/FooterSlim";
import UserAlerts from "../components/UserAlerts";
import CustomSnackBar from "../components/CustomSnackBar";
// import { Visibility, VisibilityOff } from "@mui/icons-material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

function Join() {
    //Axios
    // const { err, fetchData}  = useAxios();
    // const {response, error } = useAxiosInterceptor();
    // const [open, setOpen] = useState(false);

    //  Fetch Products 
    const [products, setProducts] = useState([]);
    useEffect(() => {

    axios.get('/products/')
    .then(res => {
        // console.log("res",res);
        console.log("res.data", res.data);
        setProducts(res.data)
        // navigate(from, { replace: true });
        })
    }, []); 


    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    // const [password, setPassword] = useState('');
    const [prodCode, setProdCode] = useState('');
    const [units, setUnits] = useState('');
    const [error, setError] = useState('');

    const [nameError, setNameError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [mobileError, setMobileError] = useState(false)
    const [companyError, setCompanyError] = useState(false)
    const [prodCodeError, setProdCodeError] = useState(false)
    const [unitsError, setUnitsError] = useState(false)

    //Handle snackbar for success feedback to the user
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const handleSnackBarClose = () => {
    // console.log("DRAWER CLOSE")
    setSuccess(false)
    };

    const [response, setResponse] = useState('');
    useEffect(() => {
        // console.log("RESPONSE",response.status)
        // if (response.status === 201){
        if (response.status === 201 && success === false){
            // navigate(from, { replace: true });
            navigate("/login/");


        }    
    }, [ response.status, success]); 


    const [values, setValues] = useState({
        // amount: '',
        password: '',
        // weight: '',
        // weightRange: '',
        showPassword: false,
    });

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({
        ...values,
        showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    const handleSubmit = (e, data) => {
        e.preventDefault();
        
        if (name === '') {
        setNameError(true)
        }
        else{
        setNameError(false)
        }

        if (email === '' ) {
            setEmailError(true)
        }

        if (mobile === '' ) {
        setMobileError(true)
        }

        if (company === '' ) {
        setCompanyError(true)
        }

        if (prodCode === '' ) {
        setProdCodeError(true)
        }

        if (name && email && company) {

            const prodChoice = products.find(function(product) {
                return product.code === prodCode;
            });
            const product = prodChoice.id

            // console.log("PASS", values.password)
            const password = values.password

            const telephone = mobile.replace(/[()" "]/g,"")


            const registration = { email, password, name, company, telephone,  product, units };

            console.log("REGISTRATION", registration)

            // fetchData({
            //   url: '/saasleads/',
            //   method: 'post',
            //   data: lead
            // })

            axios.post('/register/', registration )
            .then(res => {
                // console.log("res",res);
                // console.log("res.data", res.data);
                setResponse(res)
                setSuccess(true)
                setSuccessMessage("Registration successful. Please login")
                // console.log("RESPONSE",response.status)
                
                // navigate(from, { replace: true });

            })
            .catch(function (error) {
                if (error.response.status === 400 ){
                setError(error.message + " - " + error.response.request.response);
                }
                else if (error.response.status === 401){
                setError(error.response.status +" " + error.response.statusText + " - " + error.response.data.detail);
                }
                else {
                setError(error.response.status + " " + error.response.statusText);
                }
                // setError(error.message)
                // console.log("error", error.response);
                console.log("error", error);

            });
            

        }
    }


    return (
        <div className="container-fluid">

        <div className="container">
          <Header />

          {/* <div className="container-auth"> */}

          <div className="landing-form-container">
            <div className="landing-form-bg-purple">
              <div className="auth-img-title">
                Join Today!
              </div>
              <div className="auth-img-subtitle">
                #1 Accommodation Management Platform
              </div>
            </div>
            <div className="landing-form">


              {/* User Alerts */}
              {/* <UserAlerts error={error} /> */}
              <UserAlerts error={error} />


              <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            
                {/* <div className='auth-form-brand'>
                  <img src={logo} alt="brand logo"/>  

                </div> */}

      

                <TextField
                  onChange={e => {
                    setEmailError(false)
                    setEmail(e.target.value)
                  }}
                  className="custom-input"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  // autoComplete="email"
                  autoFocus
                  error={emailError}
                />

                <FormControl                   
                    fullWidth
                    // margin="normal"
                    className="custom-input"

                >
                <InputLabel htmlFor="password">Password</InputLabel>
                <OutlinedInput
                // <TextField
                    // onChange={e => {
                    //     setNameError(false)
                    //     setName(e.target.value)
                    // }}
                    className="custom-outlinedinput"
                    required
                    //   fullWidth
                    id="password"
                    //   type="password"
                    type={values.showPassword ? 'text' : 'password'}
                    value={values.password}
                    onChange={handleChange('password')}
                    // name="password"
                    // autoFocus
                    // error={nameError}
                    endAdornment={
                        <InputAdornment 
                        position="end"
                        // className="custom-endoadornment"

                        >
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                className="custom-iconbutton"
                            >
                                {values.showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    label="Password"
                />
                </FormControl>


                <TextField
                  onChange={e => {
                    setNameError(false)
                    setName(e.target.value)
                  }}
                  className="custom-input"
                  margin="normal"
                  required
                  fullWidth
                  id="name"
                  label="Your Name"
                  type="text"
                  name="name"
                  // autoComplete="email"
                //   autoFocus
                  error={nameError}
                />

                <TextField
                  onChange={e => {
                    setCompanyError(false)
                    setCompany(e.target.value)
                  }}
                  className="custom-input"
                  margin="normal"
                  required
                  fullWidth
                  id="name"
                  label="Business Name"
                  type="text"
                  name="name"
                  // autoComplete="email"
                //   autoFocus
                  error={companyError}
                />

                <NumberFormat 
                  onChange={(e) => {
                    setMobileError(false)
                    setMobile(e.target.value)
                  }}
                  className="custom-input"
                  fullWidth
                  label="Mobile" 
                  variant="outlined" 
                  customInput={TextField}
                  format="### (###) ######" mask="_"
                  // thousandSeparator={true} 
                  // prefix={'KES '} 
                  required
                  error={mobileError}
                />
              
                <FormControl 
                  className="custom-formcontrol" 
                  // error={productError}
                  error={prodCodeError}
                  required
                  onChange={(e) => {
                    setProdCodeError(false)
                    setProdCode(e.target.value)
                    // setProductError(false)
                    // setProduct(e.target.value)
                  }}
                >
                  <FormLabel id="demo-radio-buttons-group-label">Type of Business</FormLabel>
                  <RadioGroup
                    // row
                    // aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    defaultChecked=""
                    // onChange={(e) => {
                    //   setProdCodeError(false)
                    //   setProdCode(e.target.value)
                    // }}
                    // className="custom-input"
                  >
                    <FormControlLabel className="custom-radio" value="10" control={<Radio />} label="AirBnB/Vacation Homes"/>
                    <FormControlLabel className="custom-radio" value="20" control={<Radio />} label="Serviced Apartments" />
                    <FormControlLabel className="custom-radio" value="30" control={<Radio />} label="Hotels and Lodges" />
                    {/* <FormControlLabel className="custom-radio" value="" control={<Radio />} label="Other" /> */}
                  </RadioGroup>
                </FormControl>

                <NumberFormat 
                  onChange={(e) => {
                    setUnitsError(false)
                    setUnits(e.target.value)} 
                  }
                  className="custom-input"
                  fullWidth
                  label="Number of Units" 
                  variant="outlined" 
                  customInput={TextField}
                  // format="254 (###) ######" mask="_"
                  thousandSeparator={true} 
                  // prefix={'KES '} 
                  required
                  error={unitsError}

                />

                
                {/* <TextField className="custom-input"
                    onChange={(e) => {
                      setMessageError(false)
                      setMessage(e.target.value)
                    }}
                    label="Message"
                    variant="outlined"
                    multiline
                    rows={3}
                    fullWidth
                    required
                    error={messageError}
                /> */}

                <div>
                  <button className='btn btn-primary' type="submit">Register</button>
                </div>
              </form>
            </div>
          </div>

          <CustomSnackBar success={success} successMessage={successMessage} handleSnackBarClose={handleSnackBarClose}/>


        </div>

        <div className="footer-bg-lightblue">
            {/* <div className="container"> */}
              <FooterSlim />
              {/* <Footer /> */}

            {/* </div> */}
          </div>

      </div>

    )
  }


 export default Join;
