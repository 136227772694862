import React from "react"
// import hero from '../assets/hero.png'
import hero from '../assets/zidi-hero.png'


// import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";


function Hero() {
    const navigate = useNavigate();

    return (
        <section className="hero">
            <div className="hero-title">
                {/* #1 ERP Software for Growing Businesses */}
                Enterprise software for growing businesses

            </div>
            <div className="hero-subtitle">
                {/* zidi automates and streamlines every aspect of your growing and changing business including customer relationships, inventory, operations and financials. */}
                {/* Our platform is designed to streamline and automate your business operations, allowing you to focus on growth and success. With our easy-to-use interface and robust features, you can manage finances, inventory, sales, and more all in one platform. */}
                Our unified enterprise management platform provides a full suite of products that help small and medium sized organisations to manage and accurately track in realtime moving parts within the business including sales, inventory, customers, people and finances.
            </div>
            {/* <button className="btn btn-primary" onClick={() => navigate("/contact")}>Learn More</button>  */}
            <button className="btn btn-primary" onClick={() => navigate("/")}>Learn More</button> 


            <div className="hero-body">               
                <div className="hero-img">
                    <img src={hero} alt="hero"/> 
                </div> 
            </div>
        </section>
    ) 
}

export default Hero