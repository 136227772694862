import React from "react";
import { useState, useEffect } from "react";
// import axios from "axios";
import useAxios from "../../components/useAxios";
// import authAxios from "./authAxios";

import { createSearchParams, Link } from "react-router-dom";
// import { useNavigate } from "react-router-dom";


// import Pagination from "./Pagination";
import Pagination from '@mui/material/Pagination';


// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import DashboardHeader from "../../components/DashboardHeader";
import { useMediaQuery } from 'react-responsive'
import { Card, CardContent } from "@mui/material";

import Layout from "../../components/Layout";
import ThreeDotsMenu from "../../components/ThreeDotsMenu";
// import FormDrawer from "./FormDrawer";


function Relisting() {
    const isMobile = useMediaQuery({ query: '(max-width: 750px)' })
    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();
    // const { response } = useAxiosInterceptor();

    const abortController = new AbortController()

    const { data, fetchData}  = useAxios();   


    // FETCH CUSTOMERS 
    const [customers, setCustomers] = useState([]);

    // const fetchCustomers = () => {
    //     fetchData({
    //         url: `/crm/customers/?page=${page}`,
    //         method: 'get',
    //         signal: abortController.signal
    //       })
    // }

    // FETCH OFFERS 
    const [relists, setRelists] = useState([]);
    const fetchRelists = () => {
        fetchData({
            url: `/ebay/relist/?page=${page}`,
            method: 'get'
          })
    }

    const handlePageChange = (event, value) => {
        setPage(value);
    };

     const threeSubMenu = [
        {
            id: 1,
            menu: "View",
            url: "/dashboard/offer/",

        }, 
        // {
        //     id: 2,
        //     menu: "Edit",
        //     url: "/customeredit/",
        // }
    ];

    useEffect (() => {
        // fetchCustomers()
        fetchRelists()
        // console.log("CUSTOMERS",data)
        if (data.page){
            setPageCount(data.page_count)
            // setCustomers(Array.from(data.results))
            setRelists(Array.from(data.results))
        }

        return () => {
            abortController.abort();
        };
    },[ page, data.page ])
    

    return (
        <>
            {isMobile? 
            <>
            
            {relists && relists.map(relist => ( 

            <Card className="dashboard-card" key={relist.id}>
                
                <CardContent className="dashboard-card-content">
                    <div className="dashboard-card-title">{relist.name}</div>
                    <div className="dashboard-card-subtitle">{relist.telephone}</div>                            
                    <p>{relist.address}</p>
                    <p>{relist.email}</p>
                    {/* <p>Gender: {customer.gender}</p> */}
                    {/* <Pillchip label={booking.status_name} /> */}
                </CardContent>
                {/* <CardActions>
                    <Button size="small">Booking</Button>
                </CardActions> */}
            </Card>
            ))}

            </>

            :
            // If Device is not mobile, display data using a table
            <>
            <table className="styled-table">
                <thead>
                    <tr >
                        <th>ID</th>
                        <th>Offer</th>
                        <th>Timestamp</th>
                        <th>Relist Quantity</th>
                        <th>Available Quantity</th>
                        {/* <th>Status</th> */}
                        <th></th>
                    </tr>
                </thead>

                {relists && relists.map(relist => (

                    <tbody key={relist.id}>
                        <tr>
                            <td>{relist.id}</td>
                            <td>{relist.ebay_offer_id}</td>
                            <td>{new Date(relist.created_at).toLocaleString('en-UK', 
                                {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    second: 'numeric'
                                })
                                .replace('at', '')
                                }
                            </td>

                            <td>{relist.quantity}</td>
                            <td>{relist.available_quantity}</td>
                            {/* <td>{offer.status}</td> */}
                            {/* <td className='styled-table-menu'>
                                <ThreeDotsMenu data={offer} threeSubMenu={threeSubMenu} />
                            </td> */}
                        </tr>
                    </tbody>
                ))}
                

            </table>
            </>

            }
        </>
    )
}
export default Relisting