// import React from "react";
// import useAxios from "./useAxios";
// import useAxiosInterceptor from '../components/useAxiosInterceptor';

import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

import authAxios from "./authAxios";

import { Autocomplete } from "@mui/material";
// import { FormControl } from "@mui/material";
// import { MenuItem } from "@mui/material";
// import { Radio } from "@mui/material";
// import { RadioGroup } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import NumberFormat from "react-number-format";
import { TextField } from "@mui/material";
// import { Select } from "@mui/material";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import moment from 'moment';

// ANT DESIGN
import { message, Upload } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Modal, Radio } from 'antd';



// import Sidebar from "./Sidebar";
import UserAlerts from "./UserAlerts";
import Layout from "./Layout";
import DashboardHeader from "./DashboardHeader";


const ContactNew = ({ tag, isModalOpen, setIsModalOpen }) => {
    // const {response, error, fetchData}  = useAxios();
    const [error, setError] = useState('');
    const errorRef = useRef(null);

    const queryClient = useQueryClient();


    // FETCH COUNTRIES
    // const { data: countries } = useAxios({
    //     url: '/countries',
    //     method: 'get'
    // })

    // const { data: contacts } = useAxios({
    //     url: '/contacts',
    //     method: 'get'
    // })

    // Fetch Lead Sources
    // const { data: leadsources } = useAxios({
    //     url: '/leadsources',
    //     method: 'get'
    // })


    const { data: leadsources } = useQuery({
        queryKey: ['leadsources'],
        queryFn: async () => {
          const response = await authAxios.get('/leadsources');
          return response.data
        },
    });

    const { data: countries } = useQuery({
        queryKey: ['countries'],
        queryFn: async () => {
          const response = await authAxios.get('/countries');
          return response.data
        },
    });

    const { data: contacts } = useQuery({
        queryKey: ['contacts'],
        queryFn: async () => {
          const response = await authAxios.get('/contacts');
          return response.data
        },
    });

    const navigate = useNavigate();
    const location = useLocation();

    // const tag = location.state.tag

    // const contactHeader = useRef("");
    // if (tag==="LEAD"){
    //     contactHeader.title="Add Lead"
    //     contactHeader.pretitle="CRM"
    //     contactHeader.showLeadSources=true
    // }
    // if (tag==="LOCATION"){
    //     contactHeader.title="Add Location"
    //     contactHeader.pretitle="Inventory"
    // }

    

    // console.log(contactHeader)

    // const [dt, setDt] = useState(new Date());
    const [dt, setDt] = useState(null);


    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [website, setWebsite] = useState('');
    const [id_type, setId_type] = useState('');
    const [id_number, setId_number] = useState('');
    const [id_copy, setIdCopy] = useState(null);
    const [id_files, setIdFiles] = useState([]);
    const [gender, setGender] = useState('');
    const [type, setType] = useState('10');
    const user = localStorage.getItem('userID');
    const client = localStorage.getItem('clientID');
    const [memo, setMemo] = useState('');
    const [source, setSource] = useState('');
    const [locationType, setLocationType] = useState('');


    const [nameError, setNameError] = useState(false)
    const [mobileError, setMobileError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [idNumberError, setIdNumberError] = useState(false)
    const [addressError, setAddressError] = useState(false);
    const [cityError, setCityError] = useState(false);

    const [isCompany, setIsCompany] = useState(false);
    const [isPerson, setIsPerson] = useState(false);


    // const contactRef = useRef("");

    const contactRef = useRef({
        title: 'initialValue1',
        pretitle: 'initialValue2',
        showLeadSources: false,
        showLocationTypes: false
      });
    contactRef.showContactType=true

    if (tag==="OWNER"){
        contactRef.title="Add Property Owner"
        contactRef.pretitle="CRM"
        contactRef.showLeadSources=false
    }
    if (tag==="LEAD"){
        contactRef.title="Add Lead"
        contactRef.pretitle="CRM"
        contactRef.showLeadSources=true
    }
    if (tag==="LOCATION"){
        contactRef.title="Add Location"
        contactRef.pretitle="Inventory"
        contactRef.showContactType=false
        contactRef.showLocationTypes=true

        // setType('20')
    }

    if (tag==="TENANT"){
        contactRef.current.title="Add Tenant"
        contactRef.current.pretitle="CRM"
        contactRef.current.showContactType=false
        contactRef.current.showLocationTypes=true

        // setType('20')
    }

    if (tag==="CUSTOMER"){
        contactRef.current.title="Add Customer"
        contactRef.current.pretitle="CRM"
        contactRef.current.showContactType=false
        contactRef.current.showLocationTypes=true

        // setType('20')
    }


    // const handleCustomerType = (e) => {
    //     // setType(e.target.value)
    //     setType(locationTypes.filter(type => type.code === e.target.value))
    // }

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleCustomerType = (e) => {
        setType(e.target.value)
        if (e.target.value==="10"){
            // console.log("TRUE")
            setIsPerson(true)
        }
        else {
            console.log("FALSE")
            setIsPerson(false)
        }
    }



    const handleIdNumber = (e) => {
        if (e.target.value.length>9){
            setIdNumberError(true)
        }
        else{
            setIdNumberError(false)
            setId_number(e.target.value)
        }
    }


    // IMAGE UPLOAD ANT DESIGN
    const [imageUrl, setImageUrl] = useState();
    const [loading, setLoading] = useState(false);
    // const [fileList, setFileList] = useState([]);
    var fileList = []


    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const beforeUpload = (file) => {
        // setFileList([...fileList, file]);


        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      
        if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
        }
      
        const isLt2M = file.size / 1024 / 1024 < 2;
      
        if (!isLt2M) {
          message.error('Image must smaller than 2MB!');
        }
      
        return isJpgOrPng && isLt2M;
      };
    
    // const handleUpload = (info) => {

    const handleUpload = (info) => {
        // console.log("UPLOAD 1",info.fileList[0].originFileObj)
        
        console.log("UPLOAD",info.fileList)
        console.log("ARRAY LENGTH 2",(info.fileList).length)
        setIdCopy(info.file)
        for(let i= 0; i < info.fileList.length; i++){
            fileList.push(info.fileList[i].originFileObj)
        }

        setIdFiles(fileList)

        if (info.file.status === 'uploading') {
          setLoading(true);
          return;
        }
    
        if (info.file.status === 'done') {
          // Get this url from response in real world.
          getBase64(info.file.originFileObj, (url) => {
            setLoading(false);
            setImageUrl(url);
          });
        }
    };

    const uploadButton = (
        <div>
          {loading ? <LoadingOutlined /> : <PlusOutlined />}
          <div 
            style={{
              marginTop: 8,
            }}
          >
            Upload
          </div>
        </div>
    );

    const mutation = useMutation({
        mutationFn: (contact) => {
            // return authAxios.post("/contacts/", supplier);
            return authAxios.post('/contacts/', contact, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
            });
        },
        onError: (error, variables, context) => {
            console.log("ERROR",error)

            if (error.response.data.error){
                // If it's an array, display each error message
                if (Array.isArray(error.response.data.error)) {
                    error.response.data.error.map((errorMessage) => (
                        setError(errorMessage)
                    ));
                } else {
                    // Handle single error message
                    setError(error.response.data.error); 
                }
            } else {
                // Fallback to Standard error message
                setError(error.message); 
            }      
        },
        onSuccess: (result) => {
            // queryClient.invalidateQueries(['owners']);
            // navigate('/dashboard/owners/');
            if (tag==="LEAD"){
                queryClient.invalidateQueries(['leads']);
                // navigate('/dashboard/leads');
            }
            if (tag==="LOCATION"){
                queryClient.invalidateQueries(['locations']);
                // navigate('/dashboard/locations');
            }
            if (tag==="OWNER"){
                queryClient.invalidateQueries(['owners']);
                // navigate('/dashboard/owners');
            }
            if (tag==="CUSTOMER"){
                queryClient.invalidateQueries(['customers']);
                // navigate('/dashboard/owners');
            }
            setIsModalOpen(false)

        },
      })



    const handleSubmit = (e) => {
        e.preventDefault();

        // console.log(name, institution, email)

        if (name === '') {
            setNameError(true)
        }

        if (mobile === '' ) {
            setMobileError(true)
        }

        if (email === '' ) {
            setEmailError(true)
        }

        if (address === '' ) {
            setAddressError(true)
        }

        if (city === '' ) {
            setCityError(true)
        }

        // if (name && mobile && email && address && city ) {
        if (name) {

            if (tag==="LOCATION"){
                setType('20')
            }

            const telephone = mobile.replace(/[()" "]/g,"")

            //Change the format of birth date before saving
            let birth_date = "";
            if (dt){
                birth_date = moment(dt).format("YYYY-MM-DD")
            }

            const location_type = locationType

            const contact = { type, client, name, address, city, country, email, website, telephone, id_type, id_number, gender, birth_date, user, memo, source, location_type, tag, id_files };
            console.log(contact)
            mutation.mutate(contact)


            // fetchData({
            //     url: '/contacts/',
            //     method: 'post',
            //     data: contact,
            //     headers: {"Content-Type" : "multipart/form-data"} //REQUIRED FOR FILE UPLOADS
            //   })
            
        } 
    
    }

    useEffect(()=>{
        if (error){
            errorRef.current.scrollIntoView();  
        }            
    },[error])


    return(
        <>
        {isModalOpen && 
            <Modal className="dashboard-modal" title={contactRef.current.title} visible={isModalOpen} open={isModalOpen} onOk={handleSubmit} onCancel={handleCancel}>
                <div className="dashboard-form">
  
                {/* <UserAlerts error={error} /> */}
                <div ref={errorRef}>
                    <UserAlerts error={error}  />
                </div>
                {/* <DashboardHeader dashboardTitle={contactRef.title} dashboardPreTitle={contactRef.pretitle} /> */}

                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    {tag === "TENANT" &&
                    <Autocomplete
                        onChange={(event, newValue) => {
                            setSource(newValue.id);
                            }}
                        options={leadsources}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField className="custom-input" fullWidth {...params} label="Property"  /> }
                    />
                    }

                    {contactRef.showLocationTypes===true &&
                        // <RadioGroup
                        //     row
                        //     aria-labelledby="demo-row-radio-buttons-group-label"
                        //     name="row-radio-buttons-group"
                        //     defaultChecked=""
                        //     onChange={(e)=>{
                        //         setLocationType(e.target.value)
                        //     }}
                        // >
                        //     <FormControlLabel className="custom-radio" value="10" control={<Radio />} label="Warehouse" />
                        //     <FormControlLabel className="custom-radio" value="20" control={<Radio />} label="Retail Store" />
                        //     <FormControlLabel className="custom-radio" value="30" control={<Radio />} label="Branch/Office" />

                        // </RadioGroup>
                        <Radio.Group 
                            size="large" 
                            defaultValue={10} 
                            // onChange={handleCustomerType} 
                            onChange={(e)=>{
                                setLocationType(e.target.value)
                            }}
                            className="custom-input">
                            <Radio.Button  value={10}>Warehouse</Radio.Button>
                            <Radio.Button value={20}>Retail Store</Radio.Button>
                            <Radio.Button value={30}>Retail Store</Radio.Button>
                        </Radio.Group>
                    }

                    {contactRef.showContactType===true &&

                        // <RadioGroup
                        //     row
                        //     aria-labelledby="demo-row-radio-buttons-group-label"
                        //     name="row-radio-buttons-group"
                        //     defaultChecked=""
                        //     onChange={handleCustomerType}
                        // >
                        //     <FormControlLabel className="custom-radio" value="10" control={<Radio />} label="Individual" />
                        //     <FormControlLabel className="custom-radio" value="20" control={<Radio />} label="Company" />
                        // </RadioGroup>
                        <Radio.Group size="large" defaultValue={10} onChange={handleCustomerType} className="custom-input">
                            <Radio.Button  value={10}>Individual</Radio.Button>
                            <Radio.Button value={20}>Company</Radio.Button>
                        </Radio.Group>
                    }

                    <TextField className="custom-input"
                        onChange={(e) => {
                            setNameError(false)
                            setName(e.target.value)
                        }}
                        label="Name" 
                        variant="outlined" 
                        fullWidth
                        required
                        error={nameError}
                    />

                    <NumberFormat 
                        onChange={(e) => {
                            // setMobileError(false)
                            setMobile(e.target.value)
                        }}
                        className="custom-input"
                        fullWidth
                        label="Telephone" 
                        variant="outlined" 
                        customInput={TextField}
                        format="### (###) #######" mask="_"
                        // required
                        // error={mobileError}
                    />


                    <TextField className="custom-input"
                        onChange={(e) => {
                            // setEmailError(false)
                            setEmail(e.target.value)
                        }}
                        label="Email" 
                        variant="outlined" 
                        fullWidth
                        // required
                        // error={emailError}
                    />

                    {type==="20" &&

                        <TextField className="custom-input"
                            onChange={(e) => setWebsite(e.target.value)}
                            label="Website" 
                            variant="outlined" 
                            fullWidth
                            // required
                            // error={mobileError}
                        />  
                    }

                    <TextField className="custom-input"
                        onChange={(e) => {
                            // setAddressError(false)
                            setAddress(e.target.value)
                        }}
                        label="Physical Address" 
                        variant="outlined" 
                        fullWidth
                        // required
                        // error={addressError}
                    />  

                    <TextField className="custom-input"
                        onChange={(e) => {
                            // setCityError(false)
                            setCity(e.target.value)
                        }}
                        label="City/Town" 
                        variant="outlined" 
                        fullWidth
                        // required
                        // error={cityError}
                    />  

                    {/* <Autocomplete
                        onChange={(event, newValue) => {
                            setCountry(newValue.id);
                            console.log("VALUE", newValue.id)
                            }}
                        options={countries}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField className="custom-input" fullWidth {...params} label="Country"  /> }
                    /> */}

                    
                {/* </div> */}

                {/* {isPerson && tag !=="OWNER" &&
                <>

                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        defaultChecked=""
                        onChange={(e) => setGender(e.target.value)}
                        className="custom-input"
                    >
                        <FormControlLabel className="custom-radio" value="F" control={<Radio />} label="Female" />
                        <FormControlLabel className="custom-radio" value="M" control={<Radio />} label="Male" />
                    </RadioGroup>

                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DesktopDatePicker
                            label="Date of Birth"
                            value={dt}
                            onChange={(newDate) => {
                                setDt(moment(newDate).format("YYYY-MM-DD"));
                                }}
                            renderInput={(params) => <TextField fullWidth required className="custom-input" {...params} />}
                        />
                    </LocalizationProvider>
                    </>

                    } */}
                    {/* <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        defaultChecked=""
                        onChange={(e) => setId_type(e.target.value)}
                        className="custom-input"
                    >
                        <FormControlLabel className="custom-radio" value="10" control={<Radio />} label="Citizen ID" />
                        <FormControlLabel className="custom-radio" value="20" control={<Radio />} label="Passport" />
                    </RadioGroup>

                    <TextField className="custom-input"
                        onChange={handleIdNumber}
                        label="ID/Passport Number" 
                        helperText="Maximum number of characters is 9" 
                        variant="outlined" 
                        fullWidth
                        required
                        error={idNumberError}
                    /> */}

                    {/* <div className="grouped-fields-light"> */}


                    {contactRef.showLeadSources===true &&
                    <Autocomplete
                        onChange={(event, newValue) => {
                            setSource(newValue.id);
                            }}
                        options={leadsources}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField className="custom-input" fullWidth {...params} label="Lead Source"  /> }
                    />
                    }

                    {/* <TextField className="custom-input"
                            onChange={(e) => setMemo(e.target.value)}
                            label="Memo"
                            variant="outlined"
                            multiline
                            rows={3}
                            fullWidth
                        /> */}

                    {/* </div> */}
                    

                    {/* <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader custom-upload-card custom-input"
                        beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
                        onChange={handleUpload}
                        maxCount={2}
                        >
                        {imageUrl ? (
                            <img
                            src={imageUrl}
                            alt="avatar"
                            style={{
                                width: '100%',
                            }}
                            />
                        ) : (
                            uploadButton
                        )}
                    </Upload> */}

                    {/* <div className="button-border-top">
                        <button id="submit" className="btn btn-primary form-buttons" type="submit">
                            Save
                        </button>
                    </div> */}


                </form>
            </div>
            </Modal>
        }

        </>


    )

}

export default ContactNew;
