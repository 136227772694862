import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuildingUser, faChartLine, faUsers, faCalendarCheck, faBarcode, faAddressCard, faCartShopping  } from '@fortawesome/free-solid-svg-icons';
{/* <FontAwesomeIcon icon="fa-solid fa-calendar-circle-user" /> */}


function Features() {
    return (
        <section className="features">

            <div className="features-card" >
                <div className="features-card-body">
                    <FontAwesomeIcon className="features-icon" icon={faUsers} />
                    <div className="features-card-title">Customers</div>
                    <p>Our technology empowers you to serve and manage more customers from one central hub, all while helping you to build deeper relationships. </p>
                </div>
            </div>

            <div className="features-card" >
                <div className="features-card-body">
                    <FontAwesomeIcon className="features-icon" icon={faChartLine} />
                    <div className="features-card-title">Finance</div>
                    <p>Our best in class accounting solution handles invoicing, receipting, double-entry book keeping, automated journal entries, debtors and creditors. </p>
                </div>
            </div>

            <div className="features-card" >
                <div className="features-card-body">
                    {/* <FontAwesomeIcon className="features-icon" icon={faCalendarCheck} /> */}
                    <FontAwesomeIcon className="features-icon" icon={faBarcode} />

                    <div className="features-card-title">Inventory</div>
                    {/* <p className="card-text">With bookings coming in from so many different channels, it can be hard keeping track of them all. We help you to manage all bookings from a single, smart dashboard.</p> */}
                    <p className="card-text">Real-time monitoring of stock levels, tracking of inventory movement, and the ability to manage replenishment. This module also provides inventory reporting and analysis, as well as the ability to set reorder levels.</p>

                    
                </div>
            </div>

            <div className="features-card" >
                <div className="features-card-body">
                    <FontAwesomeIcon className="features-icon" icon={faCartShopping} />
                    <div className="features-card-title">Sales</div>
                    <p className="card-text">Our platform is designed to streamline the order fulfillment process, providing merchants with the tools they need to efficiently process, ship, track, and manage orders.</p>

                </div>
            </div>
            
            {/* <div className="features-card" >
                <div className="features-card-body">
                    <FontAwesomeIcon className="features-icon" icon={faAddressCard} />
                    <div className="features-card-title">Human Resources</div>
                    <p className="card-text">We simplify team management so you can hire faster, streamline people processes, manage compensation, and improve performance, always. </p>

                </div>
            </div> */}

            {/* <div className="features-card" >
                <div className="features-card-body">
                    <FontAwesomeIcon className="features-icon" icon={faCalendarCheck} />
                    <div className="features-card-title">Booking Engine</div>
                    <p className="card-text">With bookings coming in from so many different channels, it can be hard keeping track of them all. We help you to manage all bookings from a single, smart dashboard.</p>
                </div>
            </div>
            
            <div className="features-card" >
                <div className="features-card-body">
                    <FontAwesomeIcon className="features-icon" icon={faBuildingUser} />
                    <div className="features-card-title">Property Management</div>
                    <p className="card-text">Whether you manage a single or multiple properties, grow and scale your business with our technology that make your life easier. </p>
                </div>
            </div> */}
            
       
        </section>
    ) 
}

export default Features