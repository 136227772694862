import { useRef, useEffect } from "react";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Cta from "../../components/Cta";

// import hero from '../../assets/crm-hero.svg'
import hero from '../../assets/crm-hero.png'

// import './Crm.css'
import './Products.css'

const Crm = () => {
    const scrollRef = useRef(null);

    useEffect(()=>{
        scrollRef.current.scrollIntoView();  
    },[])

    return (
        <div className="container-fluid">

            {/* <div className="crm-container"> */}

            <div className="product-container">
                <div ref={scrollRef}>
                    <Header />
                </div>

                {/* <div className="hero"> */}
                <div className="hero hero-bg-yellow">
                    <div className="hero-text-container">

                        <div className="hero-sub-title">
                            Customer Relationship Management
                        </div>
                        <div className="hero-title">
                            happy customers.
                        </div>
                        <div className="hero-text">
                            A single view of your prospects and customers that gives sales, marketing and service teams the real-time data they need to deliver exceptional customer experiences and growth.
                        </div>
                    </div>
                    <div className="hero-img-container">
                        {/* <div className="hero-img"> */}
                            <img src={hero} alt="hero"/> 
                        {/* </div> */}

                    </div>

                </div>

                <div className="features">
                    <div className="features-card">
                        <div className="feature-bullet">

                        </div>
                        <div className="features-card-title">
                            Sales Force Automation
                        </div>
                        <div className="features-card-text">
                            {/* a book or other written or printed work, regarded in terms of its content rather than its physical form. */}
                            Our CRM is designed to streamline and automate routine sales tasks allowing your teams to focus on strategic and high-value activities.
                        </div>

                    </div>

                    <div className="features-card">
                        <div className="feature-bullet">

                        </div>
                        <div className="features-card-title">
                            Contact Management
                        </div>
                        <div className="features-card-text">
                            {/* a book or other written or printed work, regarded in terms of its content rather than its physical form. */}
                            {/* Store and organize all customer and lead information (names, emails, phone numbers, interactions, etc.) in one place. */}
                            Seamlessly organize detailed information about your prospects and customers including names, contact details, and interactions.
                        </div>

                    </div>

                    <div className="features-card">
                        <div className="feature-bullet">

                        </div>
                        <div className="features-card-title">
                            Opportunity Management
                        </div>
                        <div className="features-card-text">
                            {/* a book or other written or printed work, regarded in terms of its content rather than its physical form. */}
                            A dynamic and centralized platform to systematically track and win opportunities throughout the entire sales lifecycle.
                        </div>

                    </div>

                    <div className="features-card">
                        <div className="feature-bullet">

                        </div>
                        <div className="features-card-title">
                            Customer Experience
                        </div>
                        <div className="features-card-text">
                            {/* a book or other written or printed work, regarded in terms of its content rather than its physical form. */}
                            Give teams that interacts with customers — sales, call centre and service — access to updated, real-time data.
                        </div>

                    </div>

                    <div className="features-card">
                        <div className="feature-bullet">

                        </div>
                        <div className="features-card-title">
                            Integrations
                        </div>
                        <div className="features-card-text">
                            {/* a book or other written or printed work, regarded in terms of its content rather than its physical form. */}
                            {/* Seamless connectivity with other modules like inventory, orders, finance will give you a 360-degree view of your customers and operations. */}
                            Get a 360-degree view of your customers through a seamless connectivity of the CRM with other business applications and tools.
                        </div>

                    </div>

                    <div className="features-card">
                        <div className="feature-bullet">

                        </div>
                        <div className="features-card-title">
                            Analytics
                        </div>
                        <div className="features-card-text">
                            {/* a book or other written or printed work, regarded in terms of its content rather than its physical form. */}
                            Analytics tools empower you to measure key metrics, identify trends, forecast future sales, and make informed strategic decisions.
                        </div>

                    </div>
                    

                

                    

                    <div className="features-card">
                        <div className="feature-bullet">

                        </div>
                        <div className="features-card-title">
                            Mobile 
                        </div>
                        <div className="features-card-text">
                            {/* a book or other written or printed work, regarded in terms of its content rather than its physical form. */}
                            Our responsive design enables your teams to access and update customer information from anywhere using mobile devices.
                        </div>

                    </div>
                    <div className="features-card">
                        <div className="feature-bullet">

                        </div>
                        <div className="features-card-title">
                            Customization
                        </div>
                        <div className="features-card-text">
                            {/* a book or other written or printed work, regarded in terms of its content rather than its physical form. */}
                            Every business is unique, and your CRM should reflect that. During implementation we mold your CRM to fit your business.
                        </div>

                    </div>

                </div>

                <Cta />

                <Footer />        
            </div>
        </div>



    )

}


export default Crm;