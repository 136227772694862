import { useState, useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import authAxios from "../../components/authAxios";
import { Link, useNavigate } from "react-router-dom";


import Pagination from '@mui/material/Pagination';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import DashboardHeader from "../../components/DashboardHeader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';

// import { Tabs } from 'antd';
import { Tabs } from "antd";

import { format } from 'date-fns';

import Pillchip from "../../components/Pillchip";
import { UserContext } from "../../App";


const Quotes = () => {
    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();
    const { clientProfile } = useContext(UserContext);
    const { TabPane } = Tabs;


    const { data: quotes } = useQuery({
        queryKey: ['quotes', page],
        queryFn: async () => {
          const response = await authAxios.get(`/crm/quotes/?page=${page}`);
          setPageCount(response.data.page_count)
          return response.data.results
        },
    });

    const convertDateTime = (myDateTime) => {
        return format(new Date(myDateTime), 'dd-MM-yyyy HH:mm');
    };

    const formatReference = (reference) => {
        return `${reference.slice(0, 3)}-${reference.slice(3, 9)}-${reference.slice(9)}`;
    };

    //Pagination
     const handlePageChange = (event, value) => {
        setPage(value);
    };

     const threeSubMenu = [
        {
            id: 1,
            menu: "View",
            url: "/customeredit/",
        }, 
    ];

    const navigate = useNavigate();

    const onChange = (key) => {
        // console.log(key);
      };

    const items = [
        {
          key: '1',
          label: 'Tab 1',
          children: 'Content of Tab Pane 1',
        },
        {
          key: '2',
          label: 'Tab 2',
          children: 'Content of Tab Pane 2',
        },
        {
          key: '3',
          label: 'Tab 3',
          children: 'Content of Tab Pane 3',
        },
      ];

    return (
           


            <div className="dashboard-main">
                {/* <Tabs defaultActiveKey="1" items={items} onChange={onChange} />  */}
                
               
                <section className="dashboard-content">

                    
                    <DashboardHeader dashboardTitle="Quotations" dashboardPreTitle="Sales" removeBorder={true}/>
                    <Tabs defaultActiveKey="1" onChange={onChange}>
                    <TabPane tab="All" key="1">

                    <table className="styled-table">
                        <thead>
                            <tr >
                                <th>Reference</th>
                                <th>Date</th>
                                <th>Customer</th>
                                <th>Value</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>

                        {quotes && quotes.map(quote => (

                            <tbody key={quote.id}>
                                <tr>
                                    <td>{formatReference(quote.reference)}</td>
                                    <td>{convertDateTime(quote.created_at)}</td>
                                    <td>{quote.contact_name}</td>
                                    {clientProfile && clientProfile.currency_code? 
                                        <td>{Number(quote.amount).toLocaleString('en-UK', { style: 'currency', currency: clientProfile.currency_code })}</td>
                                        :
                                        <td>{Number(quote.amount).toLocaleString('en-UK')}</td>
                                    }

                                    <td><Pillchip label={quote.status_name} /></td>
                                    <td className='styled-table-menu'><FontAwesomeIcon icon={faEllipsisVertical} /></td>

                                </tr>
                            </tbody>
                        ))}
                        

                    </table> 
                    

                    <Pagination 
                        className="custom-mui-pagination"
                        count={pagecount} 
                        page={page} 
                        onChange={handlePageChange} 
                    />
                    </TabPane>
                    <TabPane tab="Won" key="2">
                    </TabPane >
                    <TabPane tab="Lost" key="3">
                    </TabPane>
                    </Tabs>

                    <Link to="/dashboard/quote-new" className="nav-link">
                        <Fab className="fab" color="primary" aria-label="add">
                            <AddIcon />
                        </Fab>
                    </Link> 

                </section>
            </div>

    )
}
export default Quotes