import React from "react";
import { useState } from "react";


import { useNavigate, useParams } from "react-router-dom";

// import Sidebar from "./Sidebar"
// import Pagination from "./Pagination";
import Pagination from '@mui/material/Pagination';

import Pillchip from "./Pillchip";
// import DashboardHeader from "./DashboardHeader";

import { Card, CardContent } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

import { useMediaQuery } from 'react-responsive'
import ThreeDotsMenu from "./ThreeDotsMenu";
import BookingNew from "./BookingNew";
// import Layout from "./Layout";

const DealList =({deals, property, module, handlePageChange, page, pagecount}) => {
    const isMobile = useMediaQuery({ query: '(max-width: 750px)' })
    const navigate = useNavigate()
    const params = useParams()

    const [isDrawerOpen, setIsDrawerOpen] = useState(false)

    const handleDrawerOpen = () => {
        setIsDrawerOpen(true)
    };
  
    const handleDrawerClose = () => {
        setIsDrawerOpen(false)
    };

    // console.log("DEALS 2", deals)

    const threeSubMenu = [
        {
            id: 1,
            menu: "Check-in",
            url: "/checkin/",
        }
  
    ];

    //Pagination
    // const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(10);

     // Get current posts
    //  const indexOfLastPost = currentPage * postsPerPage;
    //  const indexOfFirstPost = indexOfLastPost - postsPerPage;
    //  const currentPosts = deals.slice(indexOfFirstPost, indexOfLastPost);
 
     // Change page
    const paginate = pageNumber => setCurrentPage(pageNumber);

    return (
        <>

            {isMobile? 
                <>
                
                {deals && deals.map(deal => ( 

                <Card className="dashboard-card" key={deal.id}>
                    
                    <CardContent className="dashboard-card-content">
                        <div className="dashboard-card-title">{deal.reference}</div>
                        <div className="dashboard-card-subtitle">{deal.contact}</div>                            
                        <p>Start: {deal.start}</p>
                        <p>End: {deal.end}</p>
                        <Pillchip label={deal.status_name} />
                    </CardContent>
                    {/* <CardActions>
                        <Button size="small">Booking</Button>
                    </CardActions> */}
                </Card>
                ))}

                </>

                :
                // If Device is not mobile, display data using a table
                <>

                <table className="styled-table">
                    <thead>
                        <tr >
                            <th>No</th>
                            <th>Date</th>
                            <th>Customer</th>
                            <th>Status</th>
                            <th>Check-in</th>
                            <th>Check-out</th>
                            <th>Value</th>
                            <th></th>
                        </tr>
                    </thead>

                    {deals && deals.map(deal => (

                        <tbody key={deal.id}>
                            <tr>
                                <td>{deal.id}</td>
                                <td>{deal.date}</td>
                                <td>{deal.contact}</td>
                                <td>
                                    <Pillchip label={deal.status_name} />
                                </td>
                                <td>{deal.checkin}</td>
                                <td>{deal.checkout}</td>
                                <td>{Number(deal.amount).toLocaleString('en-UK', { style: 'currency', currency: 'KES' })}</td>


                                {/* <td className='styled-table-menu'>
                                    <ThreeDotsMenu data={deal} threeSubMenu={threeSubMenu} />
                                </td> */}
                            </tr>
                        </tbody>
                    ))}
                    

                </table>
                </>
            }

            
            <Pagination 
            className="custom-mui-pagination"
            // color="primary" 
            count={pagecount} 
            page={page} 
            onChange={handlePageChange} 
        />

            { module === "PM" ?
            <Fab 
                className="fab" 
                color="primary" 
                aria-label="add"
                onClick={()=>{
                    // navigate('/bookingnew/',{state:{unit:params.id}})
                    navigate('/bookingnew/',{state:{property:property, unit:params.id}})
                    // handleDrawerOpen()

                }}
            >

                <AddIcon />
            </Fab>
            :
            null
            }
           

        </>

    )
}
export default DealList