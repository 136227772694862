import { useState, useContext } from 'react';
import axios from 'axios';
import authAxios from '../components/authAxios';
// import useAxiosInterceptor from '../components/useAxiosInterceptor';
import jwt from 'jwt-decode';

import { useLocation, useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import { TextField } from '@mui/material';
import { FormHelperText } from '@mui/material';

import UserAlerts from '../components/UserAlerts';
import logo from '../assets/zidi-logo.svg'

import { UserContext } from '../App';


const fetchClientProfile = async () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  };
  const response = await authAxios.get(`/clientprofiles/${localStorage.getItem('clientProfile')}`, {headers});
  return response.data;
};


function Login() {
  // const { setClientProfile } = useUserContext();
  const {setClientProfile} = useContext(UserContext);

    
  const navigate = useNavigate();
  const location = useLocation();
  // const from = location.state?.from?.pathname || "/dashboard";
  const from = location.state?.from?.pathname || "/dashboard/stats";

  // const queryClient = useQueryClient();

  const [error, setError] = useState('');


  // function loginUser() {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  // const [token, setToken] = useState([])
  const [token, setToken] = useState()
  const [emailError, setEmailError] = useState(false)
  const [passwordError, setPasswordError] = useState(false)

  const handleSubmit = async (e) => {
    
    e.preventDefault();

    setEmailError(false)
    setPasswordError(false)

    if (!email) {
      setEmailError(true)
    }
    if (!password) {
      setPasswordError(true)
    }

    if (email && password) {
      const credentials = { email, password };
        axios.post('/token/', credentials )
        .then(res => {
            setToken(res.data.access);
            localStorage.setItem('accessToken', res.data.access);
            localStorage.setItem('refreshToken', res.data.refresh);
            console.log("TOKEN", token);
            console.log("STORAGE TOKEN", localStorage.getItem('accessToken'));

            //Decode JWT token
            const tkn = res.data.access;
            const acctkn = tkn.replace('Bearer ','');
            const decodeToken = jwt(acctkn); // decode your token here
            console.log("DECODE TOKEN", decodeToken);
            localStorage.setItem('userID', decodeToken.user_id);
            localStorage.setItem('clientID', decodeToken.client_id);
            localStorage.setItem('clientProfile', decodeToken.client_profile);
         
            navigate(from, { replace: true });
        })
        .catch(function (error) {
          console.log("WE ARE HERE ERROR",error)
          if (error.response.status === 400 ){
            setError(error.message + " - " + error.response.request.response);
            }
            else if (error.response.status === 401){
            setError(error.response.status +" " + error.response.statusText + " - " + error.response.data.detail);
            }
            else {
            setError(error.response.status + " " + error.response.statusText);
            }
            console.log("error", error);        
          });


          // SET CONTEXT FOR CLIENT PROFILE
          const clientProfile = await fetchClientProfile();
          setClientProfile(clientProfile);          
      }
    }

  return(
    <div className="container-fluid">
        <div className="auth-form">
          <UserAlerts error={error} />

          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <div className='auth-form-brand'>
              <img src={logo} alt="brand logo"/>  
            </div>

            <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={e => setEmail(e.target.value)}
                error={emailError}
              />

            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={e => setPassword(e.target.value)}
              error={passwordError}
            />
            <FormHelperText id="component-helper-text">
                <Link to="/forgot">
                    Forgot password?
                </Link>
            </FormHelperText>
            <div className='auth-form-button'>
              <button className='btn btn-primary' type="submit">Login</button>
            </div>
          </form>
        </div>
      </div>

  )
}

export default Login