import React from "react";
import { useState, useEffect } from "react";
// import axios from "axios";
import useAxios from "../../components/useAxios";
// import authAxios from "./authAxios";

import { Link } from "react-router-dom";
// import { useNavigate } from "react-router-dom";


// import Pagination from "./Pagination";
import Pagination from '@mui/material/Pagination';


// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import DashboardHeader from "../../components/DashboardHeader";
import { useMediaQuery } from 'react-responsive'
import { Card, CardContent } from "@mui/material";


import ThreeDotsMenu from "../../components/ThreeDotsMenu";


function ExpenseTypes() {
    const isMobile = useMediaQuery({ query: '(max-width: 750px)' })
    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();
    // const { response } = useAxiosInterceptor();

    const abortController = new AbortController()

    const { data, fetchData}  = useAxios();   
    const { data:expenseTypeData, fetchData:fetchExpenseTypeData}  = useAxios();   


    // FETCH EXPENSE TYPES 
    const [expenseTypes, setExpenseTypes] = useState([]);
    const fetchExpenseTypes = () => {
        fetchExpenseTypeData({
            url: `/hrm/expense-types/?page=${page}`,
            method: 'get',
          })
    }

    const handlePageChange = (event, value) => {
        setPage(value);
    };

     const threeSubMenu = [
        {
            id: 1,
            menu: "View",
            url: "/dashboard/customer/",

        }, 
        // {
        //     id: 2,
        //     menu: "Edit",
        //     url: "/dashboard/customeredit/",
        // }
    ];

    useEffect (()=>{
        fetchExpenseTypes()
        console.log("WE ARE HERE",expenseTypeData)

    },[]) //IMPORTANT: RUN API CALL ONLY ONCE!

    useEffect (() => {
        if (expenseTypeData.count){
            console.log("DATA",expenseTypeData)
            setPageCount(expenseTypeData.page_count)
            setExpenseTypes(Array.from(expenseTypeData.results))
        }
    },[ expenseTypeData.count])
    

    return (
            <div className="dashboard-main">
                <section className="dashboard-content">
                    <DashboardHeader dashboardTitle="Expense Types" dashboardPreTitle="HRM" />

                    {isMobile? 
                    <>
                    
                    {expenseTypes && expenseTypes.map(expenseType => ( 

                    <Card className="dashboard-card" key={expenseType.id}>
                        
                        <CardContent className="dashboard-card-content">
                            <div className="dashboard-card-title">{expenseType.name}</div>
                            <div className="dashboard-card-subtitle">{expenseType.telephone}</div>                            
                            <p>{expenseType.address}</p>
                            <p>{expenseType.email}</p>
                        </CardContent>
                        {/* <CardActions>
                            <Button size="small">Booking</Button>
                        </CardActions> */}
                    </Card>
                    ))}

                    </>

                    :
                    // If Device is not mobile, display data using a table
                    <>
                    <table className="styled-table">
                        <thead>
                            <tr >
                                <th>ID</th>
                                {/* <th>Code</th> */}
                                <th>Name</th>
                                <th>Account</th>
                                {/* <th>Address</th> */}
                                <th></th>
                            </tr>
                        </thead>

                        {expenseTypes && expenseTypes.map(expenseType => (

                            <tbody key={expenseType.id}>
                                <tr>
                                    <td>{expenseType.id}</td>
                                    {/* <td>{expenseType.code}</td> */}
                                    <td>{expenseType.name}</td>
                                    <td>{expenseType.account}</td>
                                    {/* <td>{expenseType.address}</td> */}
                                    <td className='styled-table-menu'>
                                        <ThreeDotsMenu data={expenseType} threeSubMenu={threeSubMenu} />
                                    </td>
                                </tr>
                            </tbody>
                        ))}
                        

                    </table>
                    </>

                    }

                    <Pagination 
                        className="custom-mui-pagination"
                        count={pagecount} 
                        page={page} 
                        onChange={handlePageChange} 
                    />

                    <Link to="/dashboard/expense-type-new" className="nav-link">
                        <Fab className="fab" color="primary" aria-label="add">
                            <AddIcon />
                        </Fab>
                    </Link>
                </section>
            </div>

        // </Layout>


    )
}
export default ExpenseTypes