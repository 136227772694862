import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import authAxios from '../../components/authAxios';


import { useQuery, useMutation, useQueryClient} from '@tanstack/react-query';


import { Result } from 'antd';
import {IconButton, Button} from "@mui/material";
// import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';


const EbayAccepted = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [error, setError] = useState('');

    const client = localStorage.getItem('clientProfile');
    const queryClient = useQueryClient();

    const { data: ebayConfig } = useQuery({
        queryKey: ['ebayConfig'],
        queryFn: async () => {
        //   const response = await authAxios.get(`/clientprofiles/${localStorage.getItem('clientProfile')}`);
          const response = await authAxios.get("/ebay/ebay-config/");

        //   return response.data
          return response.data.find(config=>config.client=client)
        },
    });

    const mutation = useMutation({
        mutationFn: (ebayConfig) => {
            // return authAxios.post("/ebay/ebay-config/", ebayConfig);
            return authAxios.put(`/ebay/ebay-config/${ebayConfig.id}/`, ebayConfig);
        },
        onError: (error, variables, context) => {
            if (error.response.data.error){
                // If it's an array, display each error message
                if (Array.isArray(error.response.data.error)) {
                    error.response.data.error.map((errorMessage) => (
                        setError(errorMessage)
                    ));
                } else {
                    // Handle single error message
                    setError(error.response.data.error); 
                }
            } else {
                // Fallback to Standard error message
                setError(error.message); 
            }      
        },
        // onSuccess: (result) => {
        //     queryClient.invalidateQueries(['orders']);
        //     navigate('/dashboard/orders/')
        // },
    })


    useEffect(() => {
        // Extract the authorization code from the URL parameters
        const searchParams = new URLSearchParams(location.search);
        const authorizationCode = searchParams.get('code');
    
        // Make an API call to Django backend to exchange the authorization code for an access token
        axios.post('/ebay/token/', { code: authorizationCode, client: client })
            .then(response => {
                localStorage.setItem('ebayAccessToken', response.data.access_token);
                localStorage.setItem("ebayRefreshToken", response.data.refresh_token);
                // Do something else with the response

                const refresh_token = response.data.refresh_token

                const ebayConfig = { client, refresh_token };

                console.log(ebayConfig)
                mutation.mutate(ebayConfig)

                console.log("RESPONSE", response)
                console.log("DATA", response.data)
                console.log("STORAGE", localStorage)
                })
            .catch(error => {
                console.error(error);
                });
      }, [location.search]);
    
      


    return (
        <div className="dashboard-main">
            <Result
                status="success"
                title="eBay Authorisation Successfu!"
                subTitle="You can now access, update and sync your eBay data from this application."
                extra={[
                        // <Button type="primary" key="console">
                        //     Go Console
                        // </Button>,
                        // <Button key="Print">Print Invoice</Button>,
                        <Button
                            key="one"
                            variant="outlined" 
                            size="small"
                            // startIcon={<DownloadForOfflineIcon />}
                            onClick={()=>{
                                navigate('/dashboard/ebay-account-transactions');
                            }}
                        >
                            Dashboard
                        </Button>,
                        
                ]}
            />

        </div>
    )
}

export default EbayAccepted;