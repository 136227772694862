import React from 'react';
import { TextField } from "@mui/material";

// import { Timeline } from 'antd';
import { Timeline } from "antd";

import '../../styles/OrderView.css'

const OrderView = ({order}) =>{

    return(
        <section className="dashboard-order">


        <section className="dashboard-form">
            <form noValidate autoComplete="off" >
                <div className="grouped-fields-light">
                    <TextField className="custom-input"
                        label="Reference"
                        variant="outlined" 
                        fullWidth
                        // value = {transaction.reference}
                        defaultValue = {order.reference}
                        InputProps={{ readOnly: true }}
                    />

                    <TextField className="custom-input"
                        label="Date"
                        variant="outlined" 
                        fullWidth
                        defaultValue = {order.order_date}
                        InputProps={{ readOnly: true }}
                    />
                </div>
            </form>
        </section>

        <div className="order-timeline">
            {/* <Timeline pending="Delivery"> */}

                <Timeline pending={order.next_status_name}>
                    {order.workflow.map(workflow => (
                        <Timeline.Item color='green' key={workflow.id}>{workflow.status_name}</Timeline.Item>
                    ))}
                </Timeline>
        </div>

        </section>

    )


}

export default OrderView