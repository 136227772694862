import React from "react";
import { useState, useEffect } from "react";
// import axios from "axios";
import useAxios from "../../components/useAxios";
// import useAxiosInterceptor from "./useAxiosInterceptor";

// import { Link } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
// import Fab from '@mui/material/Fab';
// import AddIcon from '@mui/icons-material/Add';
// import Sidebar from "./Sidebar"
// import Pagination from "./Pagination";
// import { Card, CardContent } from "@mui/material";


import DashboardHeader from "../../components/DashboardHeader";
// import { useMediaQuery } from 'react-responsive'
import Layout from "../../components/Layout";
import LeadList from "./LeadList";

function Leads() {
    // const isMobile = useMediaQuery({ query: '(max-width: 750px)' })

    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();

    //Axios
    const { data, fetchData}  = useAxios();   

    // FETCH LEADS
    // const { data: leads, loading } = useAxios({
    //     url: '/leads',
    //     method: 'get'
    // })
    const [leads, setLeads] = useState([]);


    async function fetchLeads () {
        try {
            fetchData({
                url: `/leads/?page=${page}`,
                method: 'get'
            })
            .then(()=>{
                // console.log("DATA",data)
                // console.log("RESPONSE",response)
                setLeads(data.results);
                setPageCount(data.page_count)
            })

        } catch (error) {
            console.error(error);
        }
    }

    // PAGINATION
   
    const handlePageChange = (event, value) => {
        setPage(value);
        console.log("PAGE", page)
        // fetchTransactions(page)
    };

    useEffect(()=>{
        fetchLeads()
        
    },[page, data.count])


    return (

        // <Layout>
            <div className="dashboard-main">

                <section className="dashboard-content">
                    
                    <DashboardHeader dashboardTitle="Leads" dashboardPreTitle="CRM" />

                    {leads &&
                    <LeadList 
                        module="CMS" 
                        leads={leads} 
                        fetchLeads={fetchLeads} 
                        handlePageChange={handlePageChange} 
                        page={page} 
                        pagecount={pagecount}
                    />
                    }

                </section>
            </div>
        // </Layout>

    )
}

export default Leads