import React, { useEffect, useCallback } from "react"
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import useAxios from "../../components/useAxios";
// import authAxios from "../../components/authAxios";

// import { Pagination } from "@mui/material";

import DashboardHeader from "../../components/DashboardHeader";
import TransactionList from "./TransactionList";
import AccountEdit from "./AccountEdit";
import Layout from "../../components/Layout";

//TABS - BOOTSTRAP
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import Loading from "../../components/Loading";



const Account = () => {
    const { loading, data, fetchData}  = useAxios();
    const { data:transactionData, fetchData:fetchTransactionData}  = useAxios();   
    const { data:clientData, fetchData:fetchClientData}  = useAxios();   

    const abortController = new AbortController()

    const params = useParams()
    const navigate = useNavigate()

    // FETCH CUSTOMER
    const { data: account } = useAxios({
        url: `/accounting/accounts/${params.id}`,
        method: 'get',
    })  

    const [clientProfile, setClientProfile] = useState([]);
    const fetchClient = async () =>{
        await fetchClientData({
            url: `/clientprofiles/${localStorage.getItem('clientID')}`,
            method: 'get',
          })
    }

    // const { data: clientProfile } = useAxios({
    //     url: `/clientprofiles/${localStorage.getItem('clientID')}`,
    //     method: 'get',
    // })  

    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();

    const [transactions, setTransactions] = useState([]);
    const fetchTransactions = () => {
        try{
            fetchTransactionData({
                url: `/accounting/account-statement/?account=${params.id}&page=${page}`,
                // url: `/accounting/account-statement/?account=${params.id}`,
                method: 'get',
                signal: abortController.signal
                })
            }
        catch (error) {
            if (error.name === 'AbortError') {
                console.log('Request was aborted:', error.message);
            } else {
                throw error; 
            }
            }
    }

    // const fetchTransactions = useCallback(async () => {
    //     try{
    //         setLoading(true);
    //         const response = await authAxios.get(`/accounting/account-statement/?account=${params.id}&page=${page}`)
    //         setTransactions(Array.from(response.data.results))
    //         setPageCount(response.data.page_count)
    //     } 
    //     catch(error) {
    //         console.log("AXIOS ERROR",error)
    //         setError( error.message);
    //     } 
    //     finally {
    //         setLoading(false);
    //     }
    // })

    let sumDebits = 0
    let sumCredits = 0
    let balance = 0
        
    const calcStats = () => {
        try {

            for(let i= 0; i < transactions.length; i++){
                // if (transactions[i].type===invoice.id){
                if (transactions[i].entry_type==="D"){
                    sumDebits = sumDebits +  parseFloat(transactions[i].amount)
                }
                if (transactions[i].entry_type==="C"){
                    sumCredits = sumCredits +  parseFloat(transactions[i].amount)
                }
            }
            balance = sumDebits - sumCredits
        } catch (error) {
            console.log(error);
        }

    }
    calcStats()

    //Bootstrap Tabs
    const [key, setKey] = useState('profile');
    const [removeBorder, setRemoveBorder] = useState(true);


    const [cancelled, setCancelled] = useState(false);


    // PAGINATION
    const handlePageChange = (event, value) => {
        // setCancelled(true)
        // abortController.abort();
        setPage(value);
    };

    
    //MAKE API CALL WITH EVERY PAGE CHANGE
    useEffect(()=>{
        fetchTransactions()
    },[page])

    //SEPARATED TO AVOID FETCHING WITH EVERY PAGE CHANGE
    useEffect(()=>{
        fetchClient()
    },[]) 


    useEffect(()=>{
        if (transactionData.page ){
            setTransactions(Array.from(transactionData.results))
            setPageCount(transactionData.page_count)
        }

        return () => {
            abortController.abort();
        };
    },[transactionData.page])


    useEffect(()=>{
        if (clientData.id ){
            setClientProfile(clientData)
        }
    },[clientData.id])


    // console.log(clientData)


    // abortController.addEventListener('abort', () => {
    //     console.log('Fetch request aborted');
    //   });

      if (abortController.signal.aborted) {
        console.log(`Request aborted for URL: ${abortController.url}`);
      }

    // console.log("DATA", transactionData)

    // useEffect(()=>{
    //     // let canceled = false;
    //     // setCancelled(false)
    //     fetchTransactions()

    //     if (transactionData.page ){
    //         // if (!cancelled) {
    //             setTransactions(Array.from(transactionData.results))
    //             setPageCount(transactionData.page_count)
    //         // }
    //     }

    //     return () => {
    //         // canceled = true
    //         // setCancelled(true)
    //         abortController.abort();
    //         // console.log("ABORTING", abortController)
    //         // console.log("CANCEL", cancelled)

    //     };
    // },[page, transactionData.page])




    return (
        // <Layout>
            <div className="dashboard-main">
                <section className="dashboard-content">

                    <DashboardHeader dashboardTitle={account.name} dashboardPreTitle="Account" removeBorder={removeBorder} />
                    <Tabs
                        // id="dashboard-form-tabs"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="dashboard-tabs"
                        >
                        <Tab eventKey="profile" title="Profile">
                            <AccountEdit />

                        </Tab>

                        <Tab eventKey="ledger" title="Ledger">
                            <div className="dashboard-content-subheader">
                                <div className='dashboard-card'>
                                    <div className="dashboard-card-pretitle">
                                        Debits
                                    </div>
                                    <div className="dashboard-card-text">
                                        {/* KES {sumDebits.toLocaleString()} */}
                                        {clientProfile.currency_code} {sumDebits.toLocaleString()}

                                    </div>
                                </div>
                                <div className='dashboard-card'>
                                    <div className="dashboard-card-pretitle">
                                        Credits
                                    </div>
                                    <div className="dashboard-card-text">
                                        {/* KES {sumCredits.toLocaleString()} */}
                                        {clientProfile.currency_code} {sumCredits.toLocaleString()}

                                    </div>
                                </div>
                                <div className='dashboard-card'>
                                    <div className="dashboard-card-pretitle">
                                        Balance
                                    </div>
                                    <div className="dashboard-card-text">
                                        {/* KES {balance.toLocaleString()} */}
                                        {clientProfile.currency_code} {balance.toLocaleString()}

                                    </div>
                                </div>

                            </div>
                            
                            <TransactionList 
                                module="COA" 
                                transactions={transactions} 
                                fetchTransactions={fetchTransactions} 
                                handlePageChange={handlePageChange} 
                                page={page} 
                                pagecount={pagecount}
                            />
                            
                        </Tab>
                    </Tabs>     

                </section>

            </div>

    );
  };
  
  export default Account;