// import useAxios from "../../components/useAxios";
// import useAxiosInterceptor from '../../components/useAxiosInterceptor';

import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

import { Autocomplete } from "@mui/material";

import { Radio } from "@mui/material";
import { RadioGroup } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import NumberFormat from "react-number-format";
import { TextField } from "@mui/material";

// import { Select } from "@mui/material";
// import { FormControl } from "@mui/material";
// import { MenuItem } from "@mui/material";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import moment from 'moment';

// IMAGE UPLOAD ANT DESIGN
import { message, Upload } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import authAxios from "../../components/authAxios";

// import Sidebar from "./Sidebar";
import UserAlerts from "../../components/UserAlerts";
// import Layout from "../../components/Layout";
import DashboardHeader from "../../components/DashboardHeader";


function SupplierNew() {
    // const {response, error, fetchData}  = useAxios();
    const errorRef = useRef(null);
    const [error, setError] = useState('');
    const queryClient = useQueryClient();



    // useEffect(() => {
    //     if (response.status === 201){
    //         navigate('/dashboard/suppliers/');
    //     }    
    // }, [ response.status]); 


    // FETCH COUNTRIES
    // const { data: countries } = useAxios({
    //     url: '/countries',
    //     method: 'get'
    // })

    // const { data: contacts } = useAxios({
    //     url: '/contacts',
    //     method: 'get'
    // })

    const { data: countries } = useQuery({
        queryKey: ['countries'],
        queryFn: async () => {
          const response = await authAxios.get('/countries');
          return response.data
        },
    });

    const { data: contacts } = useQuery({
        queryKey: ['contacts'],
        queryFn: async () => {
          const response = await authAxios.get('/contacts');
          return response.data
        },
    });


    const navigate = useNavigate();


    // const [dt, setDt] = useState(new Date());
    const [dt, setDt] = useState(null);


    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [website, setWebsite] = useState('');
    const [id_type, setId_type] = useState('');
    const [id_number, setId_number] = useState('');
    const [id_copy, setIdCopy] = useState(null);
    const [id_files, setIdFiles] = useState([]);
    const [gender, setGender] = useState('');
    const [type, setType] = useState('10');
    const user = localStorage.getItem('userID');
    const client = localStorage.getItem('clientID');
    const [tag, setTag] = useState('SUPPLIER');

    const [nameError, setNameError] = useState(false)
    const [mobileError, setMobileError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [idNumberError, setIdNumberError] = useState(false)
    const [addressError, setAddressError] = useState(false);
    const [cityError, setCityError] = useState(false);

    const [isCompany, setIsCompany] = useState(false);
    const [isPerson, setIsPerson] = useState(false);


    const handleCustomerType = (e) => {
        setType(e.target.value)
        if (e.target.value==="10"){
            // console.log("TRUE")
            setIsPerson(true)
        }
        else {
            console.log("FALSE")
            setIsPerson(false)
        }
    }


    const handleIdNumber = (e) => {
        if (e.target.value.length>9){
            setIdNumberError(true)
        }
        else{
            setIdNumberError(false)
            setId_number(e.target.value)
        }
    }


    // IMAGE UPLOAD ANT DESIGN
    const [imageUrl, setImageUrl] = useState();
    const [loading, setLoading] = useState(false);
    // const [fileList, setFileList] = useState([]);
    var fileList = []


    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const beforeUpload = (file) => {
        // setFileList([...fileList, file]);


        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      
        if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
        }
      
        const isLt2M = file.size / 1024 / 1024 < 2;
      
        if (!isLt2M) {
          message.error('Image must smaller than 2MB!');
        }
      
        return isJpgOrPng && isLt2M;
      };
    
    // const handleUpload = (info) => {

    const handleUpload = (info) => {
        // console.log("UPLOAD 1",info.fileList[0].originFileObj)
        
        console.log("UPLOAD",info.fileList)
        console.log("ARRAY LENGTH 2",(info.fileList).length)
        setIdCopy(info.file)
        for(let i= 0; i < info.fileList.length; i++){
            fileList.push(info.fileList[i].originFileObj)
        }

        setIdFiles(fileList)

        if (info.file.status === 'uploading') {
          setLoading(true);
          return;
        }
    
        if (info.file.status === 'done') {
          // Get this url from response in real world.
          getBase64(info.file.originFileObj, (url) => {
            setLoading(false);
            setImageUrl(url);
          });
        }
    };

    const uploadButton = (
        <div>
          {loading ? <LoadingOutlined /> : <PlusOutlined />}
          <div 
            style={{
              marginTop: 8,
            }}
          >
            Upload
          </div>
        </div>
    );


    const mutation = useMutation({
        mutationFn: (supplier) => {
            // return authAxios.post("/contacts/", supplier);
            return authAxios.post('/contacts/', supplier, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
            });
        },
        onError: (error, variables, context) => {
            console.log("ERROR",error)

            if (error.response.data.error){
                // If it's an array, display each error message
                if (Array.isArray(error.response.data.error)) {
                    error.response.data.error.map((errorMessage) => (
                        setError(errorMessage)
                    ));
                } else {
                    // Handle single error message
                    setError(error.response.data.error); 
                }
            } else {
                // Fallback to Standard error message
                setError(error.message); 
            }      
        },
        onSuccess: (result) => {
            queryClient.invalidateQueries(['suppliers']);
            navigate('/dashboard/suppliers/');

        },
      })


    const handleSubmit = (e) => {
        e.preventDefault();

        // console.log(name, institution, email)

        if (name === '') {
            setNameError(true)
        }

        if (mobile === '' ) {
            setMobileError(true)
        }

        if (email === '' ) {
            setEmailError(true)
        }

        if (address === '' ) {
            setAddressError(true)
        }

        if (city === '' ) {
            setCityError(true)
        }

        if (name && mobile && email && address && city ) {
            const telephone = mobile.replace(/[()" "]/g,"")

            //Change the format of birth date before saving
            let birth_date = "";
            if (dt){
                birth_date = moment(dt).format("YYYY-MM-DD")
            }

            const supplier = { type, client, name, address, city, country, email, website, telephone, id_type, id_number, gender, birth_date, user, tag, id_files };
            console.log(supplier)
            mutation.mutate(supplier)


            // fetchData({
            //     url: '/contacts/',
            //     method: 'post',
            //     data: supplier,
            //     headers: {"Content-Type" : "multipart/form-data"} //REQUIRED FOR FILE UPLOADS
            //   })
            
        } 
    
    }

    useEffect(()=>{
        if (error){
            errorRef.current.scrollIntoView();  
        }            
    },[error])

    return(

    // <div className="dashboard-container">
    // <Layout>

        <div className="dashboard-main">
            <section className="dashboard-content">

            <section className="dashboard-form">
            {/* <section className="dashboard-form-light"> */}
                {/* <UserAlerts error={error} /> */}

                <div ref={errorRef}>
                    <UserAlerts error={error}  />
                </div>

                <DashboardHeader dashboardTitle="Add Supplier" dashboardPreTitle="Purchase" />

                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <div className="grouped-fields-light">

                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            defaultChecked=""
                            onChange={handleCustomerType}

                            // onChange={(e) => setType(e.target.value)}
                            // className="custom-input"
                        >
                            <FormControlLabel className="custom-radio" value="10" control={<Radio />} label="Individual" />
                            <FormControlLabel className="custom-radio" value="20" control={<Radio />} label="Company" />
                        </RadioGroup>

                        <TextField className="custom-input"
                            onChange={(e) => {
                                setNameError(false)
                                setName(e.target.value)
                            }}
                            label="Name" 
                            variant="outlined" 
                            fullWidth
                            required
                            error={nameError}
                        />
                    </div>
                    <div className="grouped-fields-light">

                        <NumberFormat 
                            onChange={(e) => {
                                setMobileError(false)
                                setMobile(e.target.value)
                            }}
                            className="custom-input"
                            fullWidth
                            label="Telephone" 
                            variant="outlined" 
                            customInput={TextField}
                            format="### (###) #######" mask="_"
                            required
                            error={mobileError}
                        />


                        <TextField className="custom-input"
                            onChange={(e) => {
                                setEmailError(false)
                                setEmail(e.target.value)
                            }}
                            label="Email" 
                            variant="outlined" 
                            fullWidth
                            required
                            error={emailError}
                        />

                        <TextField className="custom-input"
                            onChange={(e) => setWebsite(e.target.value)}
                            label="Website" 
                            variant="outlined" 
                            fullWidth
                            // required
                            // error={mobileError}
                        />  
                    </div>
                    <div className="grouped-fields-light">

                    <TextField className="custom-input"
                        onChange={(e) => {
                            setAddressError(false)
                            setAddress(e.target.value)
                        }}
                        label="Physical Address" 
                        variant="outlined" 
                        fullWidth
                        required
                        error={addressError}
                    />  

                    <TextField className="custom-input"
                        onChange={(e) => {
                            setCityError(false)
                            setCity(e.target.value)
                        }}
                        label="City/Town" 
                        variant="outlined" 
                        fullWidth
                        required
                        error={cityError}
                    />  

                    <Autocomplete
                        onChange={(event, newValue) => {
                            setCountry(newValue.id);
                            console.log("VALUE", newValue.id)
                            }}
                        options={countries}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField className="custom-input" fullWidth {...params} label="Country"  /> }
                    />

                    
                </div>

                {isPerson && 
                    <div className="grouped-fields-light">

                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        defaultChecked=""
                        onChange={(e) => setGender(e.target.value)}
                        // className="custom-input"
                    >
                        <FormControlLabel className="custom-radio" value="F" control={<Radio />} label="Female" />
                        <FormControlLabel className="custom-radio" value="M" control={<Radio />} label="Male" />
                    </RadioGroup>

                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DesktopDatePicker
                            label="Date of Birth"
                            value={dt}
                            onChange={(newDate) => {
                                setDt(moment(newDate).format("YYYY-MM-DD"));
                                }}
                            renderInput={(params) => <TextField fullWidth required className="custom-input" {...params} />}
                        />
                    </LocalizationProvider>
                    </div>
                    }
                    {/* <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        defaultChecked=""
                        onChange={(e) => setId_type(e.target.value)}
                        className="custom-input"
                    >
                        <FormControlLabel className="custom-radio" value="10" control={<Radio />} label="Citizen ID" />
                        <FormControlLabel className="custom-radio" value="20" control={<Radio />} label="Passport" />
                    </RadioGroup>

                    <TextField className="custom-input"
                        onChange={handleIdNumber}
                        label="ID/Passport Number" 
                        helperText="Maximum number of characters is 9" 
                        variant="outlined" 
                        fullWidth
                        required
                        error={idNumberError}
                    /> */}
                    

                    {/* <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader custom-upload-card custom-input"
                        beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
                        onChange={handleUpload}
                        maxCount={2}
                        >
                        {imageUrl ? (
                            <img
                            src={imageUrl}
                            alt="avatar"
                            style={{
                                width: '100%',
                            }}
                            />
                        ) : (
                            uploadButton
                        )}
                    </Upload> */}

                    <div className="button-border-top">
                        <button id="submit" className="btn btn-primary form-buttons" type="submit">
                            Save
                        </button>
                    </div>


                </form>

            </section>
            </section> 

        </div>

        // </Layout>
    )

}

export default SupplierNew;
